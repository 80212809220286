import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {DndProvider} from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import actionsData from 'data/actions-data-part2';
import {popupsData} from 'data/popups-data';
import {shuffleArray} from 'helpers/array-helper';
import {sortArrayByProperty} from 'helpers/array-helper';
import {generalUiTexts} from 'data/ui-texts';
import Actions from './actions';

class ActionsController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showAvatarInfo: false,
			draggingItemId: false,
			isMoving: false,
			isAnimating: false,
			animateDropActionId: null,
			initialActions: [],
			actions: [],
			selected: [],
			placeHolderActions: [],
			numberOfPlaceholders: 3
		};
		this.openIntroPopup = this.openIntroPopup.bind(this);
		this.closeIntroPopup = this.closeIntroPopup.bind(this);
		this.toggleAvatarInfo = this.toggleAvatarInfo.bind(this);
		this.handleDragStart = this.handleDragStart.bind(this);
		this.handleDragEnd = this.handleDragEnd.bind(this);
		this.evaluateActions = this.evaluateActions.bind(this);
		this.resetChallenge = this.resetChallenge.bind(this);
		this.timeout = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		/* Reset challenge */
		if (this.props.cachedProfile && this.props.cachedProfile.actions) {
			this.resetChallenge(this.props.cachedProfile.actions);
		} else {
			this.resetChallenge();
		}

		/* Open intro popup */
		if (this.props.introPopupStatuses.actionsIntroSeen !== true) {
			// this.openIntroPopup();
		}
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount() {
		if (this.timeout) clearTimeout(this.timeout);
	}

	/**
	 * Open intro popup
	 */
	openIntroPopup() {
		let popupData = JSON.parse(JSON.stringify(popupsData.startActions));
		let popupBtns = [{text: generalUiTexts.ok, action: this.closeIntroPopup, actionParams: []}];
		this.props.openPopup(
			null, popupData.texts, popupBtns, null, 
			{action: this.closeIntroPopup, actionParams: []}, false, 
			'introPopup', null, null, null, popupData.audio
		);
	}

	/**
	 * Close intro popup
	 */
	closeIntroPopup() {
		this.props.closePopup();
		if (this.props.introPopupStatuses.actionsIntroSeen !== true) {
			this.props.updateIntroPopupStatus('actionsIntroSeen', true);
		}
	}

	/**
	 * Hide / show avatar info
	 * @param {bool} showAvatarInfo 
	 */
	toggleAvatarInfo(showAvatarInfo) {
		this.setState({showAvatarInfo: showAvatarInfo});
	}

	/**
	 * User started dragging an action
	 */
	handleDragStart(id) {
		this.setState({draggingItemId: id});
	}

	/**
	 * Update state with result of drag and drop
	 * @param {object} result 
	 */
	handleDragEnd(actions, selected) {
		let itemId = this.state.draggingItemId;
		if (this.state.isMoving) return;
		this.setState({isMoving: true}, () => {
			let newActions = JSON.parse(JSON.stringify(actions));
			let newSelected = JSON.parse(JSON.stringify(selected));
			newSelected = sortArrayByProperty(newSelected, 'index', 'ASC');
	
			/* Update actions, animate dropped action */
			this.setState({
				draggingItemId: null, 
				isMoving: false, 
				animateDropActionId: itemId, 
				actions: newActions, 
				selected: newSelected
			});
		});
	}

	/**
	 * Reset challenge
	 * @param {array} cachedActions
	 * @param {bool} closePopup 
	 */
	resetChallenge(cachedActions = null, closePopup = false) {
		let availableActions = JSON.parse(JSON.stringify(shuffleArray(actionsData)));
		availableActions.forEach((action, index)  => {
			action.index = index;
			action.initialIndex = index;
			action.isSelected = false;
		});
		let selectedActions = [];
		let placeHolderActions = [...Array(this.state.numberOfPlaceholders)].map((_, index) => {
			return {id: null, index: index, initialIndex: index};
		});
		
		/* Get selected actions from cached profile */
		if (cachedActions) {
			// placeHolderActions = JSON.parse(JSON.stringify(cachedActions));

			cachedActions.forEach((cachedAction, index) => {
				let availableActionsIndex = availableActions.findIndex((action) => {
					return cachedAction.id === action.id;
				});
				if (availableActionsIndex >= 0) {
					placeHolderActions[index].id = cachedAction.id;
					availableActions[availableActionsIndex].isSelected = true;
					selectedActions.push(cachedAction);
				}
			});

		}

		this.setState({
			draggingItemId: null,
			isMoving: false,
			animateDropActionId: null,
			initialActions: JSON.parse(JSON.stringify(availableActions)),
			actions: availableActions,
			selected: selectedActions,
			placeHolderActions: placeHolderActions
		});
		
		if (closePopup) this.props.closePopup();
	}

	/**
	 * Show evaluate actions popup
	 */
	evaluateActions() {
		/* Not enough actions selected */
		if (this.state.selected.length < 3) {
			let popupData = JSON.parse(JSON.stringify(popupsData.notEnoughActionsSelected));
			let popupBtns = [
				{text: generalUiTexts.ok, action: this.props.closePopup, actionParams: []},
			];
			this.props.openPopup(null, popupData.text, popupBtns, null, null, null, 'redActions');
			return;
		}

		/* Evaluate actions */
		let greenActions = 0;
		this.state.selected.forEach((action) => {
			if (action.type === 'green') {
				greenActions ++;
			}
		});

		let popupData = JSON.parse(JSON.stringify(popupsData.evaluateActions));
		let popupBtns = [
			{text: generalUiTexts.regret, action: this.resetChallenge, actionParams: [null, true]},
			{text: generalUiTexts.save, action: this.props.saveActions, actionParams: [this.state.selected]}
		];
		let popupText;
		let bgColor;
		if (greenActions === 0) {
			popupText = popupData.texts.red;
			bgColor = 'redActions';
		} else if (greenActions === 1) {
			popupText = popupData.texts.yellow;
			bgColor = 'yellowActions';
		} else {
			popupText = popupData.texts.green;
			bgColor = 'greenActions';
		}
		this.props.openPopup(null, popupText, popupBtns, null, null, null, bgColor);
	}


	/**
	 * Render component
	 */
	render() {
		let dragIsDisabled = this.state.isAnimating;
		let actions = sortArrayByProperty(this.state.actions, 'initialIndex', 'ASC');
		let selected = sortArrayByProperty(this.state.selected, 'initialIndex', 'ASC');
		let placeHolderActions = sortArrayByProperty(this.state.placeHolderActions, 'initialIndex', 'ASC');		
		return (
			<div aria-hidden={this.props.popupIsOpen} style={{height: '100%'}}>
				<DndProvider backend={HTML5Backend}>
					<Actions
						showAvatarInfo={this.state.showAvatarInfo}
						dragIsDisabled={dragIsDisabled}
						toggleAvatarInfo={this.toggleAvatarInfo}
						numberOfPlaceholders={this.state.numberOfPlaceholders}
						initialActions={this.state.initialActions}
						actions={actions}
						selected={selected}
						placeHolderActions={placeHolderActions}
						handleDragEnd={this.handleDragEnd}
						evaluateActions={this.evaluateActions}
						profile={this.props.profile}
						animateDropActionId={this.state.animateDropActionId}
						handleDragStart={this.handleDragStart}
						goToStep={this.props.goToStep}
						openIntroPopup={this.openIntroPopup}
					/>
				</DndProvider>
			</div>
		);
	}
}

ActionsController.defaultProps = {
	cachedProfile: null
};

ActionsController.propTypes = {
	popupIsOpen: PropTypes.bool.isRequired,
	introPopupStatuses: PropTypes.object.isRequired,
	openPopup: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
	goToStep: PropTypes.func.isRequired,
	saveActions: PropTypes.func.isRequired,
	profile: PropTypes.object.isRequired,
	cachedProfile: PropTypes.object,
	updateIntroPopupStatus: PropTypes.func.isRequired,
};


export default ActionsController;