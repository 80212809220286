import React from 'react';
import PropTypes from 'prop-types';
import {avatarUiTexts} from 'data/ui-texts';
import AvatarImage from 'components/avatar-image/avatar-image';
import AvatarStatsController from 'components/avatar-stats/avatar-stats-controller';
import Button from 'components/button/button';
import './actions-header.scss';

const ActionsHeader = (props) => {
	let {
		showAvatarInfo,
		avatar, 
		statsColors,
		toggleAvatarInfo,
		goToStep,
		openIntroPopup,
		selectedList
	} = props;

	return (
		<div className="ActionsHeader">
			<Button viewMode="landscape" class="goBack2" onClick={() => {goToStep('interests');}} />	
			<Button viewMode="landscape" class="help" onClick={() => {openIntroPopup();}} />	
			<AvatarImage 
				viewMode="landscape" 
				avatarId={0} 
				initialLetter={avatar.name ? avatar.name.charAt(0).toUpperCase() : ''}
				type="actions" 
				// onClick={() => {toggleAvatarInfo(true);}}
			/>
			<div 
				className={'ActionsHeader-avatarBonusInfo' + (showAvatarInfo ? ' show' : '')}
				onClick={() => {toggleAvatarInfo(false);}} 
			>
				{/* <span>{avatar.description}</span> */}blah
			</div>
			{(avatar.name && avatar.age) && <div className="ActionsHeader-avatarInfo">
				<div className="ActionsHeader-avatarInfoText">
					<span>{avatar.name}</span>
					<div>({avatar.age} {avatarUiTexts.years})</div>
				</div>					
			</div>}
			{avatar.stats && <div className="ActionsHeader-avatarStats">
				<AvatarStatsController 
					viewMode="landscape"
					type="actions" 
					avatar={avatar} 
					stats={avatar.stats} 
					statsColors={statsColors}
				/>
			</div>}
			{avatar.interest && <div className={'ActionsHeader-goal ' + (selectedList.length < 3 ? 'blue' : 'green')}>
				<div className="ActionsHeader-goalTitle">
					<span>{avatarUiTexts.interestArea}:</span>
					<span>{avatar.interest.title}</span>
				</div>
			</div>}
		</div>
	);
};



ActionsHeader.propTypes = {
	showAvatarInfo: PropTypes.bool.isRequired,
	avatar: PropTypes.object.isRequired,
	statsColors: PropTypes.object.isRequired,
	toggleAvatarInfo: PropTypes.func.isRequired,
	goToStep: PropTypes.func.isRequired,
	openIntroPopup: PropTypes.func.isRequired,
	selectedList: PropTypes.array.isRequired
};

export default ActionsHeader;