import firebase from 'firebase/app';
import 'firebase/firestore';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase: test */
if (env === 'development' || env === 'test') {
	firebaseConfig = {
		apiKey: 'AIzaSyAdIkwHBkNcm5dY6AlTSGD-lwvlMAp2xwY',
		authDomain: 'cgl-vuc-test.firebaseapp.com',
		databaseURL: 'https://cgl-vuc-test.firebaseio.com',
		projectId: 'cgl-vuc-test',
		storageBucket: 'cgl-vuc-test.appspot.com',
		messagingSenderId: '380908203224',
		appId: '1:380908203224:web:667b65bae4f2c765'
	};
}

/* Connect to firebase: demo */
if (env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyDjLoFk1uDV-CfyGMrg_04G47rjyI_bvbI',
		authDomain: 'cgl-vuc-demo.firebaseapp.com',
		databaseURL: 'https://cgl-vuc-demo.firebaseio.com',
		projectId: 'cgl-vuc-demo',
		storageBucket: 'cgl-vuc-demo.appspot.com',
		messagingSenderId: '277219964919',
		appId: '1:277219964919:web:58280aa767f2dfd2'
	};
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyDDSDrlqwIy0xzgCrwrn-3ee_tPc3ujK9E',
		authDomain: 'cgl-vuc-production.firebaseapp.com',
		databaseURL: 'https://cgl-vuc-production.firebaseio.com',
		projectId: 'cgl-vuc-production',
		storageBucket: 'cgl-vuc-production.appspot.com',
		messagingSenderId: '60452513315',
		appId: '1:60452513315:web:5c3e12a7c53e3f65dc3396'
	};
}

/* Connect to firebase: production */
if (env === 'adaptive-test') {
	firebaseConfig = {
		apiKey: 'AIzaSyBCfSMTKJRV-VWik3OQ7opPpn7-kFH5spU',
		authDomain: 'cgl-vuc-adaptive-test.firebaseapp.com',
		databaseURL: 'https://cgl-vuc-adaptive-test.firebaseio.com',
		projectId: 'cgl-vuc-adaptive-test',
		storageBucket: 'cgl-vuc-adaptive-test.appspot.com',
		messagingSenderId: '695107990119',
		appId: '1:695107990119:web:4eb51632620b0397fbdac2'
	};
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);


export default firebase.firestore().enablePersistence()
	.then(() => {
		console.log('Firestore persistence enabled!');
		return firebase.firestore();
	})
	.catch((error) => {
		if (error.code === 'failed-precondition') {
			console.error('Multiple tabs open, persistence can only be enabled in one tab at a time.');
		} else if (error.code === 'unimplemented') {
			console.error('The current browser does not support all of the features required to enable persistence');
		} else {
			console.error('Unknown persistence error: ', error);
		}
		return firebase.firestore();
	});