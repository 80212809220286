import React from 'react';
import PropTypes from 'prop-types';
import Home from './home';

class HomeController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showFirstPartDone: (this.props.challengeCompleted && this.props.prevStepId === 'challenge'),
			showResultBtnInfo: false
		};
		this.toggleResultBtnInfo = this.toggleResultBtnInfo.bind(this);
		this.toggleFirstPartDone = this.toggleFirstPartDone.bind(this);
	}

	toggleFirstPartDone(showFirstPartDone) {
		this.setState({showFirstPartDone: showFirstPartDone, showResultBtnInfo: false});
	}

	toggleResultBtnInfo(showResultBtnInfo) {
		this.setState({showResultBtnInfo: showResultBtnInfo, showFirstPartDone: false});
	}

	render() {
		return (
			<Home 
				isFullscreen={this.props.isFullscreen}
				viewMode={this.props.viewMode}
				showResultBtnInfo={this.state.showResultBtnInfo}
				showFirstPartDone={this.state.showFirstPartDone}
				cachedProfile={this.props.cachedProfile}
				goToStep={this.props.goToStep}
				toggleResultBtnInfo={this.toggleResultBtnInfo}
				toggleFirstPartDone={this.toggleFirstPartDone}
				openPopup={this.props.openPopup}
				closePopup={this.props.closePopup}
				handleToggleFullscreen={this.props.handleToggleFullscreen}
			/>
		);
	}
}

HomeController.propTypes = {
	isFullscreen: PropTypes.bool.isRequired,
	challengeCompleted: PropTypes.bool.isRequired,
	viewMode: PropTypes.string.isRequired,
	prevStepId: PropTypes.string,
	cachedProfile: PropTypes.object,
	goToStep: PropTypes.func.isRequired,
	openPopup: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
	handleToggleFullscreen: PropTypes.func.isRequired
};

export default HomeController;