import React from 'react';
import PropTypes from 'prop-types';
// import {generalUiTexts} from 'data/ui-texts';
import './tips.scss';

const Tips = ({type, steps, stepIndex, stepText, showStepIndex}) => {
	return (
		<div className={'Tips Tips--' + type}>
			{/* <div className="Tips-title">{generalUiTexts.tipsTitle}:</div> */}
			<div className="Tips-text" dangerouslySetInnerHTML={{__html: stepText}} />
			{steps.length > 1 && <div className="Tips-nav">
				{steps.map((_, index) => {
					return (
						<div 
							key={index}
							className={'Tips-navItem' +
										(stepIndex === index ? ' selected' : '')}
							onClick={() => {showStepIndex(index);}} 
						/>
					);
				})}
			</div>}
		</div>
	);
};

Tips.propTypes = {
	type: PropTypes.string.isRequired,
	steps: PropTypes.array.isRequired,
	stepIndex: PropTypes.number.isRequired,
	stepText: PropTypes.string.isRequired,
	showStepIndex: PropTypes.func.isRequired
};

export default Tips;