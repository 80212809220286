import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Motion, spring } from 'react-motion';
import {moveAction, moveAction2, findAction} from 'helpers/dnd-helper';
import {getStatsColors2} from 'helpers/stats-helper';
import ChallengeAction from 'components/challenge/challenge-action';
import ChallengeDndContainer from 'components/challenge/challenge-dnd-container';
import ChallengeDndItem from 'components/challenge/challenge-dnd-item';
import ChallengeDndDragLayer from 'components/challenge/challenge-dnd-drag-layer';
import AvatarStatsController from 'components/avatar-stats/avatar-stats-controller';
import Button from 'components/button/button';
import Scrollbar from 'components/layout/scrollbar';
import {generalUiTexts} from 'data/ui-texts';
import './actions.scss';

const Actions = ({
	dragIsDisabled,
	numberOfPlaceholders,
	actionsScrollPos,
	maxSelected,
	actions,
	toggleBodyScroll,
	selected,
	toggleAvatarInfo,
	updateActionsLists,
	evaluateActions,
	profile,
	animateDropActionId,
	handleDragStart,
	handleScroll
}) => {

	let [actionsList, setActions] = useState([]);
	useEffect(() => {setActions(actions);}, [actions]);

	let [selectedList, setSelected] = useState([]);
	useEffect(() => {setSelected(selected);}, [selected]);

	let actionPlaceholders = (selectedList.length <= numberOfPlaceholders 
		? [...Array(numberOfPlaceholders - selectedList.length + 1)].map((_, index) => {return index;})
		: []
	);

	/**
	 * Move action to another action's position
	 * @param {string} id 
	 * @param {string} toId 
	 */
	const handleMoveAction = (id, toId) => {
		let [newActionsList, newSelectedList] = moveAction(id, toId, actionsList, selectedList, maxSelected);
		setActions(newActionsList);
		setSelected(newSelectedList);
	};

	/**
	 * Move action to another container
	 * @param {string} id 
	 */
	const handleMoveAction2 = (id) => {
		let [newActionsList, newSelectedList] = moveAction2(id, actionsList, selectedList, maxSelected);
		setActions(newActionsList);
		setSelected(newSelectedList);
	};

	/**
	 * Find action 
	 * @param {string} id 
	 */
	const handleFindAction = (id) => {
		return findAction(id, actionsList, selectedList);
	};

	/**
	 * Drop action
	 */
	let dropAction = () => {
		updateActionsLists(actionsList, selectedList);
	};

	// let minHeight = 35;
	// let availableActionsHeight = Math.max(actionsList.length * 4.5, minHeight);
	let availableActionsHeight = (actionsList.length - 1) * 4.5;

	/* Get stats colors */
	let statsColors = getStatsColors2(profile.stats);


	return (
		<div className="Actions">
			<AvatarStatsController
				type="quiz"
				avatar={profile}
				stats={profile.stats}
				handleRefresh={() => {toggleAvatarInfo(true);}}
				statsColors={statsColors}
			/>
			<div className="Challenge-body">
				{/* Goal */}
				<div className={'Challenge-goal no-requirements ' + (selectedList.length < 4 ? 'green' : 'blue')}>
					<div className="Challenge-goalTitle"><span>{profile.interest.title}</span></div>
				</div>


				{/* Available actions */}
				<div id="scrollableContainer1" className="Challenge-options">
					<ChallengeDndContainer
						containerId="actions"
						containerHeight={availableActionsHeight}
						handleFindAction={handleFindAction}
						handleMoveAction2={handleMoveAction2}
					>
						{actionsList.map((action, index) => {
							return (
								<Motion 
									key={index}
									style={{ y: spring(action.index * 4.5, { stiffness: 500, damping: 32 }) }}      
								>
									{({ y }) => {
										return (
											<ChallengeDndItem
												id={action.id}
												dragIsDisabled={dragIsDisabled}
												style={{transform: 'translate3d(0, ' + y + 'em, 0)'}}
												toggleBodyScroll={toggleBodyScroll}
												handleDragStart={handleDragStart}
												dropAction={dropAction}
												handleMoveAction={handleMoveAction}
												handleFindAction={handleFindAction}
												toggleActionInfo={() => {}}
											>
												<ChallengeAction
													type="actions"
													animateDrop={animateDropActionId === action.id}
													action={action}
													toggleActionInfo={() => {}}
												/>
											</ChallengeDndItem>
										);
									}}
								</Motion>
							);
						})}
					</ChallengeDndContainer>
				</div>
				<div className="Challenge-path">
					<ChallengeDndContainer
						containerId="selected"
						handleFindAction={handleFindAction}
						handleMoveAction2={handleMoveAction2}
					>
						{selectedList.map((action, index) => {
							return (
								<Motion 
									key={index}
									style={{ y: spring(action.index * 5.25, { stiffness: 500, damping: 32 }) }}      
								>
									{({ y }) => {
										return (
											<ChallengeDndItem
												key={index}
												id={action.id}
												dragIsDisabled={dragIsDisabled}
												style={{transform: 'translate3d(0, ' + y + 'em, 0)'}}
												toggleBodyScroll={toggleBodyScroll}
												handleDragStart={handleDragStart}
												dropAction={dropAction}
												handleMoveAction={handleMoveAction}
												handleFindAction={handleFindAction}
												toggleActionInfo={() => {}}
											>
												<ChallengeAction
													type="selected"
													animateDrop={animateDropActionId === action.id}
													action={action}
													avatar={profile}
													selectedList={selected}
													toggleActionInfo={() => {}}
													position={action.index + 1}
												/>
											</ChallengeDndItem>
										);
									}}
								</Motion>
							);
						})}
						{actionPlaceholders.map((_, index) => {
							let y = (selectedList.length + index - 1) * 5.25;
							return (
								<div 
									key={index} 
									style={{transform: 'translate3d(0, ' + y + 'em, 0)'}}
									className="Challenge-actionWrap"
								><div className="ChallengeAction-placeholder"/></div>
							);
						})}					
					</ChallengeDndContainer>
				</div>
				<ChallengeDndDragLayer actions={actions} selected={selected} />
				<Scrollbar 
					name="actionsScrollPos"
					containerId = "scrollableContainer1"
					contentId = "actions"
					type="actions" 
					value={actionsScrollPos}
					onChange={handleScroll}
				/>
				<Button
					class="evaluateActions"
					text={generalUiTexts.next}
					disabled={selected.length !== 4}
					onClick={() => {evaluateActions();}}
				/>
			</div>
		</div>
	);
};

Actions.propTypes = {
	dragIsDisabled: PropTypes.bool.isRequired,
	numberOfPlaceholders: PropTypes.number.isRequired,
	actionsScrollPos: PropTypes.number.isRequired,
	maxSelected: PropTypes.number.isRequired,
	actions: PropTypes.array.isRequired,
	selected: PropTypes.array.isRequired,
	toggleBodyScroll: PropTypes.func.isRequired,
	toggleAvatarInfo: PropTypes.func.isRequired,
	updateActionsLists: PropTypes.func.isRequired,
	evaluateActions: PropTypes.func.isRequired,
	profile: PropTypes.object.isRequired,
	animateDropActionId: PropTypes.number,
	handleDragStart: PropTypes.func.isRequired,
	handleScroll: PropTypes.func.isRequired
};

export default Actions;