import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {goalsData} from 'data/goals-data';
import Goals from 'components/admin/goals/goals';

class GoalsController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isEditing: false,
			isSaving: false,
			goals: [],
			goalIndex: null,
			selectedGoal: null,
			errorMsg: null
		};
		this.componentDidMount = this.componentDidMount.bind(this);
		this.subscribeToGoals = this.subscribeToGoals.bind(this);
		this.unsubscribeGoals = null;
		this.selectGoal = this.selectGoal.bind(this);
		this.updateGoal = this.updateGoal.bind(this);
		this.saveGoal = this.saveGoal.bind(this);
	};

	/**
	 * Component did mount
	 */
	componentDidMount() {
		this.subscribeToGoals().then((goals) => {
			if (goals.length > 0) {
				let goalIndex = 0;
				let selectedGoal = goals[goalIndex];
				this.setState({
					isLoading: false,
					goalIndex: goalIndex,
					selectedGoal: selectedGoal
				});
			} else {
				this.setState({
					isLoading: false
				});
			}
		});
	}

	/**
	 * Subscribe to goals
	 */
	subscribeToGoals() {
		if (this.unsubscribeGoals !== null) this.unsubscribeGoals();
		const db = firebase.firestore();
		return new Promise((resolve, reject)=>{
			this.unsubscribePages = db.collection('goals').onSnapshot((querySnapshot) => {
				let goals = querySnapshot.docs.map((doc) => {
					let data = doc.data();
					data.id = doc.id;
					if (goalsData.some((goalData) => {return goalData.id === data.id;})) {
						data.title = 
							goalsData.filter((goalData) => {return goalData.id === data.id;})[0].title;
					} else {
						data.title = data.id;
					}
					return data;						
				});		
				this.setState({goals: goals}, () => {resolve(goals);});			
			}, (error) => {
				reject(error);
			});
		});
	}

	/**
	 * Select goal
	 * @param {object} event 
	 */
	selectGoal(event) {
		let goalIndex = parseInt(event.target.value);
		let selectedGoal = JSON.parse(JSON.stringify(this.state.goals[goalIndex]));
		this.setState({
			goalIndex: goalIndex,
			selectedGoal: selectedGoal,
			isEditing: false,
			errorMsg: null
		});
	}

	/**
	 * Update goal
	 * @param {object} event 
	 */
	updateGoal(event) {
		let selectedGoal = JSON.parse(JSON.stringify(this.state.selectedGoal));
		selectedGoal[event.target.name] = event.target.value;
		this.setState({
			selectedGoal: selectedGoal,
			isEditing: true
		});
	}

	/**
	 * Save goal
	 * @param {object} event 
	 */
	saveGoal(event) {
		if (!this.state.isEditing || this.state.isSaving) return;
		this.setState({isSaving: true});

		let selectedGoal = JSON.parse(JSON.stringify(this.state.selectedGoal));
		const db = firebase.firestore();
		const goalData = {energy: parseInt(selectedGoal.energy)};
		db.collection('goals').doc(selectedGoal.id).update(goalData).then(
			() => {
				this.setState({
					isEditing: false, 
					isSaving: false
				});		
			},
			(error) => {
				console.error(error);this.setState({isSaving: false});
			}
		);
	}

	/**
	 * Render component
	 */
	render() {
		let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
		let databaseProject;
		if (env === 'development' || env === 'test' || env === 'adaptive-test') {
			databaseProject = 'test';
		} else if (env === 'demo') {
			databaseProject = 'demo';
		} else if (env === 'production') {
			databaseProject = 'production';
		}

		if (databaseProject !== 'test') {
			return (
				<div className="GoalsAdmin">
					<div className="GoalsAdmin-title">Rediger mål</div>
					<p className="GoalsAdmin-backBtn" onClick={() => {this.props.goToPage(null);}}>&lArr; tilbage</p>
					<p>You are on the {databaseProject} server. You can only edit goals data on the test server.</p>
					<p>To update goal data on this server, download the backup file from the test server, 
						and upload it to this server.</p>
				</div>
			);
		}

		return (
			<Goals
				isEditing={this.state.isEditing}
				isSaving={this.state.isSaving}
				goals={this.state.goals}
				selectedGoal={this.state.selectedGoal}
				errorMsg={this.state.errorMsg}
				selectGoal={this.selectGoal}
				updateGoal={this.updateGoal}
				saveGoal={this.saveGoal}
				goToPage={this.props.goToPage}
			/>
		);
	}
};

GoalsController.propTypes = {
	goToPage: PropTypes.func.isRequired
};

export default GoalsController;

