import React from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts} from 'data/ui-texts';
import './logo.scss';

const Logo = ({isLoading, viewMode}) => {
	return (
		<div 
			role="img" 
			aria-label={generalUiTexts.title} 
			className={'Logo Logo--' + viewMode + (isLoading ? ' Logo--loading' : '')} 
		/>
	);
};

Logo.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	viewMode: PropTypes.string.isRequired
};

export default Logo;