import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {sortArrayByProperty} from 'helpers/array-helper';
import {getYear} from 'helpers/date-helper';
import Stats from './stats';

class StatsController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			stats: [],
			availableYears: [],
			selectedYears: []
		};
		this.subscribeToStats = this.subscribeToStats.bind(this);
		this.unsubscribeStats = null;
		this.toggleYear = this.toggleYear.bind(this);
	};

	/**
	 * Component did mount
	 */
	componentDidMount() {
		let initialYear = 2019;
		let currentYear = new Date().getFullYear();
		let availableYears = [];
		for (let i = initialYear; i <= currentYear; i++) {
			availableYears.push(i);
		}
		let selectedYears = [currentYear];
		this.subscribeToStats().then(() => {
			this.setState({
				isLoading: false,
				availableYears: availableYears,
				selectedYears: selectedYears
			});
		});
	}

	/**
	 * Subscribe to goals
	 */
	subscribeToStats() {
		if (this.unsubscribeStats !== null) this.unsubscribeStats();
		const db = firebase.firestore();
		return new Promise((resolve, reject)=>{
			this.unsubscribeStats = db.collection('statistics').onSnapshot((querySnapshot) => {
				let stats = querySnapshot.docs.map((doc) => {
					let data = doc.data();
					return data;						
				});		
				this.setState({stats: stats}, () => {resolve();});			
			}, (error) => {
				reject(error);
			});
		});
	}

	toggleYear(year) {
		let selectedYears = JSON.parse(JSON.stringify(this.state.selectedYears));
		let yearIndex = selectedYears.indexOf(year);
		if (yearIndex < 0) {
			selectedYears.push(year);
		} else {
			selectedYears.splice(yearIndex, 1);
		}
		this.setState({selectedYears: selectedYears});
	}

	/**
	 * Render component
	 */
	render() {
		let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
		let databaseProject;
		if (env === 'development' || env === 'test' || env === 'adaptive-test') {
			databaseProject = 'test';
		} else if (env === 'demo') {
			databaseProject = 'demo';
		} else if (env === 'production') {
			databaseProject = 'production';
		}

		let filteredStats = this.state.stats.filter((stat) => {
			let year = parseInt(getYear(stat.startPart1Time));
			return this.state.selectedYears.indexOf(year) >= 0;
		});

		let sortedStats = sortArrayByProperty(filteredStats, 'startPart1Time', 'DESC');

		
		return (
			<Stats
				isLoading={this.state.isLoading}
				databaseProject={databaseProject}
				stats={sortedStats}
				availableYears={this.state.availableYears}
				selectedYears={this.state.selectedYears}
				goToPage={this.props.goToPage}
				toggleYear={this.toggleYear}
			/>
		);
	}
};

StatsController.propTypes = {
	goToPage: PropTypes.func.isRequired
};

export default StatsController;

