import React from 'react';
import PropTypes from 'prop-types';
import './popup-icon.scss';
import './popup-icon-landscape.scss';

const PopupIcon = ({name, viewMode}) => {
	return (
		<div className={'PopupIcon PopupIcon--' + name + ' PopupIcon--' + viewMode}>
			<svg className="star-outline" viewBox="0 0 54.5 51.91">
				<path 
					id="Path_1148" 
					data-name="Path 1148" 
					d="M27.25.71l8.2,16.62L53.8,20,40.52,32.93,43.66,51.2,27.25,42.57,10.85,51.2,14,32.93.71,20l18.34-2.66Z"
					fill="#fff"
					stroke="#bfbfbf"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					strokeWidth="1.4"
				/>
			</svg>
			<svg className="star-filled" viewBox="0 0 54.59 51.99">
				<defs>
					<radialGradient id="radial-gradient" cx="-1237.9" cy="877.18" r="0.38" gradientTransform="matrix(53.09, 0, 0, -50.49, 65748.61, 44317.66)" gradientUnits="userSpaceOnUse">
						<stop offset="0" stopColor="#fff7b9"/>
						<stop offset="1" stopColor="#ffc924"/>
					</radialGradient>
				</defs>
				<path 
					id="Path_1147-2" 
					data-name="Path 1147-2" 
					d="M27.3.75l7.26,14.72.94,1.9L53.84,20,40.57,33,43.7,51.24,27.3,42.61,10.89,51.24,14,33,.75,20l18.34-2.67Z"
					stroke="#f7d670"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.4"
					fill="url(#radial-gradient)"
				/>
			</svg>
			{name === 'star' && <div className="PopupIcon-animation">{[...Array(70)].map((_, index) => {
				return <div key={index} className="PopupIcon-animationObject" />;
			})}</div>}
		</div>
	);
};

PopupIcon.propTypes = {
	name: PropTypes.string.isRequired,
	viewMode: PropTypes.string.isRequired
};

export default PopupIcon;