import React from 'react';
import PropTypes from 'prop-types';
import {useDrop, useDrag} from 'react-dnd';

const Item = (props) => {
	let {
		dragIsDisabled, 
		id, 
		toggleBodyScroll,
		style,
		handleDragStart,
		dropAction,
		handleMoveAction,
		handleFindAction,
		toggleActionInfo,
		children
	} = props;

	const [, originalIndex, originalContainerId] = handleFindAction(id);
	const [{ isDragging }, drag, preview] = useDrag({
		item: { type: 'action', id, originalContainerId, originalIndex },
		canDrag() {return !dragIsDisabled;},
		begin() {handleDragStart(id);},
		end() {dropAction();},
		isDragging(monitor) {return (id === monitor.getItem().id);},
		collect: (monitor) => {return {isDragging: monitor.isDragging()};},

	});
	const [, drop] = useDrop({
		accept: 'action',
		canDrop: () => {return false;},
		hover(item) {
			if (item.id === id) return;
			handleMoveAction(item.id, id);
		},
	});
	
	const opacity = isDragging ? 0.4 : 1;
	let className = 'Challenge-actionWrap' +
		' Challenge-actionWrap--' + id +
		(isDragging ? ' Challenge-actionWrap--dragging' : '');

	let onlyChild = React.Children.only(children);
	let childCloned = React.cloneElement(onlyChild, {isDragging: isDragging});

	return (
		<div 
			ref={preview}  
			className={className} 
			style={{opacity, ...style}} 
			onClick={(event) => {toggleActionInfo(id, event);}}
		>
			{childCloned}
			<div 
				ref={(node) => {return drag(drop(node));}} 
				className="ChallengeAction-dragHandle" 
				onTouchStart={() => {if (!dragIsDisabled) toggleBodyScroll(false);}}
			/>
		</div>
	);

};

Item.propTypes = {
	dragIsDisabled: PropTypes.bool.isRequired,
	id: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	style: PropTypes.object.isRequired,
	toggleBodyScroll: PropTypes.func.isRequired,
	handleDragStart: PropTypes.func.isRequired,
	dropAction: PropTypes.func.isRequired,
	handleMoveAction: PropTypes.func.isRequired,
	handleFindAction: PropTypes.func.isRequired,
	toggleActionInfo: PropTypes.func.isRequired,
	children: PropTypes.object.isRequired
};

export default Item;