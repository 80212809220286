const quizData = [
	{
		index: 0,
		id: 'teamwork',
		name: 'Samarbejde',
		questions: [
			'Jeg kan godt lide at arbejde i grupper.',
			'Jeg er god til at løse konflikter.',
			'Jeg har nemt ved at forstå, hvad andre vil.',
			'Jeg er god til at sige fra på en god måde.',
			'Jeg er god til at modtage kritik.'
		]
	},
	{
		index: 1,
		id: 'danish',
		name: 'Dansk',
		questions: [
			'Jeg er tydelig og forståelig, når jeg taler.',
			'Jeg synes, at det er let at bruge sproget.',
			'Jeg vil gerne tale foran mange mennesker.',
			'Jeg forstår, hvad andre siger.',
			'Jeg er god nok til dansk.'
		]
	},
	{
		index: 2,
		id: 'reading',
		name: 'Læsning',
		questions: [
			'Jeg kan godt lide at læse.',
			'Jeg læser hurtigt.',
			'Jeg læser højt for andre.',
			'Jeg læser næsten hver dag.',
			'Læsning må gerne være del af mit arbejde.'
		]
	},
	{
		index: 3,
		id: 'math',
		name: 'Matematik',
		questions: [
			'Det er sjovt at lege med tal, som fx i Sudoku.',
			'Jeg er hurtig til hovedregning.',
			'Jeg er tryg ved at regne opgaver ud.',
			'Jeg har det fint med at lægge et budget.',
			'Mit arbejde må gerne være noget med tal.'
		]
	},
	{
		index: 4,
		id: 'workEthic',
		name: 'Stabilitet',
		questions: [
			'Jeg overholder mine aftaler.',
			'Jeg løser mine opgaver - også de mindre sjove.',
			'Jeg møder på arbejde - selvom jeg har lyst til en fridag.',
			'Jeg møder til tiden - og bliver til jeg har løst mine opgaver.',
			'Min chef kan stole på mig.'
		]
	}
]

export {quizData};