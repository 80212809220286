import React from 'react';
import PropTypes from 'prop-types';
import GoalsController from 'components/admin/goals/goals-controller';
import BackupController from './backup/backup-controller';
import StatsController from './stats/stats-controller';
import './admin.scss';

const Admin = ({page, goToPage, handleLogout}) => {

	
	switch (page) {
	case 'goals':
		return <GoalsController goToPage={goToPage} />;
	case 'backup':
		return <BackupController goToPage={goToPage}/>;
	case 'stats':
		return <StatsController goToPage={goToPage} />;
	default:
		return (
			<div className="Admin">
				<h2>Velkommen til kontrolpanelet!</h2>
				{/* {hasFullAccess &&
					<React.Fragment>
						<p className="Admin-link" onClick={() => {goToPage('goals');}}>Rediger mål</p>		
						<p className="Admin-link" onClick={() => {goToPage('backup');}}>Backup data</p>
					</React.Fragment>
				} */}
				<p className="Admin-link" onClick={() => {goToPage('stats');}}>Se statistik</p>

				<p>&nbsp;</p>
				<p className="Admin-link" onClick={() => {window.location.href = '../';}}>Tilbage til spillet</p>
				<p className="Admin-link" onClick={() => {handleLogout();}}>Log ud</p>
			</div>
		);
	}
};

Admin.propTypes = {
	// hasFullAccess: PropTypes.bool.isRequired,
	page: PropTypes.string,
	goToPage: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default Admin;