/**
 * Register the service worker
 * Configuration file is located at './service-worker-config.js'
 */ 

let newWorker;
let refreshing;

const isLocalhost = Boolean(
	window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

document.getElementById('SWReload').addEventListener('click', () => {
	if (newWorker) {
		newWorker.postMessage({action: 'skipWaiting'});
	}
});


/**
 * Check environment, browser support and configuration file before loading service worker
 */
export function register() {
	if (
		(process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'demo' || 
		process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'adaptive-test') 
		&& 'serviceWorker' in navigator
	) {

		if ('serviceWorker' in navigator) {
			// The URL constructor is available in all browsers that support SW.
			const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
			if (publicUrl.origin !== window.location.origin) {
				// Our service worker won't work if PUBLIC_URL is on a different origin
				// from what our page is served on. This might happen if a CDN is used to
				// serve assets; see https://github.com/facebook/create-react-app/issues/2374
				return;
			}

			window.addEventListener('load', () => {
				const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

				// Localhost
				if (isLocalhost) {
					// Check if a service worker still exists or not.					
					checkValidServiceWorker(swUrl);

					// Add some additional logging to localhost, pointing developers to the
					// service worker/PWA documentation.
					navigator.serviceWorker.ready.then(() => {
						console.log(
							'This web app is being served cache-first by a service ' +
								'worker. To learn more, visit https://bit.ly/CRA-PWA'
						);
					});
				// Not localhost
				} else {
					// Register service worker
					registerValidSW(swUrl);
				}
			});
		}
	}
}

/**
 * Register valid service worker
 * @param {string} swUrl 
 */
function registerValidSW(swUrl) {
	
	navigator.serviceWorker.register(swUrl)
		// Registration successful
		.then((registration) => {
			// if (registration.waiting) {
			// 	this.newWorker = registration.waiting;
			// }
			registration.addEventListener('updatefound', () => {			
				newWorker = registration.installing;
				if (newWorker == null) return;
				
				newWorker.addEventListener('statechange', () => {
					// A new service worker is available
					if (newWorker.state === 'installed') {
						if (navigator.serviceWorker.controller) {				
							// At this point, the updated precached content has been fetched,
							// but the previous service worker will still serve the older
							// content until all client tabs are closed.
							console.log(
								'New content is available and will be used when all ' +
                  'tabs for this page are closed. See https://bit.ly/CRA-PWA.'
							);

							// Show notification
							let notification = document.getElementById('SWNotification');
							notification.className = 'show';							
						} else {
							// At this point, everything has been precached.
							// It's the perfect time to display a
							// "Content is cached for offline use." message.
							console.log('Content is cached for offline use.');
						}
					}
				});
			});
		})
		.catch((error) => {
			console.error('Error during service worker registration:', error);
		});

	// Refresh window
	navigator.serviceWorker.addEventListener('controllerchange', () => {
		if (refreshing)  return;
		window.location.reload();
		refreshing = true;
	});
}

function checkValidServiceWorker(swUrl, config) {
	// Check if the service worker can be found. If it can't reload the page.
	fetch(swUrl)
		.then((response) => {
			// Ensure service worker exists, and that we really are getting a JS file.
			const contentType = response.headers.get('content-type');
			if (
				response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
			) {
				// No service worker found. Probably a different app. Reload the page.
				navigator.serviceWorker.ready.then((registration) => {
					registration.unregister().then(() => {
						window.location.reload();
					});
				});
			} else {
				// Service worker found. Proceed as normal.
				registerValidSW(swUrl, config);
			}
		})
		.catch(() => {
			console.log(
				'No internet connection found. App is running in offline mode.'
			);
		});
}

export function unregister() {
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.ready.then((registration) => {
			registration.unregister();
		});
	}
}
