import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import Diploma from './diploma';
import DiplomaPrint from './diploma_print';
import apiHelper from 'helpers/api-helper';
import {printContent} from 'helpers/print-helper';
import {generalUiTexts as uiTexts} from 'data/ui-texts';
import {popupsData} from 'data/popups-data';

class DiplomaController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			profile: null,
			email: '',
			feedback: null,
			isLoading: false
		};
		this.handleSendEmail = this.handleSendEmail.bind(this);
		this.isValid = this.isValid.bind(this);
		this.handlePrint = this.handlePrint.bind(this);
		this.handleEmailPopup = this.handleEmailPopup.bind(this);
		this.handleEmailSuccessfulPopup = this.handleEmailSuccessfulPopup.bind(this);
		this.handleEmailFailedPopup = this.handleEmailFailedPopup.bind(this);
		this.handleCloseEmailPopup = this.handleCloseEmailPopup.bind(this);
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		/* Scroll to top */
		window.scrollTo(0, 0);

		/* Show cached profile */
		if (this.props.cachedProfile) {
			this.setState({profile: this.props.cachedProfile});
		/* Show game state profile */
		} else {	
			this.setState({profile: this.props.profile});
		}
	}

	handlePrint() {
		let html = ReactDOMServer.renderToStaticMarkup(<DiplomaPrint profile={this.state.profile} />);
		printContent(html, 'Job i Spil - Din Plan');
	}

	/**
	 * Show send email popup
	 */
	handleEmailPopup() {
		let popupData = JSON.parse(JSON.stringify(popupsData.sendEmail));
		let popupText = popupData.texts;
		let popupBtns = [{text: uiTexts.send, action: this.handleSendEmail, actionParams: [], showLoadingIcon: true}];
		let closeIcon = {action: this.props.closePopup, actionParams: []};
		if (!this.props.diplomaEmailed) {
			closeIcon = {action: this.handleCloseEmailPopup, actionParams: []};
		}
		this.props.openPopup(null, popupText, popupBtns, null, null, false, 'send-email', null, closeIcon, true);
	}

	/**
	 * Show confirm close without sending email popup
	 */
	handleCloseEmailPopup() {
		let popupData = JSON.parse(JSON.stringify(popupsData.closeSendEmail));
		let popupText = popupData.text;
		let popupBtns = [
			{text: uiTexts.back, action: this.handleEmailPopup, actionParams: []},
			{text: uiTexts.close, action: this.props.goToStep, actionParams: ['home']},
		];
		this.props.openPopup(null, popupText, popupBtns, null, null, false, 'close-send-email', null, null, false);
	}

	/**
	 * Show email send success popup
	 */
	handleEmailSuccessfulPopup() {
		/* Show popup */
		let popupData = JSON.parse(JSON.stringify(popupsData.emailSentSuccessfully));
		let popupText = popupData.texts;
		let popupBtns = [{text: uiTexts.close, action: this.props.goToStep, actionParams: ['home']}];
		this.props.openPopup(null, popupText, popupBtns, null, null, false, 'email-successful', null, null, false);
	}

	/**
	 * Show email send failure poup
	 * @param {string} failure 
	 */
	handleEmailFailedPopup(failure) {
		let popupData;
		let popupText;
		if (failure === 'invalid') {
			popupData = JSON.parse(JSON.stringify(popupsData.emailInvalid));
			popupText = popupData.texts;
		} else {
			popupData = JSON.parse(JSON.stringify(popupsData.emailFailed));
			popupText = popupData.text;
		}
		let popupBtns = [{text: uiTexts.back, action: this.handleEmailPopup, actionParams: []}];
		this.props.openPopup(null, popupText, popupBtns, null, null, false, 'email-failed', null, null, false);
	}

	/**
	 * Check if email is valid
	 * @param {string} email 
	 */
	isValid(email) {
		let re = /\S+@\S+/;
		return re.test(email);
	}
	
	/**
	 * Send email
	 */
	handleSendEmail() {
		let email = this.props.emailValue;
		if (this.state.isLoading) return;

		if (this.isValid(email)) {
			this.setState({ isLoading: true });
			this.setState({
				email: email
			}, () => {
				apiHelper('user/send-email', {
					email: this.state.email,
					profile: this.state.profile
				}).then(
					(response)=> {
						this.setState({ isLoading: false });
						if (response.status === 'success') {
							this.handleEmailSuccessfulPopup();
							this.props.handleDiplomaEmailed();
						}
					},
					(rejection) => {
						console.error(rejection);
						this.setState({ isLoading: false });
						if (rejection.errorType === 'invalid-email') {
							this.handleEmailFailedPopup('invalid');
						} else {
							this.handleEmailFailedPopup('unknown');
						}
					}
				);
			});
		} else {
			this.handleEmailFailedPopup('invalid');
		}
	}

	/**
	 * Render component
	 */
	render() {
		return (
			<Diploma
				viewMode={this.props.viewMode}
				popupIsOpen={this.props.popupIsOpen}
				handleRefresh={this.props.handleRefresh}
				goToStep={this.props.goToStep}
				profile={this.state.profile}
				diplomaEmailed={this.props.diplomaEmailed}
				handlePrint={this.handlePrint}
				handleEmailPopup={this.handleEmailPopup}
			/>
		);
	}
}

DiplomaController.defaultProps = {
	cachedProfile: null,
	prevStepId: null
};

DiplomaController.propTypes = {
	viewMode: PropTypes.string.isRequired,
	diplomaEmailed: PropTypes.bool.isRequired,
	handleDiplomaEmailed: PropTypes.func.isRequired,
	popupIsOpen: PropTypes.bool.isRequired,
	handleRefresh: PropTypes.func.isRequired,
	goToStep: PropTypes.func.isRequired,
	profile: PropTypes.object.isRequired,
	cachedProfile: PropTypes.object,
	openPopup: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
	emailValue: PropTypes.string,
};


export default DiplomaController;