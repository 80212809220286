import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {DndProvider} from 'react-dnd';
import TouchBackend from 'react-dnd-touch-backend';
import {clearAllBodyScrollLocks, disableBodyScroll} from 'body-scroll-lock';
import actionsData from 'data/actions-data-part2';
import {popupsData} from 'data/popups-data';
import {dummyAction} from 'data/actions-data';
import {shuffleArray} from 'helpers/array-helper';
import {sortArrayByProperty} from 'helpers/array-helper';
import {generalUiTexts} from 'data/ui-texts';
import Actions from './actions';
import Button from 'components/button/button';
import AvatarInfoController from 'components/avatar-info/avatar-info-controller';


class ActionsController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showAvatarInfo: false,
			draggingItemId: false,
			isMoving: false,
			isAnimating: false,
			animateDropActionId: null,
			actions: [],
			selected: [],
			actionsScrollPos: 0,
			numberOfPlaceholders: 3,
			maxSelected: 4 // includes dummy
		};
		this.openIntroPopup = this.openIntroPopup.bind(this);
		this.closeIntroPopup = this.closeIntroPopup.bind(this);
		this.toggleAvatarInfo = this.toggleAvatarInfo.bind(this);
		this.toggleBodyScroll = this.toggleBodyScroll.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
		this.handleDragStart = this.handleDragStart.bind(this);
		this.updateActionsLists = this.updateActionsLists.bind(this);
		this.evaluateActions = this.evaluateActions.bind(this);
		this.resetChallenge = this.resetChallenge.bind(this);
		this.timeout = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		/* Disable bodyscroll */
		this.toggleBodyScroll(true);

		/* Reset challenge */
		if (this.props.cachedProfile && this.props.cachedProfile.actions) {
			this.resetChallenge(this.props.cachedProfile.actions);
		} else {
			this.resetChallenge();
		}

		/* Open intro popup */
		if (this.props.introPopupStatuses.actionsIntroSeen !== true) {
			this.openIntroPopup();
		}
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount() {
		if (this.timeout) clearTimeout(this.timeout);
	}

	/**
	 * Open intro popup
	 */
	openIntroPopup() {
		let popupData = JSON.parse(JSON.stringify(popupsData.startActions));
		let popupBtns = [{text: generalUiTexts.ok, action: this.closeIntroPopup, actionParams: []}];
		this.props.openPopup(
			null, popupData.texts, popupBtns, null, 
			{action: this.closeIntroPopup, actionParams: []}, false, 
			'introPopup', null, null, null, popupData.audio
		);
	}

	/**
	 * Close intro popup
	 */
	closeIntroPopup() {
		this.props.closePopup();
		if (this.props.introPopupStatuses.actionsIntroSeen !== true) {
			this.props.updateIntroPopupStatus('actionsIntroSeen', true);
		}
	}

	/**
	 * Hide / show avatar info
	 * @param {bool} showAvatarInfo 
	 */
	toggleAvatarInfo(showAvatarInfo) {
		this.setState({showAvatarInfo: showAvatarInfo});
	}

	/**
	 * Toggle body scroll
	 * @param {bool} unLockActions 
	 */
	toggleBodyScroll(unLockActions) {
		clearAllBodyScrollLocks();
		if (unLockActions) {
			let list1 = document.querySelector('#scrollableContainer1');
			disableBodyScroll(list1);
		} else {
			let targetElement = document.querySelector('#app');
			if (targetElement) disableBodyScroll(targetElement);	
		}
	}

	/**
	 * Scroll actions list
	 * @param {object} event 
	 */
	handleScroll(containerId, contentId, name, [value]) {
		if (containerId && contentId && name && value) {
			let adjustedValue = parseInt(value);
			if (adjustedValue <= 10) adjustedValue = 0;

			/* Update input state */
			this.setState({[name]: adjustedValue});

			/* Update container scroll */
			let container = document.querySelector('#' + containerId);
			if (container) {
				let content = document.querySelector('#' + contentId);
				let scrollHeight = content.getBoundingClientRect().height - 
				container.getBoundingClientRect().height;
				let scrollTop = Math.floor(adjustedValue * (scrollHeight / 100.));
				container.scrollTop = parseInt(scrollTop);				
			}
		}
	}

	/**
	 * User started dragging an action
	 */
	handleDragStart(id) {
		this.toggleBodyScroll(false);
		this.setState({draggingItemId: id});
	}

	/**
	 * Update state with result of drag and drop
	 * @param {object} result 
	 */
	updateActionsLists(actions, selected) {
		this.toggleBodyScroll(true);
		let itemId = this.state.draggingItemId;
		if (this.state.isMoving) return;
		this.setState({isMoving: true}, () => {
			let newActions = JSON.parse(JSON.stringify(actions));
			newActions.forEach((action, index) => {action.initialIndex = index;});
			newActions = sortArrayByProperty(newActions, 'index', 'ASC');
			
			let newSelected = JSON.parse(JSON.stringify(selected));
			newSelected.forEach((action, index) => {action.initialIndex = index; action.color = 'blue';});
			newSelected = sortArrayByProperty(newSelected, 'index', 'ASC');
	

			/* Update actions, animate dropped action */
			this.setState({
				draggingItemId: null, 
				isMoving: false, 
				animateDropActionId: itemId, 
				actions: newActions, 
				selected: newSelected
			});
		});
	}

	/**
	 * Reset challenge
	 * @param {array} cachedActions
	 * @param {bool} closePopup 
	 */
	resetChallenge(cachedActions = null, closePopup = false) {
		let availableActions = JSON.parse(JSON.stringify(shuffleArray(actionsData)));
		availableActions.forEach((action, index) => {action.index = index; action.initialIndex = index;});
		let selectedActions = [];
		
		/* Include dummy action to avoid weird DnD bug */
		availableActions.push({...dummyAction, id: 'dummy-1', 
			index: availableActions.length, initialIndex: availableActions.length});
		selectedActions.push({...dummyAction, id: 'dummy-2', index: 0, initialIndex: 0});

		/* Get selected actions from cached profile */
		if (cachedActions) {
			selectedActions = JSON.parse(JSON.stringify(cachedActions));
			availableActions = availableActions.filter((action) => {
				return selectedActions.findIndex((selectedAction) => {return selectedAction.id === action.id;}) < 0;
			});
			availableActions.forEach((action, index) => {action.index = index; action.initialIndex = index;});
		}

		this.setState({
			draggingItemId: null,
			isMoving: false,
			animateDropActionId: null,
			actions: availableActions,
			selected: selectedActions,
		});
		
		if (closePopup) this.props.closePopup();
	}

	/**
	 * Show evaluate actions popup
	 */
	evaluateActions() {
		let greenActions = 0;
		this.state.selected.forEach((action) => {
			if (action.type === 'green') {
				greenActions ++;
			}
		});

		let popupData = JSON.parse(JSON.stringify(popupsData.evaluateActions));
		let popupBtns = [
			{text: generalUiTexts.regret, action: this.resetChallenge, actionParams: [null, true]},
			{text: generalUiTexts.save, action: this.props.saveActions, actionParams: [this.state.selected]}
		];
		let popupText;
		let bgColor;
		if (greenActions === 0) {
			popupText = popupData.texts.red;
			bgColor = 'redActions';
		} else if (greenActions === 1) {
			popupText = popupData.texts.yellow;
			bgColor = 'yellowActions';
		} else {
			popupText = popupData.texts.green;
			bgColor = 'greenActions';
		}
		this.props.openPopup(null, popupText, popupBtns, null, null, null, bgColor);
	}

	getDropTargetElementsAtPoint(x, y, dropTargets) {
		return dropTargets.filter((t) => {
			const rect = t.getBoundingClientRect();
			return (
				x >= rect.left &&
				x <= rect.right &&
				y <= rect.bottom &&
				y >= rect.top
			);
		});
	}

	/**
	 * Render component
	 */
	render() {
		if (this.state.showAvatarInfo) {
			return (
				<AvatarInfoController 
					page="part2"
					avatar={this.props.profile}
					goToStep={null} 
					toggleAvatarInfo={this.toggleAvatarInfo}
					handleRefresh={null}
					openPopup={this.props.openPopup}
					closePopup={this.props.closePopup}
				/>
			);
		}

		/* Check if elementsFromPoint is supported */
		const elementsFromPointSupported =
			(document && (document.elementsFromPoint || document.msElementsFromPoint));


		let dragIsDisabled = this.state.isAnimating;
		let actions = sortArrayByProperty(this.state.actions, 'initialIndex', 'ASC');
		let selected = sortArrayByProperty(this.state.selected, 'initialIndex', 'ASC');
		return (
			<div aria-hidden={this.props.popupIsOpen} style={{height: '100%'}}>
				<DndProvider
					backend={TouchBackend}
					options={{
						enableMouseEvents: true,
						getDropTargetElementsAtPoint: !elementsFromPointSupported && this.getDropTargetElementsAtPoint
					}}
				>
					<Actions
						dragIsDisabled={dragIsDisabled}
						toggleAvatarInfo={this.toggleAvatarInfo}
						actionsScrollPos={this.state.actionsScrollPos}
						numberOfPlaceholders={this.state.numberOfPlaceholders}
						maxSelected={this.state.maxSelected}
						actions={actions}
						selected={selected}
						toggleBodyScroll={this.toggleBodyScroll}
						updateActionsLists={this.updateActionsLists}
						evaluateActions={this.evaluateActions}
						profile={this.props.profile}
						animateDropActionId={this.state.animateDropActionId}
						handleScroll={this.handleScroll}
						handleDragStart={this.handleDragStart}
					/>
				</DndProvider>
				<Button class="goBack" onClick={() => {this.props.goToStep('interests');}} />	
				<Button class="help" onClick={() => {this.openIntroPopup();}} />	
			</div>
		);
	}
}

ActionsController.defaultProps = {
	cachedProfile: null
};

ActionsController.propTypes = {
	popupIsOpen: PropTypes.bool.isRequired,
	introPopupStatuses: PropTypes.object.isRequired,
	openPopup: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
	goToStep: PropTypes.func.isRequired,
	saveActions: PropTypes.func.isRequired,
	profile: PropTypes.object.isRequired,
	cachedProfile: PropTypes.object,
	updateIntroPopupStatus: PropTypes.func.isRequired,
};


export default ActionsController;