import React from 'react';
import PropTypes from 'prop-types';
import './fullscreen-toggle.scss';

const FullscreenToggle = ({isFullscreen, handleToggleFullscreen, type}) => {
	let className = 'FullscreenToggle' + (isFullscreen ? ' FullscreenToggle--isFullscreen' : '');
	if (type) className = className += ' FullscreenToggle--' + type;
	return (
		<div className={className} onClick={() => {handleToggleFullscreen();}} />
	);
};

FullscreenToggle.propTypes = {
	isFullscreen: PropTypes.bool.isRequired,
	handleToggleFullscreen: PropTypes.func.isRequired,
	type: PropTypes.string
};

export default FullscreenToggle;