let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;

if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

let apiURL = 'http://localhost:8080/api/';
if (env === 'test') {
	apiURL = 'https://cgl-vuc-test.web.app/api/';
}

if (env === 'demo') {
	apiURL = 'https://cgl-vuc-demo.web.app/api/';
}

if (env === 'production') {
	apiURL = 'https://cgl-vuc-production.web.app/api/';
}

if (env === 'adaptive-test') {
	apiURL = 'https://cgl-vuc-adaptive-test.web.app/api/';
}

const gameConfig = {
	env: env,
	name: 'vuc',
	apiURL: apiURL,
	adminFullAccess: ['diana@cphgamelab.dk', 'it@cphgamelab.dk']
};

export default gameConfig;