import React from 'react';
import PropTypes from 'prop-types';
import {popupsData} from 'data/popups-data';
import AvatarStats from './avatar-stats';
import './avatar-stats.scss';

class AvatarStatsController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			prevStatsColors: null,
			statsColors: this.props.statsColors,
			popupStatId: null,
			popupStatText: null
		};
		this.showStatPopup = this.showStatPopup.bind(this);
	}

	/**
	 * Component will receive props
	 * @param {object} nextProps 
	 */
	componentWillReceiveProps(nextProps) {
		/* Only update prevStatsColors if next color changes */
		if (nextProps.statsColors) {
			if (!this.state.prevStatsColors) {
				this.setState({
					prevStatsColors: this.props.statsColors,
					statsColors: nextProps.statsColors
				});
			}  else {
				let prevStatsColors = JSON.parse(JSON.stringify(this.state.prevStatsColors));
				if (this.props.statsColors.danish !== nextProps.statsColors.danish) {
					prevStatsColors.danish = this.props.statsColors.danish;
				}
				if (this.props.statsColors.reading !== nextProps.statsColors.reading) {
					prevStatsColors.reading = this.props.statsColors.reading;
				}
				if (this.props.statsColors.math !== nextProps.statsColors.math) {
					prevStatsColors.math = this.props.statsColors.math;
				}
				if (this.props.statsColors.teamwork !== nextProps.statsColors.teamwork) {
					prevStatsColors.teamwork = this.props.statsColors.teamwork;
				}
				if (this.props.statsColors.workEthic !== nextProps.statsColors.workEthic) {
					prevStatsColors.workEthic = this.props.statsColors.workEthic;
				}

				this.setState({
					prevStatsColors: prevStatsColors,
					statsColors: nextProps.statsColors
				});
			}
		}
	}

	/**
	 * Show popup with info about stat
	 * @param {string} statId 
	 */
	showStatPopup(statId) {
		let popupStatId = (statId === this.state.popupStatId ? null : statId);
		let popupStatText = null;

		if (popupStatId) {
			// We only show initial stats in popup 
			// let statValue = (this.props.stats 
			// 	? this.props.stats[statId] <= 5 ? this.props.stats[statId] : 5 
			// 	: this.props.avatar.stats[statId] <= 5 ? this.props.avatar.stats[statId] : 5
			// );
			let statValue = this.props.avatar.stats[statId] <= 5 ? this.props.avatar.stats[statId] : 5;
			if (popupsData.challengeAvatarStatInfo[statId].some(
				(text) => {return text.values.indexOf(statValue) >= 0;})
			) {
				popupStatText = popupsData.challengeAvatarStatInfo[statId].filter(
					(text) => {return text.values.indexOf(statValue) >= 0;}
				)[0].text;
				popupStatText = popupStatText.replace(/%name%/g, this.props.avatar.name);
				popupStatText = popupStatText.replace(/%stat%/g, statValue);
				popupStatText = popupStatText.replace(/%Pronoun%/g, (this.props.avatar.gender === 'f' ? 'Hun' : 'Han'));
				popupStatText = popupStatText.replace(/%pronoun%/g, (this.props.avatar.gender === 'f' ? 'hun' : 'han'));
				

			}
			
		}
		
		this.setState({popupStatId, popupStatText});
	}


	/**
	 * Render component
	 */
	render() {
		let handleClickStat = null;
		if (this.props.type === 'quiz' && this.props.handleGoToStat) handleClickStat = this.props.handleGoToStat;
		if (this.props.type === 'challenge' || this.props.type === 'goals') handleClickStat = this.showStatPopup;

		return (			
			<AvatarStats 
				viewMode = {this.props.viewMode}
				type = {this.props.type}	
				avatar = {this.props.avatar}
				stats = {this.props.stats}
				prevStatsColors = {this.state.prevStatsColors}
				statsColors = {this.state.statsColors}
				requiredStats = {this.props.requiredStats}
				energy = {this.props.energy}
				initialEnergy = {this.props.initialEnergy}
				popupStatId = {this.state.popupStatId}
				popupStatText = {this.state.popupStatText}
				handleRefresh = {this.props.handleRefresh}
				handleClickStat = {handleClickStat}
			/>
		);
	}
}

AvatarStatsController.defaultProps = {
	viewMode: 'portrait',
	type: null,
	avatar: null,
	stats: null,
	statsColors: null,
	requiredStats: null,
	energy: null,
	handleRefresh: null,
	handleGoToStat: null
};

AvatarStatsController.propTypes = {
	viewMode: PropTypes.string,
	type: PropTypes.string,
	avatar: PropTypes.object,
	stats: PropTypes.object,
	statsColors: PropTypes.object,
	requiredStats: PropTypes.object,
	energy: PropTypes.number,
	initialEnergy: PropTypes.number,
	handleRefresh: PropTypes.func,
	handleGoToStat: PropTypes.func
};

export default AvatarStatsController;
