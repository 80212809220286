import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDragLayer} from 'react-dnd';
import {useSpring, animated} from 'react-spring';
import {getItemStyles} from 'helpers/dnd-helper';
import ChallengeAction from './challenge-action';
import './challenge-dnd-drag-layer.scss';

const ItemDragLayer = ({actions, selected}) => {
	const {
		isDragging,
		item,
		initialOffset,
		currentOffset,
	} = useDragLayer((monitor) => {
		return {
			item: monitor.getItem(),
			itemType: monitor.getItemType(),
			initialOffset: monitor.getInitialSourceClientOffset(),
			currentOffset: monitor.getSourceClientOffset(),
			isDragging: monitor.isDragging(),
		};
	});

	// Interpolates scale into a css transform
	const trans = (s) => {return `scale(${s})`;};
	const [springProps, set] = useSpring(() => {return {scale: 1};});
	const [itemProps, setItem] = useState([{}, {}, {}]);

	useEffect(() => {
		set(() => {
			return {
				scale: isDragging ? 1.1 : 1, 
				config: { friction: 8, tension: 80, mass: 1}
			};
		});

	}, [isDragging, set]); 

	useEffect(() => {
		if (item && initialOffset && currentOffset) setItem([item, initialOffset, currentOffset]);
	}, [item, initialOffset, currentOffset]);

	
	/* Get info of action being dragged */
	let action = null;
	if (itemProps && itemProps[0] && itemProps[0].id) {
		if (actions.some((action) => {return action.id === itemProps[0].id;})) {
			action = actions.filter((action) => {return action.id === itemProps[0].id;})[0];
		} else {
			if (selected.some((action) => {return action.id === itemProps[0].id;})) {
				action = selected.filter((action) => {return action.id === itemProps[0].id;})[0];
			}
		}
	}
	return (
		<div className="ChallengeAction-dragLayer">
			{action &&
				// <div style={getItemStyles(itemProps[1], itemProps[2])} >
				<div style={getItemStyles(initialOffset, currentOffset)} >
					<animated.div style={{transform: springProps.scale.interpolate((trans)) }}>
						<ChallengeAction 
							type={'actions'}
							animateDrop={false}
							action={action}
						/>
						<div className="ChallengeAction-dragHandle" />
					</animated.div>
				</div>	
			}
		</div>
	);
};

ItemDragLayer.propTypes = {
	actions: PropTypes.array.isRequired,
	selected: PropTypes.array.isRequired
};

export default ItemDragLayer;