import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/firestore';
import Backup from './backup';

class BackupController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isDownloading: null,
			isUploading: false,
			fileToUpload: null,
			feedbackMsg: null
		};
		this.downloadGoalsData = this.downloadGoalsData.bind(this);
		this.selectFileToUpload = this.selectFileToUpload.bind(this);
		this.uploadFileToDatabase = this.uploadFileToDatabase.bind(this);
		this.readJSONFile = this.readJSONFile.bind(this);
	};

	/**
	 * Download goals data from database
	 */
	downloadGoalsData() {
		if (this.state.isDownloading !== null) return;
		this.setState({isDownloading: 'goalsData'});
		const db = firebase.firestore();
		db.collection('goals').get().then((docs) => {
			let goalsData = [];
			docs.forEach((doc) => {
				let pageData = doc.data();
				pageData.id = doc.id;
				goalsData.push(pageData);
			});

			let collection = {
				timestamp: Math.floor(Date.now() / 1000),
				collectionId: 'goals',
				documents: goalsData
			};
			
			let json = JSON.stringify(collection, null, 4);
			let blob = new Blob([json], {type: 'text/json'});
			let url = window.URL.createObjectURL(blob);
			let link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'goalsData_' + db._databaseId.projectId + '.json');
			document.body.appendChild(link);
			link.click();
			link.parentNode.removeChild(link);
			this.setState({isDownloading: null});
		}).catch((error) => {
			console.error(error);
			this.setState({isDownloading: null});
		});
	}

	/**
	 * Select json-file
	 * @param {object} event 
	 */
	selectFileToUpload(event) {
		if (event.target.files.length > 1) {
			let feedbackMsg = 'Choose 1 file only!';
			this.setState({feedbackMsg: feedbackMsg});
		} else {
			this.setState({fileToUpload: event.target.files[0]});
		}
	}


	/**
	 * Upload json file to database
	 */
	uploadFileToDatabase() {
		let fileToUpload = this.state.fileToUpload;
		let allowedFileNames = [
			'goalsData_cgl-vuc-test.json',
			'goalsData_cgl-vuc-demo.json'
		];
		if (allowedFileNames.indexOf(fileToUpload.name) === -1) {
			let feedbackMsg = 'Wrong file name';
			console.error(feedbackMsg);
			this.setState({feedbackMsg: feedbackMsg});
		} else {
			this.setState({isUploading: true});
			this.readJSONFile(fileToUpload).then((file)=> {	
				if (file.hasOwnProperty('collectionId') && file.hasOwnProperty('documents')) {
					let collectionId = file.collectionId;
					const db = firebase.firestore();
					let batch = db.batch();
					// Update each document in collection
					file.documents.forEach((document) => {
						let documentId = document.id;
						let documentObj = JSON.parse(JSON.stringify(document));
						delete documentObj.id;

						let documentRef = db.collection(collectionId).doc(documentId);
						batch.set(documentRef, documentObj);
					});

					// Commit batch
					batch.commit().then(() => {
						this.setState({
							isUploading: false,
							feedbackMsg: 'Upload successful'
						});
						setTimeout(() => {
							this.setState({ feedbackMsg: null });
						}, 3000);
					}).catch((error) => {
						console.error(error);
						this.setState({
							isUploading: false,
							feedbackMsg: 'Something went wrong'
						});
					});
				} else {
					this.setState({isUploading: false});
					console.error('wrong format');
				}
			});
		}
	}

	/**
	 * Read json file from client computer
	 * @param {object} file
	 */
	readJSONFile(file) {
		return new Promise((resolve, reject)=>{
			let fileReader = new FileReader();
			fileReader.readAsText(file, 'UTF-8');
			fileReader.onerror = (event)=>{
				reject(event.error);
			};
			fileReader.onloadend = (event)=>{
				let textData = event.target.result;
				let data = JSON.parse(textData);
				resolve(data);
			};
		});
	}

	/**
	 * Render component
	 */
	render() {
		let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
		let databaseProject;
		if (env === 'development' || env === 'test' | env === 'adaptive-test') {
			databaseProject = 'test';
		} else if (env === 'demo') {
			databaseProject = 'demo';
		} else if (env === 'production') {
			databaseProject = 'production';
		}
		return (
			<Backup
				db={databaseProject}
				isDownloading={this.state.isDownloading}
				isUploading={this.state.isUploading}
				downloadGoalsData={this.downloadGoalsData}
				goToPage={this.props.goToPage}
				selectFileToUpload={this.selectFileToUpload}
				uploadFileToDatabase={this.uploadFileToDatabase}
				feedbackMsg={this.state.feedbackMsg}
			/>
		);
	}
}


BackupController.propTypes = {
	goToPage: PropTypes.func.isRequired
};

export default BackupController;