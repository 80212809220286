import React from 'react';
import PropTypes from 'prop-types';
import {interestsData} from 'data/interests-data';
import {getStatsColors2} from 'helpers/stats-helper';
import {avatarUiTexts} from 'data/ui-texts';
import Carousel from 'components/layout/carousel';
import CarouselLandscape from 'components/layout/carousel-landscape';
import AvatarStatsController from 'components/avatar-stats/avatar-stats-controller';
import InterestCard from './interest-card';
import Button from 'components/button/button';
import AvatarImage from 'components/avatar-image/avatar-image';
import './interests.scss';

const Interests = ({
	viewMode,
	handleSelectInterest, 
	toggleAvatarInfo,
	profile,
	openIntroPopup,
	goToStep
}) => {

	let CarouselComponent = (viewMode === 'landscape' ? CarouselLandscape : Carousel);
	let statsColors = getStatsColors2(profile.stats);

	return (
		<div className={'Interests Interests--' + viewMode}>
			<div className="Interests-header">
				<Button viewMode={viewMode} class="goBack2" onClick={() => {goToStep('quiz');}} />
				<Button viewMode={viewMode} class="help" onClick={() => {openIntroPopup();}} />
				{viewMode === 'landscape' && 
					<React.Fragment>						
						<div className="Interests-headerAvatarInfo">
							<div className="Interests-headerAvatarInfoText">
								<span>{profile.name}</span>
								<div>({profile.age} {avatarUiTexts.years})</div>
							</div>					
						</div>
						<div className="Interests-headerAvatarStats">
							<AvatarStatsController 
								viewMode="landscape"
								type="interests" 
								avatar={{...profile, id: 0}}
								stats={profile.stats}
								statsColors={statsColors}
								handleRefresh={() => {return null;}}
							/>			
						</div>
						<AvatarImage 
							viewMode="landscape" 
							avatarId={0} 
							initialLetter={profile.name.charAt(0).toUpperCase()}
							type="interests" 
						/>
					</React.Fragment>
				}
			</div>
			{viewMode !== 'landscape' && <AvatarStatsController
				type="quiz"
				avatar={profile}
				stats={profile.stats}
				handleRefresh={() => {toggleAvatarInfo(true);}}
				statsColors={statsColors}
			/>}
			<div className="Interests-carousel">
				<CarouselComponent
					type="interests"
					initialIndex={2}
					cards={interestsData}
					Card={InterestCard}
					handleSelectCard={handleSelectInterest}
				/>
			</div>
		</div>
	);
};

Interests.propTypes = {
	viewMode: PropTypes.string.isRequired,
	handleSelectInterest: PropTypes.func.isRequired,
	toggleAvatarInfo: PropTypes.func.isRequired,
	profile: PropTypes.object.isRequired,
	openIntroPopup: PropTypes.func.isRequired,
	goToStep: PropTypes.func.isRequired
};

export default Interests;