import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Motion, spring } from 'react-motion';
import {moveAction2, moveAction3, findAction} from 'helpers/dnd-helper2';
import {getStatsColors2} from 'helpers/stats-helper';
import {generalUiTexts} from 'data/ui-texts';
import ActionsHeader from './actions-header';
import ChallengeDndContainer from 'components/challenge-landscape/challenge-dnd-container';
import ChallengeDndItem from 'components/challenge-landscape/challenge-dnd-item';
import ChallengeAction from 'components/challenge/challenge-action';
import Button from 'components/button/button';
import './actions.scss';

const Actions = ({
	showAvatarInfo,
	dragIsDisabled,
	initialActions,
	actions,
	selected,
	placeHolderActions,
	toggleAvatarInfo,
	handleDragEnd,
	evaluateActions,
	profile,
	animateDropActionId,
	handleDragStart,
	goToStep,
	openIntroPopup
}) => {

	let [actionsList, setActions] = useState([]);
	useEffect(() => {setActions(actions);}, [actions]);

	let [selectedList, setSelected] = useState([]);
	useEffect(() => {setSelected(selected);}, [selected]);

	let [placeHoldersList, setPlaceholders] = useState([]);
	useEffect(() => {setPlaceholders(placeHolderActions);}, [placeHolderActions]);


	/**
	 * Move action from available to selected position or from selected to selected
	 * @param {string} id 
	 * @param {number} fromIndex
	 * @param {number} toIndex 
	 */
	const handleMoveAction3 = (id, fromIndex, toIndex) => {
		let [newActionsList, newSelectedList, newPlaceHoldersList] = 
			moveAction3(initialActions, actionsList, placeHoldersList, id, fromIndex, toIndex);
		setActions(newActionsList);
		setSelected(newSelectedList);
		setPlaceholders(newPlaceHoldersList);
	};

	/**
	 * Move from selected back to available
	 * @param {string} id 
	 */
	const handleMoveAction2 = (id) => {
		let [newActionsList, newSelectedList, newPlaceHoldersList] = 
			moveAction2(id, initialActions, actionsList, placeHoldersList);
		setActions(newActionsList);
		setSelected(newSelectedList);
		setPlaceholders(newPlaceHoldersList);
	};

	/**
	 * Find action 
	 * @param {string} id 
	 */
	const handleFindAction = (id) => {
		return findAction(id, actionsList, placeHoldersList);
	};

	/**
	 * Drop action
	 */
	let dropAction = () => {
		handleDragEnd(actionsList, selectedList);
	};

	/* Get stats colors */
	let statsColors = getStatsColors2(profile.stats);

	
	return (
		<div className="ActionsLandscape">
			<ActionsHeader
				showAvatarInfo={showAvatarInfo}
				avatar = {{...profile, id: 0}}
				toggleAvatarInfo  = {toggleAvatarInfo}
				statsColors={statsColors}
				goToStep={goToStep}
				openIntroPopup={openIntroPopup}
				selectedList={selectedList}
				evaluateActions={evaluateActions}
			/>
			{/* Body  */}
			<div className="ActionsLandscape-body">

				{/* Selected actions */}
				<div className="ActionsLandscape-selected">
					<ChallengeDndContainer 
						containerId="selected"
					>
						{placeHoldersList.map((placeholderAction, index) => {
							let action = JSON.parse(JSON.stringify(placeholderAction));
							if (placeholderAction.id && selected.some((action2) => {
								return action2.id === placeholderAction.id;
							})) {
								action = selected.filter((action) => {
									return action.id === placeholderAction.id;
								})[0];
								action.index = placeholderAction.index;
								action.initialIndex = placeholderAction.initialIndex;
							} else {
								if (placeholderAction.id && initialActions.some((action2) => {
									return action2.id === placeholderAction.id;
								})) {
									action = initialActions.filter((action) => {
										return action.id === placeholderAction.id;
									})[0];
									action.index = placeholderAction.index;
									action.initialIndex = placeholderAction.initialIndex;
								}
							}							
							return (
								<Motion key={index}
									style={{ 
										x: spring(placeholderAction.index * 3.45, { stiffness: 500, damping: 32 }),
									}}      
								>
									{({x}) => {
										return (							
											<ChallengeDndItem
												type="part2"
												id={action.id}
												index={placeholderAction.index}
												dragIsDisabled={dragIsDisabled || !action.id}
												style={{transform: 'translate3d(' + x + 'em, 0, 0)'}}
												handleDragStart={handleDragStart}
												dropAction={dropAction}
												handleMoveAction={handleMoveAction3}
												handleFindAction={handleFindAction}
												toggleActionInfo={() => {}}
											>
												<ChallengeAction
													type="selected"
													viewMode="landscape"
													animateDrop={animateDropActionId === action.id}
													action={{...action, color: (action.id ? 'blue' : null)}}
													avatar={profile}
													selectedList={selected}
													toggleActionInfo={() => {}}
												/>
											</ChallengeDndItem>
										);
									}}
								</Motion>								

							);
						})}		
					</ChallengeDndContainer>
					{[...Array(3)].map((_, index) => {
						return (
							<div 
								key={index} 
								className={'ActionsLandscape-selectedArrow ActionsLandscape-selectedArrow--' + index}
							/>
						);
					})}
					<Button
						viewMode="landscape"
						class="evaluateActions"
						text={generalUiTexts.save}
						inactive={selected.length < 3}
						onClick={() => {evaluateActions();}}
					/>
				</div>

				{/* Available actions */}
				<div className="ActionsLandscape-options">
					<div className="ActionsLandscape-optionArrows">
						{[...Array(6)].map((_, index) => {
							return <div key={index} className="ActionsLandscape-optionArrow" />;
						})}
					</div>
					<ChallengeDndContainer
						containerId="actions"
						handleFindAction={handleFindAction}
						handleMoveAction2={handleMoveAction2}
					>

						{actionsList.map((action, index) => {
							return (
								<ChallengeDndItem
									key={index}
									id={action.id}
									isSelected = {action.isSelected === true}
									dragIsDisabled={dragIsDisabled || action.isSelected === true}
									handleDragStart={handleDragStart}
									dropAction={dropAction}
									handleMoveAction={handleMoveAction3}
									handleFindAction={handleFindAction}
									toggleActionInfo={() => {}}
								>
									<ChallengeAction
										type="actions"
										viewMode="landscape"
										dragIsDisabled={dragIsDisabled || action.isSelected === true}
										animateDrop={animateDropActionId === action.id && action.isSelected === false}
										action={action}
										initialActions={initialActions}
										toggleActionInfo={() => {}}
									/>
								</ChallengeDndItem>
							);
						})}
					</ChallengeDndContainer>
				</div>
			</div>
		</div>
	);
};

Actions.propTypes = {
	showAvatarInfo: PropTypes.bool.isRequired,
	dragIsDisabled: PropTypes.bool.isRequired,
	initialActions: PropTypes.array,
	actions: PropTypes.array.isRequired,
	selected: PropTypes.array.isRequired,
	placeHolderActions: PropTypes.array.isRequired,
	toggleAvatarInfo: PropTypes.func.isRequired,
	handleDragEnd: PropTypes.func.isRequired,
	evaluateActions: PropTypes.func.isRequired,
	profile: PropTypes.object.isRequired,
	animateDropActionId: PropTypes.number,
	handleDragStart: PropTypes.func.isRequired,
	goToStep: PropTypes.func.isRequired,
	openIntroPopup: PropTypes.func.isRequired
};

export default Actions;