const actionTypes = {
	education: 'Uddannelse',
	job: 'Job'
};

const dummyAction = {
	id: 'dummy', 
	type: 'dummy', 
	index: -1,
	initialIndex: -1,
	cost: 0, 
	title: 'dummy', 
	status: 'dummy',
	requirements: [], 
	rewards: []
};

const actionsData = [
	/*************************************** EDUCATION ***************************************/
	/* 9. klasse */
	{
		id: 'public-school',
		type: 'education',
		energy: 1,
		cost: 4,
		title: '9. klasse (AVU fag)',
		title2: '9. klasse <br />(AVU fag)',
		requirements: [
			{type: 'stat', statId: 'danish', value: 2},
			{type: 'stat', statId: 'reading', value: 2},
			{type: 'stat', statId: 'math', value: 2},
			{type: 'stat', statId: 'teamwork', value: 2},
			{type: 'stat', statId: 'workEthic', value: 2}
		],
		subRequirements: {
			type: 'allStats',
			requirements: [],
			maxPointsDifference: 1,
			maxNumberOfStats: 3
		},
		rewards: [
			{statId: 'reading', value: 1},
			{statId: 'math', value: 1},
		],
	},
	/* Grundforløb (EUD) */
	{
		id: 'basic-eud',
		type: 'education',
		energy: 1,
		cost: 12,
		title: 'Grundforløb (EUV)',
		requirements: [
			{type: 'action', actionId: 'public-school'},
			{type: 'stat', statId: 'danish', value: 3},
			{type: 'stat', statId: 'reading', value: 3},
			{type: 'stat', statId: 'math', value: 4},
			{type: 'stat', statId: 'teamwork', value: 2},
			{type: 'stat', statId: 'workEthic', value: 3}
		],
		subRequirements: {
			type: 'allStats',
			requirements: [
				{type: 'action', actionId: 'public-school'},
			],
			maxPointsDifference: 1,
			maxNumberOfStats: 3
		},
		rewards: [
			{statId: 'danish', value: 1},
			{statId: 'reading', value: 1},
			{statId: 'math', value: 2},
			{statId: 'teamwork', value: 1},
			{statId: 'workEthic', value: 1}
		]
	},
	/* HF enkeltfag */
	{
		id: 'hf-single-subject',
		type: 'education',
		energy: 1,
		cost: 8,
		title: 'Hf-enkeltfag',
		requirements: [
			{type: 'action', actionId: 'public-school'},
			{type: 'stat', statId: 'danish', value: 3},
			{type: 'stat', statId: 'reading', value: 3},
			{type: 'stat', statId: 'math', value: 2},
			{type: 'stat', statId: 'teamwork', value: 2},
			{type: 'stat', statId: 'workEthic', value: 3}
		],
		subRequirements: {
			type: 'allStats',
			requirements: [
				{type: 'action', actionId: 'public-school'},
			],			
			maxPointsDifference: 1,
			maxNumberOfStats: 3
		},
		rewards: [
			{statId: 'danish', value: 1},
			{statId: 'reading', value: 1},
			{statId: 'math', value: 1},
			{statId: 'teamwork', value: 1},
			{statId: 'workEthic', value: 1}
		]
	},
	/* Gymnasial eksamen */
	{
		id: 'highschool',
		type: 'education',
		energy: 1,
		cost: 12,
		title: 'Gymnasial eksamen',
		requirements: [
			{type: 'action', actionId: 'public-school'},
			{type: 'stat', statId: 'danish', value: 3},
			{type: 'stat', statId: 'reading', value: 3},
			{type: 'stat', statId: 'math', value: 2},
			{type: 'stat', statId: 'teamwork', value: 3},
			{type: 'stat', statId: 'workEthic', value: 3}
		],
		subRequirements: {
			type: 'allStats',
			requirements: [
				{type: 'action', actionId: 'public-school'},
			],
			maxPointsDifference: 1,
			maxNumberOfStats: 3
		},
		rewards: [
			{statId: 'danish', value: 2},
			{statId: 'reading', value: 2},
			{statId: 'math', value: 2},
			{statId: 'teamwork', value: 2},
			{statId: 'workEthic', value: 1}
		]
	},
	/* EUX */
	{
		id: 'eux',
		type: 'education',
		energy: 1,
		cost: 18,
		title: 'EUX',
		requirements: [
			{type: 'action', actionId: 'public-school'},
			{type: 'stat', statId: 'danish', value: 3},
			{type: 'stat', statId: 'reading', value: 3},
			{type: 'stat', statId: 'math', value: 3},
			{type: 'stat', statId: 'teamwork', value: 3},
			{type: 'stat', statId: 'workEthic', value: 3}
		],
		subRequirements: {
			type: 'allStats',
			requirements: [
				{type: 'action', actionId: 'public-school'},
			],
			maxPointsDifference: 1,
			maxNumberOfStats: 3
		},
		rewards: [
			{statId: 'danish', value: 2},
			{statId: 'reading', value: 2},
			{statId: 'math', value: 2},
			{statId: 'teamwork', value: 2},
			{statId: 'workEthic', value: 1}
		]
	},
	/* Professionshøjskole */
	{
		id: 'college',
		type: 'education',
		energy: 1,
		cost: 14,
		title: 'Professionshøjskole',
		title2: 'Professions- højskole',
		requirements: [
			{type: 'action', actionIds: ['highschool', 'specific-eud']},
			{type: 'stat', statId: 'danish', value: 5},
			{type: 'stat', statId: 'reading', value: 4},
			{type: 'stat', statId: 'math', value: 3},
			{type: 'stat', statId: 'teamwork', value: 3},
			{type: 'stat', statId: 'workEthic', value: 4}
		],
		subRequirements: {
			type: 'allStats',
			requirements: [
				{type: 'action', actionId: 'highschool'},
			],
			maxPointsDifference: 1,
			maxNumberOfStats: 3
		},
		rewards: [
			{statId: 'danish', value: 2},
			{statId: 'reading', value: 2},
			{statId: 'math', value: 1},
			{statId: 'teamwork', value: 1},
			{statId: 'workEthic', value: 1}
		]
	},
	/* Erhvervsakademi */
	{
		id: 'business-academy',
		type: 'education',
		energy: 1,
		cost: 16,
		title: 'Erhvervsakademi',
		title2: 'Erhvervs- akademi',
		requirements: [
			{type: 'action', actionIds: ['highschool', 'specific-eud']},
			{type: 'stat', statId: 'danish', value: 5},
			{type: 'stat', statId: 'reading', value: 4},
			{type: 'stat', statId: 'math', value: 3},
			{type: 'stat', statId: 'teamwork', value: 4},
			{type: 'stat', statId: 'workEthic', value: 4}
		],
		subRequirements: {
			type: 'allStats',
			requirements: [
				{type: 'action', actionId: 'highschool'}
			],
			maxPointsDifference: 1,
			maxNumberOfStats: 3
		},
		rewards: [
			{statId: 'danish', value: 2},
			{statId: 'reading', value: 2},
			{statId: 'math', value: 1},
			{statId: 'teamwork', value: 1},
			{statId: 'workEthic', value: 2}
		]
	},
	/* Universitet */
	{
		id: 'university',
		type: 'education',
		energy: 1,
		cost: 18,
		title: 'Universitet',
		requirements: [
			{type: 'action', actionId: 'highschool'},
			{type: 'stat', statId: 'danish', value: 5},
			{type: 'stat', statId: 'reading', value: 4},
			{type: 'stat', statId: 'math', value: 4},
			{type: 'stat', statId: 'teamwork', value: 4},
			{type: 'stat', statId: 'workEthic', value: 5}
		],
		subRequirements: {
			type: 'allStats',
			requirements: [
				{type: 'action', actionId: 'highschool'}
			],
			maxPointsDifference: 1,
			maxNumberOfStats: 3
		},
		rewards: [
			{statId: 'danish', value: 2},
			{statId: 'reading', value: 2},
			{statId: 'math', value: 1},
			{statId: 'teamwork', value: 2},
			{statId: 'workEthic', value: 2}
		]
	},
	/* Praktikplads */
	{
		id: 'internship',
		type: 'education',
		energy: 1,
		cost: 4,
		title: 'Læreplads',
		requirements: [
			{type: 'action', actionIds: ['basic-eud', 'eux']},
			{type: 'stat', statId: 'danish', value: 2},
			{type: 'stat', statId: 'reading', value: 2},
			{type: 'stat', statId: 'teamwork', value: 1},
			{type: 'stat', statId: 'workEthic', value: 1}
		],
		subRequirements: {
			type: 'allStats',
			requirements: [],
			maxPointsDifference: 1,
			maxNumberOfStats: 3
		},
		rewards: [
			{statId: 'teamwork', value: 1},
			{statId: 'workEthic', value: 1}
		]		
	},
	/* Fagspecifik EUD */
	{
		id: 'specific-eud',
		type: 'education',
		cost: 12,
		title: 'Fagspecifik EUD',
		requirements: [
			{type: 'action', actionId: 'public-school'},
			{type: 'stat', statId: 'danish', value: 3},
			{type: 'stat', statId: 'reading', value: 3},
			{type: 'stat', statId: 'math', value: 2},
			{type: 'stat', statId: 'teamwork', value: 3},
			{type: 'stat', statId: 'workEthic', value: 3}
		],
		subRequirements: {
			type: 'allStats',
			requirements: [
				{type: 'action', actionId: 'public-school'}
			],
			maxPointsDifference: 1,
			maxNumberOfStats: 3
		},
		rewards: [
			{statId: 'danish', value: 2},
			{statId: 'reading', value: 2},
			{statId: 'teamwork', value: 2},
			{statId: 'math', value: 2},
			{statId: 'workEthic', value: 1}
		]
	},
	/* Ordblindekursus */
	{
		id: 'dyslexia-course',
		type: 'education',
		energy: 1,
		cost: 4,
		title: 'Ordblindekursus',
		title2: 'Ordblinde- kursus',
		requirements: [],
		rewards: [
			{statId: 'danish', value: 1},
			{statId: 'reading', value: 1}
		]
	},
	/* AMU-certifikat */
	{
		id: 'amu-certificate',
		type: 'education',
		energy: 1,
		cost: 6,
		title: 'AMU-certificat',
		requirements: [
			{type: 'stat', statId: 'danish', value: 2},
			{type: 'stat', statId: 'reading', value: 2},
			{type: 'stat', statId: 'math', value: 2},
			{type: 'stat', statId: 'teamwork', value: 1},
			{type: 'stat', statId: 'workEthic', value: 3}
		],
		subRequirements: {
			type: 'allStats',
			requirements: [
				{type: 'action', actionId: 'public-school'}
			],
			maxPointsDifference: 1,
			maxNumberOfStats: 3
		},
		rewards: [
			{statId: 'danish', value: 1},
			{statId: 'reading', value: 1},
			{statId: 'workEthic', value: 1}
		]
	},
	/* Læsekursus (FVU) */
	{
		id: 'danish-course-fvu',
		type: 'education',
		energy: 1,
		cost: 6,
		title: 'FVU Dansk',
		requirements: [
			{type: 'action', actionId: 'dyslexia-course', conditional: true},
			{type: 'stat', statId: 'danish', value: 1},
			{type: 'stat', statId: 'reading', value: 1},
			{type: 'stat', statId: 'teamwork', value: 1},
			{type: 'stat', statId: 'workEthic', value: 1}
		],
		subRequirements: {
			type: 'allStats',
			requirements: [],
			maxPointsDifference: 1,
			maxNumberOfStats: 3
		},
		rewards: [
			{statId: 'danish', value: 1},
			{statId: 'reading', value: 2}
		]		
	},
	/* Engelsk-kursus (FVU) */
	{
		id: 'english-course-fvu',
		type: 'education',
		energy: 1,
		cost: 4,
		title: 'FVU Engelsk',
		requirements: [
			{type: 'stat', statId: 'teamwork', value: 1},
			{type: 'stat', statId: 'workEthic', value: 1}
		],
		subRequirements: {
			type: 'allStats',
			requirements: [],
			maxPointsDifference: 1,
			maxNumberOfStats: 3
		},
		rewards: [
			{statId: 'teamwork', value: 1},
			{statId: 'workEthic', value: 1}
		]		
	},
	/* Matematik-kursus (FVU) */
	{
		id: 'math-course-fvu',
		type: 'education',
		energy: 1,
		cost: 4,
		title: 'FVU Matematik',
		requirements: [
			{type: 'stat', statId: 'math', value: 1},
			{type: 'stat', statId: 'teamwork', value: 1},
			{type: 'stat', statId: 'workEthic', value: 1}
		],
		subRequirements: {
			type: 'allStats',
			requirements: [],
			maxPointsDifference: 1,
			maxNumberOfStats: 3
		},
		rewards: [
			{statId: 'math', value: 2}
		]		
	},
	/* IT-kursus (FVU) */
	{
		id: 'it-course-fvu',
		type: 'education',
		energy: 1,
		cost: 6,
		title: 'FVU Digital',
		requirements: [
			{type: 'stat', statId: 'danish', value: 1},
			{type: 'stat', statId: 'reading', value: 1},
			{type: 'stat', statId: 'teamwork', value: 1},
			{type: 'stat', statId: 'workEthic', value: 1}
		],
		subRequirements: {
			type: 'allStats',
			requirements: [],
			maxPointsDifference: 1,
			maxNumberOfStats: 3
		},
		rewards: [
			{statId: 'danish', value: 1},
			{statId: 'reading', value: 1},
			{statId: 'teamwork', value: 1}
		]
	},
	/* Sprog-kursus (FVU) */
	{
		id: 'language-course',
		type: 'education',
		energy: 1,
		cost: 6,
		title: 'Sprog-kursus',
		requirements: [
			{type: 'stat', statId: 'workEthic', value: 2}
		],
		subRequirements: {
			type: 'allStats',
			requirements: [],
			maxPointsDifference: 1,
			maxNumberOfStats: 3
		},
		rewards: [
			{statId: 'danish', value: 1},
			{statId: 'reading', value: 1},
			{statId: 'workEthic', value: 1}
		]		
	},

	/****************************************** JOB ******************************************/
	/* Job som sælger */
	{
		id: 'job-as-salesman',
		type: 'job',
		energy: 1,
		cost: 6,
		title: 'Job som sælger',
		requirements: [
			{type: 'stat', statId: 'danish', value: 3},
			{type: 'stat', statId: 'reading', value: 2},
			{type: 'stat', statId: 'math', value: 2},
			{type: 'stat', statId: 'teamwork', value: 3},
			{type: 'stat', statId: 'workEthic', value: 3}
		],
		subRequirements: {
			type: 'specificStat',
			requirements: [],
			statId: 'danish',
			maxPointsDifference: 2,
		},
		rewards: [
			{statId: 'danish', value: 1},
			{statId: 'teamwork', value: 1},
			{statId: 'workEthic', value: 1}
		]		
	},

	/* Job i supermarked */
	{
		id: 'job-in-supermarket',
		type: 'job',
		energy: 1,
		cost: 4,
		title: 'Job i supermarked',
		requirements: [
			{type: 'stat', statId: 'danish', value: 3},
			{type: 'stat', statId: 'reading', value: 2},
			{type: 'stat', statId: 'teamwork', value: 2},
			{type: 'stat', statId: 'workEthic', value: 3}
		],
		subRequirements: {
			type: 'specificStat',
			requirements: [],
			statId: 'danish',
			maxPointsDifference: 2,
		},
		rewards: [
			{statId: 'teamwork', value: 1},
			{statId: 'workEthic', value: 1}
		]		
	},
	/* Job som brolægger */
	{
		id: 'job-as-bricklayer',
		type: 'job',
		energy: 1,
		cost: 8,
		title: 'Job som brolægger',
		requirements: [
			{type: 'stat', statId: 'danish', value: 1},
			{type: 'stat', statId: 'math', value: 1},
			{type: 'stat', statId: 'teamwork', value: 1},
			{type: 'stat', statId: 'workEthic', value: 2}
		],
		subRequirements: {
			type: 'specificStat',
			requirements: [],
			statId: 'danish',
			maxPointsDifference: 2,
		},		
		rewards: [
			{statId: 'danish', value: 1},
			{statId: 'teamwork', value: 1},
			{statId: 'workEthic', value: 2}
		]
	},
	/* Job i blomsterhandler */
	{
		id: 'job-at-florist',
		type: 'job',
		energy: 1,
		cost: 6,
		title: 'Job i blomsterhandler',
		title2: 'Job i blomster- handler',
		requirements: [
			{type: 'stat', statId: 'danish', value: 1},
			{type: 'stat', statId: 'reading', value: 1},
			{type: 'stat', statId: 'teamwork', value: 2},
			{type: 'stat', statId: 'workEthic', value: 2}
		],
		subRequirements: {
			type: 'specificStat',
			requirements: [],
			statId: 'danish',
			maxPointsDifference: 2,
		},
		rewards: [
			{statId: 'danish', value: 1},
			{statId: 'teamwork', value: 1},
			{statId: 'workEthic', value: 1}
		]
	},
	/* Virksomhedspraktik */
	{
		id: 'job-internship',
		type: 'job',
		energy: 1,
		cost: 6,
		title: 'Virksomhedspraktik',
		title2: 'Virksomheds- praktik',
		requirements: [
			{type: 'stat', statId: 'danish', value: 1},
			{type: 'stat', statId: 'reading', value: 1},
			{type: 'stat', statId: 'teamwork', value: 1},
			{type: 'stat', statId: 'workEthic', value: 1}
		],
		subRequirements: {
			type: 'specificStat',
			requirements: [],
			statId: 'danish',
			maxPointsDifference: 2,
		},
		rewards: [
			{statId: 'danish', value: 1},
			{statId: 'teamwork', value: 1},
			{statId: 'workEthic', value: 1}
		]		
	},	
	/* Praktik i medievirksomhed */ 
	{
		id: 'internship-media',
		type: 'job',
		cost: 8,
		title: 'Praktik i medievirksomhed',
		title2: 'Praktik i medie- virksomhed',
		requirements: [
			{type: 'stat', statId: 'danish', value: 2},
			{type: 'stat', statId: 'reading', value: 2},
			{type: 'stat', statId: 'teamwork', value: 2},
			{type: 'stat', statId: 'workEthic', value: 2}
		],
		subRequirements: {
			type: 'specificStat',
			requirements: [],
			statId: 'danish',
			maxPointsDifference: 2,
		},
		rewards: [
			{statId: 'danish', value: 1},
			{statId: 'reading', value: 1},
			{statId: 'teamwork', value: 1},
			{statId: 'teamwork', value: 1},
			{statId: 'workEthic', value: 1}
		]		
	},
	/* Praktik i butik */
	{
		id: 'internship-in-shop',
		type: 'job',
		energy: 1,
		cost: 6,
		title: 'Praktik i butik',
		requirements: [
			{type: 'stat', statId: 'danish', value: 3},
			{type: 'stat', statId: 'reading', value: 2},
			{type: 'stat', statId: 'math', value: 2},
			{type: 'stat', statId: 'teamwork', value: 3},
			{type: 'stat', statId: 'workEthic', value: 3}
		],
		subRequirements: {
			type: 'specificStat',
			requirements: [],
			statId: 'danish',
			maxPointsDifference: 2,
		},
		rewards: [
			{statId: 'danish', value: 1},
			{statId: 'teamwork', value: 1},
			{statId: 'workEthic', value: 1}
		]		
	},
	/* Job på hotel */
	{
		id: 'job-at-hotel',
		type: 'job',
		energy: 1,
		cost: 6,
		title: 'Job på hotel',
		requirements: [
			{type: 'stat', statId: 'danish', value: 1},
			{type: 'stat', statId: 'reading', value: 1},
			{type: 'stat', statId: 'teamwork', value: 2},
			{type: 'stat', statId: 'workEthic', value: 2}
		],
		subRequirements: {
			type: 'specificStat',
			requirements: [],
			statId: 'danish',
			maxPointsDifference: 2,
		},
		rewards: [
			{statId: 'reading', value: 1},
			{statId: 'teamwork', value: 1},
			{statId: 'workEthic', value: 1}
		]
	},
	/* Job på café */
	{
		id: 'job-at-cafe',
		type: 'job',
		energy: 1,
		cost: 6,
		title: 'Job på café',
		requirements: [
			{type: 'stat', statId: 'danish', value: 2},
			{type: 'stat', statId: 'reading', value: 2},
			{type: 'stat', statId: 'teamwork', value: 3},
			{type: 'stat', statId: 'workEthic', value: 2}
		],
		subRequirements: {
			type: 'specificStat',
			requirements: [],
			statId: 'danish',
			maxPointsDifference: 2,
		},
		rewards: [
			{statId: 'danish', value: 1},
			{statId: 'teamwork', value: 1},
			{statId: 'workEthic', value: 1}
		]
	},
	/* Afløser på plejehjem */
	{
		id: 'nursinghome-temp',
		type: 'job',
		energy: 1,
		cost: 8,
		title: 'Afløser på plejehjem',
		requirements: [
			{type: 'stat', statId: 'danish', value: 2},
			{type: 'stat', statId: 'reading', value: 2},
			{type: 'stat', statId: 'teamwork', value: 3},
			{type: 'stat', statId: 'workEthic', value: 3}
		],
		subRequirements: {
			type: 'specificStat',
			requirements: [],
			statId: 'danish',
			maxPointsDifference: 2,
		},
		rewards: [
			{statId: 'danish', value: 1},
			{statId: 'teamwork', value: 2},
			{statId: 'workEthic', value: 1}
		]		
	},
	/* Handicaphjælper */
	{
		id: 'disability-helper',
		type: 'job',
		energy: 1,
		cost: 6,
		title: 'Handicaphjælper',
		title2: 'Handicap- hjælper',
		requirements: [
			{type: 'stat', statId: 'danish', value: 1},
			{type: 'stat', statId: 'reading', value: 1},
			{type: 'stat', statId: 'teamwork', value: 1},
			{type: 'stat', statId: 'workEthic', value: 2}
		],
		subRequirements: {
			type: 'specificStat',
			requirements: [],
			statId: 'danish',
			maxPointsDifference: 2,
		},
		rewards: [
			{statId: 'danish', value: 1},
			{statId: 'teamwork', value: 1},
			{statId: 'workEthic', value: 1}
		]		
	},
	/* Rengøringsjob */
	{
		id: 'cleaning-job',
		type: 'job',
		cost: 4,
		title: 'Rengøringsjob',
		requirements: [
			{type: 'stat', statId: 'danish', value: 1},
			{type: 'stat', statId: 'workEthic', value: 3}
		],
		rewards: [
			{statId: 'workEthic', value: 2}
		]		
	},
	/* Arbejdsmand i virksomhed */
	{
		id: 'company-worker',
		type: 'job',
		cost: 8,
		title: 'Arbejdsmand i virksomhed',
		requirements: [
			{type: 'stat', statId: 'danish', value: 3},
			{type: 'stat', statId: 'teamwork', value: 3},
			{type: 'stat', statId: 'workEthic', value: 2}
		],
		rewards: [
			{statId: 'danish', value: 1},
			{statId: 'teamwork', value: 2},
			{statId: 'workEthic', value: 1}
		]
	},
	/* Fritidsjob */
	{
		id: 'job-after-school',
		type: 'job',
		cost: 8,
		title: 'Fritidsjob',
		requirements: [
			{type: 'stat', statId: 'danish', value: 1},
			{type: 'stat', statId: 'teamwork', value: 1},
			{type: 'stat', statId: 'workEthic', value: 1}
		],
		subRequirements: {
			type: 'specificStat',
			requirements: [],
			statId: 'danish',
			maxPointsDifference: 2,
		},
		rewards: [
			{statId: 'danish', value: 1},
			{statId: 'teamwork', value: 2},
			{statId: 'workEthic', value: 1}
		]
	},
	/* Job i landbrug */
	{
		id: 'job-in-farming',
		type: 'job',
		cost: 8,
		title: 'Job i landbrug',
		requirements: [
			{type: 'stat', statId: 'danish', value: 2},
			{type: 'stat', statId: 'teamwork', value: 2},
			{type: 'stat', statId: 'workEthic', value: 2}
		],
		subRequirements: {
			type: 'specificStat',
			requirements: [],
			statId: 'danish',
			maxPointsDifference: 2,
		},
		rewards: [
			{statId: 'danish', value: 1},
			{statId: 'teamwork', value: 2},
			{statId: 'workEthic', value: 1}
		]
	},	
	/* Vikar i børnehave */
	{
		id: 'temp-in-kindergarten',
		type: 'job',
		cost: 10,
		title: 'Vikar i børnehave',
		requirements: [
			{type: 'stat', statId: 'danish', value: 2},
			{type: 'stat', statId: 'reading', value: 1},
			{type: 'stat', statId: 'teamwork', value: 2},
			{type: 'stat', statId: 'workEthic', value: 2}
		],
		subRequirements: {
			type: 'specificStat',
			requirements: [],
			statId: 'danish',
			maxPointsDifference: 2,
		},
		rewards: [
			{statId: 'danish', value: 1},
			{statId: 'teamwork', value: 2},
			{statId: 'workEthic', value: 2}
		]
	},
	/* Vikar i skole */
	{
		id: 'temp-in-school',
		type: 'job',
		cost: 10,
		title: 'Vikar i skole',
		requirements: [
			{type: 'stat', statId: 'danish', value: 2},
			{type: 'stat', statId: 'reading', value: 1},
			{type: 'stat', statId: 'math', value: 1},
			{type: 'stat', statId: 'teamwork', value: 2},
			{type: 'stat', statId: 'workEthic', value: 2}
		],
		subRequirements: {
			type: 'specificStat',
			requirements: [],
			statId: 'danish',
			maxPointsDifference: 2,
		},		
		rewards: [
			{statId: 'danish', value: 1},
			{statId: 'teamwork', value: 2},
			{statId: 'workEthic', value: 2}
		]
	},
	/* Job i hjemmeplejen */
	{
		id: 'job-in-home-care',
		type: 'job',
		cost: 8,
		title: 'Job i hjemmeplejen',
		requirements: [
			{type: 'stat', statId: 'danish', value: 2},
			{type: 'stat', statId: 'reading', value: 1},
			{type: 'stat', statId: 'teamwork', value: 2},
			{type: 'stat', statId: 'workEthic', value: 2}
		],
		subRequirements: {
			type: 'specificStat',
			requirements: [],
			statId: 'danish',
			maxPointsDifference: 2,
		},
		rewards: [
			{statId: 'danish', value: 1},
			{statId: 'teamwork', value: 2},
			{statId: 'workEthic', value: 1}
		]
	},
	/* Job på sygehus */
	{
		id: 'job-in-hospital',
		type: 'job',
		cost: 10,
		title: 'Job på sygehus',
		requirements: [
			{type: 'stat', statId: 'danish', value: 2},
			{type: 'stat', statId: 'reading', value: 2},
			{type: 'stat', statId: 'teamwork', value: 2},
			{type: 'stat', statId: 'workEthic', value: 2}
		],
		subRequirements: {
			type: 'specificStat',
			requirements: [],
			statId: 'danish',
			maxPointsDifference: 2,
		},
		rewards: [
			{statId: 'danish', value: 1},
			{statId: 'teamwork', value: 2},
			{statId: 'workEthic', value: 2}
		]
	},
	/* Job i metalvirksomhed */
	{
		id: 'job-in-metal-business',
		type: 'job',
		cost: 8,
		title: 'Job i metalvirksomhed',
		title2: 'Job i metal- virksomhed',
		requirements: [
			{type: 'stat', statId: 'danish', value: 2},
			{type: 'stat', statId: 'reading', value: 2},
			{type: 'stat', statId: 'math', value: 2},
			{type: 'stat', statId: 'teamwork', value: 2},
			{type: 'stat', statId: 'workEthic', value: 2}
		],
		subRequirements: {
			type: 'specificStat',
			requirements: [],
			statId: 'danish',
			maxPointsDifference: 2,
		},
		rewards: [
			{statId: 'danish', value: 1},
			{statId: 'reading', value: 1},
			{statId: 'teamwork', value: 2},
			{statId: 'workEthic', value: 1}
		]
	},
	/* Job på lager */
	{
		id: 'job-in-storage',
		type: 'job',
		cost: 8,
		title: 'Job på lager',
		requirements: [
			{type: 'stat', statId: 'danish', value: 1},
			{type: 'stat', statId: 'reading', value: 1},
			{type: 'stat', statId: 'teamwork', value: 1},
			{type: 'stat', statId: 'workEthic', value: 1}
		],
		rewards: [
			{statId: 'reading', value: 1},
			{statId: 'teamwork', value: 2},
			{statId: 'workEthic', value: 1}
		]
	},
	/* Job i mediebranchen */
	{
		id: 'job-in-media',
		type: 'job',
		cost: 12,
		title: 'Job i mediebranchen',
		title2: 'Job i medie- branchen',
		requirements: [
			{type: 'stat', statId: 'danish', value: 2},
			{type: 'stat', statId: 'reading', value: 2},
			{type: 'stat', statId: 'math', value: 1},
			{type: 'stat', statId: 'teamwork', value: 2},
			{type: 'stat', statId: 'workEthic', value: 2}
		],
		subRequirements: {
			type: 'specificStat',
			requirements: [],
			statId: 'danish',
			maxPointsDifference: 2,
		},
		rewards: [
			{statId: 'danish', value: 1},
			{statId: 'reading', value: 1},
			{statId: 'teamwork', value: 2},
			{statId: 'workEthic', value: 2}
		]
	},
	/* Job i servicebranchen */
	{
		id: 'job-in-service-industry',
		type: 'job',
		cost: 8,
		title: 'Job i servicebranchen',
		title2: 'Job i service- branchen',
		requirements: [
			{type: 'stat', statId: 'danish', value: 2},
			{type: 'stat', statId: 'reading', value: 1},
			{type: 'stat', statId: 'teamwork', value: 2},
			{type: 'stat', statId: 'workEthic', value: 2}
		],
		subRequirements: {
			type: 'specificStat',
			requirements: [],
			statId: 'danish',
			maxPointsDifference: 2,
		},
		rewards: [
			{statId: 'danish', value: 1},
			{statId: 'teamwork', value: 2},
			{statId: 'workEthic', value: 1}
		]
	},
	/* Job i butik */
	{
		id: 'job-in-shop',
		type: 'job',
		cost: 6,
		title: 'Job i butik',
		requirements: [
			{type: 'stat', statId: 'danish', value: 3},
			{type: 'stat', statId: 'reading', value: 1},
			{type: 'stat', statId: 'math', value: 2},
			{type: 'stat', statId: 'teamwork', value: 2},
			{type: 'stat', statId: 'workEthic', value: 2}
		],
		subRequirements: {
			type: 'specificStat',
			requirements: [],
			statId: 'danish',
			maxPointsDifference: 2,
		},
		rewards: [
			{statId: 'danish', value: 1},
			{statId: 'teamwork', value: 1},
			{statId: 'workEthic', value: 1}
		]
	}
];


export{actionTypes, dummyAction, actionsData};