import React from 'react';
import PropTypes from 'prop-types';
import {Range, Direction} from 'react-range';
import './scrollbar.scss';

// Unmount issue: https://github.com/jpuri/react-range-slider/issues/12


const Scrollbar = ({type, viewMode, name, containerId, contentId, value, onChange}) => {
	return (
		<div className={'Scrollbar Scrollbar--' + type + ' Scrollbar--' + viewMode}>
			<Range
				direction={Direction.Down}
				step={0.1}
				min={0.0}
				max={100.0}
				values={[value]}
				onChange={(value) => {onChange(containerId, contentId, name, value);}}
				renderTrack={({ props, children }) => {
					return <div className="Scrollbar-track" {...props}>{children}</div>;
				}}
				renderThumb={({ props }) => {
					return <div {...props} className="Scrollbar-thumb"><div className="Scrollbar-thumbContent" /></div>;
				}}				
			/>	
		</div>
	);
};

Scrollbar.defaultProps = {
	type: 'default',
	viewMode: 'portrait'
};

Scrollbar.propTypes = {
	type: PropTypes.string,
	viewMode: PropTypes.string,
	name: PropTypes.string.isRequired,
	containerId: PropTypes.string.isRequired,
	contentId: PropTypes.string.isRequired,
	value: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired
};

export default Scrollbar;