import React from 'react';
import PropTypes from 'prop-types';
import {avatarUiTexts} from 'data/ui-texts';
import Button from 'components/button/button';
import AvatarStatsController from 'components/avatar-stats/avatar-stats-controller';
import AvatarImage from 'components/avatar-image/avatar-image';
import QuizOverview from './quiz-overview';
import QuizQuestions from './quiz-questions';
import './quiz.scss';

const Quiz = ({
	viewMode,
	popupIsOpen,
	currentStatIndex,
	currentQuestionIndex,
	avatar,
	saveAnswer,
	view,
	nextStat,
	nextQuestion,
	previousQuestion,
	answers,
	stats,
	statsColors,
	toggleAvatarInfo,
	handleGoToStat,
	handleGoBack,
	openIntroPopup,
	animation
}) => {

	return (
		<div className={'Quiz Quiz--' + viewMode} aria-hidden={popupIsOpen}>
			<div className="Quiz-header">
				<Button viewMode={viewMode} class="goBack2" onClick={() => {handleGoBack();}} />
				<Button viewMode={viewMode} class="help" onClick={() => {openIntroPopup();}} />
				{viewMode === 'landscape' && 
					<React.Fragment>						
						<div className="Quiz-headerAvatarInfo">
							<div className="Quiz-headerAvatarInfoText">
								<span>{avatar.name}</span>
								<div>({avatar.age} {avatarUiTexts.years})</div>
							</div>					
						</div>
						<div className="Quiz-headerAvatarStats">
							<AvatarStatsController 
								viewMode="landscape"
								type="quiz" 
								avatar={avatar} 
								stats={stats}
								handleGoToStat={handleGoToStat}
								statsColors={statsColors}
								handleRefresh={() => {return null;}}
							/>			
						</div>
						<AvatarImage 
							viewMode="landscape" 
							avatarId={0} 
							initialLetter={avatar.name.charAt(0).toUpperCase()}
							type="quiz" 
						/>
					</React.Fragment>
				}
			</div>

			{viewMode !== 'landscape' && <AvatarStatsController
				type="quiz"
				avatar={avatar}
				stats={stats}
				statsColors={statsColors}
				handleRefresh={() => {toggleAvatarInfo(true);}}
				handleGoToStat={handleGoToStat}
			/>}

			{/* Questions */}
			{view === 'questions' &&
				<QuizQuestions 
					viewMode={viewMode}
					currentStatIndex={currentStatIndex}
					currentQuestionIndex={currentQuestionIndex}
					saveAnswer={saveAnswer}
					nextQuestion={nextQuestion}
					previousQuestion={previousQuestion}
					answers={answers}
					animation={animation}
				/>
			}

			{/* Overview */}
			{view === 'overview' &&
				<QuizOverview 
					viewMode={viewMode}
					currentStatIndex = {currentStatIndex}
					currentQuestionIndex = {currentQuestionIndex}
					nextStat = {nextStat}
					previousQuestion = {previousQuestion}
					stats = {stats}
				/>
			}
		</div>
	);
};

Quiz.propTypes = {
	viewMode: PropTypes.string.isRequired,
	popupIsOpen: PropTypes.bool.isRequired,
	currentStatIndex: PropTypes.number.isRequired,
	avatar: PropTypes.object.isRequired,
	currentQuestionIndex: PropTypes.number.isRequired,
	saveAnswer: PropTypes.func.isRequired,
	view: PropTypes.string.isRequired,
	nextStat: PropTypes.func.isRequired,
	nextQuestion: PropTypes.func.isRequired,
	previousQuestion: PropTypes.func.isRequired,
	answers: PropTypes.array.isRequired,
	stats: PropTypes.object.isRequired,
	statsColors: PropTypes.object.isRequired,
	toggleAvatarInfo: PropTypes.func.isRequired,
	handleGoToStat: PropTypes.func,
	handleGoBack: PropTypes.func.isRequired,
	openIntroPopup: PropTypes.func.isRequired,
	animation: PropTypes.string
};

export default Quiz;