import React from 'react';
import PropTypes from 'prop-types';
import {actionsData} from 'data/actions-data';
import {statsData} from 'data/stats-data';
import {avatarUiTexts, generalUiTexts} from 'data/ui-texts';
import {calculateStats} from 'helpers/stats-helper';
import {getPrevActions} from 'helpers/challenge-helper';
import { sortArrayByProperty } from 'helpers/array-helper';
import './challenge-action-info.scss';

const ActionInfoLandscape = (props) => {
	let {
		actionId, 
		actionInfoClass, 
		actionInfoStyle, 
		avatar, 
		selectedActions, 
		initialActions, 
		toggleActionInfo
	} = props;

	let sortedSelectedActions = sortArrayByProperty(JSON.parse(JSON.stringify(selectedActions)), 'index', 'ASC');
	let prevSelectedActions = getPrevActions(actionId, sortedSelectedActions);
	let stats = calculateStats(avatar.stats, prevSelectedActions);
	let actionData = JSON.parse(JSON.stringify(initialActions)).filter((action) => {
		return action.id === actionId;
	})[0];
	
	let requiredActions = [];
	actionData.requirements.forEach((requirement) => {
		if (requirement.type === 'action') {
			let requirementIsMet = false;
			if (requirement.hasOwnProperty('actionIds')) {
				let title = '';
				requirement.actionIds.forEach((actionId, index) => {
					let requirementData = actionsData.filter((action) => {
						return action.id === actionId;
					})[0];
					if (index === 0) {
						title = requirementData.title;
					} else {
						title = title + ' / <br/>' + requirementData.title;
					}
					if (prevSelectedActions.some((action) => {return action.id === actionId;})) requirementIsMet = true;
					if (avatar.actions.indexOf(actionId) >= 0) requirementIsMet = true;
				});
				requiredActions.push({
					title: title,
					requirementIsMet: requirementIsMet
				});
			} else {
				let requirementData = actionsData.filter((action) => {
					return action.id === requirement.actionId;
				})[0];
				if (prevSelectedActions.some((action) => {return action.id === requirement.actionId;})) {
					requirementIsMet = true;
				}
				if (avatar.actions.indexOf(requirement.actionId) >= 0) requirementIsMet = true;
				requiredActions.push({
					title: requirementData.title,
					requirementIsMet: requirementIsMet
				});
			}
		}
	});

	let hasActionRequirements = (requiredActions && requiredActions.length > 0);

	return (
		<div className={'ActionInfoLandscape ActionInfoLandscape--' + actionInfoClass} style={actionInfoStyle}> 
			<div className={'ActionInfoLandscape-header' + 
				(hasActionRequirements ? ' ActionInfoLandscape-header--big' : '')}>
				{hasActionRequirements && 
					<div className="ActionInfoLandscape-title">{avatarUiTexts.formalRequirements}:</div>}
				<div className="ActionInfoLandscape-closeBtn" onClick={() => {toggleActionInfo(null);}}></div>
				{hasActionRequirements && 
					<div className={'ActionInfoLandscape-actionRequirements'}>
						{requiredActions.map((action, index) => {
							return (
								<div 
									key={index} 
									className={'ActionInfoLandscape-requirementTitle' + 
									(action.title.includes('br') ? ' ActionInfoLandscape-requirementTitle--2' : '') +
									(action.requirementIsMet ? ' ActionInfoLandscape-requirementTitle--ok' : '')}
									dangerouslySetInnerHTML={{__html: action.title}}>
								</div>
							);
						})}
					</div>}
			</div>
			<div className="ActionInfoLandscape-stats">
				<div className="ActionInfoLandscape-statTypes">
					{statsData.map((stat) => {
						return (
							<div 
								key={stat.id} 
								className={'ActionInfoLandscape-statType ActionInfoLandscape-statType--' + stat.id} 
							/>
						);
					})}
				</div>
				<div className="ActionInfoLandscape-statsRequirements">
					<div className="ActionInfoLandscape-requiredStatLabel">{generalUiTexts.requires}</div>
					{statsData.map((stat) => {
						let requiredValue = 0;
						if (actionData.requirements.some((requirement) => {
							return requirement.type === 'stat' && requirement.statId === stat.id;
						})) {
							requiredValue = actionData.requirements.filter((requirement) => {
								return requirement.type === 'stat' && requirement.statId === stat.id;
							})[0].value;
						}
						let valueIsMet = stats[stat.id] >= requiredValue;
						return (
							<div key={stat.id} className={'ActionInfoLandscape-requiredStatValue' + 
								(valueIsMet ? ' ActionInfoLandscape-requiredStatValue--ok' : '')}>
								<span>{requiredValue !== 0 ? requiredValue : ' '}</span>
							</div>
						);
					})}
				</div>
				<div className="ActionInfoLandscape-reward">
					<div className="ActionInfoLandscape-rewardLabel">{generalUiTexts.rewards}</div>
					{statsData.map((stat) => {
						let rewardedValue = 0;
						if (actionData.rewards.some((reward) => {return reward.statId === stat.id;})) {
							rewardedValue = actionData.rewards.filter((reward) => {
								return reward.statId === stat.id;
							})[0].value;
						}
						return (
							<div key={stat.id} className="ActionInfoLandscape-rewardedValue">
								<span>{rewardedValue !== 0 ? '+' + rewardedValue : ' '}</span>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};


ActionInfoLandscape.propTypes = {
	actionId: PropTypes.string.isRequired,
	actionInfoClass: PropTypes.string.isRequired,
	actionInfoStyle: PropTypes.object.isRequired,
	avatar: PropTypes.object.isRequired,
	selectedActions: PropTypes.array.isRequired,
	initialActions: PropTypes.array.isRequired,
	toggleActionInfo: PropTypes.func.isRequired
};

export default ActionInfoLandscape;