import React from 'react';
import PropTypes from 'prop-types';
import './circular-progress.scss';

const CircularProgress = (props) => {
	let {
		viewMode,
		max,
		percentageFactor,
		radius,
		stroke,
		lightColor,
		mainColor,
		negativeColor
	} = props;
	let progress = (percentageFactor * 100) / max;
	let normalizedRadius = radius - stroke * 2;
	let circumference = normalizedRadius * 2 * Math.PI;
	let strokeDashoffset = -(circumference - progress / 100 * circumference);
	return (
		<div className={'CircularProgress CircularProgress--' + viewMode}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				preserveAspectRatio="xMinYMin meet"
				height={radius * 2 + 'em'}
				width={radius * 2 + 'em'}
			>
				<circle
					stroke={lightColor}
					fill="transparent"
					strokeWidth={(stroke - 0.01) + 'em'}
					strokeDasharray={circumference + 'em ' + circumference + 'em' }
					r={normalizedRadius + 'em'}
					cx={radius + 'em'}
					cy={radius + 'em'}
					vectorEffect="non-scaling-stroke"
				/>
				<circle
					stroke={progress >= 0 ? mainColor : negativeColor}
					fill="transparent"
					strokeWidth={stroke + 'em'}
					strokeDasharray={circumference + 'em ' + circumference + 'em' }
					style={{strokeDashoffset: strokeDashoffset + 'em'}}
					r={normalizedRadius + 'em'}
					cx={radius + 'em'}
					cy={radius + 'em'}
					vectorEffect="non-scaling-stroke"
				/>
			</svg>
		</div>
	);
};

CircularProgress.defaultProps = {
	percentageFactor: null,
	radius: null,
	stroke: null,
	lightColor: '#ccc',
	mainColor: '#000',
	negativeColor: '#f00'
};

CircularProgress.propTypes = {
	viewMode: PropTypes.string.isRequired,
	percentageFactor: PropTypes.number.isRequired,
	radius: PropTypes.number.isRequired,
	stroke: PropTypes.number.isRequired,
	lightColor: PropTypes.string,
	mainColor: PropTypes.string,
	negativeColor: PropTypes.string,
	max: PropTypes.number.isRequired
};

export default CircularProgress;