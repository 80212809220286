import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts} from 'data/ui-texts';
import Button from 'components/button/button';
import './interest-card.scss';

const InterestCard = ({viewMode, ariaHidden, card, handleSelectCard}) => {
	let [interestTitle, updateInterestTitle] = useState(null);

	return (
		<div className={'InterestCard InterestCard--' + viewMode} aria-hidden={ariaHidden} >
			<div className="InterestCard-title">{card.title}</div>
			<div className={'InterestCard-image InterestCard-image--' + card.id} />
			<div className="InterestCard-text">{card.description}</div>
			{card.id === 'user' && 
				<div className="InterestCard-form">
					<input
						className='InterestCard-input'
						name="title"
						placeholder="Jobtitle"
						maxLength="30"
						onClick={(event) => {event.stopPropagation();}}
						onChange={(event)=>{updateInterestTitle(event.target.value);}}
					/>
				</div>
			}
			{card.id !== 'user' && <div className="InterestCard-line"></div>}
			<div className="InterestCard-selectCard">
				<Button 
					viewMode={viewMode}
					text={generalUiTexts.choose} 
					onClick={() => {handleSelectCard(card.id, interestTitle);}}
				/>
			</div>	
		</div>
	);
};

InterestCard.propTypes = {
	viewMode: PropTypes.string.isRequired,
	ariaHidden: PropTypes.bool.isRequired,
	card: PropTypes.object.isRequired,
	handleSelectCard: PropTypes.func.isRequired,
};

export default InterestCard;