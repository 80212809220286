import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Motion, spring } from 'react-motion';
import {moveAction, moveAction2, findAction} from 'helpers/dnd-helper';
import {generalUiTexts} from 'data/ui-texts';
import AvatarStatsController from 'components/avatar-stats/avatar-stats-controller';
import ChallengeDndContainer from './challenge-dnd-container';
import ChallengeDndItem from './challenge-dnd-item';
import ChallengeDndDragLayer from './challenge-dnd-drag-layer';
import ChallengeAction from 'components/challenge/challenge-action';
import ChallengeActionInfo from 'components/challenge/challenge-action-info';
import Scrollbar from 'components/layout/scrollbar';
import Button from 'components/button/button';
import './challenge.scss';

const Challenge = (props) => {
	let {
		isBuying,
		tutorialIsOpen,
		showGoalInfo,
		dragIsDisabled,
		buyBtnIsActive,
		requiredActionsSelected,
		showActionsScrollbar,
		showSelectedScrollbar,
		numberOfPlaceholders,
		challengeStatus,
		draggingItemId,
		avatar, 
		stats,
		statsColors,
		goal, 
		energy,
		initialEnergy,
		initialActions,
		actionId,
		mouseCoordinates,
		animateDropActionId,
		requiredActions,
		actions,
		selected,
		actionsScrollPos,
		selectedScrollPos,
		toggleBodyScroll,
		toggleAvatarInfo,
		toggleGoalInfo,
		toggleActionInfo,
		handleScroll,
		handleDragStart,
		handleDragEnd,
		handleStartChallenge,
		challengeFinished
	} = props;

	let [actionsList, setActions] = useState([]);
	useEffect(() => {setActions(actions);}, [actions]);
	
	let [selectedList, setSelected] = useState([]);
	useEffect(() => {setSelected(selected);}, [selected]);

	// let actionPlaceholders = 
	// (challengeStatus === 'playing' && !isBuying && selectedList.length <= numberOfPlaceholders
	let actionPlaceholders = (challengeStatus === 'playing' && !isBuying && actionsList.length > 1
		? [...Array(Math.max(numberOfPlaceholders - selectedList.length + 1, 1))].map((_, index) => {return index;})
		: []
	);

	/**
	 * Move action to another action's position
	 * @param {string} id 
	 * @param {string} toId 
	 */
	const handleMoveAction = (id, toId) => {
		let [newActionsList, newSelectedList] = moveAction(id, toId, actionsList, selectedList);
		setActions(newActionsList);
		setSelected(newSelectedList);
	};

	/**
	 * Move action to another container
	 * @param {string} id 
	 */
	const handleMoveAction2 = (id) => {
		let [newActionsList, newSelectedList] = moveAction2(id, actionsList, selectedList);
		setActions(newActionsList);
		setSelected(newSelectedList);
	};

	/**
	 * Find action 
	 * @param {string} id 
	 */
	const handleFindAction = (id) => {
		return findAction(id, actionsList, selectedList);
	};

	/**
	 * Drop action
	 */
	let dropAction = () => {
		handleDragEnd(actionsList, selectedList);
	};

	// let minHeight = 26.5;
	// let availableActionsHeight = Math.max(actionsList.length * 4.5, minHeight) + 1.5;
	// let selectedActionsHeight = Math.max(selectedList.length * 4.5, (numberOfPlaceholders + 1) * 4.5) + 4.5;
	let availableActionsHeight = (actionsList.length - 1) * 4.5;
	let selectedActionsHeight = Math.max(selected.length, numberOfPlaceholders) * 4.9 + 3;

	return (
		<div className="Challenge" aria-hidden={tutorialIsOpen}>
			<div className="Challenge-avatarStats" aria-hidden={actionId !== null}>
				<AvatarStatsController 
					type="challenge" 
					avatar={avatar} 
					stats={stats} 
					statsColors={statsColors}
					energy={energy}
					initialEnergy={initialEnergy}
					handleRefresh={() => {toggleAvatarInfo(true);}}
				/>
			</div>
			<div className="Challenge-body" aria-hidden={actionId !== null}>
				{/* Goal */}
				<div 
					aria-hidden={!showGoalInfo}
					className={'Challenge-goal' +
						(showGoalInfo ? ' Challenge-goal--open' : '') + 
						(challengeFinished ? ' finished' : (requiredActionsSelected ? ' green' : ''))}
					onClick={() => {toggleGoalInfo(!showGoalInfo);}}
				>
					<div className="Challenge-goalRequiredActions">
						{requiredActions.map((action, index) => {
							let className = 'Challenge-goalRequiredAction' + (action.isSelected ? ' selected' : '');
							return <div key={index} className={className}>{action.title}</div>;
						})}
					</div>
					<div className="Challenge-goalTitle"><span>{goal.title}</span></div>
				</div>


				{/* Available actions */}
				<div id="scrollableContainer1" className="Challenge-options">
					<ChallengeDndContainer 
						containerId="actions" 
						containerHeight={availableActionsHeight}
						handleFindAction={handleFindAction}
						handleMoveAction2={handleMoveAction2}
					>
						{actionsList.map((action, index) => {		
							return (
								<Motion 
									key={index}
									style={{ y: spring(action.index * 4.5, { stiffness: 500, damping: 32 }) }}      
								>
									{({ y }) => {
										return (
											<ChallengeDndItem
												id={action.id}
												dragIsDisabled={dragIsDisabled}
												style={{transform: 'translate3d(0, ' + y + 'em, 0)'}}
												toggleBodyScroll={toggleBodyScroll}
												handleDragStart={handleDragStart}
												dropAction={dropAction}
												handleMoveAction={handleMoveAction}
												handleFindAction={handleFindAction}
												toggleActionInfo={toggleActionInfo}
											>
												<ChallengeAction 
													type="actions"
													animateDrop={animateDropActionId === action.id}
													action={action}
													initialActions={initialActions}
													toggleActionInfo={toggleActionInfo}
												/>
											</ChallengeDndItem>
										);
									}}
								</Motion>
							);
						})}
					</ChallengeDndContainer>
				</div>
				<div id="scrollableContainer2" className={'Challenge-path' + (isBuying ? ' smoothScroll' : '')}>
					<ChallengeDndContainer 
						containerId="selected"
						containerHeight={selectedActionsHeight}
						handleFindAction={handleFindAction}
						handleMoveAction2={handleMoveAction2}
					>
						{selectedList.map((action, index) => {
							return (
								<Motion 
									key={index}
									style={{ y: spring(action.index * 4.9, { stiffness: 500, damping: 32 }) }}      
								>
									{({ y }) => {
										return (
											<ChallengeDndItem
												id={action.id}
												dragIsDisabled={dragIsDisabled || action.status === 'bought'}
												style={{transform: 'translate3d(0, ' + y + 'em, 0)'}}
												toggleBodyScroll={toggleBodyScroll}
												handleDragStart={handleDragStart}
												dropAction={dropAction}
												handleMoveAction={handleMoveAction}
												handleFindAction={handleFindAction}
												toggleActionInfo={toggleActionInfo}
											>
												<ChallengeAction 
													type="selected"
													animateDrop={animateDropActionId === action.id}
													action={action}
													avatar={avatar}
													selectedList={selected}
													toggleActionInfo={toggleActionInfo}
												/>
											</ChallengeDndItem>
										);
									}}
								</Motion>
							);
						})}
						{actionPlaceholders.map((_, index) => {
							let y = (selectedList.length + index - 1) * 4.9;
							return (
								<div 
									key={index} 
									style={{transform: 'translate3d(0, ' + y + 'em, 0)'}}
									className="Challenge-actionWrap"
								><div className="ChallengeAction-placeholder" /></div>
							);
						})}
					</ChallengeDndContainer>
				</div>
				<ChallengeDndDragLayer actions={actions} selected={selected} />


				{/* Scrollbars */}
				{showActionsScrollbar && <Scrollbar 
					name="actionsScrollPos"
					containerId = "scrollableContainer1"
					contentId = "actions"
					type="actions" 
					value={actionsScrollPos}
					onChange={handleScroll}
				/>}
				{showSelectedScrollbar && <Scrollbar 
					name="selectedScrollPos"
					containerId = "scrollableContainer2"
					contentId = "selected"
					type="selected" 
					value={selectedScrollPos}
					onChange={handleScroll}
				/>}
			</div>

			{/* Start btn */}
			{challengeStatus === 'playing' && 
				<div id = "startBtn" className={'Challenge-buyBtn' + (draggingItemId ? 
					' Challenge-buyBtn--dragging' : '')} aria-hidden={true}>
					<Button 
						class="startChallenge"
						text={generalUiTexts.start} 
						inactive={!buyBtnIsActive}
						onClick={() => {handleStartChallenge();}}
					/>
				</div>}
			
			{/* Action info */}
			<div 
				id="challengeOverlay"
				className={'Challenge-overlay' + (actionId ? ' Challenge-overlay--open' : '')}
				aria-hidden={tutorialIsOpen}
				onClick={(event) => {event.stopPropagation(); toggleActionInfo(null);}} 
				onTouchMove={(event) => {event.stopPropagation(); toggleActionInfo(null);}} 
			>
				<ChallengeActionInfo 
					actionId={actionId}
					mouseCoordinates={mouseCoordinates}
					avatar={avatar}
					selectedActions={selected}
					initialActions={initialActions}
					toggleActionInfo={toggleActionInfo}
				/>
			</div>
		</div>
	);
};



Challenge.propTypes = {
	isBuying: PropTypes.bool.isRequired,
	tutorialIsOpen: PropTypes.bool.isRequired,
	showGoalInfo: PropTypes.bool.isRequired,
	dragIsDisabled: PropTypes.bool.isRequired,
	buyBtnIsActive: PropTypes.bool.isRequired,
	requiredActionsSelected: PropTypes.bool.isRequired,
	showActionsScrollbar: PropTypes.bool.isRequired,
	showSelectedScrollbar: PropTypes.bool.isRequired,
	numberOfPlaceholders: PropTypes.number.isRequired,
	challengeStatus: PropTypes.string.isRequired,
	draggingItemId: PropTypes.string,
	avatar: PropTypes.object.isRequired,
	stats: PropTypes.object.isRequired,
	statsColors: PropTypes.object.isRequired,
	goal: PropTypes.object.isRequired,
	energy: PropTypes.number.isRequired,
	initialEnergy: PropTypes.number,
	initialActions: PropTypes.array,
	actionId: PropTypes.string,
	mouseCoordinates: PropTypes.array,
	animateDropActionId: PropTypes.string,
	requiredActions: PropTypes.array.isRequired,
	actions: PropTypes.array.isRequired,
	selected: PropTypes.array.isRequired,
	actionsScrollPos: PropTypes.number,
	selectedScrollPos: PropTypes.number,
	toggleBodyScroll: PropTypes.func.isRequired,
	toggleAvatarInfo: PropTypes.func.isRequired,
	toggleGoalInfo: PropTypes.func.isRequired,
	toggleActionInfo: PropTypes.func.isRequired,
	handleScroll: PropTypes.func,
	handleDragStart: PropTypes.func.isRequired,
	handleDragEnd: PropTypes.func.isRequired,
	handleStartChallenge: PropTypes.func.isRequired,
	challengeFinished: PropTypes.bool.isRequired
};

export default Challenge;