import React from 'react';
import PropTypes from 'prop-types';

const AdminLogin = ({
	handleInput,
	handleLogin,
	email,
	password,
	feedback,
	isLoading
}) => {
	let buttonClass = 'Button Button--login' + (isLoading ? ' Button--Loading' : '');
	let emailValue = email ? email : '';
	let passwordValue = password ? password : '';

	return (
		<div className="Login--teacher">
			<div className="Login-title">Admin</div>
			<form className={'Login-form'} onSubmit={(event) =>{handleLogin(event);}}>
				<input 
					className='Input' 
					name="email" 
					placeholder="email" 
					autoComplete="section-teacherlogin email"
					value={emailValue} 
					onChange={(event)=>{handleInput(event);}}
				/>
				<input 
					className='Input' 
					name="password"
					type="password"
					placeholder="password"
					autoComplete="section-teacherlogin password"
					value={passwordValue} 
					onChange={(event)=>{handleInput(event);}}
				/>
				{/* <p
					className="Login-resetPassword" 
					onClick={()=>{goToTeacherBox('resetPassword');}}>{uiTexts.resetPassword}</p> */}
				<button className={buttonClass} onClick={(event)=>{handleLogin(event);}}>
					Log ind
				</button>
				<p className={'Login-errorMessage'}>{feedback}</p>
			</form>
		</div>
	);
};

AdminLogin.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	email: PropTypes.string,
	password: PropTypes.string,
	feedback: PropTypes.string,
	handleLogin: PropTypes.func.isRequired,
	handleInput: PropTypes.func.isRequired	
};

export default AdminLogin;