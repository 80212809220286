const actions = [
	{
		id: 1,
		type: 'green',
		title: 'Tage FVU Dansk'
	},
	{
		id: 2,
		type: 'green',
		title: 'Tage FVU Matematik'
	},
	{
		id: 3,
		type: 'green',
		title: 'Tage FVU Engelsk'
	},
	{
		id: 4,
		type: 'green',
		title: 'Tage FVU Digital'
	},
	{
		id: 5,
		type: 'green',
		title: 'Blive bedre til at læse'
	},
	{
		id: 6,
		type: 'green',
		title: 'Blive bedre til at samarbejde'
	},
	{
		id: 7,
		type: 'green',
		title: 'Blive bedre til at møde til tiden'
	},
	{
		id: 8,
		type: 'green',
		title: 'Starte i jobpraktik'
	},
	{
		id: 9,
		type: 'green',
		title: 'Tage et AMU kursus'
	},
	{
		id: 10,
		type: 'green',
		title: 'Starte en uddannelse'
	},
	{
		id: 11,
		type: 'green',
		title: 'Søge job'
	},
	{
		id: 12,
		type: 'green',
		title: 'Få en mentor'
	},
	{
		id: 13,
		type: 'green',
		title: 'Lære noget nyt'
	},
	{
		id: 14,
		type: 'yellow',
		title: 'Finde en fritidsinteresse'
	},
	{
		id: 15,
		type: 'yellow',
		title: 'Dyrke mine venskaber'
	},
	{
		id: 16,
		type: 'yellow',
		title: 'Blive frivillig'
	},
	{
		id: 17,
		type: 'yellow',
		title: 'Kursus i mindfulness'
	},
	// {
	// 	id: 18,
	// 	type: 'red',
	// 	title: 'Holde ferie'
	// },
	// {
	// 	id: 19,
	// 	type: 'red',
	// 	title: 'Hygge mig derhjemme'
	// },
]

export default actions;