import React from 'react';
import PropTypes from 'prop-types';
import {statsData} from 'data/stats-data';
import {generalUiTexts, avatarUiTexts} from 'data/ui-texts';
import AvatarImage from 'components/avatar-image/avatar-image';
import Button from 'components/button/button';
import './avatar-info.scss';

const AvatarInfo = ({page, avatar, goToStep, toggleAvatarInfo, openIntroPopup}) => {
	// let namePossessive = avatar.name.substr(-1, 1) === 's' ? avatar.name + '\'' : avatar.name + 's';
	// let introText = avatarUiTexts.avatarIntro.replace(/%name%/g, namePossessive);
	// introText = introText.replace(/%possessive%/g, (avatar.gender === 'f' ? 'hendes' : 'hans'));

	return (
		<React.Fragment>
			{goToStep && <Button class="goBack" onClick={() => {goToStep('avatars');}} />}
			<div 
				className={'AvatarInfo' + (page ? ' AvatarInfo--' + page : '')}
				onClick={() => { 
					if (toggleAvatarInfo) {
						toggleAvatarInfo(false);
					} else {
						if (goToStep) goToStep('goals');
					}
				}}
			>
				{page !== 'part2' && 
					<Button class="help" onClick={(event) => {event.stopPropagation(); openIntroPopup();}} />}
				<div className="AvatarInfo-card">
					<AvatarImage avatarId={avatar.id} type="avatarInfo" onClick={null}
						initialLetter={avatar.id === 0 ? avatar.name.charAt(0).toUpperCase() : null }
					/>
					<div className="AvatarInfo-body">
						<div className="AvatarInfo-name">
							{avatar.name} <span>({avatar.age} {avatarUiTexts.years})</span>
						</div>
						{page !== 'part2' && <div className="AvatarInfo-intro">
							<span>{avatar.description}</span>
						</div>}
						{page === 'part2' && <div className="AvatarInfo-intro">
							<span>{avatarUiTexts.profileIntro}</span>
						</div>}
						<div className="AvatarInfo-stats">
							{statsData.map((stat) => {
								let avatarStatValue = 
									(avatar.stats && avatar.stats[stat.id] ? avatar.stats[stat.id] : '-');
								return <div key={stat.id} className={'AvatarInfo-stat AvatarInfo-stat--' + stat.id}>
									{stat.name}<span aria-hidden={true}>{avatarStatValue}</span></div>;
							})}
						</div>
						{/* {avatar.description && 
							<div className="AvatarInfo-description"><span>{avatar.description}</span></div>} */}
						{(page === 'part2' && avatar.interest && avatar.interest.title) && 
							<div className="AvatarInfo-description">
								<span>{avatarUiTexts.interests}: {avatar.interest.title}</span>
							</div>}
						<div className="AvatarInfo-okBtn">
							{goToStep && <Button text={generalUiTexts.ok} onClick={() => {goToStep('goals');}}/>}
							{toggleAvatarInfo && 
								<Button text={generalUiTexts.ok} onClick={() => {toggleAvatarInfo(false);}}/>}
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

AvatarInfo.propTypes = {
	page: PropTypes.string,
	avatar: PropTypes.object.isRequired,
	goToStep: PropTypes.func,
	toggleAvatarInfo: PropTypes.func,
	openIntroPopup: PropTypes.func.isRequired
};

export default AvatarInfo;