import React from 'react';
import PropTypes from 'prop-types';
import {Range, Direction} from 'react-range';
import './scrollbar2.scss';

// Unmount issue: https://github.com/jpuri/react-range-slider/issues/12


const Scrollbar2 = ({value, onChange}) => {
	return (
		<div className="Scrollbar2">
			<Range
				direction={Direction.Down}
				step={0.1}
				min={0.0}
				max={100.0}
				values={[value]}
				onChange={(value) => {onChange(value);}}
				renderTrack={({ props, children }) => {
					return <div className="Scrollbar2-track" {...props}>{children}</div>;
				}}
				renderThumb={({ props }) => {
					return <div {...props} className="Scrollbar2-thumb" />;
				}}				
			/>	
		</div>
	);
};

Scrollbar2.propTypes = {
	value: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired
};

export default Scrollbar2;