import React from 'react';
import PropTypes from 'prop-types';
import {avatarUiTexts, generalUiTexts} from 'data/ui-texts';
import Button from 'components/button/button';
import './goal-card-landscape.scss';

const GoalCardLandscapeLandscape = ({ariaHidden, card, handleSelectCard}) => {
	return (
		<div className="GoalCardLandscape" aria-hidden={ariaHidden} >
			<div className="GoalCardLandscape-title"><span>{card.title}</span></div>
			<div className={'GoalCardLandscape-image GoalCardLandscape-image--' + card.iconId} />
			<div className="GoalCardLandscape-text"><span>{card.description}</span></div>
			<div className="GoalCardLandscape-requirements">
				<div className="GoalCardLandscape-requirementsTitle">{avatarUiTexts.actionRequirements}:</div>
				{card.requiredActions.map((action, index) => {
					return <div key={index} className="GoalCardLandscape-requirement"><span>{action}</span></div>;
				})}
			</div>
			{!ariaHidden &&
			<div className="GoalCardLandscape-selectCard">
				<Button 
					viewMode={'landscape'}
					text={generalUiTexts.choose} 
					onClick={() => {handleSelectCard(card.id);}}
				/>
			</div>}
		</div>
	);
};

GoalCardLandscapeLandscape.propTypes = {
	ariaHidden: PropTypes.bool.isRequired,
	card: PropTypes.object.isRequired,
	handleSelectCard: PropTypes.func.isRequired,
};

export default GoalCardLandscapeLandscape;