// Convert seconds to dd/mm-yy
function formatDate(seconds) {
	let dateObj = new Date(seconds * 1000);
	let month = String(dateObj.getMonth() + 1);
	let day = String(dateObj.getDate());
	const year = String(dateObj.getFullYear());

	if (month.length < 2) month = '0' + month;
	if (day.length < 2) day = '0' + day;

	return `${day}/${month}-${year}`;
}

function secondsToMinutes(seconds) {
	let minutes =  Math.floor(seconds / 60.);
	let secs = seconds - (60 * minutes);
	if (minutes <= 15) return minutes + ':' + (secs < 10 ? '0' : '') + secs;
	return '+15 min';
}

function getYear(seconds) {
	const dateObj = new Date(seconds * 1000);
	const year = String(dateObj.getFullYear());
	return year;
}


export {
	formatDate,
	secondsToMinutes,
	getYear
};