import React from 'react';
import marked from 'marked';

/**
* Wraps strings from Array into <p>-tags
* @param {Array} paragraphArray - array of strings
* @return Html 
*/
function addParagraph(paragraphArr) {
	return paragraphArr.map((item, index) => {
		return  <p key={index}>{item}</p>;
	});
}

/**
 * Wraps formatted text from Array into <p>-tags (with tags!)
 * @param {Array} paragraphArr - array with text
 * @return Html
 */
function addParagraphWithTags(paragraphArr) {
	return paragraphArr.map((item, index) => {
		let element = <p key={index} dangerouslySetInnerHTML={{__html: item}} />;
		return element;
	});
}

/**
 * Markdown
 * @param {string} marked
 * @return {html}
 */
function renderMarkdown(markdown) {
	let lexed = marked.lexer(markdown);
	let parsed = marked.parser(lexed);
	return <div dangerouslySetInnerHTML={{__html: parsed}} />;
}

export {addParagraph, addParagraphWithTags, renderMarkdown};