const statsData = [
	{
		id: 'teamwork',
		name: 'Samarbejde',
		name2: 'samarbejde',
		type: 'job'
	},
	{
		id: 'danish',
		name: 'Dansk',
		name2: 'dansk',
		type: 'education'
	},
	{
		id: 'reading',
		name: 'Læsning',
		name2: 'læsning',
		type: 'education'
	},
	{
		id: 'math',
		name: 'Matematik',
		name2: 'matematik',
		type: 'education'
	},
	{
		id: 'workEthic',
		name: 'Stabilitet',
		name2: 'at overholde aftaler',
		type: 'job'
	}	
];

const defaultStats = {teamwork: 0, danish: 0, reading: 0, math: 0, workEthic: 0};
const defaultStatsColors = {danish: 'green', reading: 'green', math: 'green', teamwork: 'green', workEthic: 'green'};
const maxStatValue = 5;



export{statsData, defaultStats, defaultStatsColors, maxStatValue};