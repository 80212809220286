import React from 'react';
import PropTypes from 'prop-types';
import {actionsData} from 'data/actions-data';
import {statsData} from 'data/stats-data';
import {avatarUiTexts, generalUiTexts} from 'data/ui-texts';
import {calculateStats} from 'helpers/stats-helper';
import {getPrevActions} from 'helpers/challenge-helper';
import './challenge-action-info.scss';
import { sortArrayByProperty } from 'helpers/array-helper';

const ActionInfo = (props) => {
	let {actionId, mouseCoordinates, avatar, selectedActions, initialActions, toggleActionInfo} = props;

	if (!actionId) {
		let className = 'ActionInfo' + (mouseCoordinates ? ' ActionInfo--closed' : '');
		let overlayElement = document.getElementById('challengeOverlay');
		if (overlayElement && overlayElement.getBoundingClientRect() && mouseCoordinates) {
			let boundingBox = overlayElement.getBoundingClientRect();
			let actionHeight = (boundingBox.width * (4.9 / 22.5));
			let column = ((mouseCoordinates[0] <= (boundingBox.width / 2.)) ? 'left' : 'right');
			let row = (mouseCoordinates[1] - boundingBox.y) / actionHeight;			
			className = className + ' ' + column + '-' + Math.floor(row);
		} else {
			className = className + ' center-center';
		}
		return <div className={className} />;
	}


	let sortedSelectedActions = sortArrayByProperty(JSON.parse(JSON.stringify(selectedActions)), 'index', 'ASC');
	let prevSelectedActions = getPrevActions(actionId, sortedSelectedActions);
	let stats = calculateStats(avatar.stats, prevSelectedActions);
	let actionData = JSON.parse(JSON.stringify(initialActions)).filter((action) => {
		return action.id === actionId;
	})[0];
	
	let requiredActions = [];
	actionData.requirements.forEach((requirement) => {
		if (requirement.type === 'action') {
			let requirementIsMet = false;
			if (requirement.hasOwnProperty('actionIds')) {
				let title = '';
				requirement.actionIds.forEach((actionId, index) => {
					let requirementData = actionsData.filter((action) => {
						return action.id === actionId;
					})[0];
					if (index === 0) {
						title = requirementData.title;
					} else {
						title = title + ' / <br/>' + requirementData.title;
					}
					if (prevSelectedActions.some((action) => {return action.id === actionId;})) requirementIsMet = true;
					if (avatar.actions.indexOf(actionId) >= 0) requirementIsMet = true;
				});
				requiredActions.push({
					title: title,
					requirementIsMet: requirementIsMet
				});
			} else {
				let requirementData = actionsData.filter((action) => {
					return action.id === requirement.actionId;
				})[0];
				if (prevSelectedActions.some((action) => {return action.id === requirement.actionId;})) {
					requirementIsMet = true;
				}
				if (avatar.actions.indexOf(requirement.actionId) >= 0) requirementIsMet = true;
				requiredActions.push({
					title: requirementData.title,
					requirementIsMet: requirementIsMet
				});
			}
		}
	});

	let transform = 'translate(' + mouseCoordinates[0] + 'px, ' + mouseCoordinates[1] + 'px)';
	let style = {
		transform: transform + ' scale(' + 0.1 + ')',
		WebkitTransform: transform + ' scale(' + 0.1 + ')',
	};

	return (
		<div 
			style={style} 
			className={'ActionInfo ActionInfo--open'} 
			onClick={(event) => {event.stopPropagation();}}
		> 
			<div className="ActionInfo-title"><span>{actionData.title}</span></div>
			<div className="ActionInfo-closeBtn" onClick={() => {toggleActionInfo(null);}}></div>
			<hr></hr>
			<div className="ActionInfo-stats">
				<div className="ActionInfo-statTypes">
					{statsData.map((stat) => {
						return (
							<div key={stat.id} className={'ActionInfo-statType ActionInfo-statType--' + stat.id}></div>
						);
					})}
				</div>
				<div className="ActionInfo-statsRequirements">
					<div className="ActionInfo-requiredLabel">{generalUiTexts.requires}:</div>
					{statsData.map((stat) => {
						let requiredValue = 0;
						if (actionData.requirements.some((requirement) => {
							return requirement.type === 'stat' && requirement.statId === stat.id;
						})) {
							requiredValue = actionData.requirements.filter((requirement) => {
								return requirement.type === 'stat' && requirement.statId === stat.id;
							})[0].value;
						}
						let valueIsMet = stats[stat.id] >= requiredValue;
						return (
							<div key={stat.id} className={'ActionInfo-requiredStatValue' + 
								(valueIsMet ? ' ActionInfo-requiredStatValue--ok' : '')}>
								<span>{requiredValue !== 0 ? requiredValue : ' '}</span>
							</div>
						);
					})}
				</div>
				<div className="ActionInfo-reward">
					<div className="ActionInfo-rewardLabel">{generalUiTexts.rewards}:</div>
					{statsData.map((stat) => {
						let rewardedValue = 0;
						if (actionData.rewards.some((reward) => {return reward.statId === stat.id;})) {
							rewardedValue = actionData.rewards.filter((reward) => {
								return reward.statId === stat.id;
							})[0].value;
						}
						return (
							<div key={stat.id} className="ActionInfo-rewardedValue">
								<span>{rewardedValue !== 0 ? '+' + rewardedValue : ' '}</span>
							</div>
						);
					})}
				</div>
			</div>
			<hr></hr>
			{(requiredActions && requiredActions.length !== 0) && 
			<div className="ActionInfo-actionRequirements">
				<span>{avatarUiTexts.actionRequirements}:</span>
				{requiredActions.map((action, index) => {
					return (
						<div 
							key={index} 
							className={'ActionInfo-requirementTitle' + 
									(action.requirementIsMet ? ' ActionInfo-requirementTitle--ok' : '')}>
							<span dangerouslySetInnerHTML={{__html: action.title}}></span>
						</div>
					);
				})}
			</div>}
			<div className="ActionInfo-cost"><span>Koster: <span>{actionData.cost}</span></span></div>
		</div>
	);
};

ActionInfo.defaultProps = {
	viewMode: 'portrait'
};

ActionInfo.propTypes = {
	actionId: PropTypes.string,
	mouseCoordinates: PropTypes.array,
	avatar: PropTypes.object.isRequired,
	selectedActions: PropTypes.array.isRequired,
	initialActions: PropTypes.array.isRequired,
	toggleActionInfo: PropTypes.func.isRequired
};

export default ActionInfo;