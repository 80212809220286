import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {avatarUiTexts} from 'data/ui-texts';
import AvatarStatsController from 'components/avatar-stats/avatar-stats-controller';
import Carousel from 'components/layout/carousel';
import CarouselLandscape from 'components/layout/carousel-landscape';
import GoalCard from './goal-card';
import GoalCardLandscape from './goal-card-landscape';
import Button from 'components/button/button';
import AvatarImage from 'components/avatar-image/avatar-image';
import './goals.scss';

const Goals = (props) => {
	let {
		viewMode, 
		showAvatarInfo,
		showCarousel, 
		panCards, 
		initialIndex, 
		avatar, 
		goals, 
		toggleAvatarInfo, 
		handleSelectGoal, 
		openIntroPopup,
		handleGoBack
	} = props;


	/* Reference to current card */
	let [currentIndex, setCurrentIndex] = useState(initialIndex);
	let CarouselComponent = (viewMode === 'landscape' ? CarouselLandscape : Carousel);
	let CardComponent = (viewMode === 'landscape' ? GoalCardLandscape : GoalCard);

	return (
		<div className={'Goals Goals--' + viewMode}>
			<div className="Goals-header">
				<Button viewMode={viewMode} class="goBack2" onClick={handleGoBack} />
				<Button viewMode={viewMode} class="help" onClick={openIntroPopup} />
				{viewMode === 'landscape' && 
					<React.Fragment>						
						<div 
							className="Goals-headerAvatarInfo" 
							onMouseOver={() => {toggleAvatarInfo(true);}}
							onMouseOut={() => {toggleAvatarInfo(false);}}
						>
							<div className="Goals-headerAvatarInfoText">
								<span>{avatar.name}</span>
								<div><strong>{avatarUiTexts.age}:</strong> {avatar.age}</div>
								<div><strong>{avatarUiTexts.education}:</strong> {avatar.education}</div>
								<div><strong>{avatarUiTexts.interests}:</strong> {avatar.interests}</div>		
							</div>					
						</div>
						<div className="Goals-headerAvatarStats">
							{goals.length > 0 &&
							<AvatarStatsController 
								viewMode="landscape"
								type="goals" 
								avatar={avatar} 
								stats={avatar.stats}
								requiredStats={goals[currentIndex].requiredStats}
								handleRefresh={() => {return null;}}
							/>}							
						</div>
						<AvatarImage 
							viewMode="landscape" 
							avatarId={avatar.id} 
							type="goals" 
						/>
						<div 
							className={'Goals-avatarBonusInfo' + (showAvatarInfo ? ' show' : '')}
						>
							<span>{avatar.description}</span>
						</div>
					</React.Fragment>
				}
			</div>
			{(goals.length > 0 && viewMode !== 'landscape') &&
			<AvatarStatsController 
				type="goals" 
				avatar={avatar} 
				stats={avatar.stats}
				requiredStats={goals[currentIndex].requiredStats}
				handleRefresh={() => {toggleAvatarInfo(true);}}
			/>}
			<div className="Goals-carousel">
				{(goals.length > 0 && showCarousel) && <CarouselComponent 
					type="goals" 
					panCards={panCards}
					initialIndex={initialIndex}
					cards={goals} 
					Card={CardComponent}
					setCurrentIndex={setCurrentIndex}
					handleSelectCard={handleSelectGoal}
				/>}
			</div>
		</div>
	);
};

Goals.propTypes = {
	viewMode: PropTypes.string.isRequired,
	showAvatarInfo: PropTypes.bool.isRequired,
	showCarousel: PropTypes.bool.isRequired,
	panCards: PropTypes.bool.isRequired,
	initialIndex: PropTypes.number.isRequired,
	avatar: PropTypes.object.isRequired,
	goals: PropTypes.array.isRequired,
	toggleAvatarInfo: PropTypes.func.isRequired,
	handleSelectGoal: PropTypes.func.isRequired,
	handleGoBack: PropTypes.func.isRequired,
	openIntroPopup: PropTypes.func.isRequired
};

export default Goals;