import React from 'react';
import PropTypes from 'prop-types';
import {avatarUiTexts, generalUiTexts} from 'data/ui-texts';
import Button from 'components/button/button';
import AvatarImage from 'components/avatar-image/avatar-image';
import './avatar-card.scss';

const AvatarCard = ({ariaHidden, card, handleSelectCard}) => {
	return (
		<div aria-hidden={ariaHidden} >
			<div className="AvatarCard-name"><span>{card.name}</span></div>
			<AvatarImage avatarId={card.id} type="avatars" />
			<div className="AvatarCard-info">
				<div><strong>{avatarUiTexts.age}:</strong> {card.age}</div>
				<div><strong>{avatarUiTexts.education}:</strong> {card.education}</div>
				<div><strong>{avatarUiTexts.interests}:</strong> {card.interests}</div>
			</div>
			<div className="AvatarCard-selectAvatar">
				<Button 
					text={generalUiTexts.choose} 
					onClick={() => {handleSelectCard(card.id);}}
				/>
			</div>	
		</div>
	);
};

AvatarCard.propTypes = {
	ariaHidden: PropTypes.bool.isRequired,
	card: PropTypes.object.isRequired,
	handleSelectCard: PropTypes.func.isRequired
};

export default AvatarCard;