import {actionTypes, actionsData} from 'data/actions-data';
import {calculateStats} from 'helpers/stats-helper';

/**
 * Calculate available energy
 * @param {number} initialEnergy 
 * @param {array} selectedActions 
 */
function calculateAvailableEnergy(initialEnergy, selectedActions) {
	let energySpent = 0;
	selectedActions.forEach((action) => {energySpent = energySpent + action.cost;});
	let energy = initialEnergy - energySpent;
	return energy;
}

/**
 * Get chance of failing "buy action"
 * @param {object} stats (avatar initial stats)
 * @param {string} actionId 
 * @param {array} selectedActions
 */
function calculateChanceOfFailure(stats, actions, actionId, selectedActions) {
	let chanceOfFailure = 1;
	
	/* Get unmet requirements */
	let unmetRequirements = getUnmetRequirements(stats, actions, actionId, selectedActions);

	/* No unmet requirements */
	if (unmetRequirements.length === 0) return 0;

	/* Some of the unmet requirements are actions */
	if (unmetRequirements.some((requirement) => {return requirement.type === 'action';})) {
		return chanceOfFailure;	
	}

	/* Action has no subrequirements */
	let action = actionsData.filter((actionData) => {return actionData.id === actionId;})[0];
	if (!(action.hasOwnProperty('subRequirements') && action.subRequirements)) {
		return chanceOfFailure;
	}

	/* Consider all stats */
	let subRequirements = action.subRequirements;
	if (subRequirements.type === 'allStats') {
		/* Get unmet stat requirements that are max X points away from being fullfilled */
		let unmetStatRequirements = unmetRequirements.filter((requirement) => {
			return requirement.value - stats[requirement.statId] <= subRequirements.maxPointsDifference;
		});
		if (unmetStatRequirements.length <= subRequirements.maxNumberOfStats) {
			chanceOfFailure = 0.75;
			if (unmetRequirements.length === 2) chanceOfFailure = 0.5;
			if (unmetRequirements.length === 1) chanceOfFailure = 0.25;
			return chanceOfFailure;
		}
	}

	/* Consider specific stat */
	if (subRequirements.type === 'specificStat') {
		if (unmetRequirements.length > 1) return;
		if (unmetRequirements[0].type === 'stat' && 
			unmetRequirements[0].statId === subRequirements.statId
		) {
			let missingStats = unmetRequirements[0].value - stats[unmetRequirements[0].statId];
			if (missingStats <= subRequirements.maxPointsDifference) {
				chanceOfFailure = 0.5;
				if (missingStats === 1) chanceOfFailure = 0.25;
				return chanceOfFailure;	
			}
		} 	
	}

	return chanceOfFailure;
}

/**
 * Get the actions available for the challenge
 * @param {object} goal 
 * @param {object} avatar 
 * @param {object} requiredStats 
 */
function getAvailableActions(goal, avatar, requiredStats) {
	let actions = [];
	goal.actions.forEach((goalAction) => {
		let action = JSON.parse(JSON.stringify(actionsData)).filter((actionData) => {
			return actionData.id === goalAction.id;
		})[0];

		/* Some actions are already owned by the avatar */
		if (avatar.actions.indexOf(goalAction.id) >= 0) {
			return;
		}

		/* Some actions are only available if needed */
		if (
			goalAction.conditionType === 'statVal' && 
			avatar.stats[goalAction.condition] >= goalAction.conditionMinVal) {
			return;
		}
		if (
			goalAction.conditionType === 'statRel' && 
			avatar.stats[goalAction.condition] >= requiredStats[goalAction.condition]) {
			return;
		} 

		/* Flag action as available */
		action.status = 'available';
		actions.push(action);
	});

	/* Some action requirements are conditional to what actions are available */
	actions.forEach((action) => {
		let requirements = JSON.parse(JSON.stringify(action.requirements));
		action.requirements.slice().reverse().forEach((requirement, index) => {
			if (requirement.type === 'action' && requirement.hasOwnProperty('conditional')) {
				if (!actions.some((action) => {return action.id === requirement.actionId;})) {
					requirements.splice(action.requirements.length - index - 1, 1);
				}
			}
		});
		action.requirements = requirements;
	});
	

	return actions;
}

/**
 * Get colors of selected actions
 * @param {array} selectedActions 
 * @param {object} avatar 
 */
function getColorsOfSelectedActions(selectedActions, avatar) {
	let actions = JSON.parse(JSON.stringify(selectedActions));
	actions.slice().reverse().forEach((action) => {
		action.color = 'red';
		let unmetRequirements = ((action.status === 'available' || action.status === 'shaking') ? 
			getUnmetRequirements(avatar.stats, avatar.actions, action.id, selectedActions) : []);

		if (unmetRequirements.length === 0) {
			/* No unmet requirements, action can be bought or is already (being) bought */
			action.color = (action.status === 'bought' ? 'blue' : 'green');
		} else {
			/* Action has sub requirements, might be buyable (although risky) */
			let chanceOfFailure = calculateChanceOfFailure(avatar.stats, avatar.actions, action.id, selectedActions);
			if (chanceOfFailure < 1) action.color = 'yellow';
		}
	});
	return actions;
}

/**
 * Get previous actions
 * @param {string} actionId 
 * @param {actions} array 
 */
function getPrevActions(actionId, actions) {
	if (actions.length < 2) return [];
	let actionIndex = actions.findIndex((action) => {return action.id === actionId;});
	if (actionIndex === actions.length - 1) return [];
	return actions.slice(actionIndex + 1);
}

/**
 * Get the actions required to complete a goal
 * @param {object} goal 
 */
function getRequiredActions(goal, selectedActions) {
	let requiredActions = [];
	goal.requirements.forEach((goalRequirement) => {
		let actionObj = JSON.parse(JSON.stringify(goalRequirement));
		actionObj.title = '';
		actionObj.isSelected = false;
		if (goalRequirement.hasOwnProperty('actionIds')) {
			let isSelected = false;
			goalRequirement.actionIds.forEach((actionId, index) => {
				let requirementData = actionsData.filter((action) => {return action.id === actionId;})[0];
				if (index === 0) {
					actionObj.title = requirementData.title;
				} else {
					actionObj.title = actionObj.title + ' / ' + requirementData.title;
				}
				if (selectedActions.findIndex((action) => {return action.id === actionId;}) >= 0) {
					isSelected = true;
				}
				actionObj.isSelected = isSelected;
			});
		}
		if (goalRequirement.hasOwnProperty('actionId')) {
			let actionData = 
				actionsData.filter((actionData) => {return actionData.id === goalRequirement.actionId;})[0];
			actionObj.title = actionData.title;
			actionObj.isSelected = 
				selectedActions.findIndex((action) => {return action.id === goalRequirement.actionId;}) >= 0;
		}
		if (goalRequirement.hasOwnProperty('typeId')) {
			actionObj.title = actionTypes[goalRequirement.typeId];
			actionObj.isSelected = 
				selectedActions.findIndex((action) => {return action.type === goalRequirement.typeId;}) >= 0;
		}
		requiredActions.push(actionObj);
	});

	return requiredActions;
}

/**
 * Get unmet requirements of an action
 * @param {object} initialStats
 * @param {array} initialActions
 * @param {string} actionId 
 * @param {array} selectedActions 
 */
function getUnmetRequirements(initialStats, initialActions, actionId, selectedActions) {
	let unmetRequirements = [];
	let prevActions = getPrevActions(actionId, selectedActions);
	let stats = calculateStats(initialStats, prevActions);
	let action = selectedActions.filter((action) => {return action.id === actionId;})[0];
	if (action.requirements.length > 0) {
		action.requirements.forEach((requirement) => {
			if (!checkIfRequirementIsMet(stats, initialActions, prevActions, requirement)) {
				unmetRequirements.push(requirement);
			}
		});
	}
	return unmetRequirements;
}

/**
 * Check if an action requriement is met
 * @param {object} stats 
 * @param {array} initialActions,
 * @param {array} actions
 * @param {object} requirement
 */
function checkIfRequirementIsMet(stats, initialActions, actions, requirement) {
	let requirementIsMet = false;

	/* Stat requirement */
	if (requirement.type === 'stat') {
		if (stats[requirement.statId] >= requirement.value) requirementIsMet = true;
	}

	/* Action requirement */
	if (requirement.type === 'action') {
		/* One of multiple actions */
		if (requirement.hasOwnProperty('actionIds')) {
			requirement.actionIds.forEach((actionId) => {
				if (actions.filter((action) => {return action.id === actionId;}).length > 0) {
					requirementIsMet = true;
				}	
				if (initialActions.indexOf(actionId) >= 0) requirementIsMet = true;
			});
		}
		/* Specific action */
		if (actions.filter((action) => {return action.id === requirement.actionId;}).length > 0) {
			requirementIsMet = true;
		}	
		if (initialActions.indexOf(requirement.actionId) >= 0) requirementIsMet = true;
	}

	return requirementIsMet;
}

export {
	calculateAvailableEnergy,
	calculateChanceOfFailure,
	getAvailableActions,
	getColorsOfSelectedActions,
	getPrevActions,
	getRequiredActions,
	getUnmetRequirements
};