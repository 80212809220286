import React from 'react';
import PropTypes from 'prop-types';
import {cookieUiTexts} from 'data/ui-texts';
import Button from 'components/button/button';
import './cookie-consent.scss';

const CookieConsent = ({
	viewMode,
	handleClose,
	handleReadMore
}) => {
	return (
		<div className={'CookieConsent CookieConsent--' + viewMode}>
			<div className="CookieConsent-text">
				<p>
					{cookieUiTexts.text}{(viewMode === 'landscape' ? ' ' : <br />)}
					<span onClick={() => {handleReadMore();}}>{cookieUiTexts.readMore}</span>
				</p>
			</div>
			<div className="CookieConsent-button">
				<Button 
					viewMode={viewMode} 
					class="cookie" 
					text={cookieUiTexts.okBtn} 
					onClick={() => {handleClose();}}
				/>
			</div>
		</div>
	);	
};



CookieConsent.propTypes = {
	viewMode: PropTypes.string.isRequired,
	handleClose: PropTypes.func.isRequired,
	handleReadMore: PropTypes.func.isRequired
};

export default CookieConsent;