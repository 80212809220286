import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button/button';
import Stats from 'components/avatar-stats/stats';
import {diplomaUiTexts as uiTexts} from 'data/ui-texts';
import {statsData} from 'data/stats-data';
import './diploma.scss';
import './diploma-landscape.scss';

const Diploma = ({
	viewMode,
	popupIsOpen,
	handleRefresh,
	goToStep,
	profile,
	diplomaEmailed,
	handlePrint,
	handleEmailPopup
}) => {
	if (profile && profile.answers) {
		let allAnswers = [];
		statsData.forEach((stat) => {
			let statAnswers = {
				id: stat.id,
				name: stat.name,
				answers: profile.answers.filter((answer) => {return answer.statId === stat.id;})
			};
			allAnswers.push(statAnswers);
		});

		return (
			<div className={'Diploma Diploma--' + viewMode} aria-hidden={popupIsOpen}>
				<div className="Diploma-body">
					{viewMode !== 'landscape' &&
					<React.Fragment>
						<div className="Diploma-emailBtn">
							<Button class="start" onClick={handleEmailPopup} text={uiTexts.emailBtn} />
						</div>
						<div className="Diploma-close-icon" onClick={() => {
							if (!diplomaEmailed) {handleEmailPopup();} else {goToStep('home');};
						}}></div>
					</React.Fragment>}


					{viewMode === 'landscape' &&
						<div className="Diploma-buttons">
							<Button 
								viewMode="landscape" 
								class="diplomaPrint" 
								// onClick={() => {window.print();}}
								onClick={() => {handlePrint();}}
								text={uiTexts.print}
							/>
							<Button 
								viewMode="landscape" 
								class="diplomaEmail" 
								onClick={handleEmailPopup} 
								text={uiTexts.email2}
							/>
							<Button 
								viewMode="landscape" 
								class="diplomaExit" 
								onClick={() => {goToStep('home');}} 
								text={uiTexts.exit}
							/>
						</div>
					}

					<div className="Diploma-medal" onClick={handleRefresh}></div>
					<div className="Diploma-title">
						<span>{viewMode === 'landscape' ? uiTexts.title2 : uiTexts.title}</span>
					</div>
					<div className="Diploma-section">
						<div className="Diploma-name">{profile.name} <span>({profile.age} {uiTexts.years})</span></div>
					</div>
					<div className="Diploma-line"></div>
					<div className="Diploma-section">
						<div className="Diploma-score">{uiTexts.score}:</div>
					</div>
					<Stats
						viewMode={viewMode}
						type="diploma"
						stats={profile.stats}
						avatar={profile}
					/>
					<div className="Diploma-line"></div>
					<div className="Diploma-section">
						<div className="Diploma-interest-heading">{uiTexts.interest}</div>
						<div className="Diploma-interest-value">{profile.interest.title}</div>
						
						<div className="Diploma-education-heading">{uiTexts.educationLevel}</div>
						<div className="Diploma-education-value">{profile.education.name}</div>

						<div className="Diploma-experience-heading">{uiTexts.experience}</div>
						<div className="Diploma-experience-value">{profile.experience.name} {uiTexts.years}</div>
					</div>
					<div className="Diploma-line"></div>
					<div className="Diploma-section">
						<div className="Diploma-nextStep">{uiTexts.nextStep}</div>
						{profile.actions.map((action, index) => {
							return action.id !== 'dummy-2'
								&& <div key={index} className={'Diploma-action ' + action.type}>
									<div className="Diploma-action-index" aria-hidden={true}>{index + 1}</div>
									<div className="Diploma-action-value">{action.title}</div>
								</div>;
						})}
					</div>
					<div className="Diploma-line"></div>
					<div className="Diploma-section">
						<div className="Diploma-answers">{uiTexts.answers}</div>
						{allAnswers.map((answerStat) => {
							return (
								<table key={answerStat.id} className={'Diploma-table Diploma-table--' + answerStat.id} >
									<tbody>
										<tr>
											<th><div className="Diploma-th-icon"></div>{answerStat.name}</th>
											<th>{profile.stats[answerStat.id]}</th>
										</tr>
										{answerStat.answers.map((answer, _) => {
											return (
												<tr key={_}>
													<td>{answer.questionText}</td>
													<td>{answer.answer === 0 ? '-' : answer.answer}</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
	return null;
};

Diploma.defaultProps = {
	profile: null
};

Diploma.propTypes = {
	viewMode: PropTypes.string.isRequired,
	popupIsOpen: PropTypes.bool.isRequired,
	handleRefresh: PropTypes.func.isRequired,
	goToStep: PropTypes.func.isRequired,
	profile: PropTypes.object,
	diplomaEmailed: PropTypes.bool.isRequired,
	handlePrint: PropTypes.func.isRequired,
	handleEmailPopup: PropTypes.func.isRequired
};

export default Diploma;