import React from 'react';
import PropTypes from 'prop-types';
import PwaInfo from './pwa-info';

class PwaInfoController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showPwaInfo: true
		};
		this.togglePwaInfo = this.togglePwaInfo.bind(this);
	}

	togglePwaInfo(showPwaInfo) {
		this.setState({showPwaInfo: showPwaInfo});
	}

	render() {
		if (this.state.showPwaInfo && this.props.viewMode !== 'landscape') {
			return <PwaInfo isIos={this.props.isIos} togglePwaInfo={this.togglePwaInfo} />;
		} 
		return null;
	}

}

PwaInfoController.propTypes = {
	isIos: PropTypes.bool.isRequired,
	viewMode: PropTypes.string.isRequired,
};

export default PwaInfoController;