import React from 'react';
import PropTypes from 'prop-types';
import './challenge-action.scss';

const ChallengeAction = ({isDragging, dragIsDisabled, viewMode, type, animateDrop, action, toggleActionInfo}) => {
	let actionClass = 'ChallengeAction ChallengeAction--' + viewMode;

	/* Placholder */
	if (action.id === null) {
		actionClass = actionClass + ' ChallengeAction--placeholder';	
		return <div className={actionClass} />;
	}


	if (action.status) actionClass = actionClass + ' ChallengeAction--' + action.status;	

	/* Show top arrow & color only when in selected column */
	if (type === 'selected') {
		actionClass = actionClass + ' ChallengeAction--selected';	
		if (action.color && !isDragging) actionClass = actionClass + ' ChallengeAction--' + action.color;
	}

	if (type === 'actions' && dragIsDisabled) {
		actionClass = actionClass + ' ChallengeAction--disabled';	
	}

	if (animateDrop && !isDragging) actionClass = actionClass + ' ChallengeAction--animateDrop';


	
	return (
		<div className={actionClass} onClick={(event) => {toggleActionInfo(action.id, event);}}> 
			<div className="ChallengeAction-body">
				{action.title2 
					? <span dangerouslySetInnerHTML={{__html: action.title2}} />
					: <span>{action.title}</span>
				}
				<div className="ChallengeAction-energy" aria-hidden={true}><span>{action.cost}</span></div>
				<div className="ChallengeAction-moveIcon" />
			</div>
		</div>
	);
};

ChallengeAction.defaultProps = {
	isDragging: false,
	viewMode: 'portrait'
};

ChallengeAction.propTypes = {
	isDragging: PropTypes.bool,
	dragIsDisabled: PropTypes.bool,
	type: PropTypes.string.isRequired,
	viewMode: PropTypes.string,
	animateDrop: PropTypes.bool.isRequired,
	action: PropTypes.object.isRequired,
	toggleActionInfo: PropTypes.func,
};

export default ChallengeAction;