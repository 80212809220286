import update from 'immutability-helper';

function moveAction(id, toId, actionsList, selectedList, maxSelected = null) {
	let newActionsList = JSON.parse(JSON.stringify(actionsList));
	let newSelectedList = JSON.parse(JSON.stringify(selectedList));

	let [action, index, containerId] = findAction(id, newActionsList, newSelectedList);
	let [action2, toIndex, toContainerId] = findAction(toId, newActionsList, newSelectedList);

	if (index >= 0 && toIndex >= 0) {
		/* Move within container */
		if (containerId === toContainerId) {
			if (containerId === 'actions') {
				let removeIndex = newActionsList[index].index;
				let addIndex = newActionsList[toIndex].index;
				newActionsList.forEach((action) => {
					if (action.index > removeIndex) action.index = action.index - 1;
				});
				newActionsList.forEach((action) => {
					if (action.index >= addIndex) action.index = action.index + 1;
				});
				newActionsList[index].index = addIndex;
			} else {
				let removeIndex = newSelectedList[index].index;
				let addIndex = newSelectedList[toIndex].index;
				newSelectedList.forEach((action) => {
					if (action.index > removeIndex) action.index = action.index - 1;
				});
				newSelectedList.forEach((action) => {
					if (action.index >= addIndex) action.index = action.index + 1;
				});
				newSelectedList[index].index = addIndex;
			}
			
		/* Move between containers */
		} else {
			/* Move from actions to selected */
			if (containerId === 'actions') {
				if (!maxSelected || selectedList.length < maxSelected) {
					let removeIndex = newActionsList[index].index;
					newActionsList.splice(index, 1);
					newActionsList.forEach((action) => {
						if (action.index > removeIndex) action.index = action.index - 1;
					});

					let addIndex = newSelectedList[toIndex].index;
					action.index = addIndex;
					newSelectedList.forEach((action) => {
						if (action.index >= addIndex) action.index = action.index + 1;
					});
					newSelectedList = update(newSelectedList, {$splice: [[newSelectedList.length - 1, 0, action]]});
				} else {
					/* Swap actions */
					let actionIndex = action.index;
					let action2Index = action2.index;
					action.index = action2Index;
					action2.index = actionIndex;
					newActionsList[index] = action2;
					newSelectedList[toIndex] = action;
				}
			/* Move from selected to actions */
			} else {
				let removeIndex = newSelectedList[index].index;
				newSelectedList.splice(index, 1);
				newSelectedList.forEach((action) => {
					if (action.index > removeIndex) action.index = action.index - 1;
				});

				let addIndex = newActionsList[toIndex].index;
				action.index = addIndex;
				newActionsList.forEach((action) => {
					if (action.index >= addIndex) action.index = action.index + 1;
				});
				newActionsList = update(newActionsList, {$splice: [[newActionsList.length - 1, 0, action]]});
			}
		}
	}

	return [newActionsList, newSelectedList];
};

function moveAction2(id, actionsList, selectedList, maxSelected = null) {
	let newActionsList = JSON.parse(JSON.stringify(actionsList));
	let newSelectedList = JSON.parse(JSON.stringify(selectedList));
	let [action, index, containerId] = findAction(id, actionsList, selectedList);

	if (index >= 0) {
		/* Move from actions to selected */
		if (containerId === 'actions') {
			if (!maxSelected || selectedList.length < maxSelected) {
				let removeIndex = newActionsList[index].index;
				newActionsList.splice(index, 1);
				newActionsList.forEach((action) => {
					if (action.index > removeIndex) action.index = action.index - 1;
				});			
				action.index = selectedList.length - 1;	
				newSelectedList[selectedList.length - 1].index = selectedList.length - 1;
				newSelectedList = update(newSelectedList, {$splice: [[selectedList.length - 1, 0, action]]});
			}

		/* Move from selected to actions */
		} else {
			let removeIndex = newSelectedList[index].index;
			newSelectedList.splice(index, 1);
			newSelectedList.forEach((action) => {
				if (action.index > removeIndex) action.index = action.index - 1;
			});

			action.index = actionsList.length - 1;
			newActionsList[newActionsList.length - 1].index = actionsList.length - 1;
			newActionsList = update(newActionsList, {$splice: [[actionsList.length - 1, 0, action]]});
		}
	}

	return [newActionsList, newSelectedList];
}

/**
 * Find an action in one of the two lists
 * @param {string} id 
 * @param {array} actionsList 
 * @param {array} selectedList 
 */
function findAction(id, actionsList, selectedList)  {
	if (actionsList.some((action) => {return action.id === id;})) {
		let action = actionsList.filter((action) => {return action.id === id;})[0];	
		return [action, actionsList.indexOf(action), 'actions'];
	}

	let action = selectedList.filter((action) => {return action.id === id;})[0];	
	return [action, selectedList.indexOf(action), 'selected'];
};

/**
 * Get the position of the draglayer 
 * @param {object} initialOffset 
 * @param {*object currentOffset 
 */
function getItemStyles(initialOffset, currentOffset) {
	if (!initialOffset || !currentOffset) {
		return {
			display: 'none',
		};
	}
	let { x, y } = currentOffset;
	const transform = `translate(${x}px, ${y}px)`;
	return {
		transform,
		WebkitTransform: transform,
	};
}


export {moveAction, moveAction2, findAction, getItemStyles};