import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {disableBodyScroll} from 'body-scroll-lock';
import {popupsData} from 'data/popups-data';
import {interestsData} from 'data/interests-data';
import {generalUiTexts} from 'data/ui-texts';
import Interests from './interests';

import AvatarInfoController from 'components/avatar-info/avatar-info-controller';

class InterestsController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showAvatarInfo: false,
			showCarousel: true
		};
		this.openIntroPopup = this.openIntroPopup.bind(this);
		this.closeIntroPopup = this.closeIntroPopup.bind(this);
		this.toggleAvatarInfo = this.toggleAvatarInfo.bind(this);
		this.handleSelectInterest = this.handleSelectInterest.bind(this);
		this.rerenderCarousel = this.rerenderCarousel.bind(this);
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		if (this.props.viewMode !== 'landscape') {
			/* Disable body scroll */
			let targetElement = document.querySelector('#app');
			disableBodyScroll(targetElement);
		} else {
			/* Listen to window resize */
			window.addEventListener('resize', this.rerenderCarousel);
		}

		/* Open intro popup */
		if (this.props.introPopupStatuses.interestIntroSeen !== true) {
			this.openIntroPopup();
		}		
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount() {
		window.removeEventListener('resize', this.rerenderCarousel);
	}

	/**
	 * Rerender carousel on window rezie
	 */
	rerenderCarousel() {
		this.setState({showCarousel: false}, () => {this.setState({showCarousel: true});});
	}

	/**
	 * Open intro popup
	 */
	openIntroPopup() {
		let popupData = JSON.parse(JSON.stringify(popupsData.startInterestsInfo));
		let popupBtns = [{text: generalUiTexts.ok, action: this.closeIntroPopup, actionParams: []}];
		this.props.openPopup(
			null, popupData.texts, popupBtns, null, 
			{action: this.closeIntroPopup, actionParams: []}, false, 
			'introPopup', null, null, null, popupData.audio
		);
	}

	/**
	 * Close intro popup
	 */
	closeIntroPopup() {
		this.props.closePopup();
		if (this.props.introPopupStatuses.interestIntroSeen !== true) {
			this.props.updateIntroPopupStatus('interestIntroSeen', true);
		}
	}

	/**
	 * Hide / show avatar info
	 * @param {bool} showAvatarInfo 
	 */
	toggleAvatarInfo(showAvatarInfo) {
		this.setState({showAvatarInfo: showAvatarInfo});
	}

	/**
	 * Select interest
	 * @param {string} interestId 
	 * @param {string} interestTitle 
	 */
	handleSelectInterest(interestId, interestTitle = null) {
		let interest = interestsData.filter((interest) => {return interest.id === interestId;})[0];
		if (interestId === 'user' && interestTitle) {interest.title = interestTitle;}
		if (interestId === 'user' && (interestTitle === null || interestTitle.length === 0)) {
			let popupData = JSON.parse(JSON.stringify(popupsData.selectInterestError));
			this.props.openPopup(null, popupData.text, null, null, {action: this.props.closePopup, actionParams: []});
		} else {
			this.props.selectInterest(interest);
		}
	}

	/**
	 * Render component
	 */
	render() { 
		if (this.state.showAvatarInfo) {
			return (
				<AvatarInfoController 
					page="part2"
					avatar={this.props.profile}
					goToStep={null} 
					toggleAvatarInfo={this.toggleAvatarInfo}
					handleRefresh={null}
					openPopup={this.props.openPopup}
					closePopup={this.props.closePopup}
				/>
			);
		}
 
		if (this.state.showCarousel) {	
			return (
				<div aria-hidden={this.props.popupIsOpen} style={{height: '100%'}}>
					<Interests 
						viewMode={this.props.viewMode}
						handleSelectInterest={this.handleSelectInterest}
						toggleAvatarInfo={this.toggleAvatarInfo}
						profile={this.props.profile}
						goToStep={this.props.goToStep}
						openIntroPopup={this.openIntroPopup}
					/>
				</div>
			);
		}
		return null;
	}
}

InterestsController.propTypes = {
	viewMode: PropTypes.string.isRequired,
	popupIsOpen: PropTypes.bool.isRequired,
	introPopupStatuses: PropTypes.object.isRequired,
	selectInterest: PropTypes.func.isRequired,
	openPopup: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
	profile: PropTypes.object.isRequired,
	goToStep: PropTypes.func.isRequired,
	updateIntroPopupStatus: PropTypes.func.isRequired,
};


export default InterestsController;