const avatarsData = [
	{
		id: 1,
		name: 'Christina',
		gender: 'f',
		age: 23,
		location: 'Slagelse',
		education: 'Ingen',
		interests: 'Mode og skønhed',
		description: 'Christina har haft mange småjobs, men nu vil hun gerne i gang med en uddannelse.',
		actions: [],
		goals: [{id: 'social-worker'}, {id: 'photographer'}, {id: 'florist'}, {id: 'receptionist'}, {id: 'service-assistant'}, {id: 'gardener'}, {id: 'baker'}, {id: 'shop-assistant'}, {id: 'painter'}],
		stats: {
			danish: 4,
			reading: 1,
			math: 1,
			teamwork: 2,
			workEthic: 2
		}
	},
	{
		id: 2,
		name: 'Janus',
		gender: 'm',
		age: 21,
		location: 'Jyderup',
		education: 'Jord- og betonarbejder',
		interests: 'Ønsker jobskifte',
		description: 'Janus ønsker et mindre fysisk krævende arbejde, da han døjer med en kronisk rygskade.',
		actions: [],
		goals: [{id: 'salesman'}, {id: 'office-education'}, {id: 'waiter'}, {id: 'chauffeur'}, {id: 'truck-driver'}, {id: 'receptionist'}, {id: 'teaching-assistant'}, {id: 'cashier'}, {id: 'shop-assistant'}],
		stats: {
			danish: 4,
			reading: 1,
			math: 4,
			teamwork: 2,
			workEthic: 2
		}
	},
	{
		id: 3,
		name: 'Muhammed',
		gender: 'm',
		age: 38,
		location: 'Haslev',
		education: '10. klasse',
		interests: 'Arbejde med mennesker',
		description: 'Muhammed er god til mennesker og synes det er sjovt at sælge. Han kan ikke overskue at skulle tilbage på skolebænken.',
		actions: ['public-school'],
		goals: [{id: 'salesman'}, {id: 'social-worker'}, {id: 'web-developer'}, {id: 'waiter'}, {id: 'receptionist'}, {id: 'cashier'}, {id: 'chef'}, {id: 'baker'}, {id: 'shop-assistant'}, {id: 'chimney-sweeper'}],
		stats: {
			danish: 4,
			reading: 2,
			math: 2,
			teamwork: 4,
			workEthic: 2
		}
	},
	{
		id: 4,
		name: 'Mette',
		gender: 'f',
		age: 42,
		location: 'Vanløse',
		education: '9. klasse',
		interests: 'Service og omsorg',
		description: 'Mette er fysisk nedslidt efter mange år i hjemmeplejen. Hun vil gerne have et spændende men stillesiddende job.',
		actions: ['public-school', 'social-worker'],
		goals: [{id: 'office-education'}, {id: 'receptionist'}, {id: 'physiotherapist'}, {id: 'nutritionist'}, {id: 'chef'}, {id: 'clinical-assistant'}],
		stats: {
			danish: 4,
			reading: 2,
			math: 2,
			teamwork: 4,
			workEthic: 3
		}
	},
	{
		id: 5,
		name: 'Nanna',
		gender: 'f',
		age: 22,
		location: 'København',
		education: '9. klasse',
		interests: 'Medier og dyr',
		description: 'Nanna ville gerne være dyrepasser, men fik ikke læreplads. Hun vil stadig gerne arbejde med dyr og sociale medier.',
		actions: ['public-school'],
		goals: [{id: 'photographer'}, {id: 'it-technician'}, {id: 'web-developer'}, {id: 'receptionist'}, {id: 'multimedia-designer'}, {id: 'agriculture-assistant'}, {id: 'veterinary-medicin'}, {id: 'nurse'}, {id: 'painter'}],
		stats: {
			danish: 2,
			reading: 3,
			math: 2,
			teamwork: 2,
			workEthic: 2
		}
	},
	{
		id: 6,
		name: 'Fatima',
		gender: 'f',
		age: 32,
		location: 'Næstved',
		education: 'Ingen',
		interests: 'Familieliv',
		description: 'Fatima har 3 børn og en mand, der arbejder meget. Hun har store udfordringer med at tale og læse dansk.',
		actions: [],
		goals: [{id: 'social-worker'}, {id: 'waiter'}, {id: 'florist'}, {id: 'teaching-assistant'}, {id: 'service-assistant'}, {id: 'cashier'}, {id: 'baker'}, {id: 'shop-assistant'}, {id: 'clinical-assistant'}],
		stats: {
			danish: 1,
			reading: 1,
			math: 4,
			teamwork: 2,
			workEthic: 4
		}
	},
	{
		id: 7,
		name: 'Julie',
		gender: 'f',
		age: 24,
		location: 'Vejle',
		education: '9. klasse',
		interests: 'Ønsker jobskifte',
		description: 'Julie har arbejdet på fabrik i nogle få år og var glad for det. Nu er fabrikken lukket, og hun skal finde nye veje.',
		actions: ['public-school'],
		goals: [{id: 'office-education'}, {id: 'social-worker'}, {id: 'receptionist'}, {id: 'teaching-assistant'}, {id: 'shop-assistant'}, {id: 'clinical-assistant'}],
		stats: {
			danish: 5,
			reading: 2,
			math: 2,
			teamwork: 4,
			workEthic: 4
		}
	},
	{
		id: 8,
		name: 'Idris',
		gender: 'm',
		age: 29,
		location: 'Køge',
		education: 'Ingen',
		interests: 'Noget med håndværk',
		description: 'Idris har dårlige erfaringer med skolen. Han vil gerne have et job, hvor han skal bruge sine hænder.',
		actions: [],
		goals: [{id: 'mechanic'}, {id: 'carpenter'}, {id: 'mason'}, {id: 'construction-worker'}, {id: 'plumber'}, {id: 'butcher'}, {id: 'painter'}, {id: 'structor'}, {id: 'plastics-maker'}],
		stats: {
			danish: 1,
			reading: 1,
			math: 2,
			teamwork: 2,
			workEthic: 3
		}
	},
	{
		id: 9,
		name: 'John',
		gender: 'm',
		age: 53,
		location: 'Nykøbing Falster',
		education: 'Lagerekspedient',
		interests: 'Sport og sundhed',
		description: 'John har lagt sit liv om efter skilsmisse og vil nu gerne noget med mennesker og sundhed.',
		actions: [],
		goals: [{id: 'pedagogue'}, {id: 'social-worker'}, {id: 'waiter'}, {id: 'physiotherapist'}, {id: 'nutritionist'}, {id: 'nurse'}, {id: 'laboratory-technician'}, {id: 'bioanalyst'}, {id: 'clinical-assistant'}],
		stats: {
			danish: 2,
			reading: 1,
			math: 4,
			teamwork: 3,
			workEthic: 4
		}
	},

	{
		id: 10,
		name: 'William',
		gender: 'm',
		age: 18,
		location: 'Vordingborg',
		education: '9. klasse',
		interests: 'Computerspil',
		description: 'William klarede sig ok i skolen. Han startede i gymnasiet, men droppede ud. Er i tvivl om, hvad han nu skal.',
		actions: ['public-school'],
		goals: [{id: 'pedagogue'}, {id: 'office-education'}, {id: 'it-technician'}, {id: 'web-developer'}, {id: 'multimedia-designer'}, {id: 'industrial-technician'}, {id: 'electrician'}, {id: 'chimney-sweeper'}],
		stats: {
			danish: 5,
			reading: 3,
			math: 2,
			teamwork: 2,
			workEthic: 1
		}
	}
];

export{avatarsData};