import React from 'react';
import PropTypes from 'prop-types';
import {avatarUiTexts, generalUiTexts} from 'data/ui-texts';
import {statsData} from 'data/stats-data';
import Button from 'components/button/button';
import AvatarImage from 'components/avatar-image/avatar-image';
import './avatar-card-landscape.scss';

const AvatarCardLandscape = ({ariaHidden, showCardBack, card, handleSelectCard, toggleShowCardBack}) => {
	let className = 'AvatarCardLandscape AvatarCardLandscape--' + (showCardBack && !ariaHidden ? 'back' : 'front');
	let namePossessive = card.name.substr(-1, 1) === 's' ? card.name + '\'' : card.name + 's';
	let statsInfo = JSON.parse(JSON.stringify(avatarUiTexts.avatarStats));
	statsInfo = statsInfo.replace(/%name%/g, namePossessive);

	
	return (
		<div className={className} aria-hidden={ariaHidden} >
			<AvatarImage 
				viewMode="landscape" 
				avatarId={card.id} 
				type={(showCardBack && !ariaHidden ? 'small' : 'avatars')}
			/>
			<div className="AvatarCardLandscape-name">{card.name}</div>
			
			<div className="AvatarCardLandscape-info">
				<div className="AvatarCardLandscape-infoText">
					<div><strong>{avatarUiTexts.age}:</strong> {card.age}</div>
					<div><strong>{avatarUiTexts.education}:</strong> {card.education}</div>
					<div><strong>{avatarUiTexts.interests}:</strong> {card.interests}</div>
				</div>
				<div className="AvatarCardLandscape-infoText2">{card.description}</div>				
			</div>

			<div className="AvatarCardLandscape-statsWrap">
				<div className="AvatarCardLandscape-statsInfo">{statsInfo}</div>
				<div className="AvatarCardLandscape-stats">
					{statsData.map((stat) => {
						let avatarStatValue = (card.stats && card.stats[stat.id] ? card.stats[stat.id] : '-');
						return (
							<div 
								key={stat.id} 
								className={'AvatarCardLandscape-stat AvatarCardLandscape-stat--' + stat.id}
							>
								<div className="AvatarCardLandscape-statName">
									{stat.name}<span aria-hidden={true}>{avatarStatValue}</span>
								</div>
								
							</div>
						);
					})}
				</div>
			</div>
			{!ariaHidden &&
			<div className="AvatarCardLandscape-selectAvatar">
				<Button 
					viewMode="landscape"
					text={(showCardBack ? generalUiTexts.choose : generalUiTexts.readMore)} 
					onClick={(e) => {
						e.stopPropagation();
						if (showCardBack) {
							handleSelectCard(card.id);
						} else {
							toggleShowCardBack(true);
						}
					}}
				/>
			</div>	
			}
		</div>
	);
};

AvatarCardLandscape.propTypes = {
	ariaHidden: PropTypes.bool.isRequired,
	showCardBack: PropTypes.bool.isRequired,
	card: PropTypes.object.isRequired,
	handleSelectCard: PropTypes.func.isRequired,
	toggleShowCardBack: PropTypes.func.isRequired
};

export default AvatarCardLandscape;