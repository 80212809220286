import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown, addParagraph} from 'helpers/text-helper';
import Button from 'components/button/button';
import PopupIcon from './popup-icon';
import Audio from 'components/audio/audio';
import './popup.scss';
import './popup-landscape.scss';

const Popup = (props) => {
	let {
		isIos,
		isLoading,
		viewMode,
		title,
		text,
		buttons,
		audioFile,
		bodyAction,
		className,
		handleButtonClick,
		closePopup,
		popupIcons,
		filledStars,
		closeBtn,
		popupEmailInput,
		handleEmailInput,
		emailValue
	} = props;
	let popupClass = 'Popup'; 
	if (className) popupClass += ' Popup--' + className;
	if (popupIcons && filledStars) popupClass += ' Popup--icons-' + filledStars;

	return (
		<div className={popupClass} onClick={(e) => {
			e.stopPropagation();
			if (bodyAction) {
				bodyAction.action(...bodyAction.actionParams);
				closePopup();
			}
		}}>
			{closeBtn && <div className="Popup-closeBtn" onClick={() => {handleButtonClick(closeBtn);}}></div>}
			{audioFile && <Audio isIos={isIos} audioFile={audioFile} align="left" viewMode={viewMode} />}
			<div className="Popup-body">
				<div className="Popup-title"><span>{title}</span></div>
				<div className="Popup-text"><span>
					{text.constructor === Array
						? addParagraph(text)
						: renderMarkdown(text)
					}
				</span></div>
				{popupIcons && 
					<div className="Popup-icons">
						{popupIcons.map((icon, index) => {
							return <PopupIcon key={index} name={icon.name} viewMode={viewMode} />;
						})}
					</div>}
				{popupEmailInput && 
					<div className="Popup-input">
						<input
							className='Input'
							name="email"
							placeholder="E-mail"
							autoComplete="off"
							value={emailValue}
							type="email"
							onChange={(event)=>{handleEmailInput(event);}}
						/>
					</div>}
				{buttons && <div className={'Popup-buttons Popup-buttons--' + buttons.length}>
					{buttons.map((btn, index) => {
						return (
							<Button
								key={index}
								type="button"
								viewMode={viewMode}
								class={className ? className : 'popup'}
								text={btn.text}
								loading={btn.showLoadingIcon && isLoading}
								onClick={() => {handleButtonClick(btn);}}
							/>
						);
					})}
				</div>}
			</div>
		</div>
	);
};

Popup.propTypes = {
	isIos: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	viewMode: PropTypes.string.isRequired,
	title: PropTypes.string,
	text: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.array
	]),
	buttons: PropTypes.array,
	audioFile: PropTypes.string,
	bodyAction: PropTypes.object,
	className: PropTypes.string,
	handleButtonClick: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
	popupIcons: PropTypes.array,
	filledStars: PropTypes.number,
	closeBtn: PropTypes.object,
	popupEmailInput: PropTypes.bool,
	handleEmailInput: PropTypes.func.isRequired,
	emailValue: PropTypes.string
};

export default Popup;