import React from 'react';
import PropTypes from 'prop-types';
import 'firebase/firestore';
import gameConfig from 'config/app.config';
import {clearAllBodyScrollLocks} from 'body-scroll-lock';
import {avatarsData} from 'data/avatars-data';
import {goalsData} from 'data/goals-data';
import {dummyProfile} from 'data/diploma-data';
import {createNewLogEntry, updateLogEntry} from 'helpers/game-statistics-helper';
import PwaInfo from 'components/pwa-info/pwa-info';
import Start from 'components/start/start';
import HomeController from 'components/home/home-controller';
import AvatarsController from 'components/avatars/avatars-controller';
import AvatarInfoController from 'components/avatar-info/avatar-info-controller';
import GoalsController from 'components/goals/goals-controller';
import ChallengeController from 'components/challenge/challenge-controller';
import ChallengeControllerLandscape from 'components/challenge-landscape/challenge-controller';
import QuizController from 'components/quiz/quiz-controller';
import InterestsController from 'components/interests/interests-controller';
import ActionsController from 'components/actions/actions-controller';
import ActionsControllerLandscape from 'components/actions-landscape/actions-controller';
import DiplomaController from 'components/diploma/diploma-controller';
import ProfileController from 'components/profile/profile-controller';
import TutorialController from 'components/tutorial/tutorial-controller';

const gameSteps = {
	start: {component: Start},
	home:	{component: HomeController},
	pwaInfo: {component: PwaInfo},
	avatars: {component: AvatarsController},
	avatarInfo: {component: AvatarInfoController},
	goals: {component: GoalsController},
	challenge: {component: ChallengeController, landscapeComponent: ChallengeControllerLandscape},
	profile: {component: ProfileController},
	quiz: {component: QuizController},
	interests: {component: InterestsController},
	actions: {component: ActionsController, landscapeComponent: ActionsControllerLandscape},
	diploma: {component: DiplomaController}
};

class GameController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			logGameProgress: (gameConfig.env !== 'development'),
			prevStepId: null,
			stepId: 'start',
			avatar: null,			
			goal: null,
			// stepId: 'challenge',
			// avatar: avatarsData[0],
			// goal: goalsData[8],
			interest: null,
			profile: {},
			cachedChallengeCompleted: null,
			cachedDiplomaEmailed: null,
			cachedProfile: null,
			challengeCompleted: false,
			diplomaEmailed: false,
			introPopupStatuses: {
				avatarIntroSeen: false,
				avatarInfoIntroSeen: false,
				goalIntroSeen: false,
				profileIntroSeen: false,
				quizIntroSeen: false,
				interestIntroSeen: false,
				actionsIntroSeen: false
			},
			tutorialStep: null,
			numberOfSelected: null,
			tutorialStatus: {
				part1Seen: false,
				part2Seen: false
			},
			statistics: {
				docRef: null,
				challengesWon: 0,
				challengesFailed: 0,
				startPart1Time: null,
				endPart1Time: null,
				startPart2Time: null,
				endPart2Time: null,
				diplomaEmailed: null
			}
		};
		this.loadUserDataCache = this.loadUserDataCache.bind(this);
		this.updateStatistics = this.updateStatistics.bind(this);
		this.clearCache = this.clearCache.bind(this);
		this.goToStep = this.goToStep.bind(this);
		this.updateIntroPopupStatus = this.updateIntroPopupStatus.bind(this);
		this.handleRefresh = this.handleRefresh.bind(this);
		this.showTutorialStep = this.showTutorialStep.bind(this);
		this.updateTutorialStatus = this.updateTutorialStatus.bind(this);
		this.handleSelectAvatar = this.handleSelectAvatar.bind(this);
		this.handleSelectGoal = this.handleSelectGoal.bind(this);
		this.saveQuizResults = this.saveQuizResults.bind(this);
		this.saveActions = this.saveActions.bind(this);
		this.saveProfile = this.saveProfile.bind(this);
		this.selectInterest = this.selectInterest.bind(this);
		this.handleChallengeCompleted = this.handleChallengeCompleted.bind(this);
		this.handleChallengeFailed = this.handleChallengeFailed.bind(this);
		this.handleDiplomaEmailed = this.handleDiplomaEmailed.bind(this);
	}

	/* Component mounted, load data */
	componentDidMount() {
		Promise.all([
			this.loadUserDataCache(),
			// this.loadGoals(),
		]).then((response) => {
			let cachedProfile = null;
			let cachedChallengeCompleted = null;
			let cachedDiplomaEmailed = null;
			let tutorialStatus = JSON.parse(JSON.stringify(this.state.tutorialStatus));
			let introPopupStatuses = JSON.parse(JSON.stringify(this.state.introPopupStatuses));

			/* Get cached data */
			if (response[0].status === 'ok') {
				cachedProfile = response[0].cachedProfile;
				cachedChallengeCompleted = response[0].cachedChallengeCompleted;
				cachedDiplomaEmailed = response[0].cachedDiplomaEmailed;

				/* TODO: Check that cached profile has all the content it needs, otherwise reset */
				// if (gameConfig.env !== 'development') {
				// 	if (!(cachedProfile.hasOwnProperty('age'))) {
				// 		fetch('vucUserData', {
				// 			method: 'POST',
				// 			body: JSON.stringify({
				// 				challengeCompleted: true, 
				// 			})
				// 		});
				// cachedProfile = null;
				// cachedDiplomaEmailed = false;
				// 	}
				// }
			}

			/* TESTING: profile */
			let useDummyProfile = false;
			if (useDummyProfile && gameConfig.env === 'development') {
				cachedChallengeCompleted = true;
				this.setState({profile: dummyProfile});
			}

			/* TESTING: cached profile	*/
			let useDummyCache = false;
			if (useDummyCache && gameConfig.env === 'development') {
				cachedChallengeCompleted = true;
				cachedProfile = dummyProfile;
			}

			/* Tutorial & intro popup statuses */
			if (cachedChallengeCompleted) {
				introPopupStatuses.avatarIntroSeen = true;
				introPopupStatuses.avatarInfoIntroSeen = true;
				introPopupStatuses.goalIntroSeen = true;
				tutorialStatus.part1Seen = true;
				tutorialStatus.part2Seen = true;
			}
			if (cachedProfile) {
				introPopupStatuses.profileIntroSeen = true;
				introPopupStatuses.quizIntroSeen = true;
				introPopupStatuses.interestIntroSeen = true;
				introPopupStatuses.actionsIntroSeen = true;
			}
			
			this.setState({
				isLoading: false, 
				cachedProfile: cachedProfile, 
				cachedChallengeCompleted: cachedChallengeCompleted, 
				cachedDiplomaEmailed: cachedDiplomaEmailed,
				introPopupStatuses: introPopupStatuses,
				tutorialStatus: tutorialStatus
			});
		}).catch((error) => {
			console.error(error);
			this.setState({ isLoading: false});
		});
	}

	/* Load user data from cache */
	loadUserDataCache() {
		return new Promise((resolve) => {
			let cachedProfile = null;
			let cachedChallengeCompleted = null;
			let cachedDiplomaEmailed = null;

			if (gameConfig.env !== 'development') {
				fetch('vucUserData').then((response) => {return response.json();}).then((data) => {
					if (data) {
						if (data.profile) cachedProfile = data.profile;
						if (data.hasOwnProperty('challengeCompleted')) {
							cachedChallengeCompleted = data.challengeCompleted;
						}
						if (data.hasOwnProperty('diplomaEmailed')) {
							cachedDiplomaEmailed = data.diplomaEmailed;
						}
					}
					resolve({status: 'ok', cachedProfile, cachedChallengeCompleted, cachedDiplomaEmailed});
				}, (error) => {resolve({status: 'error', error});});
			} else {
				resolve({status: 'ok', cachedProfile, cachedChallengeCompleted, cachedDiplomaEmailed});
			}
		});
	}

	/**
	 * Clear cache
	 * @param {string} path 
	 */
	clearCache(path = null) {
		if (!path) {
			fetch('vucCookieConsent', {method: 'POST', body: JSON.stringify({})})
				.then(() => {this.handleRefresh();}, (error) => {console.error(error);});
			fetch('vucUserData', {method: 'POST', body: JSON.stringify({})})
				.then(() => {this.handleRefresh();}, (error) => {console.error(error);});
		} else {
			fetch(path, {method: 'POST', body: JSON.stringify({})})
				.then(() => {this.handleRefresh();}, (error) => {console.error(error);});
		}
	}

	/**
	 * Go to step
	 * @param {string} stepId 
	 */
	goToStep(stepId) {
		/* Clear body scroll locks */
		clearAllBodyScrollLocks();

		/* Go fullscreen (if on mobile, but not iOS) */
		if (
			this.props.isFullscreen === false && 
			this.props.fullscreenAllowed === true && 
			this.props.viewMode !== 'landscape'
		) {
			this.props.handleToggleFullscreen();
		}

		/* Close popup if open */
		if (this.props.popupIsOpen) this.props.closePopup();

		/* Go to step */
		let prevStepId = this.state.stepId;
		this.setState({stepId: stepId, prevStepId: prevStepId, tutorialStep: null});

		/* Log game progress */
		if (this.state.logGameProgress) {
			/* Log part 1 started */
			if (stepId === 'avatars' && prevStepId === 'start') {
				let statistics = JSON.parse(JSON.stringify(this.state.statistics));
				statistics.startPart1Time = Math.floor(Date.now() / 1000.);
				this.updateStatistics(statistics);
			}

			/* Log part 1 completed */
			if (stepId === 'home' && prevStepId === 'challenge' && this.state.statistics.endPart1Time === null) {
				let statistics = JSON.parse(JSON.stringify(this.state.statistics));
				statistics.endPart1Time = Math.floor(Date.now() / 1000.);
				this.updateStatistics(statistics);
			}

			/* Log part 2 started */
			if (stepId === 'profile' && prevStepId === 'home' && this.state.statistics.startPart2Time === null) {
				let statistics = JSON.parse(JSON.stringify(this.state.statistics));
				statistics.startPart2Time = Math.floor(Date.now() / 1000.);
				this.updateStatistics(statistics);
			}

			/* Log part 2 completed */
			if (stepId === 'diploma' && prevStepId === 'actions' && this.state.statistics.endPart2Time === null) {
				let statistics = JSON.parse(JSON.stringify(this.state.statistics));
				statistics.endPart2Time = Math.floor(Date.now() / 1000.);
				statistics.diplomaEmailed = false;
				this.updateStatistics(statistics);
			}
		}
	}

	/**
	 * Update statistics in DB
	 * @param {object} statistics 
	 */
	updateStatistics(statistics) {
		if (statistics.docRef !== null) {
			updateLogEntry(statistics).then((response) => {this.setState({statistics: response.statistics});});
		} else {
			createNewLogEntry(statistics).then((response) => {this.setState({statistics: response.statistics});});
		}
	}

	/**
	 * Update status of intro popup
	 * @param {string} property 
	 * @param {bool} value 
	 */
	updateIntroPopupStatus(property, value) {
		let introPopupStatuses = JSON.parse(JSON.stringify(this.state.introPopupStatuses));
		introPopupStatuses[property] = value;
		this.setState({introPopupStatuses: introPopupStatuses});
	}

	/**
	 * Show tutorial step
	 * @param {string} step 
	 * @param {number} numberOfSelected
	 */
	showTutorialStep(step, numberOfSelected) {
		this.setState({tutorialStep: step, numberOfSelected: numberOfSelected});
	}

	/**
	 * Update status of tutorial step
	 * @param {string} property 
	 * @param {bool} value 
	 */
	updateTutorialStatus(property, value) {
		let tutorialStatus = JSON.parse(JSON.stringify(this.state.tutorialStatus));
		tutorialStatus[property] = value;
		this.setState({tutorialStatus: tutorialStatus});
	}

	/**
	 * Part 1: select avatar
	 * @param {number} avatarId 
	 */
	handleSelectAvatar(avatarId) {
		let avatar = avatarsData.filter((avatar) => {return avatar.id === avatarId;})[0];
		this.setState({avatar: avatar}, () => {
			if (this.props.viewMode === 'landscape') {
				this.goToStep('goals');
			} else {
				this.goToStep('avatarInfo');
			}
		});
	}

	/**
	 * Part 1: select goal
	 * @param {string} goalId 
	 */
	handleSelectGoal(goalId) {
		let goal = goalsData.filter((goal) => {return goal.id === goalId;})[0];
		this.setState({goal: goal}, () => {this.goToStep('challenge');});
	}

	/**
	 * Part 1: challenge completed
	 */
	handleChallengeCompleted() {
		let tutorialStatus = JSON.parse(JSON.stringify(this.state.tutorialStatus));
		tutorialStatus.challengeDnD = true;
		tutorialStatus.challengeReorder = true;
		tutorialStatus.challengeInfo = true; 
		tutorialStatus.challengeGoal = true;
		tutorialStatus.challengeColors = true;
		tutorialStatus.challengeActionOrder = true;
		this.setState({ challengeCompleted: true, tutorialStatus: tutorialStatus});

		/* Save in cache */
		if (gameConfig.env !== 'development' && !this.state.cachedChallengeCompleted) {
			fetch('vucUserData', {
				method: 'POST',
				body: JSON.stringify({
					profile: this.state.cachedProfile, 
					challengeCompleted: true, 
					diplomaEmailed: this.state.cachedDiplomaEmailed
				})
			}).then(() => {
				this.setState({cachedChallengeCompleted: true});
			});
		}

		/* Log part 1 challenge completed */
		if (this.state.logGameProgress) {
			let statistics = JSON.parse(JSON.stringify(this.state.statistics));
			statistics.challengesWon = statistics.challengesWon + 1;
			this.updateStatistics(statistics);	
		}
	}

	/**
	 * Part 1: challenge failed
	 */
	handleChallengeFailed() {
		/* Log part 1 challenge failed */
		if (this.state.logGameProgress) {
			let statistics = JSON.parse(JSON.stringify(this.state.statistics));
			statistics.challengesFailed = statistics.challengesFailed + 1;
			this.updateStatistics(statistics);
		}
	}

	/**
	 * Part 2: save user profile
	 * @param {string} name 
	 * @param {number} age 
	 * @param {object} education 
	 * @param {object} experience 
	 */
	saveProfile(name, age, education, experience) {
		this.setState({
			profile: {
				id: 0,
				name: name,
				age: age,
				education: education,
				experience: experience
			}
		}, () => {this.goToStep('quiz');});
	}

	/**
	 * Part 2: save user stats and quiz answers
	 * @param {object} stats 
	 * @param {array} answers 
	 */
	saveQuizResults(stats, answers) {
		this.setState({
			profile: {...this.state.profile, stats: stats, answers: answers}
		}, () => {this.goToStep('interests');});
	}

	/**
	 * Part 2: save user interest
	 * @param {object} interest
	 */
	selectInterest(interest) {
		this.setState({
			profile: {...this.state.profile, interest: interest}
		}, () => {this.goToStep('actions');});
	}

	/**
	 * Part 2: save user-selected actions
	 * @param {array} actions 
	 */
	saveActions(actions) {
		this.setState({
			profile: {...this.state.profile, actions: actions},
			diplomaEmailed: false,
		}, () => {
			/* Save user profile in cache */
			if (gameConfig.env !== 'development') {
				fetch('vucUserData', {
					method: 'POST',
					body: JSON.stringify({ 
						profile: this.state.profile, 
						challengeCompleted: true, 
						diplomaEmailed: false
					})
				}).then(() => {
					this.setState({cachedDiplomaEmailed: false, cachedProfile: this.state.profile}, () => {
						this.goToStep('diploma');
					});
				});
			}	 else {
				this.goToStep('diploma');
			}	
		});
	}

	/**
	 * Part 2: diploma emailed
	 */
	handleDiplomaEmailed() {
		this.setState({ diplomaEmailed: true });

		/* Log diploma emailed */
		if (this.state.logGameProgress && !this.state.statistics.diplomaEmailed) {
			let statistics = JSON.parse(JSON.stringify(this.state.statistics));
			statistics.diplomaEmailed = true;
			this.updateStatistics(statistics);
		}


		/* Save in cache */
		if (gameConfig.env !== 'development' && !this.state.cachedDiplomaEmailed) {
			fetch('vucUserData', {
				method: 'POST',
				body: JSON.stringify({
					profile: this.state.cachedProfile, 
					challengeCompleted: true, 
					diplomaEmailed: true
				})
			}).then(() => {
				this.setState({ cachedChallengeCompleted: true, cachedDiplomaEmailed: true});
			});
		}
	}

	/**
	 * Refresh game
	 */
	handleRefresh() {
		// eslint-disable-next-line no-restricted-globals
		location.reload();
	}

	/**
	 * Render component
	 */
	render() {
		let Component = Start;
		if (gameSteps.hasOwnProperty(this.state.stepId)) {
			Component = gameSteps[this.state.stepId].component;
			if (
				this.props.viewMode === 'landscape' && 
				gameSteps[this.state.stepId].hasOwnProperty('landscapeComponent')
			) {
				Component = gameSteps[this.state.stepId].landscapeComponent;
			}
		}

		let initialEnergy = 40;
		if (this.state.goal && this.state.goal.energy) initialEnergy = this.state.goal.energy;

		return (
			<React.Fragment>
				<Component
					isLoading={this.state.isLoading}
					isInStandaloneMode={this.props.isInStandaloneMode}
					isFullscreen={this.props.isFullscreen}
					isIos={this.props.isIos}
					viewMode={this.props.viewMode}
					popupIsOpen={this.props.popupIsOpen}
					tutorialIsOpen={this.state.tutorialStep !== null}
					stepId={this.state.stepId}
					prevStepId={this.state.prevStepId}
					introPopupStatuses={this.state.introPopupStatuses}
					tutorialStatus={this.state.tutorialStatus}
					avatar={this.state.avatar} 
					goal={this.state.goal}
					interest={this.state.interest}
					clearCache={this.clearCache}
					goToStep={this.goToStep} 
					updateIntroPopupStatus={this.updateIntroPopupStatus}
					showTutorialStep={this.showTutorialStep}
					updateTutorialStatus={this.updateTutorialStatus}
					handleRefresh={this.handleRefresh}
					handleSelectAvatar={this.handleSelectAvatar}
					handleSelectGoal={this.handleSelectGoal}
					selectInterest={this.selectInterest}
					openPopup={this.props.openPopup}
					closePopup={this.props.closePopup}
					saveQuizResults={this.saveQuizResults}
					profile={this.state.profile}
					cachedProfile={this.state.cachedProfile}
					saveActions={this.saveActions}
					saveProfile={this.saveProfile}
					initialEnergy={initialEnergy}
					challengeCompleted={(this.state.challengeCompleted || this.state.cachedChallengeCompleted === true)}
					diplomaEmailed={this.state.diplomaEmailed || this.state.cachedDiplomaEmailed === true}
					emailValue={this.props.emailValue}
					handleToggleFullscreen={this.props.handleToggleFullscreen}
					handleChallengeCompleted={this.handleChallengeCompleted}
					handleChallengeFailed={this.handleChallengeFailed}
					handleDiplomaEmailed={this.handleDiplomaEmailed}
				/>
				<TutorialController 
					viewMode={this.props.viewMode}
					isIos={this.props.isIos}
					avatar={this.state.avatar} 
					step={this.state.tutorialStep} 
					numberOfSelected={this.state.numberOfSelected}
					showTutorialStep={this.showTutorialStep}
					updateTutorialStatus={this.updateTutorialStatus}
				/>
			</React.Fragment>
		);
	}
}

GameController.defaultProps = {
	popupIsOpen: false
};

GameController.propTypes = {
	isInStandaloneMode: PropTypes.bool.isRequired,
	isIos: PropTypes.bool.isRequired,
	fullscreenAllowed: PropTypes.bool.isRequired,
	isFullscreen: PropTypes.bool.isRequired,
	handleToggleFullscreen: PropTypes.func.isRequired,
	popupIsOpen: PropTypes.bool,
	viewMode: PropTypes.string.isRequired,
	openPopup: PropTypes.func,
	closePopup: PropTypes.func,
	emailValue: PropTypes.string
};

export default GameController;