import React from 'react';
import PropTypes from 'prop-types';
import gameConfig from 'config/app.config';
// import { setCookie, getCookie } from 'helpers/cookie-helper';
import {popupsData} from 'data/popups-data';
import CookieConsent from './cookie-consent';

class CookieConsentController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showCookieConsent: false
		};
		this.loadCookieConsent = this.loadCookieConsent.bind(this);
		this.updateCookieConsent = this.updateCookieConsent.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleReadMore = this.handleReadMore.bind(this);
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		if (gameConfig.env !== 'development') {
			let showCookieConsent = true;
			this.loadCookieConsent().then((response) => {
				if (response.status === 'ok' && response.cookiesAccepted === true) showCookieConsent = false;
				this.setState({showCookieConsent: showCookieConsent});
			});
		} else {
			this.setState({showCookieConsent: true});
		}

		// let token = getCookie('vuc_cookie_accepted');
		// if (token && token === 'true') {
		// 	this.setState({showCookieConsent: false});
		// } else {
		// 	this.setState({showCookieConsent: true});
		// }
	}


	/* Load cookie consent from cache */
	loadCookieConsent() {
		return new Promise((resolve) => {
			fetch('vucCookieConsent').then((response) => {return response.json();}).then((data) => {
				if (data && data.hasOwnProperty('cookiesAccepted')) {
					resolve({status: 'ok', cookiesAccepted: data.cookiesAccepted});
				} else {
					resolve({status: 'ok', cookiesAccepted: false});
				}
			}, (error) => {resolve({status: 'error', error});});
		});
	}

	/* Update cached cookie consent */
	updateCookieConsent(cookiesAccepted) {
		fetch('vucCookieConsent', {
			method: 'POST', 
			body: JSON.stringify({cookiesAccepted: cookiesAccepted})
		}).then(() => {
			this.setState({showCookieConsent: !cookiesAccepted});
		}, (error) => {console.error(error);});
	}


	/* Accept and close cookies popup */
	handleClose() {
		if (gameConfig.env !== 'development') {this.updateCookieConsent(true);}
		this.setState({showCookieConsent: false});
		// setCookie('vuc_cookie_accepted', 'true', 365);
		// this.setState({showCookieConsent: false});
	}

	/* Open read more popup */
	handleReadMore() {
		let popupData = JSON.parse(JSON.stringify(popupsData.cookieInfo));
		this.props.openPopup(
			popupData.title, 
			popupData.text, null, null, {action: this.props.closePopup, actionParams: []}
		);
	}

	/* Render component */
	render() {
		if (this.state.showCookieConsent) {
			return (
				<CookieConsent 
					viewMode = {this.props.viewMode}
					handleClose = {this.handleClose}
					handleReadMore = {this.handleReadMore}
				/>
			);
		}
		return null;
	}
}

CookieConsentController.propTypes = {
	viewMode: PropTypes.string.isRequired,
	openPopup: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
};

export default CookieConsentController;