const goalsData = [
	/* Tømrer */
	{
		id: 'carpenter',
		title: 'Tømrer',
		description: 'En tømrer tegner, planlægger og bygger i træ og andre byggematerialer.',
		education: 'Tømrer (EUD)',
		iconId: 'crafts_construction',
		energy: 40,
		requirements: [
			{actionId: 'internship'},
			{actionId: 'basic-eud'}
		],
		actions: [
			{id: 'internship'},
			{id: 'basic-eud'},
			{id: 'public-school'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'company-worker'},
			{id: 'job-after-school'}
		]
	},
	/* Veterinærmedicin */
	{
		id: 'veterinary-medicin',
		title: 'Veterinærmedicin',
		education: 'Universitetsuddannelse',
		description: 'En kandidat i veterinærmedicin kan arbejde som dyrlæge eller med fødevarekontrol.',
		iconId: 'food_health',
		energy: 50,
		requirements: [
			{actionId: 'university'}
		],
		actions: [
			{id: 'university'},
			{id: 'public-school'},
			{id: 'highschool'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'job-in-farming'},
			{id: 'temp-in-school'}
		]
	},
	/* Gartner */
	{
		id: 'gardener',
		title: 'Gartner',
		education: 'Gartner (EUD)',
		description: 'En gartner dyrker blomster, træer, potteplanter eller grøntsager.',
		iconId: 'animals_nature_environment',
		energy: 40,
		requirements: [
			{actionId: 'internship'},
			{actionId: 'basic-eud'}
		],
		actions: [
			{id: 'internship'},
			{id: 'basic-eud'},
			{id: 'public-school'},
			{id: 'job-internship'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'job-at-florist'},
			{id: 'job-after-school'}
		]
	},
	/* Pædagog */
	{
		id: 'pedagogue',
		title: 'Pædagog',
		description: 'En pædagog arbejder med udviklings- og omsorgsopgaver med børn, unge og voksne.',
		education: 'Professionshøjskole',
		iconId: 'caregiving',
		energy: 55,
		requirements: [
			{actionId: 'college'}
		],
		actions: [
			{id: 'college'},
			{id: 'specific-eud'},
			{id: 'public-school'},
			{id: 'highschool'},
			{id: 'job-internship'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'temp-in-kindergarten'},
			{id: 'temp-in-school'}, 
			{id: 'job-in-home-care'},
			{id: 'disability-helper'},
		]
	},
	/* Diætist */
	{
		id: 'nutritionist',
		title: 'Diætist',
		description: 'En diætisk vejleder om sundhed, kost og fysisk aktivitet.',
		education: 'Professionshøjskole',
		iconId: 'food_health',
		energy: 40,
		requirements: [
			{actionId: 'college'}
		],
		actions: [
			{id: 'college'},
			{id: 'public-school'},
			{id: 'highschool'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'job-at-cafe'},
			{id: 'job-in-hospital'},
		]
	},
	/* Kok */
	{
		id: 'chef',
		title: 'Kok',
		education: 'Gastronom (EUD)',
		description: 'En kok arbejder sammen med andre i et køkken. Det er kokkens ansvar at lave og sammensætte mad.',
		iconId: 'food_health',
		energy: 40,
		requirements: [
			{actionId: 'internship'},
			{actionId: 'basic-eud'}
		],
		actions: [
			{id: 'internship'},
			{id: 'basic-eud'},
			{id: 'public-school'},
			{id: 'job-at-hotel'},
			{id: 'job-internship'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'job-at-cafe'},
			{id: 'cleaning-job'}
		]
	},
	/* Fysioterapeut */
	{
		id: 'physiotherapist',
		title: 'Fysioterapeut',
		education: 'Professionshøjskole',
		description: 'En fysioterapeut arbejder med sundhed og sygdom med udgangspunkt i krop og bevægelse.',
		iconId: 'food_health',
		energy: 55,
		requirements: [
			{actionId: 'college'}
		],
		actions: [
			{id: 'college'},
			{id: 'specific-eud'},
			{id: 'public-school'},
			{id: 'highschool'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'job-in-home-care'},
			{id: 'disability-helper'},
			{id: 'temp-in-kindergarten'},
			{id: 'temp-in-school'}
		]
	},
	/* Sygeplejerske */
	{
		id: 'nurse',
		title: 'Sygeplejerske',
		education: 'Professionshøjskole',
		description: 'En sygeplejerske udfører og formidler sygepleje tæt på mennesker i svære livssituationer.',
		iconId: 'caregiving',
		energy: 40,
		requirements: [
			{actionId: 'college'}
		],
		actions: [
			{id: 'college'},
			{id: 'specific-eud'},
			{id: 'public-school'},
			{id: 'highschool'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'job-in-home-care'},
			{id: 'disability-helper'},
			{id: 'temp-in-kindergarten'},
			{id: 'temp-in-school'}
		]
	},
	/* Social- og sundhedshjælper */
	{
		id: 'social-worker',
		title: 'Social- og sundhedshjælper',
		education: 'Social- og sundhedshjælper (EUD)',
		description: 'En social- og sundhedshjælper plejer ældre mennesker hjemme eller på plejehjem.',
		iconId: 'caregiving',
		energy: 50,
		requirements: [
			{actionId: 'internship'},
			{actionId: 'basic-eud'}
		],
		actions: [
			{id: 'internship'},
			{id: 'basic-eud'},
			{id: 'public-school'},
			{id: 'job-internship'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'it-course-fvu'},
			{id: 'job-in-home-care'},
			{id: 'temp-in-kindergarten'},
			{id: 'disability-helper'},
		]
	},
	/* Murer */
	{
		id: 'mason',
		title: 'Murer',
		description: 'En murer bygger og vedligeholder huse af sten.',
		education: 'Murer (EUD)',
		iconId: 'crafts_construction',
		energy: 40,
		requirements: [
			{actionId: 'internship'},
			{actionId: 'basic-eud'}
		],
		actions: [
			{id: 'internship'},
			{id: 'basic-eud'},
			{id: 'public-school'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'job-after-school'},
			{id: 'company-worker'}
		]
	},
	/* Serviceassistent */
	{
		id: 'service-assistant',
		title: 'Serviceassistent',
		description: 'En serviceassistent gør rent, rydder op og hjælper patienter på en god og positiv måde.',
		education: 'Serviceassistent (EUD)',
		iconId: 'crafts_construction',
		energy: 40,
		requirements: [
			{actionId: 'cleaning-job'}
		],
		actions: [
			{id: 'cleaning-job'},
			{id: 'job-internship'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'it-course-fvu'}, 
			{id: 'job-after-school'},
			{id: 'company-worker'}
		]
	},
	/* Mekaniker */
	{
		id: 'mechanic',
		title: 'Mekaniker',
		description: 'En mekaniker reparerer skader og laver eftersyn på biler, lastbiler, busser og påhængsvogne.',
		education: 'Mekanikeruddannelse (EUD)',
		iconId: 'engineering_mechanics_machines',
		energy: 45,
		requirements: [
			{actionId: 'internship'},
			{actionId: 'basic-eud'}
		],
		actions: [
			{id: 'internship'},
			{id: 'basic-eud'},
			{id: 'public-school'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'job-in-metal-business'},
			{id: 'job-in-storage'},
		]
	},
	/* Chauffør */
	{
		id: 'chauffeur',
		title: 'Chauffør',
		education: 'Vejgodstransport (AMU)',
		description: 'En chauffør kører folk fra et sted til et andet og sørge for, at de er tilfredse.',
		iconId: 'transport_logistics',
		energy: 40,
		requirements: [
			{actionId: 'amu-certificate'},
		],
		actions: [
			{id: 'amu-certificate'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'company-worker'},
			{id: 'job-after-school'},
			{id: 'job-in-storage'},
		]
	},
	/* Receptionist */
	{
		id: 'receptionist',
		title: 'Receptionist',
		description: 'En receptionist arbejder fx på hotel med at modtage og hjælpe gæster, så de får et godt ophold.',
		education: 'Receptionist (EUD)',
		iconId: 'food_health',
		energy: 40,
		requirements: [
			{actionId: 'internship'},
			{actionId: 'basic-eud'}
		],
		actions: [
			{id: 'internship'},
			{id: 'basic-eud'},
			{id: 'public-school'},
			{id: 'job-internship'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'job-at-hotel'},
			{id: 'job-after-school'},
			{id: 'job-in-service-industry'},
			{id: 'it-course-fvu'},
		]
	},
	/* Truckfører */
	{
		id: 'truck-driver',
		title: 'Truckfører',
		description: 'Du får fx certifikat til gaffeltruck og gaffelstabler, og arbejder med lagerstyring.',
		education: 'Lager, terminal og logistik (AMU)',
		iconId: 'transport_logistics',
		energy: 40,
		requirements: [
			{actionId: 'amu-certificate'}
		],
		actions: [
			{id: 'amu-certificate'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'job-after-school'},
			{id: 'job-in-storage'},
			{id: 'company-worker'},
		]
	},
	/* Blomsterbinder */
	{
		id: 'florist',
		title: 'Blomsterbinder',
		description: 'En blomsterbinder arbejder med sammensætning af blomster og planter til fx buketter.',
		education: 'Blomsterbinder (AMU)',
		iconId: 'office_commerce',
		energy: 40,
		requirements: [
			{actionId: 'amu-certificate'}
		],
		actions: [
			{id: 'amu-certificate'},
			{id: 'job-internship'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'job-after-school'},
			{id: 'job-in-shop'}, 
			{id: 'job-at-florist'}
		]
	},
	/* Sælger */
	{
		id: 'salesman',
		title: 'Sælger',
		description: 'En sælger arbejder med salg af varer og skal derfor optræde venlig og udadvendt.',
		education: 'Job',
		iconId: 'office_commerce',
		energy: 40,
		requirements: [
			{actionId: 'internship-in-shop'}
		],
		actions: [
			{id: 'internship-in-shop'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'it-course-fvu'},
			{id: 'job-in-shop'}, 
			{id: 'job-after-school'}
		]
	},
	/* Tjener */
	{
		id: 'waiter',
		title: 'Tjener',
		description: 'En tjener serverer mad og drikke på et spisested og hjælper gæsterne med at vælge.',
		education: 'Tjener (EUD)',
		iconId: 'food_health',
		energy: 40,
		requirements: [
			{actionId: 'internship'},
			{actionId: 'basic-eud'}
		],
		actions: [
			{id: 'internship'},
			{id: 'basic-eud'},
			{id: 'public-school'},
			{id: 'job-internship'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'it-course-fvu'},
			{id: 'job-at-cafe'},
			{id: 'job-after-school'},
			{id: 'job-at-hotel'}
		]
	},
	/* Kontoruddannelse */
	{
		id: 'office-education',
		title: 'Kontoruddannelse',
		description: 'En kontoruddannet tager sig fx af kundekontakt, markedsføring, økonomi og information.',
		education: 'EUX',
		iconId: 'office_commerce',
		energy: 45,
		requirements: [
			{actionId: 'internship'},
			{actionId: 'eux'}
		],
		actions: [
			{id: 'internship'},
			{id: 'eux'},
			{id: 'hf-single-subject'},
			{id: 'public-school'},
			{id: 'job-internship'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'job-as-salesman'},
			{id: 'job-after-school'},
			{id: 'it-course-fvu'},
		]
	},
	/* Landbrugsassistent */
	{
		id: 'agriculture-assistant',
		title: 'Landbrugsassistent',
		education: 'Landbrugsuddannelsen (EUD)',
		description: 'En landmand arbejder med husdyr og dyrker jorden med brug af forskellige landbrugsmaskiner.',
		iconId: 'animals_nature_environment',
		energy: 40,
		requirements: [
			{actionId: 'internship'},
			{actionId: 'basic-eud'}
		],
		actions: [
			{id: 'internship'},
			{id: 'basic-eud'},
			{id: 'specific-eud'},
			{id: 'public-school'},
			{id: 'job-internship'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'it-course-fvu'},
			{id: 'job-in-farming'},
			{id: 'job-after-school'},
			{id: 'company-worker'},
		]
	},
	/* Fotograf */
	{
		id: 'photographer',
		title: 'Fotograf',
		description: 'En fotograf tager og tænker i billeder. Der er fokus på motiv, teknik, lys og efterbehandling.',
		education: 'Fotograf (EUD)',
		iconId: 'design_media',
		energy: 40,
		requirements: [
			{actionId: 'internship'},
			{actionId: 'basic-eud'}
		],
		actions: [
			{id: 'internship'},
			{id: 'basic-eud'},
			{id: 'public-school'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'job-in-media'},
			{id: 'internship-media'},
			{id: 'job-after-school'},
		]
	},
	/* Multimediedesigner */
	{
		id: 'multimedia-designer',
		title: 'Multimediedesigner',
		education: 'Erhvervsakademi',
		description: 'En multimediedesigner udvikler og implementerer digitalt design og brugeroplevelser.',
		iconId: 'office_commerce',
		energy: 55,
		requirements: [
			{actionId: 'business-academy'}
		],
		actions: [
			{id: 'business-academy'},
			{id: 'internship'},
			{id: 'public-school'},
			{id: 'highschool'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'job-in-media'},
			{id: 'internship-media'},
			{id: 'it-course-fvu'},
			{id: 'job-internship'},
			{id: 'job-after-school'}
		]
	},
	/* Webudvikler */
	{
		id: 'web-developer',
		title: 'Webudvikler',
		description: 'En webudvikler koder og designer hjemmesider, med brug af fx databaser, billeder, video og lyd.',
		education: 'Webudvikler (EUD)',
		iconId: 'it_electronics',
		energy: 40,
		requirements: [
			// {actionId: 'internship'},
			{actionId: 'basic-eud'}
		],
		actions: [
			// {id: 'internship'},
			{id: 'basic-eud'},
			{id: 'public-school'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'job-in-media'},
			{id: 'internship-media'},
			{id: 'it-course-fvu'},
			{id: 'job-internship'},
			{id: 'job-after-school'}
		]
	},
	/* IT-tekniker */
	{
		id: 'it-technician',
		title: 'IT-Teknolog',
		description: 'En IT-teknolog arbejder med computere, elektronik, server- og netværksteknologi.',
		education: 'IT-Teknolog (EUD)',
		iconId: 'it_electronics',
		energy: 40,
		requirements: [
			{actionId: 'internship'},
			{actionId: 'basic-eud'}
		],
		actions: [
			{id: 'internship'},
			{id: 'basic-eud'},
			{id: 'public-school'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'it-course-fvu'},
			{id: 'job-in-media'},
			{id: 'internship-media'},
			{id: 'job-after-school'}
		]
	},
	/* Kassemedarbejder */
	{
		id: 'cashier',
		title: 'Kassemedarbejder',
		description: 'En kassemedarbejder sælger varer i fx et supermarked og gør det nemt for kunderne at købe ind.',
		education: 'Job',
		iconId: 'office_commerce',
		energy: 40,
		requirements: [
			{actionId: 'job-in-supermarket'}
		],
		actions: [
			{id: 'job-in-supermarket'},
			{id: 'job-internship'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'it-course-fvu'},
			{id: 'job-in-shop'}, 
			{id: 'job-after-school'}
		]
	},
	/* Elektriker */
	{
		id: 'electrician',
		title: 'Elektriker',
		description: 'En elektriker reparerer og installerer elektriske anlæg, strøm og kontakter i bygninger.',
		education: 'Elektriker (EUD)',
		iconId: 'it_electronics',
		energy: 40,
		requirements: [
			{actionId: 'internship'},
			{actionId: 'basic-eud'}
		],
		actions: [
			{id: 'internship'},
			{id: 'basic-eud'},
			{id: 'public-school'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'company-worker'},
			{id: 'job-after-school'},
			{id: 'job-in-shop'}, 
			{id: 'job-in-storage'}, 
		]
	},
	/* Jord- og betonarbejder */
	{
		id: 'construction-worker',
		title: 'Jord- og betonarbejder',
		description: 'En byggearbejder arbejder med en lang række praktiske opgaver ved byggeri og anlægsarbejde.',
		education: 'Job',
		iconId: 'crafts_construction',
		energy: 40,
		requirements: [
			{actionId: 'company-worker'}
		],
		actions: [
			{id: 'company-worker'},
			{id: 'job-in-farming'},
			{id: 'job-as-bricklayer'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'job-internship'},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
		]
	},
	/* Laborant */
	{
		id: 'laboratory-technician',
		title: 'Laborant',
		education: 'Erhvervsakademi',
		description: 'En laborant arbejder på laboratorium med fødevare- og miljøprøver til medicin eller produktion.',
		iconId: 'caregiving',
		energy: 40,
		requirements: [
			{actionId: 'business-academy'}
		],
		actions: [
			{id: 'business-academy'},
			{id: 'public-school'},
			{id: 'highschool'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'job-in-hospital'},
			{id: 'job-at-cafe'},
			{id: 'company-worker'},
		]
	},
	/* Bioanalytiker */
	{
		id: 'bioanalyst',
		title: 'Bioanalytiker',
		education: 'Professionshøjskole',
		description: 'En bioanalytiker arbejder med analyse af biologisk materiale fra mennesker - fx blodprøver.',
		iconId: 'animals_nature_environment',
		energy: 55,
		requirements: [
			{actionId: 'college'}
		],
		actions: [
			{id: 'college'},
			{id: 'specific-eud'},
			{id: 'public-school'},
			{id: 'highschool'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'job-in-home-care'},
			{id: 'job-in-hospital'},
		]
	},
	/* Pædagogisk assistent */
	{
		id: 'teaching-assistant',
		title: 'Pædagogisk assistent',
		description: 'En pædagogisk assistent arbejder med at støtte og stimulere børn, unge eller voksne.',
		education: 'Den pædagogiske assistentuddannelse (EUD)',
		iconId: 'caregiving',
		energy: 40,
		requirements: [
			{actionId: 'internship'},
			{actionId: 'basic-eud'}
		],
		actions: [
			{id: 'internship'},
			{id: 'basic-eud'},
			{id: 'public-school'},
			{id: 'job-internship'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'temp-in-kindergarten'},
			{id: 'job-after-school'},
			{id: 'nursinghome-temp'}
		]
	},
	/* Bager */
	{
		id: 'baker',
		title: 'Bager',
		description: 'En bager og konditor bager brød og kager og står også for planlægning og indkøb af råvarer.',
		education: 'Bager og konditor (EUD)',
		iconId: 'food_health',
		energy: 40,
		requirements: [
			{actionId: 'internship'},
			{actionId: 'basic-eud'}
		],
		actions: [
			{id: 'internship'},
			{id: 'basic-eud'},
			{id: 'public-school'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'job-in-shop'}, 
			{id: 'job-in-service-industry'},
			{id: 'job-as-salesman'},
			{id: 'job-after-school'}
		]
	},
	/* Blikkenslager */
	{
		id: 'plumber',
		title: 'Blikkenslager',
		description: 'En VVS-energiuddannet arbejder med fx gas, vand, varme og ventilationsanlæg.',
		education: 'VVS-Energiuddannelsen (EUD)',
		iconId: 'crafts_construction',
		energy: 40,
		requirements: [
			{actionId: 'internship'},
			{actionId: 'basic-eud'}
		],
		actions: [
			{id: 'internship'},
			{id: 'basic-eud'},
			{id: 'public-school'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'job-in-metal-business'},
			{id: 'job-after-school'}
		]
	},
	/* Slagter */
	{
		id: 'butcher',
		title: 'Slagter',
		description: 'En slagter arbejder med slagtning og partering af fx kreaturer og svin, og kvalitetskontrol.',
		education: 'Slagter (EUD)',
		iconId: 'food_health',
		energy: 40,
		requirements: [
			{actionId: 'internship'},
			{actionId: 'basic-eud'}
		],
		actions: [
			{id: 'internship'},
			{id: 'basic-eud'},
			{id: 'public-school'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'job-as-salesman'},
			{id: 'job-after-school'},
			{id: 'job-in-farming'}
		]
	},
	/* Butiksassistent */
	{
		id: 'shop-assistant',
		title: 'Butiksassistent',
		description: 'En salgsassistent arbejder med salg i en butik og skal kunne vejlede kunderne.',
		education: 'Detailhandel (EUD)',
		iconId: 'office_commerce',
		energy: 40,
		requirements: [
			{actionId: 'internship'},
			{actionId: 'basic-eud'}
		],
		actions: [
			{id: 'internship'},
			{id: 'basic-eud'},
			{id: 'public-school'},
			{id: 'job-internship'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'job-as-salesman'},
			{id: 'job-in-service-industry'},
			{id: 'job-in-shop'}
		]
	},
	/* Maler */
	{
		id: 'painter',
		title: 'Maler',
		description: 'En bygningsmaler maler fx murværk og træværk, blander farver, og vejleder om stil og trends.',
		education: 'Bygningsmaler (EUD)',
		iconId: 'crafts_construction',
		energy: 40,
		requirements: [
			{actionId: 'internship'},
			{actionId: 'basic-eud'}
		],
		actions: [
			{id: 'internship'},
			{id: 'basic-eud'},
			{id: 'public-school'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'company-worker'},
			{id: 'job-in-service-industry'},
			{id: 'job-after-school'},
		]
	},
	/* Industritekniker */
	{
		id: 'industrial-technician',
		title: 'Industritekniker',
		description: 'En industritekniker arbejder med maskiner, der kan dreje, bore og fræse i fx metal',
		education: 'Industritekniker (EUD)',
		iconId: 'engineering_mechanics_machines',
		energy: 40,
		requirements: [
			{actionId: 'internship'},
			{actionId: 'basic-eud'}
		],
		actions: [
			{id: 'internship'},
			{id: 'basic-eud'},
			{id: 'public-school'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'job-after-school'},
			{id: 'job-in-metal-business'},
			{id: 'job-internship'},
			{id: 'company-worker'},
		]
	},
	/* Klinikassistent */
	{
		id: 'clinical-assistant',
		title: 'Klinikassistent',
		description: 'En klinikassistent hjælper tandlægen med at modtage patienter, passe telefon og gøre redskaber klar.',
		education: 'Klinikassistent (EUD)',
		iconId: 'caregiving',
		energy: 40,
		requirements: [
			{actionId: 'internship'},
			{actionId: 'basic-eud'}
		],
		actions: [
			{id: 'internship'},
			{id: 'basic-eud'},
			{id: 'public-school'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'job-in-shop'}, 
			{id: 'job-in-service-industry'},
			{id: 'cleaning-job'}
		]
	},
	/* Stuktør */
	{
		id: 'structor',
		title: 'Struktør',
		description: 'En struktør anlægger fx fortove, veje og pladser, og lægger rør i jorden og etablerer kloakker.',
		education: 'Struktøruddannelse (EUD)',
		iconId: 'crafts_construction',
		energy: 40,
		requirements: [
			{actionId: 'internship'},
			{actionId: 'basic-eud'}
		],
		actions: [
			{id: 'internship'},
			{id: 'basic-eud'},
			{id: 'public-school'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'job-after-school'}
		]
	},
	/* Plastmager */
	{
		id: 'plastics-maker',
		title: 'Plastmager',
		description: 'En plastmager arbejder med plastik, fra små dimser til fx vinger til en vindmølle.',
		education: 'Plastmager (EUD)',
		iconId: 'crafts_construction',
		energy: 40,
		requirements: [
			{actionId: 'internship'},
			{actionId: 'basic-eud'}
		],
		actions: [
			{id: 'internship'},
			{id: 'basic-eud'},
			{id: 'public-school'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'company-worker'},
			{id: 'job-after-school'}
		]
	},
	/* Skorstensfejer */
	{
		id: 'chimney-sweeper',
		title: 'Skorstensfejer',
		description: 'En skorstensfejer renser kedler, skorstene og rør. De rådgiver også om fx fyring og brandfare.',
		education: 'Skorstensfejer (EUD)',
		iconId: 'crafts_construction',
		energy: 40,
		requirements: [
			{actionId: 'internship'},
			{actionId: 'basic-eud'}
		],
		actions: [
			{id: 'internship'},
			{id: 'basic-eud'},
			{id: 'public-school'},
			{id: 'language-course', condition: 'danish', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'danish-course-fvu', condition: 'reading', conditionType: 'statRel'},
			{id: 'dyslexia-course', condition: 'reading', conditionType: 'statVal', conditionMinVal: 2},
			{id: 'math-course-fvu', condition: 'math', conditionType: 'statRel'},
			{id: 'job-after-school'},
			{id: 'cleaning-job'},
			{id: 'job-in-storage'},
			{id: 'company-worker'}
		]
	},
];




export{goalsData};