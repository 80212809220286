import React from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts, startUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import gameConfig from 'config/app.config';
import Logo from 'components/logo/logo';
import FullscreenToggle from 'components/layout/fullscreen-toggle';
import Button from 'components/button/button';
import CookieConsentController from 'components/cookie-consent/cookie-consent-controller';
import PwaInfoController from 'components/pwa-info/pwa-info-controller';
import './start.scss';

const Start = (props) => {
	let {
		isLoading, 
		isFullscreen,
		viewMode, 
		// challengeCompleted, 
		isInStandaloneMode, 
		isIos, 
		clearCache, 
		goToStep, 
		openPopup, 
		closePopup,
		handleToggleFullscreen,
	} = props;
	
	let osType = (isIos ? 'ios' : 'android');

	return (
		<React.Fragment>
			<div className={'Start Start--' + osType + ' Start--' + viewMode + (isLoading ? ' Start--loading' : '')}>
				{viewMode === 'landscape' && 
					<FullscreenToggle 
						isFullscreen={isFullscreen} 
						handleToggleFullscreen={handleToggleFullscreen} 
						type='start'
					/>}
				<Logo isLoading={isLoading} viewMode={viewMode} />
				{!isLoading && 
					<React.Fragment>
						{gameConfig.env === 'test' && 
							<Button text="Reset" class="clearCache" onClick={() => {clearCache();}} />}
						<div className="Start-text">{renderMarkdown(startUiTexts.welcome)}</div>
						<div className="Start-startBtn">
							<Button
								viewMode={viewMode}
								text={(viewMode === 'portrait' ? generalUiTexts.start : generalUiTexts.startGame)}
								class="start"
								onClick={() => {goToStep('home');}}
								// onClick={()=> {
								// 	if (challengeCompleted) {
								// 		goToStep('home');
								// 	} else {
								// 		goToStep('avatars');
								// 	}
								// }}
							/>
						</div>
						<div className="Start-footer" />
					</React.Fragment>
				}
			</div>
			{(!isLoading && !isInStandaloneMode) && <PwaInfoController isIos={isIos} viewMode={viewMode} />}

			<CookieConsentController viewMode={viewMode} openPopup={openPopup} closePopup={closePopup} />
		</React.Fragment>
	);
};

Start.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	isFullscreen: PropTypes.bool.isRequired,
	viewMode: PropTypes.string.isRequired,
	// challengeCompleted: PropTypes.bool.isRequired,
	isInStandaloneMode: PropTypes.bool.isRequired,
	isIos: PropTypes.bool.isRequired,
	clearCache: PropTypes.func.isRequired,
	goToStep: PropTypes.func.isRequired,
	openPopup: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
	handleToggleFullscreen: PropTypes.func.isRequired
};

export default Start;