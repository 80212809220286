import React from 'react';
import PropTypes from 'prop-types';
import {DndProvider} from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import {sortArrayByProperty} from 'helpers/array-helper';
import {popupsData} from 'data/popups-data';
import {statsData} from 'data/stats-data';
import {generalUiTexts} from 'data/ui-texts';
import {
	getRequiredActions,
	getAvailableActions,
	calculateAvailableEnergy, 
	calculateChanceOfFailure,
	getColorsOfSelectedActions,
	getUnmetRequirements
} from 'helpers/challenge-helper';
import {getRequiredStats, calculateStats, getStatsColors} from 'helpers/stats-helper';
import Challenge from './challenge';

class ChallengeController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			draggingItemId: null,
			isMoving: false,
			isBuying: false,
			actionId: null,
			showAvatarInfo: false,
			animateDropActionId: null,
			challengeStatus: 'playing',
			initialActions: [],
			initialSelected: [],
			initialPlaceholderActions: [],
			actions: [],
			selected: [],
			placeHolderActions: [],
			scrollPos: 0,
			tipIndex: 0,
			challengeFinished: false
		};
		this.handleGoBack = this.handleGoBack.bind(this);
		this.toggleAvatarInfo = this.toggleAvatarInfo.bind(this);
		this.toggleActionInfo = this.toggleActionInfo.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
		this.handleWheelScroll = this.handleWheelScroll.bind(this);
		this.showTipIndex = this.showTipIndex.bind(this);
		this.handleDragStart = this.handleDragStart.bind(this);
		this.handleDragEnd = this.handleDragEnd.bind(this);
		this.handleStartChallenge = this.handleStartChallenge.bind(this);
		this.showStartChallengeErrorPopup = this.showStartChallengeErrorPopup.bind(this);
		this.showStartChallengeConfirmPopup = this.showStartChallengeConfirmPopup.bind(this);
		this.runChallenge = this.runChallenge.bind(this);
		this.challengeCompletedPopup = this.challengeCompletedPopup.bind(this);
		this.challengeFailedPopup = this.challengeFailedPopup.bind(this);
		this.resetChallenge = this.resetChallenge.bind(this);
		this.chanceActionSuccess = this.chanceActionSuccess.bind(this);
		this.timeout = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		/* Get required stats, required actions and available actions for the selected goal */
		let requiredStats = getRequiredStats(this.props.goal);
		let availableActions = getAvailableActions(this.props.goal, this.props.avatar, requiredStats);
		availableActions.forEach((action)  => {action.isSelected = false;});
		let selectedActions = [];
		let placeHolderActions = [...Array(availableActions.length)].map((_, index) => {
			return {id: null, index: index, initialIndex: index};
		});


		/* Add wheel event listener to scrollable actions */
		if (availableActions.length > 6) {
			let container = document.getElementById('scrollableContainer');
			if (container) {
				container.addEventListener('wheel', this.handleWheelScroll, false);
				container.addEventListener('DOMMouseScroll', this.handleWheelScroll, false);
			}
		}

		/* Set state */
		this.setState({
			initialActions: JSON.parse(JSON.stringify(availableActions)),
			initialSelected: JSON.parse(JSON.stringify(selectedActions)),
			initialPlaceholderActions: JSON.parse(JSON.stringify(placeHolderActions)),
			actions: availableActions,
			selected: selectedActions,
			placeHolderActions: placeHolderActions
		});
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount() {
		if (this.timeout) clearTimeout(this.timeout);
		let container = document.getElementById('scrollableContainer');
		if (container) {
			container.removeEventListener('wheel', this.handleWheelScroll, false);
			container.removeEventListener('DOMMouseScroll', this.handleWheelScroll, false);
		}
	}

	/**
	 * Go back to "select goal"
	 */
	handleGoBack() {
		this.props.goToStep('goals');
	}

	/** 
	 * Hide / show avatar info
	 */
	toggleAvatarInfo(showAvatarInfoParam = null) {
		if (showAvatarInfoParam === null) {
			let showAvatarInfo = !this.state.showAvatarInfo;
			this.setState({showAvatarInfo});
		} else {
			this.setState({showAvatarInfo: showAvatarInfoParam});
		}
	}


	/**
	 * Hide / show action info
	 * @param {string} actionId 
	 */
	toggleActionInfo(actionId) {
		if (actionId === this.state.actionId) {
			this.setState({actionId: null});
		}  else {
			this.setState({actionId: actionId});
		}
	}

	/**
	 * Scroll actions list manually
	 * @param {object} event 
	 */
	handleScroll([value]) {
		this.setState({scrollPos: parseInt(value), actionId: null});		
	}

	/**
	 * Handle scrolling actions with wheel / touchpad
	 * @param {object} e 
	 */
	handleWheelScroll(e) {
		e.preventDefault();
		if (e.deltaY === '0' || e.deltaY === '-0') return;
		let direction = (parseInt(e.deltaY) > 0 ? 1 : -1);
		if (this.state.scrollPos <= 0 && direction === -1) return;
		if (this.state.scrollPos >= 100 && direction === 1) return;
		let scrollPos = this.state.scrollPos + (direction * 5);
		if (scrollPos < 0) scrollPos = 0;
		if (scrollPos > 100) scrollPos = 100;
		this.handleScroll([scrollPos]);
	}

	showTipIndex(tipIndex) {
		this.setState({tipIndex: tipIndex});
	}

	/**
	 * User started dragging an action
	 */
	handleDragStart(id) {
		this.setState({draggingItemId: id, actionId: null});
	}

	/**
	 * Update state with result of drag and drop
	 * @param {object} result 
	 */
	handleDragEnd(actions, selected) {
		let itemId = this.state.draggingItemId;
		if (this.state.isMoving) return;
		this.setState({isMoving: true}, () => {
			/* Update lists */
			let newActions = JSON.parse(JSON.stringify(actions));			
			let newSelected = JSON.parse(JSON.stringify(selected));
			newSelected = sortArrayByProperty(newSelected, 'index', 'ASC');

			/* Update actions, animate dropped action */
			this.setState({
				draggingItemId: null, 
				isMoving: false, 
				animateDropActionId: itemId, 
				actions: newActions, 
				selected: newSelected,
			}, () => {
				
				/* First time there are 3 actions on the right list */
				// if (this.props.tutorialStatus.part2Seen !== true && newSelected.length > 3) {
				// let selectedActions = getColorsOfSelectedActions(newSelected, this.props.avatar);
				// if (selectedActions.filter((action) => {return action.color === 'red';}).length > 0) {
				// this.props.showTutorialStep('part2', newSelected.length - 1);
				// }
				// }
			});
		});
	}

	/**
	 * Handle start challenge
	 */
	handleStartChallenge() {
		/* Already running challenge or game is over */
		if (this.state.isBuying || this.state.challengeStatus !== 'playing') return;

		/* Scroll list to top */
		this.handleScroll([0]);

		let energy = calculateAvailableEnergy(this.props.initialEnergy, this.state.selected);
		let selectedActions = getColorsOfSelectedActions(this.state.selected, this.props.avatar);
		
		/* No actions selected */
		let startChallengeError = null;
		if (selectedActions.filter((action) => {return action.status === 'available';}).length === 0) {
			startChallengeError = 'noActionsAvailable';
		} else {
			/* Not enough energy and/or red actions on the list */
			if (energy < 0 || selectedActions.filter((action) => {return action.color === 'red';}).length > 0) {
				if (energy < 0 && selectedActions.filter((action) => {return action.color === 'red';}).length > 0) {
					startChallengeError = 'notEnoughEnergyAndRedActions';
				} else {
					if (energy < 0) {
						startChallengeError = 'notEnoughEnergy';
					} else {
						startChallengeError = 'redActions';
					}
				}
			/* Not all required actions selected */
			} else {
				let requiredActions = getRequiredActions(this.props.goal, selectedActions);
				if (requiredActions.filter((action) => {return !action.isSelected;}).length > 0) {
					startChallengeError = 'redGoal';
				}
			}
		}
		if (startChallengeError) {
			this.showStartChallengeErrorPopup(startChallengeError);
			return;
		}
	
		/* All ok, confirm start challenge */
		let numberOfRiskyActions = selectedActions.filter((action) => {return action.color === 'yellow';}).length;
		let startChallengeConfirm = 'confirmStartChallenge';
		if (numberOfRiskyActions === 1) startChallengeConfirm = 'confirmStartEasyChallenge';
		if (numberOfRiskyActions > 1) startChallengeConfirm = 'confirmStartHardChallenge';
		this.showStartChallengeConfirmPopup(startChallengeConfirm);
	}

	/**
	 * Show start challenge error popup
	 * @param {number} energy 
	 * @param {number} numberOfRedActions 
	 */
	showStartChallengeErrorPopup(startChallengeError) {
		let popupData = JSON.parse(JSON.stringify(popupsData[startChallengeError]));
		let popupTitle = popupData.title;
		let popupText = popupData.text;
		let popupBtns = [{text: generalUiTexts.ok, action: this.props.closePopup, actionParams: []}];
		this.props.openPopup(popupTitle, popupText, popupBtns, null, null, true, startChallengeError, null);
	}

	/**
	 * Show start challenge confirm popup
	 */
	showStartChallengeConfirmPopup(startChallengeConfirm) {
		let popupData = JSON.parse(JSON.stringify(popupsData[startChallengeConfirm]));
		let popupTitle = popupData.title;
		let popupText = popupData.text;
		let popupBtns = [
			{text: generalUiTexts.start, action: this.runChallenge, actionParams: []},
			{text: generalUiTexts.cancel, action: this.props.closePopup, actionParams: []}
		];
		this.props.openPopup(popupTitle, popupText, popupBtns, null, null, true, 'start-challenge-confirm', null);
	}

	/**
	 * Run challenge
	 */
	runChallenge() {
		this.props.closePopup();
		this.setState({
			isBuying: true,
			animateDropActionId: null,
			actionId: null
		}, () => {
			let challengeFailed = false;
			let failedAction = null;
			let selectedActions = getColorsOfSelectedActions(this.state.selected, this.props.avatar);
			let numberOfRiskyActions = selectedActions.filter((action) => {return action.color === 'yellow';}).length;
			let intervalNum = selectedActions.length - 1;
			let self = this; 

			/* Scroll down if necessary */
			let bottomActionIndex = -1;
			for (let i = selectedActions.length - 1; i > -1; i--) { 
				if (selectedActions[i].id !== null) {
					bottomActionIndex = selectedActions[i].index;
					break;
				}
			}
			if (bottomActionIndex >= 6) {
				let scrollPos = (bottomActionIndex - 5) * 20;
				this.setState({scrollPos: scrollPos});
			}

			/* Animate buying each action */
			this.timeout = setTimeout(async function animateAction() {
				/* Animation is done */

				/* Scroll to top */
				if (intervalNum >= 0 && selectedActions[intervalNum].index <= 5 && self.state.scrollPos > 0) {
					self.setState({scrollPos: 0});
				}
				if (intervalNum < -1) {
					intervalNum = null;
					if (challengeFailed) {
						self.challengeFailedPopup(failedAction);
						self.setState({challengeStatus: 'failed'});
					} else {
						self.setState({
							selected: selectedActions, 
							isBuying: false
						}, () => {
							self.challengeCompletedPopup(numberOfRiskyActions);
							self.setState({challengeStatus: 'completed'});
						});
					}
				/* Animation is running */
				} else {
					/* Animate goal tab */
					if (intervalNum < 0) {
						self.setState({challengeFinished: true});
					/* Animation action */
					} else {	
						/* Safe action */
						if (selectedActions[intervalNum].color === 'green') {
							selectedActions[intervalNum].status = 'bought';
						/* Chance action */
						} else if (selectedActions[intervalNum].color === 'yellow') {
							selectedActions[intervalNum].status = 'shaking';
							self.setState({selected: selectedActions});
							/* Calculate chance of failure */
							let chanceOfFailure = calculateChanceOfFailure(
								self.props.avatar.stats, 
								self.props.avatar.actions, 
								selectedActions[intervalNum].id, 
								selectedActions
							);
							if (chanceOfFailure > 0) {
								let randomNumber = Math.random();
								if (randomNumber < chanceOfFailure) {
								// Fail
									challengeFailed = true;
									failedAction = selectedActions[intervalNum];
									intervalNum = -2;
								} else {
								// Success
									await self.chanceActionSuccess(selectedActions, intervalNum);
								}
							}
						}
						self.setState({selected: selectedActions});
					}
					intervalNum--;
					this.timeout = setTimeout(animateAction, 600); 
				}
			}, 600);
		});
	}
 
	/**
	 * Chance action succeeded
	 * @param {array} selectedActions 
	 * @param {number} intervalNum 
	 */
	chanceActionSuccess(selectedActions, intervalNum) {
		return new Promise((resolve) => {
			setTimeout(() => {
				selectedActions[intervalNum].status = 'bought';
				resolve(selectedActions[intervalNum]);
			}, 700);
		});
	}

	/**
	 * Show challenge completed popup
	 * @param {number} energyLeft 
	 */
	challengeCompletedPopup(numberOfRiskyActions) {
		let numberOfIcons = Math.max(1, 3 - numberOfRiskyActions);
		let popupIcons = [];
		for (let i = 1; i <= 3; i++) {
			if (i <= numberOfIcons) {
				popupIcons.push({name:'star'});
			} else {
				popupIcons.push({name:'star-outline'});
			}
		}
		let popupData = JSON.parse(JSON.stringify(popupsData.challengeCompleted));
		let popupTitle = popupData.titles[numberOfIcons - 1];
		let popupText1 = popupData.texts[numberOfIcons - 1][0].replace(/%name%/g, this.props.avatar.name);
		let popupText2 = popupData.texts[numberOfIcons - 1][1];
		let popupText = [popupText1, popupText2];
		let popupBtns = [
			{text: generalUiTexts.playAgain, action: this.props.goToStep, actionParams: ['avatars']},
			{text: generalUiTexts.plan, action: this.props.goToStep, actionParams: ['home']}
		];
		this.props.handleChallengeCompleted();
		this.props.openPopup(popupTitle, popupText, popupBtns, null, null, false, 'challenge-completed', popupIcons);
	}

	/**
	 * Show challenge failed popup
	 * @param {object} failedAction
	 */
	challengeFailedPopup(failedAction) {
		let unmetRequirements = getUnmetRequirements(
			this.props.avatar.stats, 
			this.props.avatar.actions, 
			failedAction.id, 
			this.state.selected
		).filter((requirement) => {return requirement.type === 'stat';});
		let popupData = JSON.parse(JSON.stringify(popupsData.challengeFailed));
		let popupTitle = popupData.title;
		let stat1Name = statsData.filter((stat) => {return stat.id === unmetRequirements[0].statId;})[0].name;
		let stat2Name = (unmetRequirements.length > 1 
			? statsData.filter((stat) => {return stat.id === unmetRequirements[1].statId;})[0].name
			: null
		);

		/* 1 unmet requirement */
		let popupText = popupData.text1a;
		if (unmetRequirements.length === 1 && unmetRequirements[0].statId === 'workEthic') popupText = popupData.text1b;

		/* 1+ unmet requirements */
		if (unmetRequirements.length > 1) {
			popupText = popupData.text2a;
			if (unmetRequirements[0].statId === 'workEthic') popupText = popupData.text2b;
			if (unmetRequirements[1].statId === 'workEthic') popupText = popupData.text2c;
		}

		/* Replace placeholder texts */
		popupText =	popupText.replace(/%name%/g, this.props.avatar.name);
		popupText = popupText.replace(/%action%/g, failedAction.title);
		popupText = popupText.replace(/%pronoun%/g, (this.props.avatar.gender === 'f' ? 'hun' : 'han'));
		if (stat1Name && stat2Name) {
			popupText = popupText.replace(/%stat%/g, stat1Name);
			popupText = popupText.replace(/%stat2%/g, stat2Name);
		} else {
			if (stat1Name) {
				popupText = popupText.replace(/%stat%/g, stat1Name);
			}
		}

		let popupBtns = [
			{text: generalUiTexts.tryAgain, action: this.resetChallenge, actionParams: []},
			{text: generalUiTexts.finish, action: this.props.goToStep, actionParams: ['avatars']}
		];
		this.props.handleChallengeFailed();
		this.props.openPopup(popupTitle, popupText, popupBtns, null, null, false, 'challenge-failed');
	}

	/**
	 * Reset challenge
	 */
	resetChallenge() {
		let availableActions = JSON.parse(JSON.stringify(this.state.initialActions));
		let placeHolderActions = JSON.parse(JSON.stringify(this.state.initialPlaceholderActions));
		let selectedActions = [];
		this.setState({
			isMoving: false,
			isBuying: false,
			challengeFinished: false,
			actionId: null,
			animateDropActionId: null,
			challengeStatus: 'playing',
			actions: availableActions,
			selected: selectedActions,
			placeHolderActions: placeHolderActions
		}, () => {
			this.props.closePopup();
		});
	}	



	/**
	 * Render component
	 */
	render() {
		/* Calculate available energy */
		let energy = calculateAvailableEnergy(this.props.initialEnergy, this.state.selected);

		/* Get selected actions with colors */
		let selectedActions = getColorsOfSelectedActions(this.state.selected, this.props.avatar);

		/* Calculate stats */
		let stats = calculateStats(this.props.avatar.stats, this.state.selected, true);
		let statsColors = getStatsColors(this.props.avatar.stats, this.props.avatar.actions, selectedActions);
	
		/* Check if all required actions are selected */
		let requiredActions = getRequiredActions(this.props.goal, selectedActions);
		let requiredActionsSelected = requiredActions.filter((action) => {return !action.isSelected;}).length === 0;

		/* Draggable status */
		let dragIsDisabled = (
			this.state.isMoving ||
			this.state.isBuying || 
			this.state.challengeStatus !== 'playing'
		);


		/* Buy button is active */
		let buyBtnIsActive = 
			energy >= 0 && 
			!this.state.isBuying && 
			this.state.challengeStatus === 'playing' && 
			requiredActionsSelected && 
			selectedActions.filter((action) => {return action.color === 'red';}).length === 0;

		/* Sort actions and selected */
		let actions = sortArrayByProperty(this.state.actions, 'initialIndex', 'ASC');
		let selected = sortArrayByProperty(selectedActions, 'initialIndex', 'ASC');		
		let placeHolderActions = sortArrayByProperty(this.state.placeHolderActions, 'initialIndex', 'ASC');		


		return (
			<div aria-hidden={this.props.popupIsOpen} style={{height: '100%'}}>
				{/* <TutorialIcon 
					step="tutorial" 
					numberOfSelected={selected.length - 1} 
					showTutorialStep={this.props.showTutorialStep}
				/> */}
				<DndProvider backend={HTML5Backend}>
					<Challenge 
						isBuying={this.state.isBuying}
						dragIsDisabled={dragIsDisabled}
						buyBtnIsActive={buyBtnIsActive}
						requiredActionsSelected={requiredActionsSelected}
						showAvatarInfo={this.state.showAvatarInfo}
						challengeStatus={this.state.challengeStatus}
						draggingItemId={this.state.draggingItemId}
						tipIndex={this.state.tipIndex}
						avatar={this.props.avatar} 
						stats={stats}
						statsColors={statsColors}
						goal={this.props.goal} 
						energy={energy}
						initialEnergy={this.props.initialEnergy}
						initialActions={this.state.initialActions}
						actionId={this.state.actionId}
						animateDropActionId={this.state.animateDropActionId}
						requiredActions={requiredActions}
						actions={actions}
						selected={selected}
						placeHolderActions={placeHolderActions}
						scrollPos={this.state.scrollPos}
						handleGoBack={this.handleGoBack}
						toggleAvatarInfo={this.toggleAvatarInfo}
						toggleActionInfo={this.toggleActionInfo}
						handleMoveAction={this.handleMoveAction}
						moveActionToBottomOfList={this.moveActionToBottomOfList}
						handleScroll={this.handleScroll}
						showTipIndex={this.showTipIndex}
						handleDragStart={this.handleDragStart}
						handleDragEnd={this.handleDragEnd}
						handleStartChallenge={this.handleStartChallenge}
						challengeFinished={this.state.challengeFinished}
					/>
				</DndProvider>
			</div>
		);
	}
}

ChallengeController.propTypes = {
	popupIsOpen: PropTypes.bool.isRequired,
	// tutorialIsOpen: PropTypes.bool.isRequired,
	// tutorialStatus: PropTypes.object.isRequired,
	avatar: PropTypes.object.isRequired,
	goal: PropTypes.object.isRequired,
	goToStep: PropTypes.func.isRequired,
	// showTutorialStep: PropTypes.func.isRequired,
	openPopup: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
	initialEnergy: PropTypes.number.isRequired,
	handleChallengeCompleted: PropTypes.func.isRequired,
	handleChallengeFailed: PropTypes.func.isRequired
};

export default ChallengeController;