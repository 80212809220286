import React from 'react';
import PropTypes from 'prop-types';
import {disableBodyScroll} from 'body-scroll-lock';
import {popupsData} from 'data/popups-data';
import {avatarsData} from 'data/avatars-data';
import {generalUiTexts} from 'data/ui-texts';
import Avatars from './avatars';

class AvatarsController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showCarousel: true,
			panCards: false
		};
		this.handleGoBack = this.handleGoBack.bind(this);
		this.openIntroPopup = this.openIntroPopup.bind(this);
		this.closeIntroPopup = this.closeIntroPopup.bind(this);
		this.rerenderCarousel = this.rerenderCarousel.bind(this);
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		if (this.props.viewMode !== 'landscape') {
			/* Disable body scroll */
			let targetElement = document.querySelector('#app');
			disableBodyScroll(targetElement);
		} else {
			/* Listen to window resize */
			window.addEventListener('resize', this.rerenderCarousel);
		}

		/* Open intro popup */
		if (this.props.introPopupStatuses.avatarIntroSeen !== true) {
			this.openIntroPopup();
		}
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount() {
		window.removeEventListener('resize', this.rerenderCarousel);
	}
	

	/**
	 * Open intro popup
	 */
	openIntroPopup() {
		let popupData = JSON.parse(JSON.stringify(popupsData.selectAvatarInfo));
		let popupBtns = [{text: generalUiTexts.ok, action: this.closeIntroPopup, actionParams: []}];
		this.props.openPopup(
			popupData.title, popupData.text, popupBtns, null, 
			{action: this.closeIntroPopup, actionParams: []}, false, 
			'introPopup', null, null, null, popupData.audio
		);
	}

	/**
	 * Rerender carousel on window rezie
	 */
	rerenderCarousel() {
		this.setState({showCarousel: false}, () => {this.setState({showCarousel: true});});
	}



	/**
	 * Close intro popup
	 */
	closeIntroPopup() {
		this.props.closePopup();
		if (this.props.introPopupStatuses.avatarIntroSeen !== true) {
			this.props.updateIntroPopupStatus('avatarIntroSeen', true);
			let panCards = (this.props.viewMode !== 'landscape');
			this.setState({showCarousel: false}, () => {this.setState({showCarousel: true, panCards: panCards});});
		}
	}

	/**
	 * Go to previous step
	 */
	handleGoBack() {
		if (this.props.challengeCompleted) {
			this.props.goToStep('home');
		} else {
			this.props.goToStep('start');
		}
	}

	/**
	 * Render component
	 */
	render() {
		let initialIndex = 0;
		if (this.props.prevStepId === 'avatarInfo' && this.props.avatar && this.props.avatar.id) {
			let avatarIndex = avatarsData.findIndex((avatarData) => {return avatarData.id === this.props.avatar.id;});
			if (avatarIndex >= 0) initialIndex = avatarIndex;
		}
		if (!this.props.challengeCompleted && this.props.prevStepId !== 'avatarInfo' && !this.state.panCards) {
			initialIndex = avatarsData.length - 2;
		} 

		if (this.state.showCarousel) {
			return (
				<Avatars
					viewMode={this.props.viewMode}
					showCarousel={this.state.showCarousel}
					panCards={this.state.panCards}
					initialIndex={initialIndex}
					handleGoBack={this.handleGoBack} 
					handleSelectAvatar={this.props.handleSelectAvatar}
					openIntroPopup={this.openIntroPopup}
				/>
			);
		}
		return null;
	}
}

AvatarsController.propTypes = {
	viewMode: PropTypes.string.isRequired,
	challengeCompleted: PropTypes.bool.isRequired,
	introPopupStatuses: PropTypes.object.isRequired,
	prevStepId: PropTypes.string.isRequired,
	avatar: PropTypes.object,
	goToStep: PropTypes.func.isRequired,
	updateIntroPopupStatus: PropTypes.func.isRequired,
	handleSelectAvatar: PropTypes.func.isRequired,
	openPopup: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
};

export default AvatarsController;