import React from 'react';
import PropTypes from 'prop-types';
import {statsData} from 'data/stats-data';
import './stats.scss';

const Stats = ({
	viewMode,
	type,
	stats,
	requiredStats,
	avatar,
	statsColors,
	prevStatsColors,
	handleClickStat
}) => {
	return <div className={'AvatarStats-stats AvatarStats-stats--' + viewMode + ' AvatarStats-stats--' + type}>
		{statsData.map((stat, index) => {
			let className = 'AvatarStats-stat AvatarStats-stat--' + stat.id + ' AvatarStats-stat--' + viewMode;
			if (requiredStats) {
				stats[stat.id] < requiredStats[stat.id]
					? className += ' AvatarStats-stat--red'
					: className += ' AvatarStats-stat--green';
			}
			
			if (statsColors && statsColors.hasOwnProperty(stat.id) && statsColors[stat.id]) {
				className += ' AvatarStats-stat--' + statsColors[stat.id];
			}

			/* Animated stats colors */
			if (type === 'challenge' || type === 'goals') {
				className = className + ' AvatarStats-stat--clickable';
				let prevColor = 'green';
				if (
					prevStatsColors && prevStatsColors.hasOwnProperty(stat.id) && prevStatsColors[stat.id] && 
							prevStatsColors[stat.id] !== statsColors[stat.id]
				) {
					prevColor = prevStatsColors[stat.id];
					let nextColor = statsColors[stat.id];
					className = className + ' AvatarStats-stat--' + prevColor + 'to' + nextColor;
				}

				if (viewMode === 'landscape') className = className + ' AvatarStats-stat--hovereffect';
				
			}
			if (type === 'quiz') {
				let prevColor = 'default';
				if (
					prevStatsColors && !prevStatsColors.hasOwnProperty(stat.id) && 
						statsColors.hasOwnProperty(stat.id)
				) {
					let nextColor = statsColors[stat.id];
					className = className + ' AvatarStats-stat--' + prevColor + 'to' + nextColor;
				}
				if (statsColors.hasOwnProperty(stat.id)) className = className + ' AvatarStats-stat--clickable';
				
			}

			let value = (stats 
				? stats[stat.id] <= 5 ? stats[stat.id] : 5 
				: avatar.stats[stat.id] <= 5 ? avatar.stats[stat.id] : 5
			);
					
			return (
				<div 
					key={stat.id} 
					className={className} 
					aria-hidden="true"
					onClick={() => {
						if (type === 'quiz' && statsColors[stat.id] !== 'default' && handleClickStat) {
							handleClickStat(index);
						}
						if (viewMode !== 'landscape' && (type === 'challenge' || type === 'goals') && handleClickStat) {
							handleClickStat(stat.id);
						}
					}}
					onMouseOver={() => {
						if (viewMode === 'landscape' && (type === 'challenge' || type === 'goals') && handleClickStat) {
							handleClickStat(stat.id);
						}
					}}
					onMouseOut={() => {
						if (viewMode === 'landscape' && (type === 'challenge' || type === 'goals') && handleClickStat) {
							handleClickStat(null);
						}
					}}
				>
					<span>{value}</span>
					{viewMode === 'landscape' && <div className="AvatarStats-statName">{stat.name}</div>}
				</div>
			);
		})}
	</div>;
};

Stats.propTypes = {
	viewMode: PropTypes.string.isRequired,
	type: PropTypes.string,
	stats: PropTypes.object,
	requiredStats: PropTypes.object,
	avatar: PropTypes.object,
	statsColors: PropTypes.object,
	prevStatsColors: PropTypes.object,
	handleClickStat: PropTypes.func
};

export default Stats;