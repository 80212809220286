import React from 'react';
import PropTypes from 'prop-types';
import {disableBodyScroll} from 'body-scroll-lock';
import {popupsData} from 'data/popups-data';
import {goalsData} from 'data/goals-data';
import {actionTypes, actionsData} from 'data/actions-data';
import {getRequiredStats} from 'helpers/stats-helper';
import {generalUiTexts} from 'data/ui-texts';
import AvatarInfoController from 'components/avatar-info/avatar-info-controller';
import Goals from './goals';
import './goals.scss';

class GoalsController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {			
			showAvatarInfo: false,
			showCarousel: false,
			panCards: false,
			goals: []
		};
		this.openIntroPopup = this.openIntroPopup.bind(this);
		this.closeIntroPopup = this.closeIntroPopup.bind(this);
		this.toggleAvatarInfo = this.toggleAvatarInfo.bind(this);
		this.handleGoBack = this.handleGoBack.bind(this);
		this.rerenderCarousel = this.rerenderCarousel.bind(this);
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		/* Get goals that avatar can choose from */
		let goals = [];
		this.props.avatar.goals.forEach((avatarGoal) => {
			if (goalsData.some((goalData) => {return goalData.id === avatarGoal.id;})) {
				let goalObj = goalsData.filter((goalData) => {return goalData.id === avatarGoal.id;})[0];

				/* Get required stats */
				goalObj.requiredStats = getRequiredStats(goalObj);

				/* Get required actions */
				let requiredActions = [];
				goalObj.requirements.forEach((goalRequirement) => {
					/* One of multiple actions */
					if (goalRequirement.hasOwnProperty('actionIds')) {
						let title = '';
						goalRequirement.actionIds.forEach((actionId, index) => {
							let requirementData = actionsData.filter((action) => {
								return action.id === actionId;
							})[0];
							if (index === 0) {
								title = requirementData.title;
							} else {
								title = title + ' / ' + requirementData.title;
							}
						});
						requiredActions.push(title);
					}
					/* Specific action */
					if (goalRequirement.hasOwnProperty('actionId')) {
						if (actionsData.some((action) => {return action.id === goalRequirement.actionId;})) {
							let requirementData = actionsData.filter((action) => {
								return action.id === goalRequirement.actionId;
							})[0];
							requiredActions.push(requirementData.title);
						}
					}
					/* Action type */
					if (goalRequirement.hasOwnProperty('typeId') && 
						actionTypes.hasOwnProperty(goalRequirement.typeId)
					) {
						requiredActions.push(actionTypes[goalRequirement['typeId']]);
					}
				});	
				goalObj.requiredActions = requiredActions;	
				goals.push(goalObj);
			}
		});
		this.setState({goals: goals, showCarousel: true}, () => {
		/* Open intro popup */
			if (this.props.introPopupStatuses.goalIntroSeen !== true) {
				this.openIntroPopup();
			}

			if (this.props.viewMode !== 'landscape') {
			/* Disable body scroll */
				let targetElement = document.querySelector('#app');
				disableBodyScroll(targetElement);
			} else {
			/* Listen to window resize */
				window.addEventListener('resize', this.rerenderCarousel);
			}
		});
	}	

	/**
	 * Component will unmount
	 */
	componentWillUnmount() {
		window.removeEventListener('resize', this.rerenderCarousel);
	}

	/**
	 * Rerender carousel on window rezie
	 */
	rerenderCarousel() {
		this.setState({showCarousel: false}, () => {this.setState({showCarousel: true});});
	}


	/**
	 * Open intro popup
	 */
	openIntroPopup() {
		let popupData = JSON.parse(JSON.stringify(popupsData.goalInfo));
		let popupText = popupData.text.replace(/%name%/g, this.props.avatar.name);
		popupText = popupText.replace(/%pronoun%/g, (this.props.avatar.gender === 'f' ? 'hun' : 'han'));
		let popupBtns = [{text: generalUiTexts.ok, action: this.closeIntroPopup, actionParams: []}];
		this.props.openPopup(
			popupData.title, popupText, popupBtns, null, 
			{action: this.closeIntroPopup, actionParams: []}, false, 
			'introPopup', null, null, null, popupData.audio
		);
	}

	/**
	 * Close intro popup
	 */
	closeIntroPopup() {
		this.props.closePopup();
		if (this.props.introPopupStatuses.goalIntroSeen !== true) {
			this.props.updateIntroPopupStatus('goalIntroSeen', true);
			let panCards = (this.props.viewMode !== 'landscape');
			this.setState({showCarousel: false}, () => {this.setState({showCarousel: true, panCards: panCards});});
		}
	}

	/**
	 * Hide / show avatar info
	 * @param {bool} showAvatarInfoParam 
	 */
	toggleAvatarInfo(showAvatarInfoParam = null) {
		if (showAvatarInfoParam === null) {
			let showAvatarInfo = !this.state.showAvatarInfo;
			this.setState({showAvatarInfo});
		} else {
			this.setState({showAvatarInfo: showAvatarInfoParam});
		}
	}

	/**
	 * Go to previous step
	 */
	handleGoBack() {
		if (this.props.viewMode === 'landscape') {
			this.props.goToStep('avatars');
		} else {
			this.props.goToStep('avatarInfo');
		}
	}	

	render() {
		if (this.state.showAvatarInfo && this.props.viewMode !== 'landscape') {
			return (
				<AvatarInfoController 
					page="goals"
					avatar={this.props.avatar} 
					goToStep={null} 
					toggleAvatarInfo={this.toggleAvatarInfo}
					handleRefresh={this.props.handleRefresh}
					openPopup={this.props.openPopup}
					closePopup={this.props.closePopup}
				/>
			);
		}

		let initialIndex = 0;
		if (this.props.prevStepId === 'challenge' && this.props.goal && this.props.goal.id) {
			let goalIndex = this.state.goals.findIndex((goalData) => {return goalData.id === this.props.goal.id;});
			if (goalIndex >= 0) initialIndex = goalIndex;
		}
		if (!this.props.challengeCompleted && this.props.prevStepId === 'avatarInfo' && !this.state.panCards) {
			initialIndex = this.state.goals.length - 2;
		}

		if (this.state.showCarousel) {	
			return (
				<Goals 
					showAvatarInfo={this.state.showAvatarInfo}
					showCarousel={this.state.showCarousel}
					viewMode={this.props.viewMode}
					panCards={this.state.panCards}
					initialIndex={initialIndex}
					avatar={this.props.avatar} 
					goals={this.state.goals}
					toggleAvatarInfo={this.toggleAvatarInfo}
					openIntroPopup={this.openIntroPopup}
					handleGoBack={this.handleGoBack} 
					handleSelectGoal={this.props.handleSelectGoal}
				/>
			);
		}
		return null;
	}
}


GoalsController.propTypes = {
	challengeCompleted: PropTypes.bool.isRequired,
	introPopupStatuses: PropTypes.object.isRequired,
	prevStepId: PropTypes.string.isRequired,
	viewMode: PropTypes.string.isRequired,
	avatar: PropTypes.object.isRequired,
	goal: PropTypes.object,
	goToStep: PropTypes.func.isRequired,
	updateIntroPopupStatus: PropTypes.func.isRequired,
	handleSelectGoal: PropTypes.func.isRequired,
	handleRefresh: PropTypes.func.isRequired,
	openPopup: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired
};

export default GoalsController;