import React from 'react';
import PropTypes from 'prop-types';
import {avatarUiTexts, generalUiTexts} from 'data/ui-texts';
import Button from 'components/button/button';
import './goal-card.scss';

const GoalCard = ({ariaHidden, card, handleSelectCard}) => {
	return (
		<div aria-hidden={ariaHidden} >
			<div className="GoalCard-title">{card.title}</div>
			<div className={'GoalCard-image GoalCard-image--' + card.iconId} />
			<div className="GoalCard-text">{card.description}</div>
			<div className="GoalCard-requirements">
				<div className="GoalCard-requirementsTitle">{avatarUiTexts.actionRequirements}:</div>
				{card.requiredActions.map((action, index) => {
					return <div key={index} className="GoalCard-requirement">{action}</div>;
				})}
			</div>
			<div className="GoalCard-selectCard">
				<Button 
					text={generalUiTexts.choose} 
					onClick={() => {handleSelectCard(card.id);}}
				/>
			</div>	
		</div>
	);
};

GoalCard.propTypes = {
	ariaHidden: PropTypes.bool.isRequired,
	card: PropTypes.object.isRequired,
	handleSelectCard: PropTypes.func.isRequired,
};

export default GoalCard;