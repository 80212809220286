import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Motion, spring } from 'react-motion';
import {moveAction, moveAction2, findAction} from 'helpers/dnd-helper2';
import {generalUiTexts} from 'data/ui-texts';
import {tipsData} from 'data/tips-data';
import ChallengeHeader from './challenge-header';
import ChallengeDndContainer from './challenge-dnd-container';
import ChallengeDndItem from './challenge-dnd-item';
import ChallengeAction from 'components/challenge/challenge-action';
import ChallengeActionInfo from './challenge-action-info';
import Scrollbar2 from 'components/layout/scrollbar2';
import Tips from 'components/layout/tips';
import Button from 'components/button/button';
import './challenge.scss';

const Challenge = (props) => {
	let {
		isBuying,
		dragIsDisabled,
		buyBtnIsActive,
		requiredActionsSelected,
		showAvatarInfo,
		challengeStatus,
		draggingItemId,
		tipIndex,
		avatar, 
		stats,
		statsColors,
		goal, 
		energy,
		initialEnergy,
		initialActions,
		actionId,
		animateDropActionId,
		requiredActions,
		actions,
		selected,
		placeHolderActions,
		scrollPos,
		handleGoBack,
		toggleActionInfo,
		toggleAvatarInfo,
		handleScroll,
		showTipIndex,
		handleDragStart,
		handleDragEnd,
		handleStartChallenge,
		challengeFinished
	} = props;

	let [actionsList, setActions] = useState([]);
	useEffect(() => {setActions(actions);}, [actions]);

	let [selectedList, setSelected] = useState([]);
	useEffect(() => {setSelected(selected);}, [selected]);

	let [placeHoldersList, setPlaceholders] = useState([]);
	useEffect(() => {setPlaceholders(placeHolderActions);}, [placeHolderActions]);


	/**
	 * Move action to another action's position
	 * @param {string} id 
	 * @param {string} toId 
	 */
	const handleMoveAction = (fromIndex, toIndex) => {
		let [newSelectedList, newPlaceHoldersList] = 
			moveAction(initialActions, placeHoldersList, fromIndex, toIndex);
		setSelected(newSelectedList);
		setPlaceholders(newPlaceHoldersList);
	};

	/**
	 * Move action to another container
	 * @param {string} id 
	 */
	const handleMoveAction2 = (id) => {
		let [newActionsList, newSelectedList, newPlaceHoldersList] = 
			moveAction2(id, initialActions, actionsList, placeHoldersList);
		setActions(newActionsList);
		setSelected(newSelectedList);
		setPlaceholders(newPlaceHoldersList);
	};

	/**
	 * Find action 
	 * @param {string} id 
	 */
	const handleFindAction = (id) => {
		return findAction(id, actionsList, placeHoldersList);
	};

	/**
	 * Drop action
	 */
	let dropAction = () => {
		handleDragEnd(actionsList, selectedList);
	};

	/**
	 * Positions of selected actions (depends on scroll)
	 * @param {number} index 
	 */
	let getXPos = (index) => {
		let scrollPos2 = scrollPos;
		let xPos = 0;
		let container = document.querySelector('#scrollableContainer');
		if (container) scrollPos2 = scrollPos2 - container.scrollTop;
		if (index - (0.05 * Math.min(scrollPos2, 100)) > 0) {
			xPos = -0.55 * ((index - (0.05 * Math.min(scrollPos2, 100))) * 
				Math.sqrt((index - (0.05 * Math.min(scrollPos2, 100)))));
		}
		return xPos;
	};

	let getYPos = (index) => {
		let scrollPos2 = scrollPos;
		let container = document.querySelector('#scrollableContainer');
		if (container) scrollPos2 = scrollPos2 - container.scrollTop;
		let yPos = 1.65 * (index - (0.05 * Math.min(scrollPos2, 100)));
		return yPos;
	};


	let selectedActionsHeight = placeHolderActions.length * 1.2;

	let tipStep = tipsData.tips.steps[tipIndex];
	let tipText = JSON.parse(JSON.stringify(tipsData[tipStep].text));
	let namePossessive = avatar.name.substr(-1, 1) === 's' ? avatar.name + '\'' : avatar.name + 's';
	tipText = tipText.replace(/%name%/g, namePossessive);

	let actionInfoClass = null;
	let actionInfoPos = {x: 0, y: 0};
	if (actionId) {
		let [index, arrayIndex, containerId] = handleFindAction(actionId);
		if (containerId === 'actions') {
			actionInfoClass = 'actions' + arrayIndex;
		} else {
			actionInfoClass = 'selected';	
			actionInfoPos.x = getXPos(index);
			actionInfoPos.y = getYPos(index);
			// actionInfoStyle = {transform: 'translate3d(' + xPos + 'em,' + yPos + 'em, 0)'};
		}
	}


	return (
		<div className="ChallengeLandscape">
			{/* Header  */}
			<ChallengeHeader 
				showAvatarInfo = {showAvatarInfo}
				requiredActionsSelected = {requiredActionsSelected}
				avatar = {avatar}
				stats = {stats}
				statsColors = {statsColors}
				goal = {goal} 
				energy = {energy}
				initialEnergy = {initialEnergy}
				actionId = {actionId}
				requiredActions = {requiredActions}
				handleGoBack = {handleGoBack}
				challengeFinished = {challengeFinished}
				toggleAvatarInfo = {toggleAvatarInfo}
			/>
			
			{/* Body  */}
			<div className="ChallengeLandscape-body" aria-hidden={actionId !== null}>

				{/* Tips */}
				<Tips 
					type="challenge"
					steps={tipsData.tips.steps}
					stepIndex={tipIndex}
					stepText={tipText}
					showStepIndex={showTipIndex}
				/>

				{/* Available actions */}
				<div className="ChallengeLandscape-options">
					<ChallengeDndContainer 
						containerId="actions" 
						handleFindAction={handleFindAction}
						handleMoveAction2={handleMoveAction2}
					>
						{actionsList.map((action, index) => {		
							return (								
								<ChallengeDndItem
									key={index}
									id={action.id}
									isSelected = {action.isSelected === true}
									dragIsDisabled={dragIsDisabled || action.isSelected === true}
									handleDragStart={handleDragStart}
									dropAction={dropAction}
									handleMoveAction={handleMoveAction}
									handleFindAction={handleFindAction}
									toggleActionInfo={toggleActionInfo}
								>
									<ChallengeAction 
										type="actions"
										viewMode="landscape"
										dragIsDisabled={dragIsDisabled || action.isSelected === true}
										animateDrop={animateDropActionId === action.id && action.isSelected === false}
										action={action}
										initialActions={initialActions}
										toggleActionInfo={toggleActionInfo}
									/>
								</ChallengeDndItem>
							);
						})}
					</ChallengeDndContainer>
				</div>

				{/* Selected actions */}
				<div 
					id="scrollableContainer" 
					className={'ChallengeLandscape-path' + (isBuying ? ' smoothScroll' : '')}
				>
					<ChallengeDndContainer 
						containerId="selected"
						containerHeight={selectedActionsHeight}
						handleFindAction={handleFindAction}
						handleMoveAction2={handleMoveAction2}
					>
						{placeHoldersList.map((placeholderAction, index) => {
							let action = placeholderAction;
							if (placeholderAction.id && selected.some((action2) => {
								return action2.id === placeholderAction.id;
							})) {
								action = selected.filter((action) => {
									return action.id === placeholderAction.id;
								})[0];
								action.index = placeholderAction.index;
								action.initialIndex = placeholderAction.initialIndex;
							} else {
								if (placeholderAction.id && initialActions.some((action2) => {
									return action2.id === placeholderAction.id;
								})) {
									action = initialActions.filter((action) => {
										return action.id === placeholderAction.id;
									})[0];
									action.index = placeholderAction.index;
									action.initialIndex = placeholderAction.initialIndex;
								}
							}
							return (
								<Motion 
									key={index}
									style={{ 
										x: spring(getXPos(placeholderAction.index), { stiffness: 500, damping: 32 }),
										y: spring(getYPos(placeholderAction.index), { stiffness: 500, damping: 32 }) 
									}}      
								>
									{({ x, y }) => {
										return (
											<React.Fragment>
												<ChallengeDndItem
													id={action.id}
													index={placeholderAction.index}
													dragIsDisabled={dragIsDisabled || action.status === 'bought' || 
													!action.id}
													style={{transform: 'translate3d(' + x + 'em,' + y + 'em, 0)'}}
													handleDragStart={handleDragStart}
													dropAction={dropAction}
													handleMoveAction={handleMoveAction}
													handleFindAction={handleFindAction}
													toggleActionInfo={toggleActionInfo}
												>
													<ChallengeAction 
														type="selected"
														viewMode="landscape"
														animateDrop={animateDropActionId === action.id}
														action={action}
														avatar={avatar}
														selectedList={selected}
														toggleActionInfo={toggleActionInfo}
													/>
												</ChallengeDndItem>
												<div 
													className="ChallengeLandscape-selectedArrow"
													style={{transform: 'translate3d(' + x + 'em,' + y + 'em, 0)'}}
												/>
											</React.Fragment>
										);
									}}
								</Motion>
							);
						})}
					
					</ChallengeDndContainer>
				</div>

				{/* Scrollbar */}
				{initialActions.length > 6 && <Scrollbar2 
					name="scrollPos"
					value={Math.min(scrollPos, 100)}
					onChange={handleScroll}
				/>}

			</div>

			{/* Start btn */}
			{challengeStatus === 'playing' && 
				<div id = "startBtn" className={'ChallengeLandscape-buyBtn' + (draggingItemId ? 
					' ChallengeLandscape-buyBtn--dragging' : '')} aria-hidden={true}>
					<Button 
						viewMode="landscape"
						class="startChallenge"
						text={generalUiTexts.start} 
						inactive={!buyBtnIsActive}
						onClick={() => {handleStartChallenge();}}
					/>
				</div>}
			
			{/* Action info */}

			{actionId && 
				<Motion 
					style={{ 
						x: spring(actionInfoPos.x, { stiffness: 500, damping: 32 }),
						y: spring(actionInfoPos.y, { stiffness: 500, damping: 32 }) 
					}}      
				>
					{({ x, y }) => {
						return (
							<ChallengeActionInfo 
								actionId={actionId}
								actionInfoClass={actionInfoClass}
								actionInfoStyle={{transform: 'translate3d(' + x + 'em,' + y + 'em, 0)'}}
								viewMode="landscape"
								avatar={avatar}
								selectedActions={selected}
								initialActions={initialActions}
								toggleActionInfo={toggleActionInfo}
							/>);
					}}
				</Motion>}
		</div>
	);
};



Challenge.propTypes = {
	isBuying: PropTypes.bool.isRequired,
	dragIsDisabled: PropTypes.bool.isRequired,
	buyBtnIsActive: PropTypes.bool.isRequired,
	requiredActionsSelected: PropTypes.bool.isRequired,
	showAvatarInfo: PropTypes.bool.isRequired,
	challengeStatus: PropTypes.string.isRequired,
	draggingItemId: PropTypes.string,
	tipIndex: PropTypes.number.isRequired,
	avatar: PropTypes.object.isRequired,
	stats: PropTypes.object.isRequired,
	statsColors: PropTypes.object.isRequired,
	goal: PropTypes.object.isRequired,
	energy: PropTypes.number.isRequired,
	initialEnergy: PropTypes.number,
	initialActions: PropTypes.array,
	actionId: PropTypes.string,
	animateDropActionId: PropTypes.string,
	requiredActions: PropTypes.array.isRequired,
	actions: PropTypes.array.isRequired,
	selected: PropTypes.array.isRequired,
	placeHolderActions: PropTypes.array.isRequired,
	scrollPos: PropTypes.number,
	handleGoBack: PropTypes.func.isRequired,
	toggleActionInfo: PropTypes.func.isRequired,
	toggleAvatarInfo: PropTypes.func.isRequired,
	handleScroll: PropTypes.func,
	showTipIndex: PropTypes.func.isRequired,
	handleDragStart: PropTypes.func.isRequired,
	handleDragEnd: PropTypes.func.isRequired,
	handleStartChallenge: PropTypes.func.isRequired,
	challengeFinished: PropTypes.bool.isRequired
};

export default Challenge;