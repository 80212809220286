import React from 'react';
import PropTypes from 'prop-types';
import {avatarsData} from 'data/avatars-data';
import {avatarUiTexts} from 'data/ui-texts';
import Button from 'components/button/button';
import Carousel from 'components/layout/carousel';
import CarouselLandscape from 'components/layout/carousel-landscape';
import AvatarCard from './avatar-card';
import AvatarCardLandscape from './avatar-card-landscape';
import './avatars.scss';

const Avatars = (props) => {
	let {viewMode, showCarousel, panCards, initialIndex, handleGoBack, handleSelectAvatar, openIntroPopup} = props;
	let avatars = JSON.parse(JSON.stringify(avatarsData));

	let CarouselComponent = (viewMode === 'landscape' ? CarouselLandscape : Carousel);
	let CardComponent = (viewMode === 'landscape' ? AvatarCardLandscape : AvatarCard);
	return (
		<div className={'Avatars Avatars--' + viewMode}>
			<div className="Avatars-header">
				<Button viewMode={viewMode} class="goBack" onClick={handleGoBack} />
				<Button viewMode={viewMode} class="help" onClick={openIntroPopup} />
				{viewMode === 'landscape' && <div className="Avatars-headerTitle">{avatarUiTexts.chooseAPerson}</div>}
			</div>
			<div className="Avatars-carousel">
				{showCarousel && <CarouselComponent
					type="avatars"
					panCards={panCards}
					initialIndex={initialIndex}
					cards={avatars}
					Card={CardComponent}
					handleSelectCard={handleSelectAvatar}
				/>}
			</div>
		</div>
		
	);
};

Avatars.propTypes = {
	viewMode: PropTypes.string.isRequired,
	showCarousel: PropTypes.bool.isRequired,
	panCards: PropTypes.bool.isRequired,
	initialIndex: PropTypes.number.isRequired,
	handleGoBack: PropTypes.func.isRequired,
	handleSelectAvatar: PropTypes.func.isRequired,
	openIntroPopup: PropTypes.func.isRequired
};

export default Avatars;