import React from 'react';
import PropTypes from 'prop-types';
import AvatarImage from 'components/avatar-image/avatar-image';
import Button from 'components/button/button';
import Select from 'components/select/select';
import Tips from 'components/layout/tips';
import {profileUiTexts, generalUiTexts} from 'data/ui-texts';
import {tipsData} from 'data/tips-data';
import './profile.scss';
import './profile-landscape.scss';

const Profile = ({
	popupIsOpen,
	viewMode,
	tipIndex,
	name,
	handleInput,
	age,
	feedback,
	tipsText,
	goToStep,
	handleSaveProfile,
	handleSelect,
	education,
	experience,
	openIntroPopup,
	showTipIndex
}) => {
	let nameValue = name ? name : '';
	let ageValue = age ? age : '';
	let educationValue = education ? education.id : null;
	let experienceValue = experience ? experience.id : null;

	let tipStep = tipsData.tips.profileSteps[tipIndex];
	let tipText = tipsData[tipStep].text;

	return (
		<div className={'Profile Profile--' + viewMode} aria-hidden={popupIsOpen}>
			<Button class="goBack" viewMode={viewMode} onClick={() => {goToStep('home');}} />
			{viewMode === 'portrait' && <Button class="help" viewMode={viewMode} onClick={() => {openIntroPopup();}} />}
			<AvatarImage 
				viewMode={viewMode} 
				avatarId={0} 
				initialLetter={nameValue ? nameValue.charAt(0).toUpperCase() : '?'} 
			/>
			<div className="Profile-form">
				<div className="Profile-formEl Profile-formEl--nameAndAge">
					<div className="Profile-label">{profileUiTexts.nameAndAgeLabel}</div>
					<input
						className='Input'
						name="name"
						placeholder={profileUiTexts.namePlaceholder}
						value={nameValue}
						autoComplete="off"
						onChange={(event)=>{handleInput(event);}}
					/>
					<input
						className='Input'
						name="age"
						placeholder={profileUiTexts.agePlaceholder}
						value={ageValue}
						type="number"
						autoComplete="off"
						onChange={(event)=>{handleInput(event);}}
					/>
				</div>

				<div className="Profile-formEl Profile-formEl--education">
					<div className="Profile-label">{profileUiTexts.educationLabel}</div>
					<Select
						viewMode={viewMode} 
						type="education-select"
						class="education"
						options={profileUiTexts.educationOptions}
						defaultValue={generalUiTexts.choose}
						onSelect={handleSelect}
						selectedId={educationValue}
					/>
				</div>

				<div className="Profile-formEl Profile-formEl--experience">
					<div className="Profile-label">{profileUiTexts.experienceLabel}</div>
					<Select
						viewMode={viewMode} 
						type="experience-select"
						class="experience"
						options={profileUiTexts.experienceOptions}
						defaultValue={generalUiTexts.choose}
						onSelect={handleSelect}
						selectedId={experienceValue}
					/>
				</div>
				{viewMode === 'landscape' && <div className="Profile-feedback">{feedback}</div>}
			</div>
			{viewMode === 'portrait' && <div className="Profile-feedback">{feedback}</div>}
			{/* Tips */}
			{viewMode === 'landscape' && 
				<Tips 
					type="profile"
					steps={tipsData.tips.profileSteps}
					stepIndex={tipIndex}
					stepText={tipText}
					showStepIndex={showTipIndex}
				/>}
			<Button
				class="next"
				viewMode={viewMode} 
				text={generalUiTexts.next}
				onClick={()=> {handleSaveProfile();}}/>
		</div>
	);
};

Profile.propTypes = {
	popupIsOpen: PropTypes.bool.isRequired,
	viewMode: PropTypes.string.isRequired,
	tipIndex: PropTypes.number.isRequired,
	handleSaveProfile: PropTypes.func.isRequired,
	goToStep: PropTypes.func.isRequired,
	handleSelect: PropTypes.func.isRequired,
	name: PropTypes.string,
	tipsText: PropTypes.array,
	handleInput: PropTypes.func.isRequired,
	age: PropTypes.string,
	feedback: PropTypes.string.isRequired,
	education: PropTypes.object,
	experience: PropTypes.object,
	openIntroPopup: PropTypes.func.isRequired,
	showTipIndex: PropTypes.func.isRequired
};

export default Profile;