import React from 'react';
import PropTypes from 'prop-types';
import {formatDate, secondsToMinutes} from 'helpers/date-helper';
import './stats.scss';

const Stats = (props) => {
	let {
		// isLoading,
		databaseProject,
		stats,
		availableYears,
		selectedYears,
		goToPage,
		toggleYear
	} = props;

	let numberOfStartedGames = stats.filter((stat) => {return stat.startPart1Time !== null;}).length;
	let numberOfFinishedGames = stats.filter((stat) => {return stat.endPart2Time !== null;}).length;
	// let numberOfFinishedAndEmailedGames = stats.filter((stat) => {
	// 	return stat.endPart2Time !== null && stat.diplomaEmailed && stat.diplomaEmail === true;
	// }).length;

	

	return (
		<div className="StatsAdmin">
			<div className="StatsAdmin-title">Spilstatistik</div>
			<p className="StatsAdmin-backBtn" onClick={() => {goToPage(null);}}>&lArr; tilbage</p>
			<p>Statistik for {databaseProject}-serveren.</p>
			<div className="StatsAdmin-toggleYear">
				{availableYears.map((year, index) => {
					return (
						<div 
							key={index}
							onClick={() => {toggleYear(year);}} 
							className={'StatAdmin-year' + (selectedYears.indexOf(year) >= 0 ? ' selected' : '')}
						>{year}</div>
					);
				})}
				
			</div>
			<div className="StatsAdmin-totaltStats">
				<div><span>Antal spil startet:</span> {numberOfStartedGames}</div>
				<div><span>Antal spil fuldført:</span> {numberOfFinishedGames}</div>
			</div>
			<div className="StatsAdmin-stats">
				<div className="StatsAdmin-statsHeader">
					<span>Dato</span>
					<span>Del 1</span>
					<span>Del 2</span>
					<span>Total</span>
					<span>Gennemførte jobrejser</span>
					<span>E-mail sendt</span>
				</div>
				{stats.map((stat, index) => {
					let part1diff = '-';
					if (stat.startPart1Time && stat.endPart1Time) {
						part1diff = secondsToMinutes(stat.endPart1Time - stat.startPart1Time);
					}
					let part2diff = '-';
					if (stat.startPart2Time && stat.endPart2Time) {
						part2diff = secondsToMinutes(stat.endPart2Time - stat.startPart2Time);
					}
					let totaldiff = '-';
					if (stat.startPart1Time && stat.startPart2Time && stat.endPart1Time && stat.endPart2Time) {
						totaldiff = secondsToMinutes(
							(stat.endPart1Time - stat.startPart1Time) + (stat.endPart2Time - stat.startPart2Time)
						);
					}

					let diplomaEmailed = '-';
					if (stat.hasOwnProperty('diplomaEmailed') && stat.diplomaEmailed !== null) {
						diplomaEmailed = (stat.diplomaEmailed ? 'ja' : 'nej');
					}
					return (<div key={index}>
						<span>{formatDate(stat.startPart1Time)}</span>
						<span>{part1diff}</span>
						<span>{part2diff}</span>
						<span>{totaldiff}</span>
						<span>{stat.challengesWon}/{(stat.challengesWon + stat.challengesFailed)}</span>
						<span>{diplomaEmailed}</span>
					</div>);
				})}

			</div>
		</div>
	);
};

Stats.propTypes = {
	// isLoading: PropTypes.bool.isRequired,
	databaseProject: PropTypes.string.isRequired,
	stats: PropTypes.array.isRequired,
	availableYears: PropTypes.array.isRequired,
	selectedYears: PropTypes.array.isRequired,
	goToPage: PropTypes.func.isRequired,
	toggleYear: PropTypes.func.isRequired
};

export default Stats;