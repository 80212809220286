import apiHelper from './api-helper';

function createNewLogEntry(statistics) {
	return new Promise((resolve) => {
		apiHelper('stats/create-new-log-entry', {
			collectionId: 'statistics',
			data: statistics
		}).then(
			(response)=> {
				if (response.status === 'success') statistics.docRef = response.docRef;
				resolve({status: 'ok', statistics: statistics});
			},
			(rejection) => {
				console.error(rejection);
				resolve({status: 'ok', statistics: statistics});
			}
		);
	});
}

function updateLogEntry(statistics) {
	return new Promise((resolve) => {
		apiHelper('stats/update-log-entry', {
			collectionId: 'statistics',
			data: statistics
		}).then(
			()=> {
				resolve({status: 'ok', statistics: statistics});
			},
			(rejection) => {
				console.error(rejection);
				resolve({status: 'ok', statistics: statistics});
			}
		);
	});
}



export {createNewLogEntry, updateLogEntry};