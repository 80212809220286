import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './button.scss';

class Button extends Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.handleTouchStart = this.handleTouchStart.bind(this);
	};

	handleClick(event) {
		if (this.props.disabled) event.preventDefault();
		else if (this.props.onClick) {
			event.persist();
			this.props.onClick(event);
		}
	}

	handleTouchStart(event) {
		if (this.props.disabled) event.preventDefault();
		else if (this.props.onClick) {
			event.persist();
			this.props.onClick(event);
		}
	}
		
	
	render() {
		let className = 'Button Button--' + this.props.viewMode;
		if (this.props.class) {className += ' Button--' + this.props.class;}	
		if (this.props.disabled) {className += ' Button--disabled';}	
		if (this.props.inactive) {className += ' Button--inactive';}
		if (this.props.loading) {className += ' Button--loading';}
		return <div role="button" className={className} onClick={this.handleClick}><span>{this.props.text}</span></div>;
	}
}

Button.defaultProps = {
	text: '',
	viewMode: 'portrait'
};

Button.propTypes = {
	disabled: PropTypes.bool,	
	inactive: PropTypes.bool,
	loading: PropTypes.bool,
	viewMode: PropTypes.string,
	text: PropTypes.string,
	class: PropTypes.string,
	onClick: PropTypes.func.isRequired
};


export default Button;