function printContent(html, title) {

	let documentTitle = 'Print';
	if (title) {
		documentTitle = title;
	}

	var myWindow = window.open();

	myWindow.document.write('<html><head>');
	myWindow.document.write('<title>' + documentTitle + '</title>');
	myWindow.document.write('<link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700" rel="stylesheet">');
	myWindow.document.write('</head>');
	myWindow.document.write('<body style="width: 595px; font-family: \'Open Sans\', sans-serif;">' + html + '</body>');
	myWindow.document.write('</html>');
	myWindow.document.close();
	
	myWindow.focus();

	myWindow.addEventListener('load', function() {
		myWindow.print();
		// myWindow.close();
	});
};


export {printContent};