const tipsData = {
	intro: {
		text: 'Nu skal du bygge %name% jobrejse.<br /><br />Træk handlinger over på tidslinjen til højre.<br /><br />Bemærk rækkefølgen! Det, du vil gøre FØRST, skal ligger UNDERST.',
	},
	actionOrder: {
		text: 'Flyt handlinger op og ned på listen.<br /><br />Rækkefølgen af handlingerne kan være afgørende for, om de kan gennemføres.',
	},
	actionInfo: {
		text: 'Klik på en handling for at se hvad den kræver og giver.'
	},
	actionColors: {
		text: '<span class="red">Røde</span> handlinger kan ikke gennemføres, fordi de formelle krav ikke er opfyldt endnu.<br /><br /><span class="yellow">Gul</span> betyder de formelle krav er opfyldt, men at %name% score er så lav, at det bliver svært at gennemføre.<br /><br /><span class="green">Grønne</span> handlinger er klar til start.'
	},
	actionCost: {
		text: 'Handlinger koster energi.<br /><br />Går du i minus, må du fjerne eller udskifte handlingerne på tidslinjen.'
	},
	fullIntro: {
		text: '<strong>Nu skal du bygge %name% jobrejse.</strong><br /><br /><strong>1.</strong> Træk handlinger over på tidslinjen til højre. Byg nedefra og op!<br /><br/><strong>2.</strong> Klik på en handling for at se, hvad den kræver og giver.<br /><br /><strong>3.</strong> Røde handlinger kan ikke gennemføres, gule lykkes kun måske, og grønne med sikkerhed.<br /><br /><strong>4.</strong> Handlinger koster energi. Går du i minus, må du fjerne handlinger fra tidslinjen.'
	},

	profile1: {
		text: 'I denne del skal du vurdere dig selv og vælge de næste skridt på din jobrejse.'
	},
	profile2: {
		text: 'Du kan sende resultatet til dig selv på mail, så du kan vise det til en vejleder eller jobkonsulent.'
	},
	profile3: {
		text: 'Skriv dit navn, alder, uddannelsesniveau og cirka hvor længe du har været i fast arbejde.'
	},
	fullProfile: {
		text: '<strong>Byg din egen plan</strong><br /><br />I denne del skal du vurdere dig selv og vælge de næste skridt på din jobrejse.<br /><br />Du kan sende resultatet til dig selv på mail, så du kan vise det til en vejleder eller jobkonsulent.'
	},

	tips: {
		steps: ['fullIntro'],
		// steps: ['intro', 'actionInfo', 'actionColors', 'actionCost'],
		// profileSteps: ['profile1', 'profile2', 'profile3']
		profileSteps: ['fullProfile']
	},
};


export{tipsData};