import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {useDrop, useDrag} from 'react-dnd';

/* Example: https://codesandbox.io/s/github/react-dnd/react-dnd/tree/gh-pages/examples_hooks_js/04-sortable/simple?from-embed */

const Item = (props) => {
	let {
		type,
		dragIsDisabled, 
		isSelected,
		id, 
		index,
		style,
		handleDragStart,
		dropAction,
		handleMoveAction,
		handleFindAction,
		toggleActionInfo,
		children
	} = props;

	const ref = useRef(null);
	const containerId = handleFindAction(id)[2];

	const [, drop] = useDrop({
		accept: 'action',
		canDrop: () => {return false;},
		hover(item, monitor) {
			if (!ref.current) return;

			/* Ignore hovering on the left side */
			if (containerId === 'actions') return;

			/* Ignore actions that are begin moved from the left side */
			let toContainerId = handleFindAction(item.id)[2];
			if (toContainerId === 'actions' && type !== 'part2') return;

			/* Ignore moving action to its own position */
			let fromIndex = handleFindAction(item.id)[0];
			let toIndex = index;
			if (item.id === id || (fromIndex === toIndex && toContainerId === containerId)) return;			

			if (type === 'part2') {
				if (handleFindAction(item.id)[2] === 'selected') {
					const hoverBoundingRect = ref.current.getBoundingClientRect();
					const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2.;
					const clientOffset = monitor.getClientOffset(); // mouse position
					const hoverClientX = clientOffset.x - hoverBoundingRect.left;
					if (fromIndex < toIndex && hoverClientX < hoverMiddleX) return;
					if (fromIndex > toIndex && hoverClientX > hoverMiddleX) return;
				}
				handleMoveAction(item.id, fromIndex, toIndex);
				item.index = toIndex;	
			} else {
				const hoverBoundingRect = ref.current.getBoundingClientRect();
				const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
				const clientOffset = monitor.getClientOffset();
				const hoverClientY = clientOffset.y - hoverBoundingRect.top;
				if (fromIndex < toIndex && hoverClientY < hoverMiddleY) return;
				if (fromIndex > toIndex && hoverClientY > hoverMiddleY) return;
				handleMoveAction(fromIndex, toIndex);
				item.index = toIndex;
			}
		}
	});
		
	const [{ isDragging }, drag] = useDrag({
		item: { type: 'action', id, containerId, index },
		canDrag() {return (!dragIsDisabled && id !== null);},
		begin() {handleDragStart(id);},
		end() {dropAction();},
		isDragging(monitor) {return (id === monitor.getItem().id);},
		collect: (monitor) => {return {isDragging: monitor.isDragging()};},

	});

	
	const opacity = ((isDragging || isSelected) ? 0.4 : 1);
	let className = 'ChallengeLandscape-actionWrap' +
		(id ? ' ChallengeLandscape-actionWrap--' + id : '') + 
		(isDragging ? ' ChallengeLandscape-actionWrap--dragging' : '');

	let onlyChild = React.Children.only(children);
	let childCloned = React.cloneElement(onlyChild, {isDragging: isDragging});

	return (
		<div 
			className={className} 
			ref={drag(drop(ref))} 
			style={{opacity, ...style}} 
			onClick={(event) => {if (id) toggleActionInfo(id, event);}}
		>
			{childCloned}
		
		</div>
	
	);
};

Item.defaultProps = {
	type: 'part1',
	style: {},
	isSelected: false,
	index: -1
};

Item.propTypes = {
	type: PropTypes.string,
	dragIsDisabled: PropTypes.bool.isRequired,
	isSelected: PropTypes.bool,
	index: PropTypes.number,
	id: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	style: PropTypes.object,
	handleDragStart: PropTypes.func.isRequired,
	dropAction: PropTypes.func.isRequired,
	handleMoveAction: PropTypes.func.isRequired,
	handleFindAction: PropTypes.func.isRequired,
	toggleActionInfo: PropTypes.func.isRequired,
	children: PropTypes.object.isRequired
};

export default Item;