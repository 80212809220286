import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useSprings, animated, interpolate} from 'react-spring';
import {useGesture} from 'react-use-gesture';
import './carousel.scss';

const Carousel = ({panCards, type, cards, Card, initialIndex, setCurrentIndex, handleSelectCard}) => {
	/* Configure layout */
	let windowWidthScale = 0.7;
	let windowWidth = window.innerWidth;

	let appElement = document.getElementById('app');
	if (appElement && appElement.getBoundingClientRect() && appElement.getBoundingClientRect().width) {
		windowWidth = appElement.getBoundingClientRect().width;
		let windowHeight = appElement.getBoundingClientRect().height;
		if (windowWidth / windowHeight > 0.5625) {
			let fontSize = 16 * (windowHeight / 640);
			let cardWrapWidth = 16.25 * fontSize;
			windowWidthScale = cardWrapWidth / windowWidth;
		}
	}

	/* References to current card */
	const index = React.useRef(initialIndex);
	let [focusIndex, setFocusIndex] = useState(initialIndex);

	/* Pan cards */
	const initialPosition = (i) => {
		let offsetIndex = cards.length - 2;
		let scale = 0.9;
		let x = 0;
		let display = 'none';

		if (i === offsetIndex) {
			display = 'flex';
		}
		if (i === (offsetIndex - 1)) {
			x = - (windowWidth * windowWidthScale);
			display = 'flex';
		}
		if (i === (offsetIndex + 1)) {
			x = (windowWidth * windowWidthScale);
			display = 'flex';
		}
		if (i === 0) {
			x = 2 * (windowWidth * windowWidthScale);
			display = 'flex';
		}
		if (i === 1) {
			x = 3 * (windowWidth * windowWidthScale);
			display = 'flex';
		}
		return { x: x, scale: scale, display: display};
	};

	/* Position cards */
	const position = (i) => {
		let scale = 0.9;
		let x = 0;
		let display = 'none';

		/* Center card */
		if (i === index.current) {
			scale = 1;
			x = 0;
			display = 'flex';
		}

		/* Left cards */
		for (let j = 1; j <= 2; j++) { 
			if (index.current - j < 0) {
				if (i === cards.length + (index.current - j)) {
					x = - j * (windowWidth * windowWidthScale);
					display = (j === 2 ? 'none' : 'flex');
				}
			} else {
				if (i === index.current - j) {
					x = - j * (windowWidth * windowWidthScale);
					display = (j === 2 ? 'none' : 'flex');
				}
			}
		}
		/* Right cards */
		for (let j = 1; j <= 2; j++) { 
			if (index.current + j > cards.length - 1) {
				if (i === (index.current + j) - cards.length) {
					x = j * (windowWidth * windowWidthScale);
					display = (j === 2 ? 'none' : 'flex');
				}
			} else {
				if (i === index.current + j) {
					x = j * (windowWidth * windowWidthScale);
					display = (j === 2 ? 'none' : 'flex');
				}
			}
		}

		return { x: x, scale: scale, display: display};
	};

	/* Interpolates scale into a css transform */
	const trans = (s) => {return `scale(${s})`;};

	/* Create springs */
	const to = (i) => {return {...position(i)};};
	let from = to;
	if (panCards) from = (i) => {return {...initialPosition(i)};};
	const [springProps, set] = useSprings(cards.length, (i) => {return {...to(i), from: from(i) };});

	/* Create gesture */
	const bind = useGesture(({ down, delta: [xDelta], direction: [xDir], velocity, cancel }) => {
		/* If you swipe fast and long enough it should trigger the card to fly out */
		const trigger = velocity > 0.1 && Math.abs(xDelta) > windowWidth / 4.;

		/* Direction should either point left (-1) or right (+1) */
		const dir = xDir < 0 ? -1 : 1;

		/* Swipe triggered, cancel gesture and move to next/prev card */
		if (!down && trigger) {
			let currentIndex = index.current - dir;
			if (currentIndex < 0) currentIndex = cards.length - 1;
			if (currentIndex > (cards.length - 1)) currentIndex = 0;
			setFocusIndex(currentIndex);
			cancel((index.current = currentIndex));
		}

		set((i) => {
			let positionObj = position(i);
			const x = positionObj.x + (down ? xDelta : 0);
			const scale = (i === index.current ? 1 : 0.9);
			return { x: x, scale: scale, display: positionObj.display};
		});
		if (setCurrentIndex) setCurrentIndex(index.current);
	});

	return (
		<div className={'Carousel Carousel--' + type}>
			{springProps.map(({ x, display, scale}, i) => {
				return (
					<animated.div
						key={i}
						className="Carousel-cardWrap"
						{...bind(i)}
						style={{
							display,
							transform: x.interpolate((x) => {return `translate3d(${x}px,0,0)`;})
						}}>
						<animated.div 
							className="Carousel-card" 
							style={{transform: interpolate([scale], trans)}}
							onClick={() => {handleSelectCard(cards[i].id);}}
						>
							<Card 
								viewMode="portrait"
								ariaHidden={(focusIndex !== i)}
								card={cards[i]}
								handleSelectCard={handleSelectCard}
							/>
						</animated.div>
					</animated.div>
				);
			})}
		</div>				
	);
};

Carousel.defaultProps = {
	panCards: false,
	initialIndex: 0
};

Carousel.propTypes = {
	panCards: PropTypes.bool,
	type: PropTypes.string.isRequired,
	cards: PropTypes.array.isRequired,
	Card: PropTypes.func.isRequired,
	initialIndex: PropTypes.number,
	setCurrentIndex: PropTypes.func,
	handleSelectCard: PropTypes.func.isRequired,
};

export default Carousel;