import {defaultStats, defaultStatsColors} from 'data/stats-data';
import {actionsData} from 'data/actions-data';
import {getUnmetRequirements} from 'helpers/challenge-helper';

/**
 * Calculate stats
 * @param {object} initialStats 
 * @param {array} actions 
 * @param {bool} boughtActionsOnly
 */
function calculateStats(initialStats, actions, boughtActionsOnly) {
	let stats = JSON.parse(JSON.stringify(initialStats));
	actions.forEach((action) => {
		if (!boughtActionsOnly || action.status === 'bought') {
			action.rewards.forEach((reward) => {stats[reward.statId] = stats[reward.statId] + reward.value;});
		}
	});
	return stats;
}

/**
 * Calculate required stats for goal based on required actions
 * @param {object} goal 
 */
function getRequiredStats(goal) {
	let requiredStats = JSON.parse(JSON.stringify(defaultStats));
	goal.requirements.forEach((goalRequirement) => {
	/* NOTE: stats can only be summed if the goal requirement is a specific action */
	/* Maybe not an issue since EUD/EUX usually have higher requirements */
		if (goalRequirement.hasOwnProperty('actionId') || goalRequirement.hasOwnProperty('actionIds')) {
			let actionId = goalRequirement.hasOwnProperty('actionId') 
				? goalRequirement.actionId 
				: goalRequirement.actionIds[0];
			if (actionsData.some((action) => {return action.id === actionId;})) {
				let actionData = JSON.parse(JSON.stringify(actionsData)).filter((action) => {
					return action.id === actionId;
				})[0];
				actionData.requirements.forEach((requirement) => {
					if (
						requirement.type === 'stat' && 
					requirement.hasOwnProperty('statId') &&
					requiredStats.hasOwnProperty(requirement.statId)
					) {
						requiredStats[requirement.statId] = 
						Math.max(requirement.value, requiredStats[requirement.statId]);	
					}
				});
			}
		}
	});
	return requiredStats;
}

/**
 * Get colors (red / yellow / green) of stats
 * @param {object} initialStats 
 * @param {array} initialActions 
 * @param {array} selectedActions 
 */
function getStatsColors (initialStats, initialActions, selectedActions) {
	let statsColors = JSON.parse(JSON.stringify(defaultStatsColors));
	let yellowActions = selectedActions.filter((action) => {return action.color === 'yellow';});
	yellowActions.forEach((action) => {
		let unmetRequirements = getUnmetRequirements(initialStats, initialActions, action.id, selectedActions);
		unmetRequirements.forEach((requirement) => {
			if (requirement.type === 'stat') statsColors[requirement.statId] = 'yellow';
		});
	});
	let redActions = selectedActions.filter((action) => {return action.color === 'red';});
	redActions.forEach((action) => {
		let unmetRequirements = getUnmetRequirements(initialStats, initialActions, action.id, selectedActions);
		unmetRequirements.forEach((requirement) => {
			if (requirement.type === 'stat') statsColors[requirement.statId] = 'red';
		});
	});
	return statsColors;
}

function getStatsColors2 (stats) {
	let statsColors = JSON.parse(JSON.stringify(defaultStatsColors));
	Object.keys(statsColors).forEach((key) => {
		if (stats && stats.hasOwnProperty(key)) {
			if (stats[key] < 3) statsColors[key] = 'red';
		}
	});

	return statsColors;
}


export {calculateStats, getRequiredStats, getStatsColors, getStatsColors2};