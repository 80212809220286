import React from 'react';
import PropTypes from 'prop-types';
import {diplomaUiTexts as uiTexts} from 'data/ui-texts';
import {statsData} from 'data/stats-data';

const DiplomaPrint = ({
	profile
}) => {
	if (profile && profile.answers) {
		let allAnswers = [];
		statsData.forEach((stat) => {
			let statAnswers = {
				id: stat.id,
				name: stat.name,
				answers: profile.answers.filter((answer) => {return answer.statId === stat.id;})
			};
			allAnswers.push(statAnswers);
		});

		// A4 width is 595px on screens
		let medalImg = require('../../assets/images/icon-medal.svg');

		return (
			<div 
				style={{
					position: 'relative', 
					fontSize: '14px', 
					fontWeight: '400',
					color: '#000000', 
					backgroundColor: '#ffffff'
				}}
			>

				{/* Medal image */}
				<img 
					src={medalImg}
					alt="medal"
					style={{
						position: 'absolute',
						top: '5px',
						left: '10px',
						width: '90px',
						height: '90px',
					}}
				/>
				
				{/* Title */}
				<div 
					style={{
						width: '125px',
						margin: '15px auto 0px auto',
						textTransform: 'uppercase',
						textAlign: 'center',
						fontSize: '20px',
						fontWeight: '700',
						paddingBottom: '5px',
						borderBottom: '2px solid rgba(75, 110, 114, 0.2)',
						marginBottom: '30px'
					}}
				>{uiTexts.title2}</div>

				{/* Name and age */}
				<div style={{width: '400px', margin: '0 auto'}}>
					<div style={{fontWeight: '700'}}>
						{profile.name} <span style={{fontWeight: '400'}}>({profile.age} {uiTexts.years})</span>
					</div>
				</div>

				{/* Line */}
				<div style={{width: '400px', borderBottom: '2px solid rgba(75, 110, 114, 0.2)', margin: '20px auto'}} />

				{/* Score */}
				<div style={{width: '400px', margin: '12px auto'}}>
					<div style={{fontWeight: '700', marginBottom: '10px'}}>{uiTexts.score}:</div>
					<div style={{textAlign: 'center', marginBottom: '12px'}}>
						{statsData.map((stat, index) => {
							let statIcon = require('../../assets/images/stats/' + stat.id + '.svg');
							let name = stat.name;
							let value = profile.stats[stat.id];
							return (
								<div 
									key={index} 
									style={{
										position: 'relative', 
										display: 'inline-block', 
										width: '80px',
										textAlign: 'center'
									}}>
									<img src={statIcon} alt={stat.id} style={{height: '20px'}} />
									<div style={{fontSize: '12px'}}>{name}</div>
									<div 
										style={{
											position: 'absolute', 
											right: '15px', 
											top: '5px',
											fontWeight: '700', 
											fontSize: '12px'
										}}
									>{value}</div>
								</div>
							);
						})}
					</div>
				</div>

				{/* Line */}
				<div style={{width: '400px', borderBottom: '2px solid rgba(75, 110, 114, 0.2)', margin: '20px auto'}} />
				
				{/* Interest, education, experience */}
				<div style={{width: '400px', margin: 'auto'}}>
					<div style={{fontWeight: '700'}}>{uiTexts.interest}</div>
					<div style={{marginBottom: '10px'}}>{profile.interest.title}</div>

					<div style={{fontWeight: '700'}}>{uiTexts.educationLevel}</div>
					<div style={{marginBottom: '10px'}}>{profile.education.name}</div>

					<div style={{fontWeight: '700'}}>{uiTexts.experience}</div>
					<div style={{marginBottom: '10px'}}>{profile.experience.name} {uiTexts.years}</div>
				</div>

				{/* Line */}
				<div style={{width: '400px', borderBottom: '2px solid rgba(75, 110, 114, 0.2)', margin: '20px auto'}} />

				{/* Next steps */}
				<div style={{width: '400px', margin: 'auto'}}>
					<div style={{fontWeight: '700'}}>{uiTexts.nextStep}</div>
					{profile.actions.map((action, index) => {
						let circleUrl = require('../../assets/images/circle-green.svg');
						if (action.type && action.id !== 'dummy-2') {
							circleUrl = require('../../assets/images/circle-' + action.type + '.svg');
						}
						return action.id !== 'dummy-2' && 
							<div key={index}>
								<div 
									style={{
										position: 'relative', 
										display: 'inline-block', 
										verticalAlign: 'middle', 
										height: '25px', 
										width: '25px',
										marginTop: '5px'
									}}
								>
									<img 
										src={circleUrl} 
										alt={action.type} 
										style={{
											width: '100%', 
											height: '100%', 
											position: 'absolute', 
											top: '0px', 
											left: '0px', 
											zIndex: 0
										}}
									/>
									<div 
										style={{
											position: 'absolute', 
											width: '100%', 
											height: '100%', 
											textAlign: 'center', 
											zIndex: 1, 
											fontSize: '14px', 
											lineHeight: '25px', 
											fontWeight: '700', 
											color: '#ffffff'
										}}>{index + 1}</div>
								</div>
								<div 
									style={{
										display: 'inline-block', 
										verticalAlign: 'middle', 
										marginLeft: '5px',
										lineHeight: '25px',
									}}
								>{action.title}</div>
							</div>;
					})}
				</div>

				{/* Line */}					
				<div style={{width: '400px', borderBottom: '2px solid rgba(75, 110, 114, 0.2)', margin: '20px auto'}} />

				{/* Quiz result */}
				<div style={{width: '400px', margin: 'auto'}}>
					<div style={{fontWeight: '700', marginBottom: '5px'}}>{uiTexts.answers}</div>
					{allAnswers.map((answerStat, index) => {
						let statIcon = require('../../assets/images/stats/' + answerStat.id + '.svg'); 
						return (
							<table 
								key={index} 
								style={{
									width: '100%',
									borderCollapse: 'collapse',
									marginBottom: '20px',
									textAlign: 'left',
									fontSize: '14px',
									border: '1px solid #707070'
								}}
							>
								<tbody>
									<tr>
										<th style={{border: '1px solid #707070', padding: '8px'}}>
											<img 
												src={statIcon} 
												alt={answerStat.id} 
												style={{height: '20px', verticalAlign: 'middle'}}
											/>
											<div 
												style={{
													display: 'inline-block',
													fontWeight: '700', 
													verticalAlign: 'middle', 
													paddingLeft: '5px'
												}}>{answerStat.name}</div>
										</th>
										<th 
											style={{
												border: '1px solid #707070', 
												padding: '8px 10px', 
												textAlign: 'center'
											}}>{profile.stats[answerStat.id]}</th>
									</tr>
									{answerStat.answers.map((answer, index) => {
										return (
											<tr key={index}>
												<td style={{border: '1px solid #707070', padding: '8px'}}>
													{answer.questionText}
												</td>
												<td 
													style={{
														border: '1px solid #707070', 
														padding: '8px 10px', 
														textAlign: 'center'
													}}
												>{answer.answer === 0 ? '-' : answer.answer}
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						);
					})}
				</div>
			</div>
		);
	}
	return null;
};

DiplomaPrint.propTypes = {
	profile: PropTypes.object,
};

export default DiplomaPrint;