import React from 'react';
import PropTypes from 'prop-types';
import {useDrop} from 'react-dnd';

const ChallengeDndContainer = ({
	containerId, 
	containerHeight,
	handleFindAction, 
	handleMoveAction2, 
	children
}) => {
	const [{isOver, canDrop}, drop] = useDrop({
		accept: 'action',
		hover(item, monitor) {
			if (monitor.isOver({shallow: true})) {			
				const fromContainerId = handleFindAction(item.id)[2];	
				if (containerId !== fromContainerId) {
					handleMoveAction2(item.id);
				}
			}
		},
		collect: (monitor) => {
			return {
				isOver: monitor.isOver(),
				canDrop: monitor.canDrop(),
			};
		}
	});
	let className = 'Challenge-actions';
	if (canDrop) className = className + ' Challenge-actions--dragging';
	if (isOver) className = className + ' active';

	let style = null;
	if (containerHeight) style = {height: containerHeight + 'em'};
	return <div id={containerId} ref={drop} className={className} style={style}>{children}</div>;
};

ChallengeDndContainer.propTypes = {
	containerId: PropTypes.string.isRequired,
	containerHeight: PropTypes.number,
	handleFindAction: PropTypes.func.isRequired,
	handleMoveAction2: PropTypes.func.isRequired,
	children: PropTypes.array
};


export default ChallengeDndContainer;