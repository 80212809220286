import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {generalUiTexts, avatarUiTexts} from 'data/ui-texts';
import {statsData} from 'data/stats-data';
import AvatarImage from 'components/avatar-image/avatar-image';
import CircularProgress from 'components/layout/circular-progress';
import Stats from './stats';
import './avatar-stats.scss';

const AvatarStats = (props) => {
	let {
		viewMode,
		type,
		avatar, 
		stats,
		prevStatsColors,
		statsColors,
		requiredStats,
		energy,
		initialEnergy,
		popupStatId,
		popupStatText,
		handleRefresh,
		handleClickStat
	} = props;

	let popupStatTitle = null;
	if (popupStatId) {
		if (statsData.some((stat) => {return stat.id === popupStatId;})) {
			popupStatTitle = statsData.filter((stat) => {return stat.id === popupStatId;})[0].name;
		}
	}

	return (
		<React.Fragment>
			<div className={'AvatarStats AvatarStats--' + viewMode + (type ? ' AvatarStats--' + type : '')}>
				{viewMode !== 'landscape' && <AvatarImage
					avatarId={avatar.id}
					type={type}
					onClick={handleRefresh}
					initialLetter={avatar.id === 0 ? avatar.name.charAt(0).toUpperCase() : null }
				/>}
				{viewMode !== 'landscape' && <div className="AvatarStats-nameAndAge">
					<span className="AvatarStats-name">{avatar.name}</span>
					<span className="AvatarStats-age">({avatar.age} {avatarUiTexts.years})</span>
				</div>}
				<Stats
					viewMode={viewMode}
					type={type}
					stats={stats}
					requiredStats={requiredStats}
					avatar={avatar}
					prevStatsColors={prevStatsColors}
					statsColors={statsColors}
					handleClickStat={handleClickStat}
				/>
				{energy !== null && 
					<React.Fragment>
						{viewMode === 'landscape' && 
							<div className="AvatarStats-energyLabel"><span>{generalUiTexts.energy}:</span></div>}
						<div 
							aria-hidden={true} 
							className={'AvatarStats-energy' + (energy < 0 ? ' negative' : '') +
								' AvatarStats-energy--' + viewMode
							}
						>
							<span>{energy}</span>
							<CircularProgress
								viewMode={viewMode}
								percentageFactor={energy}
								max={initialEnergy}
								radius={3}
								stroke={0.3}
								mainColor="#516B6F"
								lightColor="#dee6e7"
								negativeColor="#F15156"
							/>
						</div>
					</React.Fragment>
				}
			</div>
			{(popupStatId || viewMode === 'landscape') && 
				<div 
					className={'AvatarStats-popup AvatarStats-popup--' + type + 
					' AvatarStats-popup--' + viewMode + 
					' AvatarStats-popup--' + popupStatId + 
					(popupStatId ? ' AvatarStats-popup--show' : '')}
					onClick={() => {if (viewMode !== 'landscape') handleClickStat(null);}}
				>
					{viewMode !== 'landscape' && 
						<div className={'AvatarStats-popupTitle AvatarStats-popupTitle--' + popupStatId}>
							{popupStatTitle}</div>}
					<div className="AvatarStats-popupText">{renderMarkdown(popupStatText ? popupStatText : '')}</div>
				</div>
			}
		</React.Fragment>
	);
};

AvatarStats.defaultProps = {
	type: null,
	stats: null,
	statsColors: null,
	prevStatsColors: null,
	requiredStats: null,
	energy: null,
	popupStatId: null,
	popupStatText: null,
	showStatPopup: null,
	handleRefresh: null
};

AvatarStats.propTypes = {
	viewMode: PropTypes.string.isRequired,
	type: PropTypes.string,
	avatar: PropTypes.object.isRequired,
	stats: PropTypes.object,
	prevStatsColors: PropTypes.object,
	statsColors: PropTypes.object,
	requiredStats: PropTypes.object,
	energy: PropTypes.number,
	initialEnergy: PropTypes.number,
	popupStatId: PropTypes.string,
	popupStatText: PropTypes.string,
	handleRefresh: PropTypes.func,
	handleClickStat: PropTypes.func
};

export default AvatarStats;