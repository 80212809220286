import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AvatarInfoController from 'components/avatar-info/avatar-info-controller';
import {defaultStats} from 'data/stats-data';
import {getStatsColors2} from 'helpers/stats-helper';
import {quizData} from 'data/quiz-data';
import {popupsData} from 'data/popups-data';
import {generalUiTexts} from 'data/ui-texts';
import Quiz from './quiz';

class QuizController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showAvatarInfo: false,
			answers: [],
			stats: JSON.parse(JSON.stringify(defaultStats)),
			currentQuestionIndex: 0, // goes from 0 to 4
			currentStatIndex: 0, // goes from 0 to 4
			view: 'questions', // can be 'questions' or 'overview'
			animation: null,
			statsColors: {}
		};
		this.openIntroPopup = this.openIntroPopup.bind(this);
		this.closeIntroPopup = this.closeIntroPopup.bind(this);
		this.toggleAvatarInfo = this.toggleAvatarInfo.bind(this);
		this.saveAnswer = this.saveAnswer.bind(this);
		this.nextQuestion = this.nextQuestion.bind(this);
		this.nextStat = this.nextStat.bind(this);
		this.handleGoToStat = this.handleGoToStat.bind(this);
		this.previousQuestion = this.previousQuestion.bind(this);
		this.saveStatAverage = this.saveStatAverage.bind(this);
		this.handleAnimation = this.handleAnimation.bind(this);
		this.handleGoBack = this.handleGoBack.bind(this);
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		/* Scroll to top */
		window.scrollTo(0, 0);

		/* Use profile saved in game state */
		if (this.props.prevStepId === 'interests') {		
			let statsColors = getStatsColors2(this.props.profile.stats);
			this.setState({
				answers: this.props.profile.answers,
				stats: this.props.profile.stats,
				statsColors: statsColors,
				currentStatIndex: 4,
				currentQuestionIndex: 4,
				view: 'overview'
			});
		/* Use cached profile data */
		} else if (this.props.cachedProfile && this.props.cachedProfile.answers && this.props.cachedProfile.stats) {
			let statsColors = getStatsColors2(this.props.cachedProfile.stats);
			this.setState({
				answers: this.props.cachedProfile.answers,
				stats: this.props.cachedProfile.stats,
				statsColors: statsColors
			});
		}

		/* Open intro popup */
		if (this.props.introPopupStatuses.quizIntroSeen !== true) {
			this.openIntroPopup();
		}
	}

	/**
	 * Open intro popup
	 */
	openIntroPopup() {
		let popupData = JSON.parse(JSON.stringify(popupsData.startQuizInfo));
		let popupBtns = [{text: generalUiTexts.ok, action: this.closeIntroPopup, actionParams: []}];
		this.props.openPopup(
			null, popupData.text, popupBtns, null, 
			{action: this.closeIntroPopup, actionParams: []}, false, 
			'introPopup', null, null, null, popupData.audio
		);
	}

	/**
	 * Close intro popup
	 */
	closeIntroPopup() {
		this.props.closePopup();
		if (this.props.introPopupStatuses.quizIntroSeen !== true) {
			this.props.updateIntroPopupStatus('quizIntroSeen', true);
		}
	}

	/**
	 * Hide / show avatar info
	 * @param {bool} showAvatarInfo 
	 */
	toggleAvatarInfo(showAvatarInfo) {
		this.setState({showAvatarInfo: showAvatarInfo});
	}

	/**
	 * Show animation
	 * @param {string} type 
	 */
	handleAnimation(type) {
		this.setState({ animation: 'sliding sliding--' + type});
		setTimeout(() => {
			this.setState({ animation: null });
		}, 600);
	}

	/**
	 * Save stat average
	 */
	saveStatAverage() {
		let statIndex = this.state.currentStatIndex;
		let answers = this.state.answers;
		let sum = 0;
		
		answers.forEach((answer) => {
			if (answer.statIndex === statIndex) sum += answer.answer;
		});

		let statId = quizData.find((stat) => {return stat.index === statIndex;}).id;
		
		let newStats = Object.assign({}, this.state.stats);
		newStats[statId] = Math.round(sum / 5);

		let newStatsColors = getStatsColors2(newStats);
		this.setState({
			stats: newStats,
			statsColors: newStatsColors
		});
	}

	/**
	 * Save answer
	 */
	saveAnswer(ans = 0, goToNextQuestion = false) {
		let stat = quizData[this.state.currentStatIndex];
		let questionText = stat.questions[this.state.currentQuestionIndex];
		let answer = {
			answer: ans,
			statIndex: this.state.currentStatIndex,
			statId: stat.id,
			statName: stat.name,
			questionIndex: this.state.currentQuestionIndex,
			questionText: questionText
		};

		let answers = [...this.state.answers];
		let index = answers.findIndex((el) => {
			return el.statIndex === this.state.currentStatIndex
				&& el.questionIndex === this.state.currentQuestionIndex;
		});
		if (index > -1) {
			answers[index] = {...answers[index], answer: ans};
		} else {
			answers.push(answer);
		}
		this.setState({ answers }, () => {
			if (goToNextQuestion) this.nextQuestion(this.state.currentStatIndex, this.state.currentQuestionIndex);
		});
	}

	/**
	 * Go to next stat
	 * @param {number} statIndex 
	 * @param {number} questionIndex 
	 */
	nextStat(statIndex, questionIndex) {
		if (this.state.animation !== null) return;
		if (statIndex === 4) {
			this.props.saveQuizResults(this.state.stats, this.state.answers);
		} else {
			this.setState({
				view: 'questions',
				currentQuestionIndex: 0,
				currentStatIndex: statIndex + 1
			});
		}
	}

	/**
	 * Go to stat overview
	 * @param {number} statIndex 
	 */
	handleGoToStat(statIndex) {
		this.setState({
			view: 'overview',
			currentStatIndex: statIndex,
			currentQuestionIndex: 4
		});
	}

	/**
	 * Go to next question
	 * @param {number} statIndex 
	 * @param {number} questionIndex 
	 */
	nextQuestion(statIndex, questionIndex) {
		if (this.state.animation !== null) return;
		this.handleAnimation('next');
		setTimeout(() => {
			if (questionIndex === 4) {
				this.saveStatAverage(statIndex);
				this.setState({
					view: 'overview'
				});
			} else {
				this.setState({
					currentQuestionIndex: questionIndex + 1
				});
			}
		}, 200);
	}

	/**
	 * Go to previous questions
	 * @param {number} statIndex 
	 * @param {number} questionIndex 
	 */
	previousQuestion(statIndex, questionIndex) {
		if (this.state.animation !== null) return;
		if (questionIndex !== 0 && this.state.view === 'questions') this.handleAnimation('prev');
		
		setTimeout(() => {
			if (this.state.view === 'overview') {
				this.setState({view: 'questions'});
			} else {
				if (questionIndex === 0) {
					if (statIndex === 0) {
						this.props.goToStep('profile');
					} else {
						this.setState({
							view: 'overview',
							currentStatIndex: statIndex - 1
						});
					}
				} else {			
					this.setState({
						currentQuestionIndex: questionIndex - 1,
					});
				}
			}
		}, 200);
	}

	/**
	 * Go back (prev section or previous question)
	 */
	handleGoBack() {
		this.props.goToStep('profile');
		// let currentStatIndex = this.state.currentStatIndex;
		// if (currentStatIndex === 0) {
		// 	this.props.goToStep('profile');
		// } else {
			
		// 	this.setState({
		// 		currentStatIndex: currentStatIndex - 1,
		// 		currentQuestionIndex: 0,
		// 		view: 'questions'
		// 	});
		// }
	}

	/**
	 * Render component
	 */
	render() {
		if (this.state.showAvatarInfo) {
			return (
				<AvatarInfoController 
					page="part2"
					avatar={Object.assign({}, this.props.profile, {stats: this.state.stats})}
					goToStep={null} 
					toggleAvatarInfo={this.toggleAvatarInfo}
					handleRefresh={null}
					openPopup={this.props.openPopup}
					closePopup={this.props.closePopup}
				/>
			);
		}

		let statsClickable = (this.props.profile && this.props.profile.hasOwnProperty('answers')) ||
			(this.props.cachedProfile && this.props.cachedProfile.hasOwnProperty('answers'));
			
		return (
			<div className="Quiz" aria-hidden={this.props.popupIsOpen}>
				<Quiz
					viewMode={this.props.viewMode}
					popupIsOpen={this.props.popupIsOpen}
					currentStatIndex={this.state.currentStatIndex}
					currentQuestionIndex={this.state.currentQuestionIndex}
					avatar={{...this.props.profile, id: 0}}
					saveAnswer={this.saveAnswer}
					view={this.state.view}
					nextStat={this.nextStat}
					nextQuestion={this.nextQuestion}
					previousQuestion={this.previousQuestion}
					answers={this.state.answers}
					stats={this.state.stats}
					statsColors={this.state.statsColors}
					toggleAvatarInfo={this.toggleAvatarInfo}
					handleGoToStat={statsClickable ? this.statOverView : null}
					handleGoBack={this.handleGoBack}
					openIntroPopup={this.openIntroPopup}
					animation={this.state.animation}
				/>
			</div>
		);
	}
}

QuizController.defaultProps = {
	cachedProfile: null
};

QuizController.propTypes = {
	viewMode: PropTypes.string.isRequired,
	popupIsOpen: PropTypes.bool.isRequired,
	introPopupStatuses: PropTypes.object.isRequired,
	goToStep: PropTypes.func.isRequired,
	prevStepId: PropTypes.string.isRequired,
	saveQuizResults: PropTypes.func.isRequired,
	profile: PropTypes.object.isRequired,
	cachedProfile: PropTypes.object,
	updateIntroPopupStatus: PropTypes.func.isRequired,
	openPopup: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired
};


export default QuizController;