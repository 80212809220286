let popupsData = {

	/* Cookie */
	cookieInfo: {
		title: null,
		text: 'Vi bruger cookies til at gemme dine svar fra spillets anden del. Dette er for, at du senere kan genfinde dit spilresultat i spillet. Du kan til enhver tid slette cookies og historik under Indstillinger på din enhed.'
	},

	/* Select avatar */
	selectAvatarInfo: {
		title: 'Vælg en person',
		text: 'Spillet handler om at få en person i mål med et job eller en uddannelse - uden at gå kold undervejs.',
		audio: '1.1.mp3'
	},

	/* Avatar info */
	avatarInfo: {
		title: null,
		// text: 'Alle personerne i spillet har en score fra 0 til 5 i Samarbejde, Dansk, Læsning, Matematik og Stabilitet. Kig på tallene og læg mærke til personens styrker og svagheder.',
		text: 'Alle personerne i spillet har en score fra 1 til 5 i Samarbejde, Dansk, Læsning, Matematik og Stabilitet.',
		audio: '1.3.mp3'
	},

	/* Goal info */
	goalInfo: {
		title: 'Sæt et mål',
		text: 'Vælg et mål til %name%. Det %pronoun% ikke kan nu, kan %pronoun% sikkert lære.',
		// text: 'Vælg et mål til din person. Husk, at det man ikke kan nu, kan man lære efterhånden.',
		audio: '1.4.mp3'
	},

	/* Challenge avatar stats info */
	challengeAvatarStatInfo: {
		teamwork: [
			{values: [0, 1, 2], text: '%name% starter med %stat% i <span>Samarbejde</span>. %Pronoun% skal blive bedre til at arbejde samme med andre.'},
			{values: [3, 4], text: '%name% starter med %stat% i <span>Samarbejde</span>.'},
			{values: [5], text: '%name% starter med %stat% i <span>Samarbejde</span>. Det er der helt styr på!'}
		],
		danish: [
			{values: [0, 1, 2], text: '%name% starter med %stat% i <span>Dansk</span>. %Pronoun% har brug for at blive bedre til dansk.'},
			{values: [3, 4], text: '%name% starter med %stat% i <span>Dansk</span>.'},
			{values: [5], text: '%name% starter med %stat% i <span>Dansk</span>. Perfekt!'}
		],	
		reading: [
			{values: [0, 1], text: '%name% starter med %stat% i <span>Læsning</span>. %Pronoun% er nok ordblind og har brug for ordblinde-undervisning.'},
			{values: [2], text: '%name% starter med %stat% i <span>Læsning</span>. %Pronoun% har brug for at blive bedre til at læse og skrive.'},
			{values: [3, 4], text: '%name% starter med %stat% i <span>Læsning</span>'},
			{values: [5], text: '%name% starter med %stat% i <span>Læsning</span>. Det bliver ikke bedre!'}
		],
		math: [
			{values: [0, 1, 2], text: '%name% starter med %stat% i <span>Matematik</span>. %Pronoun% har brug for at blive bedre til matematik.'},
			{values: [3,4], text: '%name% starter med %stat% i <span>Matematik</span>.'},
			{values: [5], text: '%name% starter med %stat% i <span>Matematik</span>. Genialt!'}
		],
		workEthic: [
			{values: [0, 1, 2], text: '%name% starter med %stat% i <span>Stabilitet</span>. %Pronoun% er dårlig til at holde aftaler og har brug for mere joberfaring.'},
			{values: [3, 4], text: '%name% starter med %stat% i <span>Stabilitet</span>.'},
			{values: [5], text: '%name% starter med %stat% i <span>Stabilitet</span>. Én man kan regne med!'}
		],
	},

	/* Challenge play */
	noActionsAvailable: {
		title: 'Du er ikke klar',
		text: 'Du skal trække handlinger hen på listen til højre, før du kan starte Jobrejsen.'
	},
	notEnoughEnergy: {
		title: 'Mangler Energi!',
		text: 'Prøv at fjerne eller udskifte handlinger.',
	},
	redActions: {
		title: 'Mangler kompetencer!',
		text: 'Du kan ikke sætte i gang, før alle handlinger er grønne eller gule.'
	},
	notEnoughEnergyAndRedActions: {
		title: 'Du er ikke klar!',
		text: 'Du kan ikke sætte i gang, før alle handlinger er grønne eller gule, og du har nok Energi.'
	},
	redGoal: {
		title: 'Du er ikke klar!',
		text: 'Du kan ikke sætte i gang, før du opfylder kravene.'
	},

	/* Challenge start */
	confirmStartChallenge: {
		title: 'Nu er du klar!',
		text: 'Du opfylder kravene og du har de nødvendige kompetencer. Sæt i gang!'
	},
	confirmStartEasyChallenge: {
		title: 'Måske går det',
		text: 'De gule handlinger kan blive en udfordring. Tag chancen eller prøv at forbedre dine kompetencer.'
	},
	confirmStartHardChallenge: {
		title: 'Det bliver svært',
		text: 'De gule handlinger bliver en udfordring. Tag chancen eller prøv at forbedre dine kompetencer.'
	},

	/* Challenge result */
	challengeFailed: {
		title: 'Desværre',
		text1a: '%name% nåede ikke i mål. %action% var for svær, måske fordi %pronoun% ikke var god nok til %stat%.\nPrøv igen eller afslut.',
		text1b: '%name% nåede ikke i mål. %action% var for svær, måske fordi %pronoun% manglede Stabilitet.\nPrøv igen eller afslut.',
		text2a: '%name% nåede ikke i mål. %action% var for svær, måske fordi %pronoun% ikke var god nok til %stat% og %stat2%.\nPrøv igen eller afslut.',
		text2b: '%name% nåede ikke i mål. %action% var for svær, måske fordi %pronoun% manglede Stabilitet og ikke var god nok til %stat2%.\nPrøv igen eller afslut.',
		text2c: '%name% nåede ikke i mål. %action% var for svær, måske fordi %pronoun% ikke var god nok til %stat% og manglede Stabilitet.\nPrøv igen eller afslut.',
	},
	challengeCompleted: {
		titles: ['Heldet følger de tossede', 'Godt klaret', 'Godt gået'],
		texts: [
			['Du fik %name% i mål, men du tog mange chancer undervejs.', 'Spil igen med en anden person, eller læg din egen plan.'],
			['%name% kom i mål, selvom du chancede den lidt undervejs.', 'Spil igen med en anden person, eller læg din egen plan.'],
			['Du fik %name% i mål med grundig planlægning og hårdt arbejde.', 'Spil igen med en anden person, eller læg din egen plan.']
		],
	},

	/* Profile info */
	startProfileInfo: {
		title: null,
		text: [
			'I denne del skal du vurdere dig selv og vælge de næste skridt på din jobrejse.',
			'Resultatet bliver gemt i spillet, så du senere kan vise det til en jobkonsulent i jobcenter eller a-kasse.'
		],
		audio: '2.1.mp3'
	},

	/* Quiz info */
	startQuizInfo: {
		text: 'Hvor enig er du i spørgsmålene?<br /><br />1 er <strong>helt uenig</strong><br />5 er <strong>helt enig</strong>',
		audio: '2.2.mp3'
	},

	/* Interest */
	startInterestsInfo: {
		texts: [
			'Fedt, du har scoret dig selv!',
			'Nu skal du vælge en interesse.'
		],
		audio: '2.3.mp3'
	},
	selectInterestError: {
		text: 'Du skal skrive dit jobønske, før du kan vælge det'
	},	

	/* Select actions */
	startActions: {
		texts: [
			'Nu skal du vælge 3 handlinger, som du mener kan være første skridt på din egen Jobrejse.',
			'Træk handlingerne over på listen til højre.'
		],
		audio: '2.5.mp3'
	},
	notEnoughActionsSelected: {
		text: 'Du skal vælge tre handlinger før du kan gemme din plan'
	},
	evaluateActions: {
		texts : {
			red: 'Dine ønsker kan være svære for din jobkonsulent at hjælpe dig med. Overvej hvad du gerne vil have ud af jeres samtale.',
			yellow: 'God start! Til næste samtale vil I arbejde med, hvordan du kommer længst med dine mål.',
			green: 'Du har nogle gode overvejelser om hvordan du kommer videre. Brug dem i samtalen med din vejleder eller jobkonsulent.'
		}
	},

	/* Game finished */
	gameResultSaved: {
		texts: [
			'Dit resultat blev gemt.',
			'Du kan finde det igen i spillets hovedmenu.'
		]
	},

	/* Send diploma in email */
	sendEmail: {
		texts: [
			'Send spilresultatet til dig selv. Så har du en kopi!',
			'Skriv din email-adresse:'
		]
	},
	closeSendEmail: {
		text: 'Er du sikker på, du vil forlade siden uden at sende Spilresultatet til dig selv?'
	},
	emailSentSuccessfully: {
		texts: [
			'Spilresultatet blev sendt til din inbox.',
			'Tjek evt. din spammappe, hvis det ikke dukker op.'
		]
	},
	emailInvalid: {
		texts: [
			'Det ser ikke ud til at være en gyldig email-adresse.',
			'Prøv venligst igen.'
		]
	},
	emailFailed: {
		text: 'Noget gik galt. Tjek din email adresse og prøv igen.'
	}
}

export {popupsData};