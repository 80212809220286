import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popup from './popup';

class PopupController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showPopup: false,
			isLoading: false,
			title: false,
			text: false,
			buttons: false,
			audioFile: null,
			overlayAction: null,
			bodyAction: null,
			closesOnOverlayClick: true,
			class: false,
			popupIcons: null,
			closeBtn: false,
			popupEmailInput: null,
			email: ''
		};
		this.openPopup = this.openPopup.bind(this);
		this.closePopup = this.closePopup.bind(this);
		this.handleButtonClick = this.handleButtonClick.bind(this);
		this.handleEmailInput = this.handleEmailInput.bind(this);
	};

	/**
	 * Open popup
	 * @param {string} popupTitle 
	 * @param {string} popupText 
	 * @param {array} popupBtns 
	 * @param {object} popupOverlayAction
	 * @param {object} popupBodyAction
	 * @param {bool} popupClosesOnOverlayClick
	 * @param {string} popupClass 
	 * @param {array} popupIcons 
	 * @param {object} closeBtn
	 * @param {bool} popupEmailInput
	//  * @param {string} popupAudioFile
	 */
	openPopup(popupTitle, popupText, popupBtns, popupOverlayAction = null, 
		popupBodyAction = null, popupClosesOnOverlayClick = true, 
		popupClass = null, popupIcons = null, closeBtn = null, popupEmailInput = null, 
		popupAudioFile = null
	) {
		this.setState({
			showPopup: true, 
			isLoading: false,
			title: popupTitle,
			text: popupText,
			buttons: popupBtns,		
			audioFile: popupAudioFile,
			overlayAction: popupOverlayAction,
			bodyAction: popupBodyAction,
			closesOnOverlayClick: popupClosesOnOverlayClick,
			class: popupClass,
			popupIcons: popupIcons,
			closeBtn: closeBtn,
			popupEmailInput: popupEmailInput,
		});
	}

	/**
	 * Close popup
	 */
	closePopup() {
		this.setState({
			showPopup: false,
			isLoading: false,
			title: false,
			text: false,
			buttons: false,		
			overlayAction: null,
			bodyAction: null,
			closesOnOverlayClick: true,
			class: false,
			popupIcons: null,
			closeBtn: false,
			popupEmailInput: null,
			audioFile: null
		});
	}

	/**
	 * Handle button click
	 * @param {object} button
	 */
	handleButtonClick(button) {
		if (button.showLoadingIcon) this.setState({isLoading: true});
		button.action(...button.actionParams);
	}

	/**
	 * Handle email input change
	 * @param {object} event
	 */
	handleEmailInput(event) {
		let emailValue = event.target.value;
		this.setState({
			email: emailValue,
			feedback: null,
			emailSentFeedback: null,
		});
	}


	/**
	 * Render popup component
	 */
	render() {
		let onlyChild = React.Children.only(this.props.children);
		let childrenCloned = React.cloneElement(onlyChild, {
			openPopup: this.openPopup,
			closePopup: this.closePopup,
			popupIsOpen: this.state.showPopup,
			emailValue: this.state.email
		});

		let filledStars = null;
		if (this.state.popupIcons) {
			filledStars = this.state.popupIcons.filter((icon) => {return icon.name === 'star';}).length;
		}

		return (
			<React.Fragment>
				{this.state.showPopup && 
					<div className={'Popup-overlay Popup-overlay--' + this.props.viewMode} 
						onClick={() => {
							if (this.state.closesOnOverlayClick === true) {
								if (this.state.overlayAction) {
									this.state.overlayAction.action(...this.state.overlayAction.actionParams);
								}
								this.closePopup();
							}
						}}>
						<Popup 
							isIos={this.props.isIos}
							isLoading={this.state.isLoading}
							viewMode={this.props.viewMode}
							title={this.state.title}		
							text={this.state.text}
							buttons={this.state.buttons}
							audioFile={this.state.audioFile}
							bodyAction={this.state.bodyAction}
							className={this.state.class}
							handleButtonClick={this.handleButtonClick}
							closePopup = {this.closePopup}
							popupIcons = {this.state.popupIcons}
							filledStars =  {filledStars}
							closeBtn = {this.state.closeBtn}
							popupEmailInput = {this.state.popupEmailInput}
							handleEmailInput = {this.handleEmailInput}
							emailValue={this.state.email}
						/>
						{filledStars && <div className={'Popup-fireworks Popup-fireworks--' + filledStars}>
							{[...Array(300)].map((_, index) => {
								return <div key={index} className="Popup-fireworksObject" />;
							})}
						</div>}
					</div>
				}
				{childrenCloned}
			</React.Fragment>
		);
	}
}

PopupController.propTypes = {
	viewMode: PropTypes.string.isRequired,
	isIos: PropTypes.bool.isRequired,
	children: PropTypes.any
};

export default PopupController;