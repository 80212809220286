const interestsData = [
	{
		id: 'crafts_construction',
		title: 'Håndværk og byggeri',
		description: 'Det kunne være job som tømrer, murer eller elektriker.'
	},
	{
		id: 'animals_nature_environment',
		title: 'Dyr, natur og miljø',
		description: 'Det kunne være job som gartner, landmand eller dyrlæge.'
	},
	{
		id: 'food_health',
		title: 'Mad og sundhed',
		description: 'Det kunne være job som bager, kok, diætist eller fitnessinstruktør.'
	},
	{
		id: 'it_electronics',
		title: 'IT og elektronik',
		description: 'Det kunne være job med digitale medier, programmering eller webdesign.'
	},
	{
		id: 'engineering_mechanics_machines',
		title: 'Teknik, mekanik og maskiner',
		description: 'Det kunne være job som fx mekaniker, smed eller i industrien.'
	},
	{
		id: 'caregiving',
		title: 'Omsorg og pleje',
		description: 'Det kunne være job på plejehjem, i børnehave eller tandklinik.'
	},
	{
		id: 'office_commerce',
		title: 'Kontor og handel',
		description: 'Det kunne være job inden for økonomi, administration eller salg.'
	},
	{
		id: 'design_media',
		title: 'Design og medier',
		description: 'Det kunne være job som fotograf eller grafisk designer.'
	},
	{
		id: 'transport_logistics',
		title: 'Transport og logistik',
		description: 'Det kunne være job som chauffør, kranfører eller på lager.'
	},
	{
		id: 'user',
		title: 'Udfyld selv',
		description: 'Skriv dit jobønske her:',
		userInput: true
	}
]

export{interestsData};