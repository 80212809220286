import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './select.scss';

class Select extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdownIsOpen: false
		};
		this.toggleDropdown = this.toggleDropdown.bind(this);
	};

	toggleDropdown() {
		let dropdownIsOpen = !this.state.dropdownIsOpen;
		this.setState({dropdownIsOpen: dropdownIsOpen});
	}

	handleSelect(option) {
		this.props.onSelect(option, this.props.type);
		this.toggleDropdown();
	}

	render() {
		let options = this.props.options;
		let selectedId = this.props.selectedId;
		let selectedName = this.props.defaultValue;
		if (options.some((option) => {return option.id === selectedId;})) {
			selectedName = options.filter((option) => {return option.id === selectedId;})[0].name;
		}

		let className = 'Select Select--' + this.props.viewMode;
		if (this.state.dropdownIsOpen) {className += ' open';}
		if (this.props.class) {className += ' ' + this.props.class;}

		return (
			<div className={className}>
				<div className={'Select-panel Select-panel--' + selectedId} onClick={()=>{this.toggleDropdown();}}>
					<div className="Select-selected"><span>{selectedName}</span></div>
					<div className="Select-button" />
				</div>
				<div className="Select-dropdown" aria-hidden={!this.state.dropdownIsOpen}>
					{options.map((option) => {
						let className = 'Select-option';
						if (selectedId === option.id) {className += ' Select-option--selected';}
						return (
							<div key={option.id} className={className} onClick={()=>{this.handleSelect(option);}}>
								<span>{option.name}</span>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}

Select.propTypes = {
	viewMode: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	defaultValue: PropTypes.string.isRequired,
	selectedId: PropTypes.any,
	onSelect: PropTypes.func.isRequired,
	class: PropTypes.string
};

export default Select;
