const avatarUiTexts = {
	chooseAPerson: 'Vælg en person',
	gender: 'Køn',
	age: 'Alder',
	years: 'år',
	location: 'Bopæl',
	education: 'Uddannelse',
	interests: 'Interesser',
	interestArea: 'Interesseområde',
	description: 'Baggrund',
	yourSkills: 'Dine kompetencer',
	neededSkills: 'Nødvendige kompetencer',
	actionRequirements: 'Adgangskrav',
	formalRequirements: 'Formelle krav',
	statsRequirements: 'Forudsætninger',
	avatarStats: 'Dette er %name% score på en skala fra 1-5',
	avatarIntro: 'Dette er %name% score på en skala fra 1-5. Kig på tallene og læg mærke til %possessive% styrker og svagheder.',
	profileIntro: 'Dette er din score på en skala fra 1-5.',
};

const cookieUiTexts = {
	text: 'Spillet bruger cookies til at gemme dit spilresultat. Ved at trykke \'Forstået\' accepterer du spillets brug af cookies.',
	readMore: 'Læs mere.',
	okBtn: 'Forstået'
};

const diplomaUiTexts = {
	title: 'Spilresultat',
	title2: 'Din plan',
	years: 'år',
	interest: 'Interesseområde:',
	score: 'Din score',
	nextStep: 'Dit næste skridt:',
	message1: 'Diplomet sendes til:',
	message2: 'Ingen email tilføjet.',
	educationLevel: 'Uddannelsesniveau:',
	experience: 'År med fast arbejde:',
	answers: 'Dine svar:',
	emailBtn: 'Send til e-mail',
	print: 'Print',
	email2: 'Send',
	exit: 'Afslut'
};

const generalUiTexts = {
	title: 'Job i Spil',
	choose: 'Vælg',
	readMore: 'Læs mere',
	start: 'Start',
	startGame: 'Start spillet',
	goal: 'Mål',
	buy: 'Køb',
	ok: 'Ok',
	flip: 'Vend',
	back: 'Tilbage',
	next: 'Næste',
	try: 'Prøv',
	tryAgain: 'Prøv igen',
	playAgain: 'Spil igen',
	plan: 'Plan',
	cancel: 'Afbryd',
	regret: 'Fortryd',
	close: 'Luk',
	save: 'Gem',
	send: 'Send',
	requirements: 'Krav',
	requires: 'Kræver',
	rewards: 'Gevinst',
	finish: 'Afslut',
	costs: 'Koster',
	energy: 'Energi',
	part1: 'Del 1',
	part2: 'Del 2',
	result: 'Resultat',
	help: 'Hjælp',
	tipsTitle: 'Sådan gør du'
};

const homeUiTexts = {
	result: 'Spilresultat',
	part1: 'Jobspil',
	part2: 'Din plan',
	firstPartDoneTitle: 'Tillykke!',
	firstPartDoneText: 'Du har gennemført spillet og er klar til at lægge din egen plan.',
	resultBtnInfoText: 'Når du har gennemført Din Plan, vil du kunne finde dit spilresultat her.'
};

const profileUiTexts = {
	start: 'Start',
	nameAndAgeLabel: 'Navn og alder:',
	educationLabel: 'Uddannelse:',
	experienceLabel: 'År med fast arbejde:',
	namePlaceholder: 'Navn',
	agePlaceholder: 'Alder',
	educationOptions: [
		{
			id: 'none',
			name: 'Ingen'
		},
		{
			id: '9th-grade',
			name: '9./10. klasse'
		},
		{
			id: 'fgu',
			name: 'FGU'
		},
		{
			id: 'academy',
			name: 'Akademiuddannelse'
		},
		{
			id: 'professional',
			name: 'Erhvervsuddannelse'
		},
		{
			id: 'highschool',
			name: 'Gymnasial uddannelse'
		},
		{
			id: 'bachelor',
			name: 'Bachelor-/diplomuddannelse'
		},
		{
			id: 'master',
			name: 'Kandidatuddannelse'
		}
	],
	experienceOptions: [
		{
			id: '0',
			name: '0-2'
		},
		{
			id: '1',
			name: '3-5'
		},
		{
			id: '2',
			name: '6-8'
		},
		{
			id: '3',
			name: '9-11'
		},
		{
			id: '4',
			name: '11+'
		}
	],
	inputErrors: {
		invalidName: 'Ugyldigt input.',
		invalidAge: 'Din alder skal være mellem 18 og 67.',
		missingFields: 'Du skal udfylde alle felter.'
	}
};

const pwaInfoUiTexts = {
	ios: 'Gem Job i Spil på din telefon:<br />Tryk på %icon% og derefter<br/> <span>\'Føj til hjemmeskærm\'</span>',
	android: 'Gem Job i Spil på din telefon:<br />Tryk på %icon% og derefter<br/> <span>\'Føj til hjemmeskærm\'</span>',
	crios: 'Spiller du på en iPhone eller iPad, får du den bedste oplevelse i Safari-browseren.'
};

const quizUiTexts = {
	title: 'Hvor enig er du i spørgsmålet?'
};

const startUiTexts = {
	welcome: 'Velkommen til Job i Spil.<br />Et værktøj udviklet til vejledning om uddannelse og job.'
};

export{
	avatarUiTexts,
	cookieUiTexts,
	diplomaUiTexts,
	generalUiTexts,
	homeUiTexts,
	profileUiTexts,
	pwaInfoUiTexts,
	quizUiTexts,
	startUiTexts
};