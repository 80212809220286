import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactAudioPlayer from 'react-audio-player';
import './audio.scss';

class Audio extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			reLoad: false
		};
		this.metaDataDidLoad = this.metaDataDidLoad.bind(this);
		this.audioEnded = this.audioEnded.bind(this);
		this.playPauseAudio = this.playPauseAudio.bind(this);
	}

	/**
	 * Component mounted
	 * Activate on meta data loaded
	 */
	componentDidMount() {
		let player = document.getElementById('audio-player');
		if (player) {
			player.addEventListener('loadedmetadata', this.metaDataDidLoad, false);	
			player.addEventListener('ended', this.audioEnded, false);	
		}
	}

	/**
	 * Component will unmount
	 * Pause audio
	 */
	componentWillUnmount() {
		let player = document.getElementById('audio-player');
		if (player) {
			player.pause();
			player.removeEventListener('loadedmetadata', this.metaDataDidLoad, false);
			player.removeEventListener('ended', this.audioEnded, false);
		}
	}

	/**
	 * Component will update
	 * Pause audio if new audio src
	 * @param {object} nextProps 
	 */
	componentWillUpdate(nextProps) {
		if (nextProps.audioFile !== this.props.audioFile) {
			let player = document.getElementById('audio-player');
			if (player) player.pause();
		}
	}

	/**
	 * Metadata did load, enable player
	 */
	metaDataDidLoad() {
		this.setState({isLoading: false});
	}

	/**
	 * Audio ended, reload if iOS
	 */
	audioEnded() {
		var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		if (this.props.isIos || isSafari) {
			let player = document.getElementById('audio-player');
			player.removeEventListener('loadedmetadata', this.metaDataDidLoad, false);
			player.removeEventListener('ended', this.audioEnded, false);
			this.setState({reLoad: true, isLoading: true}, () => {
				this.setState({reLoad: false}, () => {
					let player = document.getElementById('audio-player');
					if (player) {
						player.addEventListener('loadedmetadata', this.metaDataDidLoad, false);	
						player.addEventListener('ended', this.audioEnded, false);	
					}
				});
			});
		} else {
			let player = document.getElementById('audio-player');
			if (player) player.currentTime = 0;
		}
	}

	/**
	 * Play / pause audio
	 * Reset track if played to the end
	 */
	playPauseAudio()  {
		let player = document.getElementById('audio-player');
		if (player && player.src) {
			if (player.paused || player.ended) {
				player.play();
			} else {
				player.pause();
			}
		}
	}

	/**
	 * Render component
	 */
	render() {
		if (!this.props.audioFile || this.state.reLoad) return null;
		let audioUrl = require('../../assets/audio/' + this.props.audioFile);
		return (
			<div className={'Audio' + (this.props.align ? ' Audio--' + this.props.align : '') 
				+ ' Audio--' + this.props.viewMode}>
				<ReactAudioPlayer
					id="audio-player"
					src={audioUrl}
					preload="auto"
					onLoadedMetadata={this.metaDataDidLoad}
				/>
				<div 
					onClick={(e) => {e.stopPropagation();; this.playPauseAudio();}} 
					className={'Audio-playPauseBtn' + (this.state.isLoading ? ' Audio-playPauseBtn--loading' : '')}
				/>
			</div>
		);
	}
}

Audio.defaultProps = {
	viewMode: 'portrait'
};

Audio.propTypes = {
	isIos: PropTypes.bool.isRequired,
	audioFile: PropTypes.string,
	align: PropTypes.string,
	viewMode: PropTypes.string
};

export default Audio;