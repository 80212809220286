import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts} from 'data/ui-texts';
import {popupsData} from 'data/popups-data';
import AvatarInfo from './avatar-info';


class AvatarInfoController extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.openIntroPopup = this.openIntroPopup.bind(this);
		this.closeIntroPopup = this.closeIntroPopup.bind(this);
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		/* Open intro popup */
		// if (this.props.introPopupStatuses && this.props.introPopupStatuses.avatarInfoIntroSeen !== true) {
		// 	this.openIntroPopup();
		// }
	}

	/**
	 * Open intro popup
	 */
	openIntroPopup() {
		let popupData = JSON.parse(JSON.stringify(popupsData.avatarInfo));
		let popupBtns = [{text: generalUiTexts.ok, action: this.closeIntroPopup, actionParams: []}];
		this.props.openPopup(
			null, popupData.text, popupBtns, null, 
			{action: this.closeIntroPopup, actionParams: []}, false, 
			'introPopup', null, null, null, popupData.audio
		);
	}

	/**
	 * Close intro popup
	 */
	closeIntroPopup() {
		this.props.closePopup();
		// if (
		// 	this.props.introPopupStatuses && 
		// 	this.props.introPopupStatuses.avatarInfoIntroSeen !== true && this.props.updateIntroPopupStatus
		// ) {
		// 	this.props.updateIntroPopupStatus('avatarInfoIntroSeen', true);
		// }
	}

	render() {
		return (
			<AvatarInfo 
				page={this.props.page}
				avatar={this.props.avatar}
				goToStep={this.props.goToStep}
				toggleAvatarInfo={this.props.toggleAvatarInfo}
				openIntroPopup={this.openIntroPopup}
			/>
		);
	}
};

AvatarInfoController.propTypes = {
	introPopupStatuses: PropTypes.object,
	page: PropTypes.string,
	avatar: PropTypes.object.isRequired,
	updateIntroPopupStatus: PropTypes.func,
	goToStep: PropTypes.func,
	toggleAvatarInfo: PropTypes.func,
	openPopup: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired
};

export default AvatarInfoController;