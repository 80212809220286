import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {tutorialData} from 'data/tutorial-data';
import Tutorial from './tutorial';

class TutorialController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			prevStep: null,
			stepIndex: null,
			steps: null,
			animation: null,
			showX: false
		};
		this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
		this.showNextTutorialStep = this.showNextTutorialStep.bind(this);
		this.handleCloseTutorial = this.handleCloseTutorial.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		/* Tutorial is opened */
		if (!this.props.step && nextProps.step) {
			if (nextProps.step && tutorialData.hasOwnProperty(nextProps.step)) {
				let steps = (tutorialData[nextProps.step].hasOwnProperty('steps') 
					? JSON.parse(JSON.stringify(tutorialData[nextProps.step].steps))
					: JSON.parse(JSON.stringify([nextProps.step]))
				);
				// this.props.updateTutorialStatus(steps[0], true);
				let showX = (nextProps.step === 'tutorial');
				this.setState({preStep: null, stepIndex: 0, steps: steps, animation: 'open', showX: showX});
			}
		}

		/* Tutorial is closed */
		if (this.props.step && !nextProps.step) {
			let preStep = this.state.steps[this.state.stepIndex];
			this.setState({preStep: preStep, stepIndex: null, steps: null, animation: 'close', showX: false});
		}
	}

	showNextTutorialStep() {
		this.props.updateTutorialStatus(this.state.steps[this.state.stepIndex], true);
		let stepIndex = this.state.stepIndex + 1;
		this.setState({stepIndex: stepIndex, animation: null});
	}

	handleCloseTutorial() {
		if (this.props.step === 'part1' || this.props.step === 'part2') {
			this.props.updateTutorialStatus(this.props.step + 'Seen', true);
		}
		this.props.showTutorialStep(null);
	}

	render() {
		let step = null, linkToNextStep = null, stepNumber = null, numberOfSteps = null;
		if (this.state.steps && tutorialData.hasOwnProperty(this.state.steps[this.state.stepIndex])) {
			step = this.state.steps[this.state.stepIndex];
			linkToNextStep = (this.state.stepIndex < (this.state.steps.length - 1));
			stepNumber = this.state.stepIndex + 1;
			numberOfSteps = this.state.steps.length;
		}

		return (
			<Tutorial 
				isIos={this.props.isIos}
				avatar={this.props.avatar}
				animation={this.state.animation}
				preStep={this.state.preStep}
				step={step} 
				numberOfSelected={this.props.numberOfSelected}
				stepNumber={stepNumber}
				numberOfSteps={numberOfSteps}
				showX={this.state.showX}
				handleGoToNextStep={linkToNextStep ? this.showNextTutorialStep : null}
				handleCloseTutorial={this.handleCloseTutorial}
			/>
		);
	}
}

TutorialController.defaultProps = {
	numberOfSelected: 0
};

TutorialController.propTypes = {
	isIos: PropTypes.bool.isRequired,
	avatar: PropTypes.object,
	step: PropTypes.string,
	numberOfSelected: PropTypes.number,
	showTutorialStep: PropTypes.func.isRequired,
	updateTutorialStatus: PropTypes.func.isRequired
};


export default TutorialController;