import React from 'react';
import PropTypes from 'prop-types';
import './backup.scss';

const Backup = (props) => {	
	let {
		db,
		goToPage,
		isDownloading,
		isUploading,
		downloadGoalsData,
		selectFileToUpload,
		uploadFileToDatabase,
		feedbackMsg
	} = props;
	return (
		<div className="BackupAdmin">
			<p 
				className="BackupAdmin-backBtn" 
				onClick={() => {goToPage(null);}}
			>&lArr; tilbage</p>

			<div className="BackupAdmin-section">
				<p>You are using the <b>{db}</b> database.</p>
				<p>To upload to/download from another database, please navigate to that project's URL 
					and login to the admin panel from there (cgl-vuc-test, cgl-vuc-demo, etc).</p>
			</div>

			<div className="BackupAdmin-section">
				<div className="BackupAdmin-title">Download</div>
				<p
					className={'BackupAdmin-action' + (isDownloading === 'goalsData' ? ' loading' : '')} 
					onClick={() => {downloadGoalsData();}}
				>Download goals data (file: 'goalsData_db-name.json')</p>
				<p>&nbsp;</p>
			</div>

			<div className="BackupAdmin-section">
				<div className="BackupAdmin-title">Upload</div>
				<form>
					<input
						type="file"
						accept="application/json"
						name="json"
						className="BackupAdmin-selectFile"
						onChange={(event)=>{selectFileToUpload(event);}}
					/>
					<div
						className={'BackupAdmin-uploadFile' + (isUploading ? ' loading' : '')}
						onClick={()=>{uploadFileToDatabase();}}
					>Upload fil</div>
				</form>
			</div>
			
			<p className="BackupAdmin-error">{feedbackMsg}</p>
		</div>
	);
};

Backup.propTypes = {
	db: PropTypes.string.isRequired,
	goToPage: PropTypes.func.isRequired,
	isDownloading: PropTypes.string,
	isUploading: PropTypes.bool.isRequired,
	downloadGoalsData: PropTypes.func.isRequired,
	selectFileToUpload: PropTypes.func.isRequired,
	uploadFileToDatabase: PropTypes.func.isRequired,
	feedbackMsg: PropTypes.string
};

export default Backup;