import React from 'react';
import PropTypes from 'prop-types';
import {statsData} from 'data/stats-data';
import {generalUiTexts} from 'data/ui-texts';
import Button from 'components/button/button';
import './quiz-overview.scss';

const QuizOverView = ({
	viewMode,
	currentStatIndex,
	currentQuestionIndex,
	nextStat,
	previousQuestion,
	stats,
}) => {

	return (
		<div className={'QuizOverview QuizOverview--' + viewMode}>
			<div className="QuizOverview-card">
				{Object.keys(stats).map((key, index) => {
					let stat = statsData.find((stat) => {return stat.id === key;});
					let iconClassName = 'QuizOverview-icon QuizOverview-icon--' + stat.id;
					if (currentStatIndex >= index) {
						iconClassName += ' ' + (stats[key] < 3 ? 'red' : 'green');
					}
					return (
						<div className="QuizOverview-category" key={index}>
							<div className={iconClassName}></div>
							<div className="QuizOverview-name">{stat.name} </div>
							<div className="QuizOverview-stat" aria-hidden={true}>{stats[key]}</div>
							{(currentStatIndex >= index) && 
									<div className={'QuizOverview-checkmark' + 
										(currentStatIndex === index ? ' animate' : '')} />
							}
						</div>
					);
				})}
			</div>

			<div className="Quiz-buttons">
				<Button
					viewMode={viewMode}
					class={'prev'}
					text={generalUiTexts.back}
					onClick={()=> {previousQuestion(currentStatIndex, currentQuestionIndex);}}/>
				<Button
					viewMode={viewMode}
					class={'next'}
					text={generalUiTexts.next}
					onClick={()=> {nextStat(currentStatIndex, currentQuestionIndex);}}/>
			</div>
		</div>
	);
};

QuizOverView.propTypes = {
	viewMode: PropTypes.string.isRequired,
	currentStatIndex: PropTypes.number.isRequired,
	currentQuestionIndex: PropTypes.number.isRequired,
	nextStat: PropTypes.func.isRequired,
	previousQuestion: PropTypes.func.isRequired,
	stats: PropTypes.object.isRequired
};

export default QuizOverView;