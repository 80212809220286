import {sortArrayByProperty} from 'helpers/array-helper';

/* Reorder in "selected" container */
function moveAction(initialActions, placeHoldersList, fromIndex, toIndex) {
	/* Reorder actions */
	let newPlaceholdersList = JSON.parse(JSON.stringify(placeHoldersList));
	let newSelectedList = [];
	let arrayIndex1 = newPlaceholdersList.findIndex((action) => {return action.index === fromIndex;});
	let arrayIndex2 = newPlaceholdersList.findIndex((action) => {return action.index === toIndex;});
	if (arrayIndex1 >= 0 && arrayIndex2 >= 0) {
		newPlaceholdersList[arrayIndex1].index = toIndex;
		newPlaceholdersList[arrayIndex2].index = fromIndex;
	}

	/* Update selected list */
	newPlaceholdersList.forEach((action) => {
		if (action.id !== null) {
			if (initialActions.some((action2) => {return action2.id === action.id;})) {
				let actionObj = initialActions.filter((action2) => {return action2.id === action.id;})[0];
				actionObj.index = action.index;
				newSelectedList.push(actionObj);
			}
		}
	});	

	return [newSelectedList, newPlaceholdersList];
};

/* Move between actions / selected */
function moveAction2(id, initialActions, actionsList, placeHoldersList) {
	let newActionsList = JSON.parse(JSON.stringify(actionsList));
	let newPlaceholdersList = JSON.parse(JSON.stringify(placeHoldersList));

	let fromContainerId = findAction(id, actionsList, placeHoldersList)[2];
	if (fromContainerId) {
		/* Move from actions to selected */
		if (fromContainerId === 'actions') {
			/* Flag as selected in available actions */
			let fromIndex = newActionsList.findIndex((action) => {return action.id === id;});
			newActionsList[fromIndex].isSelected = true;

			/* Put into top empty placeholder */
			let sortedPlaceholders = 
				sortArrayByProperty(JSON.parse(JSON.stringify(newPlaceholdersList)), 'index', 'ASC');
			let emptyPlaceholder = sortedPlaceholders.filter((action) => {return action.id === null;})[0];
			let toIndex = newPlaceholdersList.findIndex((action) => {return action.index === emptyPlaceholder.index;});
			newPlaceholdersList[toIndex].id = id;

		/* Move from selected to actions */
		} else {
			/* Remove from placeholder actions */
			let fromIndex = newPlaceholdersList.findIndex((action) => {return action.id === id;});
			newPlaceholdersList[fromIndex].id = null;

			/* Flag as not selected in available actions */
			let toIndex = newActionsList.findIndex((action) => {return action.id === id;});
			newActionsList[toIndex].isSelected = false;
		}
	}

	/* Update selected list */
	let newSelectedList = [];
	newPlaceholdersList.forEach((action) => {
		if (action.id !== null) {
			if (initialActions.some((action2) => {return action2.id === action.id;})) {
				let actionObj = initialActions.filter((action2) => {return action2.id === action.id;})[0];
				actionObj.index = action.index;
				newSelectedList.push(actionObj);
			}
		}
	});	

	return [newActionsList, newSelectedList, newPlaceholdersList];
}

/* Move between selected or from actions to selected (part 2) */
function moveAction3(initialActions, actionsList, placeHoldersList, id, fromIndex, toIndex) {
	let newActionsList = JSON.parse(JSON.stringify(actionsList));
	let newPlaceholdersList = JSON.parse(JSON.stringify(placeHoldersList));

	let fromContainerId = findAction(id, actionsList, placeHoldersList)[2];
	
	/* From actions to selected */
	if (fromContainerId === 'actions') {
		let fromArrayIndex = newActionsList.findIndex((action) => {return action.index === fromIndex;});		
		let toArrayIndex = newPlaceholdersList.findIndex((action) => {return action.index === toIndex;});
		newActionsList[fromArrayIndex].isSelected = true;
		
		if (newPlaceholdersList[toArrayIndex].id !== null) {
			newActionsList.forEach((action) => {
				if (action.id === newPlaceholdersList[toArrayIndex].id) action.isSelected = false;
			});
		}
		newPlaceholdersList[toArrayIndex].id = id;
	} else {
		/* From selected to selected */
		let arrayIndex1 = newPlaceholdersList.findIndex((action) => {return action.index === fromIndex;});
		let arrayIndex2 = newPlaceholdersList.findIndex((action) => {return action.index === toIndex;});
		if (arrayIndex1 >= 0 && arrayIndex2 >= 0) {
			newPlaceholdersList[arrayIndex1].index = toIndex;
			newPlaceholdersList[arrayIndex2].index = fromIndex;
		}
	}

	/* Update selected list */
	let newSelectedList = [];
	newPlaceholdersList.forEach((action) => {
		if (action.id !== null) {
			if (initialActions.some((action2) => {return action2.id === action.id;})) {
				let actionObj = initialActions.filter((action2) => {return action2.id === action.id;})[0];
				actionObj.index = action.index;
				newSelectedList.push(actionObj);
			}
		}
	});	

	return [newActionsList, newSelectedList, newPlaceholdersList];
};

/**
 * Find an action in one of the two lists
 * @param {string} id 
 * @param {array} actionsList 
 * @param {array} selectedList 
 */
function findAction(id, actionsList, selectedList)  {
	if (actionsList.some((action) => {
		return (
			action.id === id && 
			(!action.hasOwnProperty('isSelected') || action.isSelected === false)
		);
	})) {
		let action = actionsList.filter((action) => {return action.id === id;})[0];	
		return [action.index, actionsList.indexOf(action), 'actions'];
	}

	if (selectedList.some((action) => {return action.id === id;})) {
		let action = selectedList.filter((action) => {return action.id === id;})[0];	
		return [action.index, selectedList.indexOf(action), 'selected'];
	}
	
	return [null, null, 'selected'];
};

/**
 * Get the position of the draglayer 
 * @param {object} initialOffset 
 * @param {*object currentOffset 
 */
function getItemStyles(initialOffset, currentOffset) {
	if (!initialOffset || !currentOffset) {
		return {
			display: 'none',
		};
	}
	let { x, y } = currentOffset;
	const transform = `translate(${x}px, ${y}px)`;
	return {
		transform,
		WebkitTransform: transform,
	};
}


export {moveAction, moveAction2, moveAction3, findAction, getItemStyles};