import React from 'react';
import PropTypes from 'prop-types';
import {tutorialData} from 'data/tutorial-data';
import Audio from 'components/audio/audio';
import Button from 'components/button/button';
import './tutorial.scss';

const Tutorial = (props) => {
	let {
		isIos,
		avatar,
		animation,
		preStep,
		step, 
		numberOfSelected,
		// stepNumber,
		// numberOfSteps,
		showX,
		handleGoToNextStep, 
		handleCloseTutorial
	} = props;


	let text = null;
	let btnText = null;
	let audioFile = null;

	if (step) {
		if (tutorialData[step].text) {
			text = tutorialData[step].text;
			if (avatar && avatar.name) {
				let namePossessive = avatar.name.substr(-1, 1) === 's' ? avatar.name + '\'' : avatar.name + 's';
				text = text.replace(/%name%/g, namePossessive);
			}
		}
		if (tutorialData[step].btnText) {btnText = tutorialData[step].btnText;}
		if (tutorialData[step].audio) {audioFile = tutorialData[step].audio;}
	}

	return (
		<div 
			className={'Tutorial' + (step ? ' Tutorial--' + step + ' Tutorial--open' : '')}
			onClick={() => {handleGoToNextStep ? handleGoToNextStep() : handleCloseTutorial();}}
		>
			<div className={'Tutorial-body' 
				+ (step ? ' Tutorial-body--' + step : '') 
				+ (preStep ? ' Tutorial-body--' + preStep : '') 
				+ (animation ? ' Tutorial-body--' + animation : '')
				+ ((step === 'actionColors' || step === 'actionOrder' || 
					step === 'actionOrder2') && numberOfSelected < 1 ? ' Tutorial-body--center' : '')
			}>
				{showX && 
					<div className="Tutorial-close" onClick={(e) => {e.stopPropagation(); handleCloseTutorial();}} />}
				{audioFile && <Audio isIos={isIos} audioFile={audioFile} align="left" />}
				{text && <div className="Tutorial-text" dangerouslySetInnerHTML={{__html: text}} />}
				{btnText && <div className="Tutorial-nextBtn">
					<Button 
						class="introPopup" 
						text={btnText} 
						onClick={handleGoToNextStep ? handleGoToNextStep : handleCloseTutorial}
					/>
				</div>}
				{/* {numberOfSteps > 1 && 
						<div className="Tutorial-page">{stepNumber}/{numberOfSteps}</div>} */}					
			</div>
		</div>
	);
};

Tutorial.propTypes = {
	isIos: PropTypes.bool.isRequired,
	avatar: PropTypes.object,
	animation: PropTypes.string,
	preStep: PropTypes.string,
	step: PropTypes.string,
	numberOfSelected: PropTypes.number,
	// stepNumber: PropTypes.number,
	// numberOfSteps: PropTypes.number,
	showX: PropTypes.bool.isRequired,
	handleGoToNextStep: PropTypes.func,
	handleCloseTutorial: PropTypes.func
};

export default Tutorial;