const tutorialData = {
	intro: {
		text: 'Nu skal du bygge personens jobrejse. Først får du lige en guidet tur.',
		// text: 'Nu skal du bygge %name% jobrejse. Først får du lige en guidet tur.',
		btnText: 'Ok',
		audio: '1.5.mp3'
	},
	goal: {
		text: 'Tryk på målet med flaget for at se de formelle krav.',
		btnText: 'Ok',
		audio: '1.6.mp3'
	},
	actionDrag: {
		text: 'Træk handlinger over på tidslinjen til højre. <b>Husk at bygge nedefra!</b>',
		btnText: 'Ok',
		audio: '1.7.mp3'
	},
	actionInfo: {
		text: 'Tryk på en handling for at se hvad den kræver og giver.',
		btnText: 'Ok',
		audio: '1.8.mp3'
	},
	actionEnergy: {
		text: 'Handlinger koster Energi.\nGår du i minus, må du fjerne eller udskifte handlinger.',
		btnText: 'Ok',
		audio: '1.9.mp3'
	},
	actionColors: {
		text: '<span class="red">Rød</span>: Kravene er ikke opfyldt.<br /><span class="yellow">Gul</span>: De fleste krav er opfyldt.<br /><span class="green">Grøn</span>: Klar til start.',
		btnText: 'Ok',
		audio: '1.10.mp3'
	},
	actionOrder: {
		text: 'Du kan flytte handlinger op og ned på tidslinjen. Rækkefølgen kan være afgørende for, om handlingerne kan gennemføres.',
		btnText: 'Ok',
		audio: '1.11.mp3'
	},
	actionOrder2: {
		text: 'Det du skal starte med skal ligge nederst. Herefter bygger du rejsen ved at lægge de næste handlinger oven på den forrige.',
		btnText: 'Ok',
		audio: '1.12.mp3'
	},
	part1: {
		steps: ['intro', 'goal', 'actionDrag', 'actionInfo', 'actionEnergy']		
	},
	part2: {
		steps: ['actionColors', 'actionOrder']
	},
	tutorial: {
		steps: ['goal', 'actionDrag', 'actionInfo', 'actionEnergy', 'actionColors', 'actionOrder', 'actionOrder2'],
		landscapeSteps: ['intro', 'actionDrag', 'actionInfo', 'actionEnergy', 'actionColors', 'actionOrder', 'actionOrder2']
	},
};


export{tutorialData};