import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {popupsData} from 'data/popups-data';
import {profileUiTexts} from 'data/ui-texts';
import Profile from './profile';

class ProfileController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: null,
			age: null,
			feedback: '',
			selectedEducation: null,
			selectedExperience: null,
			tipIndex: 0,
		};
		this.openIntroPopup = this.openIntroPopup.bind(this);
		this.closeIntroPopup = this.closeIntroPopup.bind(this);
		this.showTipIndex = this.showTipIndex.bind(this);
		this.useCachedProfile = this.useCachedProfile.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.handleSaveProfile = this.handleSaveProfile.bind(this);
	}

	componentDidMount() {
		/* Scroll to top */
		window.scrollTo(0, 0);

		if (this.props.prevStepId === 'quiz') {
			/* User clicked back btn on next page, load profile from game state */
			if (this.props.profile.name
				&& this.props.profile.age
				&& this.props.profile.education
				&& this.props.profile.experience) {
				this.setState({
					name: this.props.profile.name,
					age: this.props.profile.age,
					selectedEducation: this.props.profile.education,
					selectedExperience: this.props.profile.experience
				});
			}
		} else {
			if (this.props.cachedProfile) {
				/* Part 2 already completed: Load profile from cache */
				this.useCachedProfile(this.props.cachedProfile);
			} else {
				/* Open intro popup */
				if (this.props.introPopupStatuses.profileIntroSeen !== true && this.props.viewMode === 'portrait') {
					this.openIntroPopup();
				}
			}
		}
	}

	/**
	 * Open intro popup
	 */
	openIntroPopup() {
		let popupData = JSON.parse(JSON.stringify(popupsData.startProfileInfo));
		this.props.openPopup(
			popupData.title, popupData.text,
			[{text: profileUiTexts.start, action: this.closeIntroPopup, actionParams: []}], null, 
			null, false, 'introPopup', null, null, null, popupData.audio
		);
	}

	/**
	 * Close intro popup
	 */
	closeIntroPopup() {
		this.props.closePopup();
		if (this.props.introPopupStatuses.profileIntroSeen !== true) {
			this.props.updateIntroPopupStatus('profileIntroSeen', true);
		}
	}

	showTipIndex(tipIndex) {
		this.setState({tipIndex: tipIndex});
	}

	/**
	 * Populate profile input fields with cached profile data
	 * @param {object} cachedProfile 
	 */
	useCachedProfile(cachedProfile) {
		let name = (cachedProfile.name ? cachedProfile.name : this.state.name);
		let age = (cachedProfile.age ? cachedProfile.age : this.state.age);
		let education = (cachedProfile.education ? cachedProfile.education : this.state.selectedEducation);
		let experience = (cachedProfile.experience ? cachedProfile.experience : this.state.selectedExperience);
		this.setState({name: name, age: age, selectedEducation: education, selectedExperience: experience});
	}

	/**
	 * Update input field
	 * @param {obj} event
	 */
	handleInput(event) {
		let value = event.target.value;
		let name = event.target.name;
		if (value.length <= 30) {
			this.setState({
				[name]: value,
				feedback: ''
			});
		}
	}

	/**
	 * Update select field
	 * @param {string} option 
	 * @param {string} selectType 
	 */
	handleSelect(option, selectType) {
		if (selectType === 'education-select') {
			this.setState({ selectedEducation: option });
		} else {
			this.setState({ selectedExperience: option });
		}
	}

	/**
	 * Save profile
	 */
	handleSaveProfile() {
		let name = this.state.name;
		let age = this.state.age;
		let education = this.state.selectedEducation;
		let experience = this.state.selectedExperience;
		if (name && age && education && experience) {
			if (age >= 18 && age <= 67) {
				if (/^[a-zA-ZæøåÆØÅ -'´-]{2,30}$/.test(name)) {
					this.props.saveProfile(name, age, education, experience);
				} else {
					this.setState({
						feedback: profileUiTexts.inputErrors.invalidName
					});
				}
			} else {
				this.setState({
					feedback: profileUiTexts.inputErrors.invalidAge
				});
			}
		} else {
			this.setState({
				feedback: profileUiTexts.inputErrors.missingFields
			});
		}
	}
	
	/**
	 * Render component
	 */
	render() {
		return (
			<Profile
				popupIsOpen={this.props.popupIsOpen}
				viewMode={this.props.viewMode}
				tipIndex={this.state.tipIndex}
				handleGoToStep={this.handleGoToStep}
				handleInput={this.handleInput}
				name={this.state.name}
				age={this.state.age}
				feedback={this.state.feedback}
				tipsText={popupsData.startProfileInfo.text}
				handleSelect={this.handleSelect}
				education={this.state.selectedEducation}
				experience={this.state.selectedExperience}
				profile={this.props.profile}
				handleSaveProfile={this.handleSaveProfile}
				goToStep={this.props.goToStep}
				openIntroPopup={this.openIntroPopup}
				showTipIndex={this.showTipIndex}
				
			/>
		);
	}
}

ProfileController.defaultProps = {
	cachedProfile: null,
	prevStepId: null
};

ProfileController.propTypes = {
	popupIsOpen: PropTypes.bool.isRequired,
	viewMode: PropTypes.string.isRequired,
	introPopupStatuses: PropTypes.object.isRequired,
	prevStepId: PropTypes.string,
	goToStep: PropTypes.func.isRequired,
	updateIntroPopupStatus: PropTypes.func.isRequired,
	openPopup: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
	saveProfile: PropTypes.func.isRequired,
	profile: PropTypes.object.isRequired,
	cachedProfile: PropTypes.object
};


export default ProfileController;