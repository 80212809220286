import React from 'react';
import PropTypes from 'prop-types';
import {quizData} from 'data/quiz-data';
import {generalUiTexts} from 'data/ui-texts';
import Button from 'components/button/button';
import './quiz-questions.scss';

const QuizQuestions = ({
	viewMode,
	currentStatIndex,
	currentQuestionIndex,
	saveAnswer,
	nextQuestion,
	previousQuestion,
	answers,
	animation
}) => {
	let quizStat = quizData[currentStatIndex];
	let question = quizStat.questions[currentQuestionIndex];
	let currentAnswer = answers.find((answer) => {
		return answer.statIndex === currentStatIndex && answer.questionIndex === currentQuestionIndex;
	});
	let cardClassName = '';
	if (animation) cardClassName = animation;

	return (
		<div className={'QuizQuestions QuizQuestions--' + viewMode}>
			<div className={'Quiz-card ' + cardClassName}>
				<div className="Quiz-category">
					<div className={'Quiz-category-icon Quiz-category-icon--' + quizStat.id}></div>
					<div className="Quiz-category-name">{quizStat.name}</div>
					<div className="Quiz-category-index" aria-hidden={true}>
						{currentQuestionIndex + 1}/{quizStat.questions.length}
					</div>
				</div>
				<div className="Quiz-question">{question}</div>
				<div className="Quiz-answers">
					{[1, 2, 3, 4, 5].map((answer) => {
						return (
							<div
								key={answer}
								aria-hidden={true}
								className={'Quiz-answer' + (currentAnswer 
										&& answer <= currentAnswer.answer ? ' filled' : '')}
								onClick={() => {saveAnswer(answer);}}>
								{answer}
							</div>
						);
					})}
				</div>
			</div>
			<div className="Quiz-buttons">
				<Button
					viewMode={viewMode}
					class={'prev'}
					text={generalUiTexts.back}
					onClick={()=> {previousQuestion(currentStatIndex, currentQuestionIndex);}}/>
				<Button
					viewMode={viewMode}
					class={'next'}
					disabled={!currentAnswer || currentAnswer.answer === 0}
					text={generalUiTexts.next}
					onClick={()=> {
						if (currentAnswer && currentAnswer.answer) {
							nextQuestion(currentStatIndex, currentQuestionIndex);
						} else {
							saveAnswer(0, true);
						}
					}}/>
			</div>
		</div>
	);
};

QuizQuestions.propTypes = {
	viewMode: PropTypes.string.isRequired,
	currentStatIndex: PropTypes.number.isRequired,
	currentQuestionIndex: PropTypes.number.isRequired,
	saveAnswer: PropTypes.func.isRequired,
	nextQuestion: PropTypes.func.isRequired,
	previousQuestion: PropTypes.func.isRequired,
	answers: PropTypes.array.isRequired,
	animation: PropTypes.string
};

export default QuizQuestions;