/* Sort array of objects by specific object property */
export function sortArrayByProperty(array, propertyName, order = 'ASC') {
	let sortedArray = array.sort(function(a, b) {
		if (order === 'ASC') return a[propertyName] - b[propertyName];
		else if (order === 'DESC') return b[propertyName] - a[propertyName];
		throw Error('order must be ASC or DESC');
	});
	return sortedArray;
};

// Randomly shuffle array
export function shuffleArray(array) {
	if (array.length < 2) {return array;}
	// https://gist.github.com/guilhermepontes/17ae0cc71fa2b13ea8c20c94c5c35dc4
	return array.map((a) => { return [Math.random(), a];})
		.sort((a, b) => { return a[0] - b[0];})
		.map((a) => { return a[1];});
}