import React from 'react';
import PropTypes from 'prop-types';
import './avatar-image.scss';

const AvatarImage = ({avatarId, viewMode, type, onClick, initialLetter}) => {
	let letter = initialLetter ? <div className="AvatarImage-letter">{initialLetter.toUpperCase()}</div> : null;
	
	let className = 'AvatarImage AvatarImage--' + viewMode + ' AvatarImage--' + avatarId;
	if (type) className = className + ' AvatarImage--' + type;
	if (onClick) className = className + ' AvatarImage--clickable';
	
	if (onClick) return <div className={className} onClick={() => {onClick();}}>{letter}</div>;
	return <div className={className}>{letter}</div>;
};

AvatarImage.defaultPropTypes = {
	viewMode: 'portrait'
};

AvatarImage.propTypes = {
	avatarId: PropTypes.number.isRequired,
	viewMode: PropTypes.string,
	type: PropTypes.string,
	onClick: PropTypes.func,
	initialLetter: PropTypes.string
};

export default AvatarImage;