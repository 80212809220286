import React from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts, avatarUiTexts} from 'data/ui-texts';
import AvatarImage from 'components/avatar-image/avatar-image';
import AvatarStatsController from 'components/avatar-stats/avatar-stats-controller';
import Button from 'components/button/button';
import './challenge-header.scss';

const ChallengeHeader = (props) => {
	let {
		showAvatarInfo,
		requiredActionsSelected,
		avatar, 
		stats,
		statsColors,
		goal, 
		energy,
		initialEnergy,
		actionId,
		requiredActions,
		handleGoBack,
		challengeFinished,
		toggleAvatarInfo
	} = props;


	return (
		<div className="ChallengeHeader">
			<Button viewMode="landscape" class="goBack2" onClick={handleGoBack} />
			<AvatarImage viewMode="landscape" avatarId={avatar.id} type="challenge"/>
			<div className={'ChallengeHeader-avatarBonusInfo' + (showAvatarInfo ? ' show' : '')}>
				<span>{avatar.description}</span>
			</div>
			<div 
				className="ChallengeHeader-avatarInfo" 
				onMouseOver={() => {toggleAvatarInfo(true);}}
				onMouseOut={() => {toggleAvatarInfo(false);}} 
			>
				<div className="ChallengeHeader-avatarInfoText">
					<span>{avatar.name}</span>
					<div><strong>{avatarUiTexts.age}:</strong> {avatar.age}</div>
					<div><strong>{avatarUiTexts.education}:</strong> {avatar.education}</div>
					<div><strong>{avatarUiTexts.interests}:</strong> {avatar.interests}</div>		
				</div>					
			</div>
			<div className="ChallengeHeader-avatarStats" aria-hidden={actionId !== null}>
				<AvatarStatsController 
					viewMode="landscape"
					type="challenge" 
					avatar={avatar} 
					stats={stats} 
					statsColors={statsColors}
					energy={energy}
					initialEnergy={initialEnergy}
				/>
			</div>
			<div className={'ChallengeHeader-goal' + 
					(challengeFinished ? ' finished' : (requiredActionsSelected ? ' green' : ''))}>
				<div className="ChallengeHeader-goalTitle">
					<span>{generalUiTexts.goal}:</span>
					<span>{goal.title}</span>
				</div>
				<div className="ChallengeHeader-goalRequiredActions">
					{requiredActions.map((action, index) => {
						let className = 'ChallengeHeader-goalRequiredAction' + (action.isSelected ? ' selected' : '');
						return <div key={index} className={className}><span>{action.title}</span></div>;
					})}
				</div>
			</div>
		</div>
			
	);
};



ChallengeHeader.propTypes = {
	showAvatarInfo: PropTypes.bool.isRequired,
	requiredActionsSelected: PropTypes.bool.isRequired,
	avatar: PropTypes.object.isRequired,
	stats: PropTypes.object.isRequired,
	statsColors: PropTypes.object.isRequired,
	goal: PropTypes.object.isRequired,
	energy: PropTypes.number.isRequired,
	initialEnergy: PropTypes.number,
	actionId: PropTypes.string,
	requiredActions: PropTypes.array.isRequired,
	handleGoBack: PropTypes.func.isRequired,
	challengeFinished: PropTypes.bool.isRequired,
	toggleAvatarInfo: PropTypes.func.isRequired
};

export default ChallengeHeader;