import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useSprings, animated, interpolate} from 'react-spring';
import './carousel-landscape.scss';

const CarouselLandscape = ({panCards, type, cards, Card, initialIndex, setCurrentIndex, handleSelectCard}) => {
	/* Configure layout */
	const windowWidthScale = 0.28;
	let windowWidth = window.innerWidth;

	let appElement = document.getElementById('appWrap');
	if (appElement && appElement.getBoundingClientRect() && appElement.getBoundingClientRect().width) {
		windowWidth = appElement.getBoundingClientRect().width;
	}

	/* References to current card */
	const index = React.useRef(initialIndex);
	let [focusIndex, setFocusIndex] = useState(initialIndex);

	let [showCardBack, setShowCardBack] = useState(false);

	const toggleShowCardBack = (value) => {
		setShowCardBack(value);
	};

	/* Pan cards */
	const initialPosition = (i) => {
		let offsetIndex = cards.length - 2;
		let scale = 0.9;
		let x = 0;
		let display = 'none';

		if (i === offsetIndex) {
			display = 'flex';
		}
		if (i === (offsetIndex - 1)) {
			x = - (windowWidth * windowWidthScale);
			display = 'flex';
		}
		if (i === (offsetIndex + 1)) {
			x = (windowWidth * windowWidthScale);
			display = 'flex';
		}
		if (i === 0) {
			x = 2 * (windowWidth * windowWidthScale);
			display = 'flex';
		}
		if (i === 1) {
			x = 3 * (windowWidth * windowWidthScale);
			display = 'flex';
		}
		return { x: x, scale: scale, display: display};
	};

	/* Position cards */
	const position = (i) => {
		let scale = 0.9;
		let x = 0;
		let display = 'none';

		/* Center card */
		if (i === index.current) {
			scale = 1;
			x = 0;
			display = 'flex';
		}

		/* Left cards */
		for (let j = 1; j <= 3; j++) { 
			if (index.current - j < 0) {
				if (i === cards.length + (index.current - j)) {
					x = - j * (windowWidth * windowWidthScale);
					display = (j === 3 ? 'none' : 'flex');
				}
			} else {
				if (i === index.current - j) {
					x = - j * (windowWidth * windowWidthScale);
					display = (j === 3 ? 'none' : 'flex');
				}
			}
		}
		/* Right cards */
		for (let j = 1; j <= 3; j++) { 
			if (index.current + j > cards.length - 1) {
				if (i === (index.current + j) - cards.length) {
					x = j * (windowWidth * windowWidthScale);
					display = (j === 3 ? 'none' : 'flex');
				}
			} else {
				if (i === index.current + j) {
					x = j * (windowWidth * windowWidthScale);
					display = (j === 3 ? 'none' : 'flex');
				}
			}
		}

		return { x: x, scale: scale, display: display};
	};

	/* Interpolates scale into a css transform */
	const trans = (s) => {return `scale(${s})`;};

	/* Create springs */
	const to = (i) => {return {...position(i)};};
	let from = to;
	if (panCards) from = (i) => {return {...initialPosition(i)};};
	const [springProps, set] = useSprings(cards.length, (i) => {return {...to(i), from: from(i) };});

	/* Navigate with prev/next buttons */
	const goToNextOrPrevCard = (direction) => {
		const dir = (direction === 'prev' ? 1 : -1);
		let currentIndex = index.current - dir;
		if (currentIndex < 0) currentIndex = cards.length - 1;
		if (currentIndex > (cards.length - 1)) currentIndex = 0;
		setFocusIndex(currentIndex);
		index.current = currentIndex;		
		set((i) => {
			let positionObj = position(i);
			const x = positionObj.x;
			const scale = (i === index.current ? 1 : 0.9);
			return { x: x, scale: scale, display: positionObj.display};
		});
		if (setCurrentIndex) setCurrentIndex(index.current);
	};

	/* Navigate by clicking card */
	const goToCard = (cardIndex) => {
		setFocusIndex(cardIndex);
		index.current = cardIndex;		
		set((i) => {
			let positionObj = position(i);
			const x = positionObj.x;
			const scale = (i === index.current ? 1 : 0.9);
			return { x: x, scale: scale, display: positionObj.display};
		});
		setShowCardBack(false);
		if (setCurrentIndex) setCurrentIndex(index.current);
	};



	return (
		<div className={'CarouselLandscape CarouselLandscape--' + type}>
			{springProps.map(({ x, display, scale}, i) => {
				return (
					<animated.div
						key={i}
						className="CarouselLandscape-cardWrap"
						style={{
							display,
							transform: x.interpolate((x) => {return `translate3d(${x}px,0,0)`;})
						}}>
						<animated.div 
							className="CarouselLandscape-card" 
							style={{transform: interpolate([scale], trans)}}
							onClick={() => {
								if (focusIndex !== i) goToCard(i);
							}}
						>
							<Card 
								viewMode="landscape"
								ariaHidden={(focusIndex !== i)}
								showCardBack={showCardBack}
								card={cards[i]}
								handleSelectCard={handleSelectCard}
								toggleShowCardBack={toggleShowCardBack}
							/>
						</animated.div>
					</animated.div>
				);
			})}
			<div onClick={() => {goToNextOrPrevCard('prev');}} className="CarouselLandscape-prevBtn" />
			<div onClick={() => {goToNextOrPrevCard('next');}} className="CarouselLandscape-nextBtn" />
		</div>				
	);
};

CarouselLandscape.defaultProps = {
	panCards: false,
	initialIndex: 0
};

CarouselLandscape.propTypes = {
	panCards: PropTypes.bool,
	type: PropTypes.string.isRequired,
	cards: PropTypes.array.isRequired,
	Card: PropTypes.func.isRequired,
	initialIndex: PropTypes.number,
	setCurrentIndex: PropTypes.func,
	handleSelectCard: PropTypes.func.isRequired,
};

export default CarouselLandscape;