import React from 'react';
import PropTypes from 'prop-types';
import './tutorial-icon.scss';

const TutorialIcon = ({step, numberOfSelected, showTutorialStep}) => {
	return (
		<div 
			onClick={() => {showTutorialStep(step, numberOfSelected);}} 
			className={'TutorialIcon TutorialIcon--' + step}
		/>
	);
};

TutorialIcon.propTypes = {
	step: PropTypes.string.isRequired,
	numberOfSelected: PropTypes.number.isRequired,
	showTutorialStep: PropTypes.func.isRequired
};

export default TutorialIcon;