import React from 'react';
import PropTypes from 'prop-types';
import {clearAllBodyScrollLocks, disableBodyScroll} from 'body-scroll-lock';
import {DndProvider} from 'react-dnd';
import TouchBackend from 'react-dnd-touch-backend';
import {sortArrayByProperty} from 'helpers/array-helper';
import {dummyAction} from 'data/actions-data';
// import {tutorialData} from 'data/tutorial-data';
import {popupsData} from 'data/popups-data';
import {statsData} from 'data/stats-data';
import {generalUiTexts} from 'data/ui-texts';
import {
	getRequiredActions,
	getAvailableActions,
	calculateAvailableEnergy, 
	calculateChanceOfFailure,
	getColorsOfSelectedActions,
	getUnmetRequirements
} from 'helpers/challenge-helper';
import {getRequiredStats, calculateStats, getStatsColors} from 'helpers/stats-helper';
import Button from 'components/button/button';
import TutorialIcon from 'components/tutorial/tutorial-icon';
import Challenge from './challenge';
import AvatarInfoController from 'components/avatar-info/avatar-info-controller';

class ChallengeController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			draggingItemId: null,
			isMoving: false,
			isBuying: false,
			showAvatarInfo: false,
			showGoalInfo: false,
			actionId: null,
			mouseCoordinates: null,
			animateDropActionId: null,
			challengeStatus: 'playing',
			initialActions: [],
			initialSelected: [],
			actions: [],
			selected: [],
			actionsScrollPos: 0,
			selectedScrollPos: 0,
			challengeFinished: false
		};
		this.toggleBodyScroll = this.toggleBodyScroll.bind(this);
		this.toggleAvatarInfo = this.toggleAvatarInfo.bind(this);
		this.toggleGoalInfo = this.toggleGoalInfo.bind(this);
		this.toggleActionInfo = this.toggleActionInfo.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
		this.handleDragStart = this.handleDragStart.bind(this);
		this.handleDragEnd = this.handleDragEnd.bind(this);
		this.handleStartChallenge = this.handleStartChallenge.bind(this);
		this.showStartChallengeErrorPopup = this.showStartChallengeErrorPopup.bind(this);
		this.showStartChallengeConfirmPopup = this.showStartChallengeConfirmPopup.bind(this);
		this.runChallenge = this.runChallenge.bind(this);
		this.challengeCompletedPopup = this.challengeCompletedPopup.bind(this);
		this.challengeFailedPopup = this.challengeFailedPopup.bind(this);
		this.resetChallenge = this.resetChallenge.bind(this);
		this.chanceActionSuccess = this.chanceActionSuccess.bind(this);
		this.timeout = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		/* Disable bodyscroll */
		this.toggleBodyScroll(true);
		
		/* Get required stats, required actions and available actions for the selected goal */
		let requiredStats = getRequiredStats(this.props.goal);
		let availableActions = getAvailableActions(this.props.goal, this.props.avatar, requiredStats);
		availableActions.forEach((action, index) => {action.index = index; action.initialIndex = index;});
		let selectedActions = [];

		/* Include dummy action to avoid weird DnD bug */
		availableActions.push({...dummyAction, id: 'dummy-1', 
			index: availableActions.length, initialIndex: availableActions.length});
		selectedActions.push({...dummyAction, id: 'dummy-2', index: 0, initialIndex: 0});


		/* Show part 1 of the tutorial */
		if (this.props.tutorialStatus.part1Seen !== true) {
			this.timeout = setTimeout(() => {this.props.showTutorialStep('part1', 0);}, 500);
		}

		this.setState({
			initialActions: JSON.parse(JSON.stringify(availableActions)),
			initialSelected: JSON.parse(JSON.stringify(selectedActions)),
			actions: availableActions,
			selected: selectedActions
		});
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount() {
		if (this.timeout) clearTimeout(this.timeout);
	}

	/**
	 * Toggle body scroll
	 * @param {bool} unLockActions 
	 */
	toggleBodyScroll(unLockActions) {
		clearAllBodyScrollLocks();
	
		if (unLockActions) {
			let list1 = document.querySelector('#scrollableContainer1');
			let list2 = document.querySelector('#scrollableContainer2');
			if (list1) disableBodyScroll(list1);
			if (list2) disableBodyScroll(list2);
		} else {
			let targetElement = document.querySelector('#app');
			if (targetElement) disableBodyScroll(targetElement);	
		}
	}

	/**
	 * Hide / show avatar info
	 * @param {bool} showAvatarInfo 
	 */
	toggleAvatarInfo(showAvatarInfo) {
		this.setState({showAvatarInfo: showAvatarInfo});
	}

	/**
	 * Hide / show goal info
	 * @param {bool} showGoalInfo 
	 */
	toggleGoalInfo(showGoalInfo) {
		this.setState({showGoalInfo: showGoalInfo});
	}

	/**
	 * Hide / show action info
	 * @param {string} actionId 
	 */
	toggleActionInfo(actionId, event) {
		if (actionId && event) {
			var x = event.clientX;     // Get the horizontal coordinate
			var y = event.clientY;     // Get the vertical coordinate
			this.setState({actionId: actionId, mouseCoordinates: [x, y]});
		}  else {
			this.setState({actionId: actionId});
		}
	}

	/**
	 * Scroll actions list
	 * @param {object} event 
	 */
	handleScroll(containerId, contentId, name, [value]) {
		if (containerId && contentId && name && value) {
			/* Update input state */
			this.setState({[name]: parseInt(value)});

			/* Update container scroll */
			let container = document.querySelector('#' + containerId);
			if (container) {
				let content = document.querySelector('#' + contentId);
				let scrollHeight = content.getBoundingClientRect().height - 
				container.getBoundingClientRect().height;
				let scrollTop = Math.floor(parseInt(value) * (scrollHeight / 100.));
				container.scrollTop = parseInt(scrollTop);				
			}
		}
	}

	/**
	 * User started dragging an action
	 */
	handleDragStart(id) {
		this.toggleBodyScroll(false);
		this.setState({draggingItemId: id});
	}

	/**
	 * Update state with result of drag and drop
	 * @param {object} result 
	 */
	handleDragEnd(actions, selected) {
		this.toggleBodyScroll(true);
		let itemId = this.state.draggingItemId;
		if (this.state.isMoving) return;
		this.setState({isMoving: true}, () => {
			/* Update lists */
			let newActions = JSON.parse(JSON.stringify(actions));
			newActions.forEach((action, index) => {action.initialIndex = index;	});
			newActions = sortArrayByProperty(newActions, 'index', 'ASC');
			
			let newSelected = JSON.parse(JSON.stringify(selected));
			newSelected.forEach((action, index) => {action.initialIndex = index;});
			newSelected = sortArrayByProperty(newSelected, 'index', 'ASC');
	
			/* Update actions, animate dropped action */
			this.setState({
				draggingItemId: null, 
				isMoving: false, 
				animateDropActionId: itemId, 
				actions: newActions, 
				selected: newSelected
			}, () => {
				
				/* First time there are 3 actions on the right list */
				if (this.props.tutorialStatus.part2Seen !== true && newSelected.length > 3) {
					// let selectedActions = getColorsOfSelectedActions(newSelected, this.props.avatar);
					// if (selectedActions.filter((action) => {return action.color === 'red';}).length > 0) {
					this.props.showTutorialStep('part2', newSelected.length - 1);
					// }
				}
			});
		});
	}

	/**
	 * Handle start challenge
	 */
	handleStartChallenge() {
		/* Already running challenge or game is over */
		if (this.state.isBuying || this.state.challengeStatus !== 'playing') return;

		/* Scroll both lists to top */
		this.handleScroll('scrollableContainer1', 'actions', 'actionsScrollPos', [0]);
		this.handleScroll('scrollableContainer2', 'selected', 'selectedScrollPos', [0]);

		let energy = calculateAvailableEnergy(this.props.initialEnergy, this.state.selected);
		let selectedActions = getColorsOfSelectedActions(this.state.selected, this.props.avatar);
		
		/* No actions selected */
		let startChallengeError = null;
		if (selectedActions.filter((action) => {return action.status === 'available';}).length === 0) {
			startChallengeError = 'noActionsAvailable';
		} else {
			/* Not enough energy and/or red actions on the list */
			if (energy < 0 || selectedActions.filter((action) => {return action.color === 'red';}).length > 0) {
				if (energy < 0 && selectedActions.filter((action) => {return action.color === 'red';}).length > 0) {
					startChallengeError = 'notEnoughEnergyAndRedActions';
				} else {
					if (energy < 0) {
						startChallengeError = 'notEnoughEnergy';
					} else {
						startChallengeError = 'redActions';
					}
				}
			/* Not all required actions selected */
			} else {
				let requiredActions = getRequiredActions(this.props.goal, selectedActions);
				if (requiredActions.filter((action) => {return !action.isSelected;}).length > 0) {
					startChallengeError = 'redGoal';
				}
			}
		}
		if (startChallengeError) {
			this.showStartChallengeErrorPopup(startChallengeError);
			return;
		}
	
		/* All ok, confirm start challenge */
		let numberOfRiskyActions = selectedActions.filter((action) => {return action.color === 'yellow';}).length;
		let startChallengeConfirm = 'confirmStartChallenge';
		if (numberOfRiskyActions === 1) startChallengeConfirm = 'confirmStartEasyChallenge';
		if (numberOfRiskyActions > 1) startChallengeConfirm = 'confirmStartHardChallenge';

		this.showStartChallengeConfirmPopup(startChallengeConfirm);

	}

	/**
	 * Show start challenge error popup
	 * @param {number} energy 
	 * @param {number} numberOfRedActions 
	 */
	showStartChallengeErrorPopup(startChallengeError) {
		let popupData = JSON.parse(JSON.stringify(popupsData[startChallengeError]));
		let popupTitle = popupData.title;
		let popupText = popupData.text;
		let popupBtns = [{text: generalUiTexts.ok, action: this.props.closePopup, actionParams: []}];
		this.props.openPopup(popupTitle, popupText, popupBtns, null, null, true, startChallengeError, null);
	}

	/**
	 * Show start challenge confirm popup
	 */
	showStartChallengeConfirmPopup(startChallengeConfirm) {
		let popupData = JSON.parse(JSON.stringify(popupsData[startChallengeConfirm]));
		let popupTitle = popupData.title;
		let popupText = popupData.text;
		let popupBtns = [
			{text: generalUiTexts.start, action: this.runChallenge, actionParams: []},
			{text: generalUiTexts.cancel, action: this.props.closePopup, actionParams: []}
		];
		this.props.openPopup(popupTitle, popupText, popupBtns, null, null, true, 'start-challenge-confirm', null);
	}

	/**
	 * Run challenge
	 */
	runChallenge() {
		this.props.closePopup();
		this.setState({
			isBuying: true,
			animateDropActionId: null
		}, () => {
			let challengeFailed = false;
			let failedAction = null;
			let selectedActions = getColorsOfSelectedActions(this.state.selected, this.props.avatar);
			let numberOfRiskyActions = selectedActions.filter((action) => {return action.color === 'yellow';}).length;
			let intervalNum = selectedActions.length - 1;
			let self = this; 

			/* Scroll to bottom if necessary */
			let scrollToBottom = false;
			let scrollToTopAtIndex = -1;
			let scrollContainer = document.querySelector('#scrollableContainer2');
			let startBtnContainer = document.querySelector('#startBtn');
			if (scrollContainer && startBtnContainer) {
				// let em = scrollContainer.getBoundingClientRect().width / 11.25;
				let em = this.getEmSize();
				let availableHeight = (scrollContainer.getBoundingClientRect().height - 
					startBtnContainer.getBoundingClientRect().height) + (0.5 * em);
				let actionHeight = 4.9 * em; // height + margin
				if (actionHeight * (selectedActions.length - 1) > availableHeight) {
					scrollToBottom = true;
					scrollToTopAtIndex = Math.floor(availableHeight / actionHeight) - 1;
				}
			}
			if (scrollToBottom) {
				scrollContainer.scrollTop = scrollContainer.scrollHeight;
			}

			/* Animate buying each action */
			this.timeout = setTimeout(async function animateAction() {
				/* Animation is done */
				if (intervalNum < -1) {
					intervalNum = null;
					if (challengeFailed) {
						self.challengeFailedPopup(failedAction);
						self.setState({challengeStatus: 'failed'});
					} else {
						self.setState({
							selected: selectedActions, 
							isBuying: false
						}, () => {
							self.challengeCompletedPopup(numberOfRiskyActions);
							self.setState({challengeStatus: 'completed'});
						});
					}
				/* Animation is running */
				} else {
					/* Animate goal tab */
					if (intervalNum < 0) {
						self.setState({challengeFinished: true});
					/* Animation action */
					} else {	
						/* Safe action */
						if (selectedActions[intervalNum].color === 'green') {
							selectedActions[intervalNum].status = 'bought';
						/* Chance action */
						} else if (selectedActions[intervalNum].color === 'yellow') {
							selectedActions[intervalNum].status = 'shaking';
							self.setState({selected: selectedActions});
							/* Calculate chance of failure */
							let chanceOfFailure = calculateChanceOfFailure(
								self.props.avatar.stats, 
								self.props.avatar.actions, 
								selectedActions[intervalNum].id, 
								selectedActions
							);
							if (chanceOfFailure > 0) {
								let randomNumber = Math.random();
								if (randomNumber < chanceOfFailure) {
								// Fail
									challengeFailed = true;
									failedAction = selectedActions[intervalNum];
									intervalNum = -2;
								} else {
								// Success
									await self.chanceActionSuccess(selectedActions, intervalNum);
								}
							}
						}
						self.setState({selected: selectedActions});
					}
					intervalNum--;
					this.timeout = setTimeout(animateAction, 600); 

					/* Scroll to top */
					if (intervalNum >= 0 && intervalNum === scrollToTopAtIndex) {
						scrollContainer.scrollTop = 0;
					}
				}
			}, 200);
		});
	}
 
	/**
	 * Chance action succeeded
	 * @param {array} selectedActions 
	 * @param {number} intervalNum 
	 */
	chanceActionSuccess(selectedActions, intervalNum) {
		return new Promise((resolve) => {
			setTimeout(() => {
				selectedActions[intervalNum].status = 'bought';
				resolve(selectedActions[intervalNum]);
			}, 700);
		});
	}

	/**
	 * Show challenge completed popup
	 * @param {number} energyLeft 
	 */
	challengeCompletedPopup(numberOfRiskyActions) {
		let numberOfIcons = Math.max(1, 3 - numberOfRiskyActions);
		let popupIcons = [];
		for (let i = 1; i <= 3; i++) {
			if (i <= numberOfIcons) {
				popupIcons.push({name:'star'});
			} else {
				popupIcons.push({name:'star-outline'});
			}
		}
		let popupData = JSON.parse(JSON.stringify(popupsData.challengeCompleted));
		let popupTitle = popupData.titles[numberOfIcons - 1];
		let popupText1 = popupData.texts[numberOfIcons - 1][0].replace(/%name%/g, this.props.avatar.name);
		let popupText2 = popupData.texts[numberOfIcons - 1][1];
		let popupText = [popupText1, popupText2];
		let popupBtns = [
			{text: generalUiTexts.playAgain, action: this.props.goToStep, actionParams: ['avatars']},
			{text: generalUiTexts.plan, action: this.props.goToStep, actionParams: ['home']}
		];
		this.props.handleChallengeCompleted();
		this.props.openPopup(popupTitle, popupText, popupBtns, null, null, false, 'challenge-completed', popupIcons);
	}

	/**
	 * Show challenge failed popup
	 * @param {object} failedAction
	 */
	challengeFailedPopup(failedAction) {
		let unmetRequirements = getUnmetRequirements(
			this.props.avatar.stats, 
			this.props.avatar.actions, 
			failedAction.id, 
			this.state.selected
		).filter((requirement) => {return requirement.type === 'stat';});
		let popupData = JSON.parse(JSON.stringify(popupsData.challengeFailed));
		let popupTitle = popupData.title;
		let stat1Name = statsData.filter((stat) => {return stat.id === unmetRequirements[0].statId;})[0].name;
		let stat2Name = (unmetRequirements.length > 1 
			? statsData.filter((stat) => {return stat.id === unmetRequirements[1].statId;})[0].name
			: null
		);

		/* 1 unmet requirement */
		let popupText = popupData.text1a;
		if (unmetRequirements.length === 1 && unmetRequirements[0].statId === 'workEthic') popupText = popupData.text1b;

		/* 1+ unmet requirements */
		if (unmetRequirements.length > 1) {
			popupText = popupData.text2a;
			if (unmetRequirements[0].statId === 'workEthic') popupText = popupData.text2b;
			if (unmetRequirements[1].statId === 'workEthic') popupText = popupData.text2c;
		}

		/* Replace placeholder texts */
		popupText =	popupText.replace(/%name%/g, this.props.avatar.name);
		popupText = popupText.replace(/%action%/g, failedAction.title);
		popupText = popupText.replace(/%pronoun%/g, (this.props.avatar.gender === 'f' ? 'hun' : 'han'));
		if (stat1Name && stat2Name) {
			popupText = popupText.replace(/%stat%/g, stat1Name);
			popupText = popupText.replace(/%stat2%/g, stat2Name);
		} else {
			if (stat1Name) {
				popupText = popupText.replace(/%stat%/g, stat1Name);
			}
		}

		let popupBtns = [
			{text: generalUiTexts.tryAgain, action: this.resetChallenge, actionParams: []},
			{text: generalUiTexts.finish, action: this.props.goToStep, actionParams: ['avatars']}
		];
		this.props.handleChallengeFailed();
		this.props.openPopup(popupTitle, popupText, popupBtns, null, null, false, 'challenge-failed');
	}

	/**
	 * Reset challenge
	 */
	resetChallenge() {
		let actions = JSON.parse(JSON.stringify(this.state.initialActions));
		let selected = JSON.parse(JSON.stringify(this.state.initialSelected));
		this.setState({
			isMoving: false,
			isBuying: false,
			challengeFinished: false,
			showGoalInfo: false,
			actionId: null,
			animateDropActionId: null,
			challengeStatus: 'playing',
			actions: actions,
			selected: selected,
		}, () => {
			this.props.closePopup();
		});
	}	

	/**
	 * Alternative way to get mouse position when dropping a DnD element
	 * @param {number} x 
	 * @param {number} y 
	 * @param {array} dropTargets 
	 */
	getDropTargetElementsAtPoint(x, y, dropTargets) {
		return dropTargets.filter((t) => {
			const rect = t.getBoundingClientRect();
			return (
				x >= rect.left &&
				x <= rect.right &&
				y <= rect.bottom &&
				y >= rect.top
			);
		});
	}

	getEmSize() {
		let em = 16;
		let appElement = document.getElementById('app');
		if (appElement && appElement.getBoundingClientRect() && appElement.getBoundingClientRect().width) {
			let windowWidth = appElement.getBoundingClientRect().width;
			let windowHeight = appElement.getBoundingClientRect().height;
			em = 16 * (windowWidth / 360);
			if (windowWidth / windowHeight > 0.5625) {
				em = 16 * (windowHeight / 640);
			}
		}

		return em;
	}

	/**
	 * Render component
	 */
	render() {
		/* Calculate available energy */
		let energy = calculateAvailableEnergy(this.props.initialEnergy, this.state.selected);

		/* Get selected actions with colors */
		let selectedActions = getColorsOfSelectedActions(this.state.selected, this.props.avatar);

		/* Calculate stats */
		let stats = calculateStats(this.props.avatar.stats, this.state.selected, true);
		let statsColors = getStatsColors(this.props.avatar.stats, this.props.avatar.actions, selectedActions);
	
		/* Check if all required actions are selected */
		let requiredActions = getRequiredActions(this.props.goal, selectedActions);
		let requiredActionsSelected = requiredActions.filter((action) => {return !action.isSelected;}).length === 0;

		/* Draggable status */
		let dragIsDisabled = (
			this.state.isMoving ||
			this.state.isBuying || 
			this.state.challengeStatus !== 'playing'
		);

		/* Placeholders & scrollbars */
		let numberOfPlaceholders = 0;
		let showActionsScrollbar = false;
		let showSelectedScrollbar = false;
		let scrollContainer1 = document.querySelector('#scrollableContainer1');
		let scrollContainer2 = document.querySelector('#scrollableContainer2');
		let startBtnContainer = document.querySelector('#startBtn');	
		if (scrollContainer1 && !this.state.isBuying) {
			// let em = scrollContainer1.getBoundingClientRect().width / 11.25;
			let em = this.getEmSize();

			let actionHeight = 4.5 * em; // height + margin
			let availableHeight = scrollContainer1.getBoundingClientRect().height;
			if (actionHeight * (this.state.actions.length - 1) > availableHeight) {
				showActionsScrollbar = true;
			} else {
				scrollContainer1.scrollTop = 0;
			}
		}
		if (scrollContainer2 && startBtnContainer && !this.state.isBuying) {
			// let em = scrollContainer2.getBoundingClientRect().width / 11.25;
			let em = this.getEmSize();
			let actionHeight = 4.9 * em; // height + margin
			let availableHeight = (scrollContainer2.getBoundingClientRect().height - 
				startBtnContainer.getBoundingClientRect().height) + (0.5 * em);
			numberOfPlaceholders = Math.ceil(availableHeight / actionHeight);
			// if (actionHeight * (this.state.selected.length - 1) > availableHeight) showSelectedScrollbar = true;
			if (actionHeight * (this.state.selected.length) > availableHeight) {
				showSelectedScrollbar = true;
				// numberOfPlaceholders = numberOfPlaceholders + 1;
			} else {
				scrollContainer2.scrollTop = 0;
			}
		}

		

		/* Buy button is active */
		let buyBtnIsActive = 
			energy >= 0 && 
			!this.state.isBuying && 
			this.state.challengeStatus === 'playing' && 
			requiredActionsSelected && 
			selectedActions.filter((action) => {return action.color === 'red';}).length === 0;

		/* Sort actions and selected */
		let actions = sortArrayByProperty(this.state.actions, 'initialIndex', 'ASC');
		let selected = sortArrayByProperty(selectedActions, 'initialIndex', 'ASC');		

		if (this.state.showAvatarInfo) {
			return (
				<AvatarInfoController 
					page="challenge"
					avatar={this.props.avatar} 
					goToStep={null} 
					toggleAvatarInfo={this.toggleAvatarInfo}
					handleRefresh={this.props.handleRefresh}
					openPopup={this.props.openPopup}
					closePopup={this.props.closePopup}
				/>
			);
		}

		/* Check if elementsFromPoint is supported */
		const elementsFromPointSupported =
			(document && (document.elementsFromPoint || document.msElementsFromPoint));

		return (
			<div aria-hidden={this.props.popupIsOpen} style={{height: '100%'}}>
				<DndProvider 
					backend={TouchBackend} 
					options={{
						enableMouseEvents: true,
						getDropTargetElementsAtPoint: !elementsFromPointSupported && this.getDropTargetElementsAtPoint
					}}
				>
					<Challenge 
						isBuying={this.state.isBuying}
						tutorialIsOpen={this.props.tutorialIsOpen}
						showGoalInfo={this.state.showGoalInfo}
						dragIsDisabled={dragIsDisabled}
						buyBtnIsActive={buyBtnIsActive}
						requiredActionsSelected={requiredActionsSelected}
						showActionsScrollbar={showActionsScrollbar}
						showSelectedScrollbar={showSelectedScrollbar}
						numberOfPlaceholders={numberOfPlaceholders}
						challengeStatus={this.state.challengeStatus}
						draggingItemId={this.state.draggingItemId}
						avatar={this.props.avatar} 
						stats={stats}
						statsColors={statsColors}
						goal={this.props.goal} 
						energy={energy}
						initialEnergy={this.props.initialEnergy}
						initialActions={this.state.initialActions}
						actionId={this.state.actionId}
						mouseCoordinates={this.state.mouseCoordinates}
						animateDropActionId={this.state.animateDropActionId}
						requiredActions={requiredActions}
						actions={actions}
						selected={selected}
						actionsScrollPos={this.state.actionsScrollPos}
						selectedScrollPos={this.state.selectedScrollPos}
						toggleBodyScroll={this.toggleBodyScroll}
						toggleAvatarInfo={this.toggleAvatarInfo}
						toggleGoalInfo={this.toggleGoalInfo}
						toggleActionInfo={this.toggleActionInfo}
						handleMoveAction={this.handleMoveAction}
						moveActionToBottomOfList={this.moveActionToBottomOfList}
						handleScroll={this.handleScroll}
						handleDragStart={this.handleDragStart}
						handleDragEnd={this.handleDragEnd}
						handleStartChallenge={this.handleStartChallenge}
						challengeFinished={this.state.challengeFinished}
					/>
				</DndProvider>
				<Button class="goBack" onClick={() => {this.props.goToStep('goals');}} />
				<TutorialIcon 
					step="tutorial" 
					numberOfSelected={selected.length - 1} 
					showTutorialStep={this.props.showTutorialStep}
				/>
			</div>
		);
	}
}

ChallengeController.propTypes = {
	popupIsOpen: PropTypes.bool.isRequired,
	tutorialIsOpen: PropTypes.bool.isRequired,
	tutorialStatus: PropTypes.object.isRequired,
	avatar: PropTypes.object.isRequired,
	goal: PropTypes.object.isRequired,
	goToStep: PropTypes.func.isRequired,
	showTutorialStep: PropTypes.func.isRequired,
	openPopup: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired,
	handleRefresh: PropTypes.func.isRequired,
	initialEnergy: PropTypes.number.isRequired,
	handleChallengeCompleted: PropTypes.func.isRequired,
	handleChallengeFailed: PropTypes.func.isRequired
};

export default ChallengeController;