import React from 'react';
import PropTypes from 'prop-types';
import {pwaInfoUiTexts} from 'data/ui-texts';
import './pwa-info.scss';

const PwaInfo = ({isIos, togglePwaInfo}) => {
	let osType = (isIos ? 'ios' : 'android');
	if (isIos && navigator.userAgent.match('CriOS')) osType = 'crios';

	let text = JSON.parse(JSON.stringify(pwaInfoUiTexts[osType]));
	text = text.replace(/%icon%/g, '<span class="PwaInfo-icon"></span>');
	
	return (
		<div className={'PwaInfo PwaInfo--' + osType} onClick={()=>{togglePwaInfo(false);}}>
			<div className="PwaInfo-text">
				<p dangerouslySetInnerHTML={{__html: text}} />
			</div>
		</div>
	);
};

PwaInfo.propTypes = {
	isIos: PropTypes.bool.isRequired,
	togglePwaInfo: PropTypes.func.isRequired
};

export default PwaInfo;