import React from 'react';
import PropTypes from 'prop-types';
import {homeUiTexts} from 'data/ui-texts';
import Logo from 'components/logo/logo';
import Button from 'components/button/button';
import FullscreenToggle from 'components/layout/fullscreen-toggle';
import './home.scss';

const Home = (props) => {
	let {
		isFullscreen,
		showFirstPartDone, 
		showResultBtnInfo, 
		viewMode,
		cachedProfile, 
		goToStep, 
		toggleFirstPartDone, 
		toggleResultBtnInfo,
		handleToggleFullscreen
	} = props;

	return (
		<React.Fragment>
			<div className={'Home Home--' + viewMode}>
				<Logo isLoading={false} viewMode={viewMode} />
				{viewMode === 'landscape' && 
					<FullscreenToggle 
						isFullscreen={isFullscreen} 
						handleToggleFullscreen={handleToggleFullscreen} 
						type='home'
					/>}
				<div className={'Home-buttons'}>
					<div className={'Home-resultBtn' + (!cachedProfile ? ' Home-resultBtn--disabled' : '')}>
						<Button 
							viewMode={viewMode}
							class="result" 
							text={homeUiTexts.result} 
							onClick={() => {
								if (cachedProfile) {
									goToStep('diploma');
								} else {
									toggleResultBtnInfo(!showResultBtnInfo);
								}
							}}
						/>
					</div>
				
					<div className="Home-partBtns">
						<Button 
							viewMode={viewMode}
							text={homeUiTexts.part1} 
							class="part1" 
							onClick={()=> {goToStep('avatars');}}
						/>
						<Button
							viewMode={viewMode}
							text={homeUiTexts.part2}
							class="part2"
							onClick={()=> {goToStep('profile');}}
						/>
					</div>
				</div>

				{showFirstPartDone && <div className="Home-firstPartDone" onClick={() => {toggleFirstPartDone(false);}}>
					<div className="Home-firstPartDoneTitle">{homeUiTexts.firstPartDoneTitle}</div>
					<div className="Home-firstPartDoneText">{homeUiTexts.firstPartDoneText}</div>	
				</div>}

				{showResultBtnInfo && <div className="Home-resultBtnInfo" onClick={() => {toggleResultBtnInfo(false);}}>
					<div className="Home-resultBtnInfoText">{homeUiTexts.resultBtnInfoText}</div>	
				</div>}

				<div className="Home-footer" />
			</div>
		</React.Fragment>
	);
};

Home.defaultProps = {
	cachedProfile: null
};

Home.propTypes = {
	isFullscreen: PropTypes.bool.isRequired,
	showFirstPartDone: PropTypes.bool.isRequired,
	showResultBtnInfo: PropTypes.bool.isRequired,
	viewMode: PropTypes.string.isRequired,
	cachedProfile: PropTypes.object,
	goToStep: PropTypes.func.isRequired,
	toggleFirstPartDone: PropTypes.func.isRequired,
	toggleResultBtnInfo: PropTypes.func.isRequired,
	handleToggleFullscreen: PropTypes.func.isRequired
};

export default Home;