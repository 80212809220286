import React from 'react';
import PropTypes from 'prop-types';
import './goals.scss';

const Goals = (props) => {
	let {
		isEditing,
		isSaving,
		goals,
		selectedGoal,
		selectGoal,
		updateGoal,
		saveGoal,
		goToPage
	} = props;

	let saveBtnClass = 'GoalsAdmin-saveBtn ' + (isSaving ? ' saving' : '');
	if (isEditing) saveBtnClass += ' editing';
	if (!isEditing) saveBtnClass += ' disabled';


	return (
		<div className="GoalsAdmin">
			<div className="GoalsAdmin-title">Rediger mål</div>
			<p className="GoalsAdmin-backBtn" onClick={() => {goToPage(null);}}>&lArr; tilbage</p>

			{/* Select goal  */}
			<form onChange={(event) => {selectGoal(event);}}>
				<select>
					{goals.map((goal, index) => {
						return (
							<option key={index} value={index}>{goal.title}</option>
						);
					})}
				</select>
			</form>


			{/* Edit goal */}
			{selectedGoal && <form className="GoalsAdmin-goal">
				<div className="GoalsAdmin-inputRow">
					<span className="GoalsAdmin-label">Energi:</span>
					<input 
						className="GoalsAdmin-input"
						name="energy"
						value={selectedGoal.energy}
						placeholder="energy"
						onChange={(event) => {updateGoal(event);}}
					/>
				</div>
				<div 
					className={saveBtnClass}
					onClick={(event) => {saveGoal(event);}}>Gem</div>
			</form>}


		</div>
	);
};

Goals.propTypes = {
	isEditing: PropTypes.bool.isRequired,
	isSaving: PropTypes.bool.isRequired,
	goals: PropTypes.array.isRequired,
	selectedGoal: PropTypes.object,
	selectGoal: PropTypes.func.isRequired,
	updateGoal: PropTypes.func.isRequired,
	saveGoal: PropTypes.func.isRequired,
	goToPage: PropTypes.func.isRequired,
};

export default Goals;