/* Check if browser is in fullscreen */
function checkIfFullscreen() {
	let isFullscreen = true;
	if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) 
				|| (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) 
				|| (document.mozFullScreen !== undefined && !document.mozFullScreen) 
				|| (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
		isFullscreen = false;
	}
	return isFullscreen;
}

/* Turn fullscreen on/off */
function toggleFullscreen(fullscreen) {
	let elem = document.documentElement;
	if (fullscreen) {
		if (elem.requestFullScreen) elem.requestFullScreen();
		else if (elem.mozRequestFullScreen) elem.mozRequestFullScreen();
		else if (elem.webkitRequestFullScreen) elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
		else if (elem.msRequestFullscreen) elem.msRequestFullscreen();
	} else {
		if (document.cancelFullScreen) document.cancelFullScreen();
		else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
		else if (document.webkitCancelFullScreen) document.webkitCancelFullScreen();
		else if (document.msExitFullscreen) document.msExitFullscreen();
	}

	// if (document.documentElement.requestFullscreen) {
	// 	document.documentElement.requestFullscreen();
	// } else if (document.documentElement.webkitRequestFullscreen) {
	// 	document.documentElement.webkitRequestFullscreen();
	// } else if (document.documentElement.mozRequestFullScreen) {
	// 	document.documentElement.mozRequestFullScreen();
	// } else if (document.documentElement.msRequestFullscreen) {
	// 	document.documentElement.msRequestFullscreen();
	// }
}

export {
	checkIfFullscreen,
	toggleFullscreen
};